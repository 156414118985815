<template>
  <div class="overallStatistics">
    <div class="main-background">
      <div df fdr fjb fic style="margin-bottom: 8px">
        <a-space>
          <a-select
            show-search
            :filter-option="filterOption"
            mode="multiple"
            :placeholder="$t('views.admin.message.selectRoom')"
            style="width: 360px"
            v-model="filter.devices"
          >
            <a-select-option
              v-for="item in deviceList"
              :key="item.index"
              :value="item.index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>

          <a-button @click="load({})">{{$t('views.admin.message.search')}}</a-button>

          <a-button
            style="margin: 4px 0px"
            type="primary"
            :disabled="!hasSelected"
            :loading="loading"
            icon="redo"
            @click="refresh"
          >
            {{$t('views.admin.message.refresh')}}
          </a-button>
        </a-space>

        <div>
          <a-popconfirm
            v-if="levelCheck === '1' ? false : true"
            @confirm="lumpDelete"
            :title="$t('views.admin.message.askDeleteMsg')"
            ok-text="Yes"
            cancel-text="No"
          >
            <a-button
              style="margin: 4px 5px"
              type="danger"
              :disabled="!hasSelected"
              :loading="loading"
              icon="delete"
            >
              {{$t('views.admin.message.delete')}}
            </a-button>
          </a-popconfirm>
          <a-button
            v-if="levelCheck === '1' ? false : true"
            @click="messageEdit(false)"
            >{{$t('views.admin.message.sendMsg')}}</a-button
          >
        </div>
      </div>

      <div df fdc>
        <a-radio-group
          v-model="filter.type"
          button-style="solid"
          @change="load({})"
        >
          <a-radio-button :value="99">{{$t('views.admin.message.all')}}</a-radio-button>
          <a-radio-button v-for="(value, key) in types" :key="key" :value="key">
            {{ value }}
          </a-radio-button>
        </a-radio-group>
      </div>

      <div df fdc style="margin: 8px 0px">
        <a-radio-group
          v-model="filter.isRead"
          button-style="solid"
          @change="load({})"
        >
          <a-radio-button value>{{$t('views.admin.message.all')}}</a-radio-button>
          <a-radio-button
            v-for="(value, key) in isReadList"
            :key="key"
            :value="key"
          >
            {{ value }}
          </a-radio-button>
        </a-radio-group>
      </div>

      <div df fje fic>
        <div
          style="
            margin-bottom: 10px;
            font-size: 14px;
            color: #db0000;
            font-weight: bold;
          "
        >
          {{$t('views.admin.message.msgNotice')}}
        </div>
      </div>

      <a-table
        rowKey="index"
        :row-selection="
          levelCheck === '1'
            ? null
            : {
                selectedRowKeys: selectedRowKeys,
                onSelect: onSelectChange,
                onSelectAll: onSelectAllChange,
              }
        "
        :columns="columns"
        :data-source="data"
        @change="load"
        :loading="loading"
        :pagination="pagination"
      >
        <template slot="roomNumber" slot-scope="_, record">
          <a @click="messageEdit(record.index)">
            {{ record.roomNumber }}
          </a>
        </template>

        <template slot="messageTitle" slot-scope="_, record">
          <a @click="messageEdit(record.index)">
            {{ record.title }}
          </a>
        </template>

        <template slot="isRead" slot-scope="_, record">
          {{ record.isRead === 0 ? "X" : "O" }}
        </template>

        <template slot="createDate" slot-scope="_, record">
          {{ moment(record.createDate).format("YYYY-MM-DD HH:mm") }}
        </template>
      </a-table>
    </div>

    <a-modal
      class="add-message-modal"
      :title="messageData.index ? messageData.roomNumber : $t('views.admin.message.addMsg')"
      :visible="visible"
      :afterClose="afterClose"
      :closable="false"
    >
      <a-select
        v-if="messageData.index ? false : true"
        mode="multiple"
        :placeholder="$t('views.admin.message.selectMsgRoom')"
        style="width: 100%"
        v-model="messageData.deviceList"
        @change="allSelcetChange"
        show-search
        :filter-option="filterOption"
      >
        <a-select-option :value="0"> {{$t('views.admin.message.all')}} </a-select-option>
        <a-select-option
          v-for="item in deviceList"
          :key="item.index"
          :value="item.index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>

      <a-select
        v-if="messageData.index ? false : true"
        style="width: 100%; margin-top: 12px"
        :placeholder="$t('views.admin.message.selectMsgType')"
        v-model="messageData.type"
      >
        <a-select-option v-for="(item, key) in types" :key="key" :value="key">
          {{ item }}
        </a-select-option>
      </a-select>

      <div
        v-if="messageData.index ? true : false"
        style="
          width: 100%;
          margin-top: 12px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          padding: 4px 11px;
        "
      >
        {{ types[messageData.type] }}
      </div>

      <div style="margin-top: 12px" v-if="messageData.type === '2'">
        <div>
          <a-date-picker
            v-model="messageData.reservation"
            style="margin-right: 12px"
          />

          <a-time-picker format="HH:mm" v-model="messageData.reservation">
          </a-time-picker>
        </div>
        <div style="font-size: 12px; font-weight: bold; margin-top: 3px">
          {{
            messageData.index ? "" : $t('views.admin.message.selectAlarmTime')
          }}
        </div>
      </div>

      <div style="margin-top: 12px">
        <a-input
          :placeholder="$t('views.admin.message.writeTitle')"
          v-model="messageData.title"
        />
      </div>

      <!-- <a-textarea
        style="margin-top: 12px"
        placeholder="메세지 내용을 입력해주세요. 최대 100자만 입력이 가능합니다."
        v-model="messageData.message"
        :maxLength="100"
        :rows="4"
      /> -->

      <quill-editor
        style="margin-top: 12px; height: 350px"
        ref="myQuillEditor"
        :options="editorOption"
        v-model="messageData.message"
      />

      <!-- <div df fdr fjc>
        <div
          style="
            background-color: #5089de;
            border-radius: 16px;
            padding: 4px 10px 4px 10px;
            margin-top: 16px;
            color: #ffffff;
          "
        >
          {{ messageData.message.length }}/100
        </div>
      </div> -->

      <template slot="footer">
        <a-button @click="handleOk">
          {{ messageData.index ? $t('views.admin.message.close') : $t('views.admin.message.cancel') }}
        </a-button>
        <a-button
          v-if="messageData.index ? false : true"
          @click="messageSave"
          type="primary"
          :loading="loading"
        >
          {{$t('views.admin.message.send')}}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import { Filter } from "@/utils/filterValue";
import {MessageColumns} from "@/utils/columns/admin/message";
import { GmtConversion, GmtConversionReverse } from "@/utils/gmtConversion";

import MessageApi from "@/api/admin/message";

import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {i18n} from "@/i18n";

@Component({
  components: { quillEditor },
})
export default class Message extends Vue {
  levelCheck = localStorage.getItem("level");
  timezone = localStorage.getItem("timezone");
  moment = moment;

  loading = false;
  visible = false;
  types = {
    0: i18n.tc('views.admin.message.common'),
    1: i18n.tc('views.admin.message.emerg'),
    2: i18n.tc('views.admin.message.alarm'),
  };

  isReadList = {
    0: i18n.tc('views.admin.message.unread'),
    1: i18n.tc('views.admin.message.read'),
  };

  @Filter
  filter = {
    devices: [],
    type: 99,
    isRead: "",
  };

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  data = [];
  deviceList = [];

  messageData = {
    deviceList: [],
    title: "",
    message: "",
    type: "0",
    reservation: moment().format("YYYY-MM-DD HH:mm"),
  };

  selectedRowKeys = [];
  totalDeleteKeys = [];

  get columns() {
    const colums =MessageColumns();
    return colums;
  }

  hasSelected() {
    return this.selectedRowKeys.length > 0;
  }

  async mounted() {
    await this.load({});
    await this.deviceLoad();
  }

  async load({ current = 1, pageSize = 10 }) {
    try {
      this.loading = true;

      const options = {
        current,
        pageSize,
        filter: {
          devices: this.filter.devices,
          type: Number(this.filter.type),
          isRead: this.filter.isRead ? Number(this.filter.isRead) : null,
        },
      };

      const { data, option } = await MessageApi.List(
        this.$route.params.key,
        options,
      );

      this.data = data.list.map(({ createDate, ...item }) => {
        return {
          ...item,
          createDate: GmtConversion(createDate, this.timezone),
        };
      });

      console.log(this.data);

      const total = data.total;
      this.totalDeleteKeys = option;

      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
        total,
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deviceLoad() {
    try {
      this.loading = true;

      const { data } = await MessageApi.DeviceList(this.$route.params.key);

      this.deviceList = data.map((item) => ({
        index: item.index,
        name: item.room?.number,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  onSelectChange(record, selected, selectedRows) {
    if (selected) {
      this.selectedRowKeys = [...this.selectedRowKeys, record.index];
    } else {
      this.selectedRowKeys = this.selectedRowKeys.filter(
        (item) => item !== record.index,
      );
    }
  }

  onSelectAllChange(selected, selectedRows, changeRows) {
    if (selected) {
      this.selectedRowKeys = this.totalDeleteKeys;
    } else {
      this.selectedRowKeys = [];
    }
  }

  async lumpDelete() {
    try {
      if (this.selectedRowKeys.length > 0) {
        await MessageApi.Delete(this.selectedRowKeys);
        await this.load({});
      } else {
        this.$message.warning($i18n.tc('views.admin.message.selectDelMsg'));
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async refresh() {
    await this.load({});
  }

  async messageEdit(item) {
    try {
      this.visible = true;

      if (!item) {
        return;
      }

      const { data } = await MessageApi.Detail(item);

      this.messageData = {
        ...data,
        type: String(data.type),
        reservation: GmtConversion(data.reservationDate, this.timezone).format(
          "YYYY-MM-DD HH:mm",
        ),
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async messageSave() {
    try {
      this.loading = true;

      if (
        this.messageData.deviceList.length <= 0 ||
        !this.messageData.message ||
        !this.messageData.title
      ) {
        return this.$message.warning(i18n.tc('views.admin.message.writeData'));
      }

      let data = {
        deviceList: this.messageData.deviceList,
        title: this.messageData.title,
        message: this.messageData.message,
        type: Number(this.messageData.type),
        reservation: GmtConversionReverse(
          this.messageData.reservation,
          this.timezone,
        ),
      };

      if (data.deviceList.some((a) => a === 0)) {
        data.deviceList = this.deviceList.map((item) => item.index);
      }

      await MessageApi.Create(this.$route.params.key, data);
      await this.load({});

      this.handleOk();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  afterClose() {
    this.messageData = {
      deviceList: [],
      title: "",
      message: "",
      type: "0",
      reservation: moment().format("YYYY-MM-DD HH:mm"),
    };
  }

  handleOk(e) {
    this.visible = false;
  }

  allSelcetChange(value) {
    if (value.some((a) => a === 0)) {
      this.messageData.deviceList = [0];
    }
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  editorOption = {
    placeholder: i18n.tc('views.admin.message.writeNotice'),
    modules: {
      imageDrop: true,
      imageResize: true,
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
        ],
      },
    },
    theme: "snow",
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };
}
</script>
<style lang="scss">
.add-message-modal {
  .ant-modal-body {
    height: 600px;
  }
}
</style>
