<template>
  <div class="SummaryStatistics">
    <div class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.summaryStatistics.title') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div df flex fdc class="main-background">
      <div df fdr fjb>
        <div>
          <a-select
              default-value="date"
              style="width: 220px;margin-right:16px"
              v-model="totalType"
              @change="totalTypeSwitch(true)"
          >
            <a-select-option
                v-for="item in types"
                :key="item.value"
                :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>

          <a-radio-group
              v-if="totalType !== 'service'"
              name="radioGroup"
              v-model="isAmount"
              @change="totalTypeSwitch(false)"
          >
            <a-radio :value="true">
              {{ $t('views.master.summaryStatistics.sale') }}
            </a-radio>
            <a-radio :value="false">
              {{ $t('views.master.summaryStatistics.saleCnt') }}
            </a-radio>
          </a-radio-group>

          <hotel-list
              v-if="totalType === 'service'"
              v-model="hotelIndex"
              @change="totalTypeSwitch(false)"
          />
        </div>
        <a-button @click="excelDownLoad">{{ $t('views.master.summaryStatistics.downloadExcel') }}</a-button>
      </div>

      <div df fdr fic style="margin:16px 0px ">
        <a-radio-group
            default-value="0"
            button-style="solid"
            v-model="periodType"
        >
          <a-radio-button value="2">
            {{ $t('views.master.summaryStatistics.selectPeriod') }}
          </a-radio-button>
          <a-radio-button value="0">
            {{ $t('views.master.summaryStatistics.selectMonth') }}
          </a-radio-button>
          <a-radio-button value="1">
            {{ $t('views.master.summaryStatistics.selectYear') }}
          </a-radio-button>
        </a-radio-group>

        <div df fic fjc style="margin-left:16px;">
          <a-month-picker
              v-if="periodType === '0'"
              style="width:120px"
              placeholder="Select month"
              v-model="selectedMonth"
          >
            <template slot="monthCellContentRender" slot-scope="date">
              <div>{{ `${moment(date).format("M")}` }}</div>
            </template>
          </a-month-picker>

          <a-select
              v-if="periodType === '1'"
              style="width: 100px;"
              :placeholder="$t('views.master.summaryStatistics.selectYear2')"
              v-model="selectedYear"
          >
            <a-select-option v-for="item in yearList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>

          <a-space v-if="periodType === '2'" df fdr>
            <a-date-picker
                v-model="periodDate.startDate"
                :disabled-date="disabledStartDate"
            />
            <span>~</span>
            <a-date-picker
                v-model="periodDate.endDate"
                :disabled-date="disabledEndDate"
            />
          </a-space>

          <a-button @click="totalTypeSwitch(false)" style="margin-left:8px"
          >{{$t('views.master.summaryStatistics.search')}}
          </a-button
          >
        </div>
      </div>

      <div style="padding:10px 0px" v-if="totalType !== 'service'">
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
              :indeterminate="indeterminate"
              :checked="checkAll"
              @change="onCheckAllChange"
          >
            {{ $t('views.master.summaryStatistics.all') }}
          </a-checkbox>
        </div>
        <br/>
        <a-checkbox-group
            :value="checkedList"
            :options="baseServices"
            @change="onChange"
        />
      </div>

      <div df flex fdc style="margin-top:12px">
        <div
            df
            fdr
            fje
            style="color:#c2c2c2;margin-bottom:4px"
            v-if="!loading && (totalType === 'date' || totalType === 'time')"
        >
          {{ isAmount ? $t('views.master.summaryStatistics.unit1') : $t('views.master.summaryStatistics.unit2') }}
        </div>
        <div v-if="loading" df fjc fic style="height:400px">
          <a-icon type="loading" style="color:blue"/>
        </div>

        <div v-if="!loading && (totalType === 'date' || totalType === 'time')">
          <div class="title-style" df fdr>
            <div v-for="(item, key) in option" :key="key" class="title-content">
              <div>
                {{ item }}
              </div>
            </div>
          </div>

          <div df fdc>
            <div
                df
                fic
                class="content-style"
                v-for="(item, key) in data"
                :key="key"
            >
              <div df fic flex style="font-weight:800;">
                {{ item.title }}
              </div>
              <div df flex fic fje>
                {{
                  isAmount
                      ? Number(item.totalAmount).toLocaleString()
                      : Number(item.totalCount).toLocaleString()
                }}
              </div>
              <div
                  df
                  flex
                  fic
                  fje
                  v-for="(item, key) in item.contents"
                  :key="key"
              >
                <div>
                  {{
                    isAmount
                        ? Number(item.amount).toLocaleString()
                        : Number(item.count).toLocaleString()
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading && totalType === 'service'">
          <div class="title-style" df fdr>
            <div class="title-content" v-for="(item, key) in option" :key="key">
              <div>
                {{ item }}
              </div>
              <div
                  style="margin-left:8px;font-size:11px;color:#919090;margin-top:2px"
              >
                {{
                  item === $t('views.master.summaryStatistics.sale')
                      ? $t('views.master.summaryStatistics.unit1')
                      : item === $t('views.master.summaryStatistics.saleCnt2')
                      ? $t('views.master.summaryStatistics.unit2')
                      : ""
                }}
              </div>
              <a
                  v-if="item === $t('views.master.summaryStatistics.sale') || item === $t('views.master.summaryStatistics.saleCnt2')"
                  @click="sortChange(item)"
              >
                <a-icon
                    :style="{
                    marginLeft: '10px',
                    color:
                      item === $t('views.master.summaryStatistics.sale') && sorting === 0
                        ? '#1890FF'
                        : item === $t('views.master.summaryStatistics.saleCnt2') && sorting === 1
                        ? '#1890FF'
                        : '#919090',
                  }"
                    type="caret-down"
                />
              </a>
            </div>
          </div>

          <div df fdc>
            <div
                df
                fic
                class="content-style"
                v-for="(item, key) in data"
                :key="key"
            >
              <div df fic flex style="font-weight:800;">
                {{ item.name }}
              </div>
              <div df flex fic fje>
                {{ Number(item.amount).toLocaleString() }}
              </div>
              <div df flex fic fje>
                {{ Number(item.count).toLocaleString() }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import moment from "moment";
import XLSX from "xlsx";

import HotelList from "@/components/HotelList.vue";

import StatisticsApi from "@/api/shared/statistics";
import MStatisticsApi from "@/api/master/statistics";
import HotelApi from "@/api/master/hotel";

import Colors from "@/utils/colors";
import {
  GmtConversionReverse,
  EndDate,
  StartDate,
} from "@/utils/gmtConversion";

import {i18n} from "@/i18n";

@Component({
  components: {HotelList},
})
export default class SummaryStatistics extends Vue {
  moment = moment;
  Colors = Colors;

  hotelIndex = "";
  loading = false;

  types = [
    {value: "service", name: i18n.tc("views.master.summaryStatistics.selectByService")},
    {value: "date", name: i18n.tc('views.master.summaryStatistics.selectByPeriod')},
    {value: "time", name: i18n.tc('views.master.summaryStatistics.selectByHour')},
  ];

  excelData = [];
  excelDate = "";
  excelTitle = "";

  isAmount = true;
  selectedMonth = moment().format("YYYY-MM");
  selectedYear = moment().format("YYYY");
  yearList = [];

  totalType = "service";
  periodType = "2";

  periodDate = {
    startDate: moment(),
    endDate: moment(),
  };

  baseServices = [];
  checkedList = [];
  totalCheckedList = [];
  indeterminate = false;
  checkAll = true;

  sorting = 0;

  data = [];
  option = [];

  disabledStartDate(value) {
    return (
        value.isAfter(moment()) ||
        (this.periodDate.endDate ? value.isAfter(this.periodDate.endDate) : false)
    );
  }

  disabledEndDate(value) {
    return (
        value.isAfter(moment()) ||
        (this.periodDate.startDate
            ? value.isBefore(this.periodDate.startDate)
            : false)
    );
  }

  async mounted() {
    await this.yearListLoad();
    await this.loadBasicService();
    await this.totalTypeSwitch(false);
  }

  async yearListLoad() {
    try {
      const {data} = await StatisticsApi.YearList();

      this.yearList = data;
    } catch {
    }
  }

  async loadBasicService() {
    try {
      this.loading = true;

      const list = await HotelApi.BasicServiceList({isCall: true});

      this.baseServices = list.map(item => ({
        value: item.code,
        label: item.name,
      }));

      this.baseServices.push({
        value: "SV_DELIVERY",
        label: i18n.tc('views.master.summaryStatistics.roomDelivery'),
      });

      this.checkedList = this.baseServices.map(item => item.value);
      this.totalCheckedList = this.baseServices.map(item => item.value);
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.summaryStatistics.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async onChange(checkedList) {
    if (checkedList.length === 0) {
      this.$message.warning(i18n.tc('views.master.summaryStatistics.selectServiceMsg'));
      return;
    }

    this.checkedList = checkedList;
    this.indeterminate =
        !!checkedList.length && checkedList.length < this.baseServices.length;
    this.checkAll = checkedList.length === this.baseServices.length;
  }

  onCheckAllChange(e) {
    Object.assign(this, {
      checkedList: e.target.checked
          ? this.totalCheckedList
          : this.totalCheckedList.length > 0
              ? [this.totalCheckedList[0]]
              : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }

  async sortChange(value) {
    this.sorting = value === i18n.tc('views.master.summaryStatistics.sale') ? 0 : 1;

    await this.serviceStatisticsLoad();
  }

  totalTypeSwitch(bool) {
    if (bool) {
      this.periodType = "2";
      this.hotelIndex = "";
      this.selectedMonth = moment().format("YYYY-MM");
      this.sorting = 0;
    }

    switch (this.totalType) {
      case "date":
        this.dateStatisticsLoad();
        break;

      case "time":
        this.timeStatisticsLoad();
        break;

      case "service":
        this.serviceStatisticsLoad();
        break;
    }
  }

  async dateStatisticsLoad() {
    try {
      this.loading = true;

      const data = await MStatisticsApi.DateStatistics(this.periodTypeSwitch);

      this.data = data.data;
      this.excelTitle = i18n.tc('views.master.summaryStatistics.summaryForPeriod');

      if (this.isAmount) {
        this.option = [i18n.tc('views.master.summaryStatistics.day'), i18n.tc('views.master.summaryStatistics.sale'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, true);
        });
      } else {
        this.option = [i18n.tc('views.master.summaryStatistics.day'), i18n.tc('views.master.summaryStatistics.slaeCnt2'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, false);
        });
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async timeStatisticsLoad() {
    try {
      this.loading = true;

      const data = await MStatisticsApi.TimeStatistics(this.periodTypeSwitch);
      this.data = data.data.map(({title, ...item}) => {
        let titleValue = title;

        if (title !== i18n.tc('views.master.summaryStatistics.total')) {
          let time = title.split("~");
          let firstTime = time[0].split("시");
          let lastTime = time[1].split("시");

          let firstTimeValue =
              Number(firstTime[0]) + 9 >= 24
                  ? Number(firstTime[0]) + 9 - 24
                  : Number(firstTime[0]) + 9;
          let lastTimeValue =
              Number(lastTime[0]) + 9 > 24
                  ? Number(lastTime[0]) + 9 - 24
                  : Number(lastTime[0]) + 9;

          titleValue = `${firstTimeValue}시 ~ ${lastTimeValue}시`;
        }

        return {
          ...item,
          title: titleValue,
        };
      });
      this.excelTitle = i18n.tc('views.master.summaryStatistics.summaryForHour');

      this.data.sort((a, b) => {
        let aTimeValue;
        let bTimeValue;
        if (a.title !== i18n.tc('views.master.summaryStatistics.total') || b.title !== i18n.tc('views.master.summaryStatistics.total')) {
          let aTime = a.title.split("~");
          let bTime = b.title.split("~");
          aTimeValue = aTime[0].split("시");
          bTimeValue = bTime[0].split("시");
        }

        {
          return Number(aTimeValue[0]) - Number(bTimeValue[0]);
        }
      });

      if (this.isAmount) {
        this.option = [i18n.tc('views.master.summaryStatistics.day'), i18n.tc('views.master.summaryStatistics.sale'), ...data.option];
        
        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, true);
        });
      } else {
        this.option = [i18n.tc('views.master.summaryStatistics.day'), i18n.tc('views.master.summaryStatistics.saleCnt2'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, false);
        });
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async serviceStatisticsLoad() {
    try {
      this.loading = true;

      const {data} = await StatisticsApi.ServiceStatistics(
          this.periodTypeSwitch,
      );

      this.excelTitle = i18n.tc('views.master.summaryStatistics.summaryForService');
      const serviceNm = i18n.tc('views.master.summaryStatistics.serviceName');
      const sale = i18n.tc('views.master.summaryStatistics.sale');
      const saleCnt = i18n.tc('views.master.summaryStatistics.saleCnt2')
      this.excelData = data.map(item => {
        return {
          [serviceNm]: item.name,
          [sale]: item.amount,
          [saleCnt]: item.count,
        };
      });

      this.data = data;
      this.option = [serviceNm, sale, saleCnt];
    } catch {
    } finally {
      this.loading = false;
    }
  }

  get periodTypeSwitch() {
    let params = {
      type: Number(this.periodType),
    };

    if (this.totalType !== "service") {
      params = {
        ...params,
        serviceCodes: this.checkedList.join(","),
        timezone: "GMT+9",
      };
    } else {
      params = {
        ...params,
        hotelId: this.hotelIndex ? this.hotelIndex : 0,
        sorting: this.sorting,
      };
    }

    switch (this.periodType) {
      case "0":
        this.excelDate = `${moment(this.selectedMonth).format("YYYY-MM")} `;

        return {
          ...params,
          startDate: GmtConversionReverse(
              StartDate(this.selectedMonth, "month"),
          ),
          endDate: GmtConversionReverse(EndDate(this.selectedMonth, "month")),
        };

      case "1":
        this.excelDate = `${this.selectedYear} `;

        return {
          ...params,
          year: Number(this.selectedYear),
          startDate: GmtConversionReverse(
              StartDate(String(this.selectedYear), "year"),
          ),
          endDate: GmtConversionReverse(
              EndDate(String(this.selectedYear), "year"),
          ),
        };
      case "2":
        this.excelDate = `${moment(this.periodDate.startDate).format(
            "YYYY-MM-DD",
        )}∼${moment(this.periodDate.endDate).format("YYYY-MM-DD")}`;

        return {
          ...params,
          startDate: GmtConversionReverse(
              StartDate(this.periodDate.startDate, "day"),
          ),
          endDate: GmtConversionReverse(
              EndDate(this.periodDate.endDate, "day"),
          ),
        };
    }
  }

  async excelDownLoad() {
    try {
      if (this.excelData.length > 0) {
        const title = this.excelDate + " " + this.excelTitle;
        var dataWS = XLSX.utils.json_to_sheet(this.excelData);
        var wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, dataWS, this.excelTitle);
        XLSX.writeFile(wb, `${title}.xlsx`);
      } else {
        this.$message.warning(i18n.tc('views.master.summaryStatistics.noStatisticMsg'));
      }
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.summaryStatistics.excelDownloadErrorMsg');

      this.$message.error(error);
    }
  }

  excelDataManager(key, data, isAmount) {
    let excelData;
    const date = i18n.tc('views.master.summaryStatistics.day');
    const sale = i18n.tc('views.master.summaryStatistics.sale');
    const saleCnt = i18n.tc('views.master.summaryStatistics.saleCnt2')
    if (isAmount) {
      excelData = {[date]: data.title, [sale]: data.totalAmount};
      if (key.length > 0) {
        for (let i = 0; i < key.length; i++) {
          excelData = {...excelData, [`${key[i]}`]: data.contents[i].amount};
        }
      }
    } else {
      excelData = {[date]: data.title, [saleCnt]: data.totalCount};
      if (key.length > 0) {
        for (let i = 0; i < key.length; i++) {
          excelData = {...excelData, [`${key[i]}`]: data.contents[i].count};
        }
      }
    }

    return excelData;
  }
}
</script>

<style lang="scss" scoped>
.title-style {
  padding: 0px 20px;

  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
}

.title-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
  }
}

.content-style {
  padding: 0px 20px;
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  height: 52px;
  font-size: 14px;
  font-weight: 500;

  &:first-child {
    font-weight: bold;
    border-bottom: 3px solid #e8e8e8;
  }
}
</style>
