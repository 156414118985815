<template>
  <div class="overallStatistics">
    <div class="main-background">
      <div df fdr fjb fic style="margin-bottom: 8px">
        <a-input-search
          @change="changeFilterType(filter)"
          v-model="filter.keyword"
          :placeholder="$t('views.admin.notice.selectSubjectMsg')"
          style="width: 260px"
        />

        <a-button
          style="margin: 4px 0px"
          type="primary"
          :loading="loading"
          icon="redo"
          @click="refresh"
        >
          {{ $t('views.admin.notice.refresh') }}
        </a-button>
      </div>

      <a-table
        rowKey="index"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="changePage"
        :loading="loading"
      >
        <template slot="noticeTitle" slot-scope="_, record">
          <a @click="noticeDetail(record.index)">
            {{ record.noticeTitle }}
          </a>
        </template>

        <template slot="registeredDate" slot-scope="_, record">
          {{ moment(record.editedDate).format("YYYY-MM-DD") }}
        </template>
      </a-table>
    </div>

    <a-modal
      class="notice-detail-modal"
      :title="detail.noticeTitle"
      :visible="visible"
      :closable="false"
      width="620px"
    >
      <div class="notice-detail-box ql-snow">
        <div class="ql-editor">
          <div v-html="detail.noticeContent"></div>
        </div>
      </div>

      <div style="font-size: 14px" df fje fic>
        <div>{{ $t('views.admin.notice.noticeDate') }} : {{ moment(detail.editedDate).format("YYYY-MM-DD") }}</div>
      </div>

      <template slot="footer">
        <a-button @click="handleOk" type="primary"> {{ $t('views.admin.notice.close') }} </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import { Filter } from "@/utils/filterValue";
import {NoticeColumns} from "@/utils/columns/admin/notice";

import NoticeApi from "@/api/shared/notice";

import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
// import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
// Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {i18n} from "@/i18n";


@Component({
  components: { quillEditor },
})
export default class Notice extends Vue {
  levelCheck = localStorage.getItem("level");
  timezone = localStorage.getItem("timezone");
  moment = moment;

  loading = false;
  visible = false;

  get columns() {
    const colums =NoticeColumns();
    return colums;
  }

  data = [];
  total = 0;
  dataIndex;
  detail = {};

  @Filter
  filter = {
    keyword: "",
  };

  get pagination() {
    const { current, pageSize } = this.$route.query;
    return {
      current: current ? Number(current) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
      total: this.total,
    };
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = ["current", "pageSize", "keyword"];

    if (keys.some((key) => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  async mounted() {
    await this.load();
  }

  changePage({ current, pageSize, total }) {
    this.pagination.total = total;
    this.$router.push({
      query: {
        ...this.$route.query,
        current,
        pageSize,
      },
    });
  }

  changeFilterType({ keyword }) {
    this.$router.push({
      query: {
        keyword,
      },
    });
  }

  async load() {
    try {
      this.loading = true;

      const { current, pageSize, keyword } = this.$route.query;

      this.filter = {
        keyword: keyword ? keyword : "",
      };

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      const { list, total } = await NoticeApi.List({
        ...pagination,
        ...this.filterValue,
      });

      this.data = list;
      this.total = total;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.admin.notice.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async refresh() {
    await this.load({});
  }

  async noticeDetail(index) {
    try {
      this.visible = true;

      if (!index) {
        return;
      }

      this.detail = await NoticeApi.Detail(index);
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  handleOk(e) {
    this.visible = false;
  }

  editorOption = {
    placeholder: i18n.tc('views.admin.notice.noticeMsg'),
    readOnly: true,
    modules: {
      imageDrop: true,
      imageResize: true,
      toolbar: false,
    },
    theme: "snow",
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };
}
</script>

<style lang="scss">
.notice-detail-modal {
  .ant-modal {
    top: 150;

    .ant-modal-body {
      // height: calc(80vh - 108px);
      overflow-y: scroll;

      .notice-detail-box {
        min-height: 600px;
      }
    }
  }
}
</style>
