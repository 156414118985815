<template>
  <div class="notice">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.master.notice.title1') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.notice.title2') }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-button>
        <router-link :to="{ name: 'NoticeCreate' }">{{ $t('views.master.notice.create.title') }}</router-link>
      </a-button>
    </div>
    <div class="main-background">
      <div df fdr fjb fic style="margin-bottom:8px">
        <a-input-search
            @change="changeFilterType(filter)"
            v-model="filter.keyword"
            :placeholder="$t('views.master.notice.selectSubjectMsg')"
            style="width: 260px"
        />

        <a-button
            style="margin:4px 0px"
            type="primary"
            :loading="loading"
            icon="redo"
            @click="refresh"
        >
          {{ $t('views.master.notice.refresh') }}
        </a-button>
      </div>

      <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="changePage"
          :loading="loading"
      >
        <template slot="noticeTitle" slot-scope="_, record">
          <router-link
              :to="{
              name: 'NoticeEdit',
              params: {index: record.index}
            }"
          >
            {{ record.noticeTitle }}
          </router-link>
        </template>

        <!-- <template slot="noticeContent" slot-scope="_, record">
          {{ record.noticeContent }}
        </template> -->

        <template slot="registeredDate" slot-scope="_, record">
          {{ moment(record.editedDate).format("YYYY-MM-DD") }}
        </template>

        <a-popconfirm
            slot="delete"
            slot-scope="_, record"
            :title="$t('views.master.notice.deleteNoticeMsg')"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteSubmit(record.index)"
        >
          <a-button type="danger" shape="circle" icon="delete"/>
        </a-popconfirm>
      </a-table>
    </div>
  </div>
</template>

<script>
import {Component, Vue, Watch} from "vue-property-decorator";
import moment from "moment";

import {Filter} from "@/utils/filterValue";
import {NoticeColumns} from "@/utils/columns/master/notice";

import NoticeApi from "@/api/shared/notice";

import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class Notice extends Vue {
  levelCheck = localStorage.getItem("level");
  timezone = localStorage.getItem("timezone");
  moment = moment;

  loading = false;

  get columns() {
    const colums =NoticeColumns();
    return colums;
  }

  data = [];
  total = 0;
  dataIndex;
  detail = {};

  @Filter
  filter = {
    keyword: "",
  };

  get pagination() {
    const {current, pageSize} = this.$route.query;
    return {
      current: current ? Number(current) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
      total: this.total,
    };
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = ["current", "pageSize", "keyword"];

    if (keys.some(key => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  async mounted() {
    await this.load();
  }

  changePage({current, pageSize, total}) {
    this.pagination.total = total;
    this.$router.push({
      query: {
        ...this.$route.query,
        current,
        pageSize,
      },
    });
  }

  changeFilterType({keyword}) {
    this.$router.push({
      query: {
        keyword,
      },
    });
  }

  async load() {
    try {
      this.loading = true;

      const {
        current,
        pageSize,
        keyword
      } = this.$route.query;

      this.filter = {
        keyword: keyword ? keyword : "",
      };

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      const {list, total} = await NoticeApi.List({
        ...pagination,
        ...this.filterValue,
      });

      this.data = list;
      this.total = total;

    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.notice.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async refresh() {
    await this.load({});
  }

  async deleteSubmit(index) {
    try {
      this.loading = true;
      await NoticeApi.Delete(index)

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.notice.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
