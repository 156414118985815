<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{
          $t("views.master.hotel.title1")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.hotel.title2")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.hotel.edit.title")
        }}</a-breadcrumb-item>
      </a-breadcrumb>

      <div>
        <a-button @click="moveHotelAdmin()">
          {{ $t("views.master.hotel.moveHome") }}</a-button
        >
      </div>
    </div>

    <div class="main-background">
      <div df fdr fic fjb>
        <a-radio-group v-model="type">
          <a-radio-button
            v-for="(value, key) in radioTabFilter"
            :key="key"
            :value="value.value"
          >
            {{ value.name }}
          </a-radio-button>
        </a-radio-group>

        <div>
          <a-button
            v-if="addButtonActivation"
            style="width: 100px; margin-right: 12px"
            :loading="loading"
            @click="addButtonClick"
          >
            {{ $t("views.master.hotel.edit.add") }}
          </a-button>
          <a-button
            v-if="modifyButtonActivation"
            style="width: 100px"
            type="primary"
            :loading="loading"
            @click="modifyButtonClick"
          >
            {{ $t("views.master.hotel.edit.save") }}
          </a-button>
        </div>
      </div>

      <a-layout-content
        :style="{
          padding: '10px',
          marginTop: '20px',
        }"
      >
        <component
          ref="componentForm"
          :key="type"
          :is="content"
          :hotelIndex="hotelIndex"
          @load="load"
        />
      </a-layout-content>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";

import Tabs from "@/utils/languageTab";

import HotelEditForm from "@/components/hotel/HotelEditForm";
import HotelServiceForm from "@/components/hotel/HotelServiceForm";
import HotelLangsForm from "@/components/hotel/HotelLangsForm";
import HotelReceptionForm from "@/components/hotel/HotelReceptionForm";
import HotelDeliveryForm from "@/components/hotel/HotelDeliveryForm";
import HotelPickUpForm from "@/components/hotel/HotelPickUpForm";

import { i18n } from "@/i18n";

@Component({
  components: {
    HotelEditForm,
    HotelServiceForm,
    HotelLangsForm,
    HotelReceptionForm,
    HotelDeliveryForm,
    HotelPickUpForm,
  },
})
export default class HotelEdit extends Vue {
  tabs = Tabs;
  hotelIndex = this.$route.params.index;

  loading = false;
  type = "hotel";

  services = [];
  radioTab = [
    {
      value: "hotel",
      name: i18n.tc("views.master.hotel.edit.hotelInfoMng"),
    },
    {
      value: "service",
      name: i18n.tc("views.master.hotel.edit.serviceMng"),
    },
    {
      value: "langs",
      name: i18n.tc("views.master.hotel.edit.languageMng"),
    },
    {
      value: "reception",
      name: i18n.tc("views.master.hotel.edit.receptionMng"),
    },
    // {
    //   value: "delivery",
    //   name: "딜리버리 매장관리",
    // },
    // {
    //   value: "pickUp",
    //   name: "픽업 매장관리",
    // },
  ];

  get radioTabFilter() {
    if (this.services.find((a) => a.code == "SV_DELIVERY_GROUP")) {
      if (this.radioTab.find((a) => a.value == "delivery")) {
        this.radioTab;
      } else {
        this.radioTab = [
          ...this.radioTab,
          {
            value: "delivery",
            name: i18n.tc("views.master.hotel.edit.deliveryMng"),
          },
        ];
      }
    }

    if (this.services.find((a) => a.code == "SV_PICKUP_GROUP")) {
      if (this.radioTab.find((a) => a.value == "pickUp")) {
        this.radioTab;
      } else {
        this.radioTab = [
          ...this.radioTab,
          {
            value: "pickUp",
            name: i18n.tc("views.master.hotel.edit.pickupMng"),
          },
        ];
      }
    }

    return this.radioTab;

    // if (this.services.some((a) => a.code == "SV_DELIVERY_GROUP")) {
    //   return this.radioTab;
    // } else {
    //   return this.radioTab.filter((item) => item.value !== "delivery");
    // }
  }

  get addButtonActivation() {
    return this.type === "service" ||
      this.type === "langs" ||
      this.type === "reception" ||
      this.type === "delivery" ||
      this.type === "pickUp"
      ? true
      : false;
  }

  get modifyButtonActivation() {
    return this.type === "service" ||
      this.type === "langs" ||
      this.type === "hotel"
      ? true
      : false;
  }

  addButtonClick() {
    switch (this.type) {
      case "service":
        this.$refs.componentForm.showServiceAddModal();
        break;

      case "langs":
        this.$refs.componentForm.showLangsAddModal();
        break;

      case "reception":
        this.$refs.componentForm.showReceptionAddModal(false);
        break;

      case "delivery":
        this.$refs.componentForm.showDIEditModal(false);
        break;

      case "pickUp":
        this.$refs.componentForm.showPUEditModal(false);
        break;
    }
  }

  modifyButtonClick() {
    switch (this.type) {
      case "service":
        this.$refs.componentForm.serviceUpdate();
        break;

      case "hotel":
        this.$refs.componentForm.submit();
        break;

      case "langs":
        this.$refs.componentForm.langsSubmit();
        break;
    }
  }

  get content() {
    switch (this.type) {
      case "hotel":
        return "hotel-edit-form";

      case "service":
        return "hotel-service-form";

      case "langs":
        return "hotel-langs-form";

      case "reception":
        return "hotel-reception-form";

      case "delivery":
        return "hotel-delivery-form";

      case "pickUp":
        return "hotel-pickUp-form";
    }
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;
      const data = await HotelApi.Detail(this.hotelIndex);

      this.services = data.services;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async moveHotelAdmin() {
    try {
      const { information, confHotel } = await HotelApi.Detail(this.hotelIndex);

      await localStorage.setItem("timezone", confHotel.timezone);
      await localStorage.setItem("country", confHotel.country);

      await this.$router.replace({
        name: "Admin_Dashboard",
        params: {
          key: Number(this.hotelIndex),
          hotelName: String(information.Kr.name),
        },
      });
    } catch {}
  }
}
</script>

<style lang="scss" scoped></style>
