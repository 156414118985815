import client from "../client";

export default class Hotel {
  static async List(options) {
    return await client.get("master/hotel", options);
  }

  static async gbStore(index) {
    return await client.get(`master/hotel/gb/store/${index}`);
  }

  static async ReceptionStoreDelete(index) {
    return await client.del(`master/hotel/reception/store/${index}`);
  }
}
