var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tablet"},[_c('div',{staticClass:"bread-crumb-parent",attrs:{"df":"","fdr":"","fjb":"","fic":""}},[_c('a-breadcrumb',{staticClass:"bread-crumb"},[_c('a-breadcrumb-item',{staticClass:"fontSub"},[_vm._v(_vm._s(_vm.$t("views.master.tablet.title1")))]),_c('a-breadcrumb-item',{staticClass:"fontMain"},[_vm._v(_vm._s(_vm.$t("views.master.tablet.title2")))])],1),_c('div',{attrs:{"df":"","fdr":""}},[_c('excel-form',{staticStyle:{"margin-right":"10px"},attrs:{"excelUpload":_vm.excelUpload,"openExcelForm":_vm.openExcelForm}}),_c('a-button',{staticStyle:{"margin-right":"10px"}},[_c('router-link',{attrs:{"to":{
            name: 'TabletCreate',
            params: { hotelIndex: _vm.filter.hotelIndex || null },
          }}},[_vm._v(" "+_vm._s(_vm.$t("views.master.tablet.edit.tabletAdd"))+" ")])],1),_c('a-button',[_c('router-link',{attrs:{"to":{
            name: 'QrCreate',
            params: { hotelIndex: _vm.filter.hotelIndex || null },
          }}},[_vm._v(" "+_vm._s(_vm.$t("views.master.tablet.edit.qrAdd"))+" ")])],1)],1)],1),_c('div',{staticClass:"main-background"},[_c('div',{attrs:{"df":"","fdr":"","fjb":"","fic":""}},[_c('hotel-list',{on:{"change":function($event){return _vm.changeFilterType(_vm.filter)}},model:{value:(_vm.filter.hotelIndex),callback:function ($$v) {_vm.$set(_vm.filter, "hotelIndex", $$v)},expression:"filter.hotelIndex"}}),_c('a-button',{on:{"click":_vm.statusBatchEditModal}},[_vm._v(_vm._s(_vm.$t("views.master.tablet.changeStatus")))])],1),_c('div',{attrs:{"df":"","fdr":"","fjb":"","fic":""}},[[_c('div',{staticStyle:{"margin-top":"16px","margin-bottom":"16px"}},[_c('a-radio-group',{attrs:{"button-style":"solid"},on:{"change":function($event){return _vm.changeFilterType(_vm.filter)}},model:{value:(_vm.filter.type),callback:function ($$v) {_vm.$set(_vm.filter, "type", $$v)},expression:"filter.type"}},[_c('a-radio-button',{attrs:{"value":99}},[_vm._v(_vm._s(_vm.$t("views.master.tablet.all")))]),_c('a-radio-button',{attrs:{"value":10}},[_vm._v(_vm._s(_vm.$t("views.master.tablet.qr")))]),_c('a-radio-button',{attrs:{"value":0}},[_vm._v(_vm._s(_vm.$t("views.master.tablet.receptionTablet")))]),_c('a-radio-button',{attrs:{"value":1}},[_vm._v(_vm._s(_vm.$t("views.master.tablet.roomTablet")))])],1)],1)],_c('a-input-search',{staticStyle:{"width":"280px"},attrs:{"placeholder":_vm.$t('views.master.tablet.selectRoomSerialMsg')},on:{"change":function($event){return _vm.changeFilterType(_vm.filter)}},model:{value:(_vm.filter.keyword),callback:function ($$v) {_vm.$set(_vm.filter, "keyword", $$v)},expression:"filter.keyword"}})],2),_c('div',[_c('a-table',{attrs:{"rowKey":"index","columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"row-selection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onSelect: _vm.onSelectChange,
          onSelectAll: _vm.onSelectAllChange,
        }},on:{"change":_vm.changePage},scopedSlots:_vm._u([{key:"index",fn:function(value){return [_c('router-link',{attrs:{"to":{
              name: 'TabletEdit',
              params: { index: value },
            }}},[_vm._v(_vm._s(value))])]}},{key:"room",fn:function(value){return [_vm._v(_vm._s(value ? value.number : ""))]}},{key:"lastRefreshTime",fn:function(value){return [_c('last-refresh-time-item',{attrs:{"value":value}})]}},{key:"status",fn:function(_, record){return [_c('div',{style:({
              color:
                record.status && record.status === '1'
                  ? "black"
                  : ("" + (_vm.Colors.grayDark)),
            }),attrs:{"df":"","fdr":"","fjc":"","fic":""}},[_c('div',[_vm._v(" "+_vm._s(record.status && record.status === "1" ? _vm.$t("views.master.tablet.use") : _vm.$t("views.master.tablet.noUse"))+" ")]),_c('a-popconfirm',{attrs:{"title":_vm.$t('views.master.tablet.changeStatusMsg'),"ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.deviceStateChange(record.index)}}},[_c('a',[_c('a-icon',{staticStyle:{"margin-left":"4px","color":"rgb(9 168 204)"},attrs:{"click":"","type":"retweet"}})],1)])],1)]}},{key:"knoxStatus",fn:function(_, record){return [_c('div',{style:({
              color:
                record.knoxStatus && record.knoxStatus === '1'
                  ? "black"
                  : ("" + (_vm.Colors.grayDark)),
            }),attrs:{"df":"","fdr":"","fjc":"","fic":""}},[_c('div',[_vm._v(" "+_vm._s(record.knoxStatus ? record.knoxStatus === "1" ? "O" : "X" : "")+" ")]),_c('a-popconfirm',{attrs:{"title":_vm.$t('views.master.tablet.changeKnoxMsg'),"ok-text":"Yes","cancel-text":"No"},on:{"confirm":function($event){return _vm.noxStateChange(record.index)}}},[_c('a',[_c('a-icon',{staticStyle:{"margin-left":"4px","color":"rgb(9 168 204)"},attrs:{"type":"retweet"}})],1)])],1)]}},{key:"display",fn:function(_, record){return [_c('display-item',{attrs:{"value":record.lastRefreshTime}})]}},{key:"serialNumber",fn:function(_, record){return _c('div',{staticStyle:{"font-family":"monospace","letter-spacing":"1.5px"}},[_vm._v(" "+_vm._s(record.serialNumber)+" ")])}}])})],1)]),_c('a-modal',{attrs:{"title":_vm.$t('views.master.tablet.changeStatus'),"visible":_vm.visible,"closable":false},on:{"cancel":_vm.statusBatchCloseModal}},[_c('div',{attrs:{"df":"","fdc":"","fjc":"","fic":""}},[_c('a-radio-group',{attrs:{"options":_vm.statusBatchKey},model:{value:(_vm.statusBatchData.key),callback:function ($$v) {_vm.$set(_vm.statusBatchData, "key", $$v)},expression:"statusBatchData.key"}}),_c('a-radio-group',{staticStyle:{"margin-top":"12px"},attrs:{"options":_vm.statusBatchStatus},model:{value:(_vm.statusBatchData.status),callback:function ($$v) {_vm.$set(_vm.statusBatchData, "status", $$v)},expression:"statusBatchData.status"}})],1),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.statusBatchCloseModal}},[_vm._v(" "+_vm._s(_vm.$t("views.master.tablet.cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.statusBatchChange}},[_vm._v(" "+_vm._s(_vm.$t("views.master.tablet.change"))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }