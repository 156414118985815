import client from "../client";

export default class Delivery {
  static async Order(index, data) {
    return await client.put(`shared/pickUp/item/order/${index}`, data);
  }

  static async Create(index, data) {
    return await client.post(`shared/pickUp/add/item/${index}`, data);
  }

  static async List(index) {
    return await client.get(`shared/pickUp/item/list/${index}`);
  }

  static async Update(index, data) {
    return await client.put(`shared/pickUp/update/item/${index}`, data);
  }

  static async Delete(index) {
    return await client.del(`shared/pickUp/${index}`);
  }
}