<template>
  <div df flex fdc class="ranking-style">
    <div df fdr fic class="ranking-title">
      <a-icon :type="icon" :style="{ fontSize: '30px', color: color }" />
      <div style="margin-left:10px">
        {{ title }}
      </div>
      <div style="margin-left:4px;font-size:15px;font-weight:600">
        TOP 10
      </div>
    </div>

    <div v-for="(item, key) in data" :key="key" class="ranking-content">
      <div df flex class="ranking-item">
        <div
          df
          fic
          fjc
          v-bind:class="rankingObjectFunc(key)"
          style="width: 26px;height: 26px;margin-right:12px"
        >
          {{ key + 1 }}
        </div>
        <div df fic fjc v-bind:class="rankingFontObjectFunc(key)">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class RankingCard extends Vue {
  @Prop({ default: "crown" }) icon;
  @Prop({ default: "" }) title;
  @Prop({ default: "#05b3b4" }) color;
  @Prop({ default: [] }) data;
  @Prop({ default: "category" }) rankingObject;

  rankingObjectFunc(value) {
    switch (this.rankingObject) {
      case "category":
        if (value === 0 || value === 1 || value === 2) {
          return { categoryTop3: true };
        } else {
          return { categoryTop3: false };
        }

      case "item":
        if (value === 0 || value === 1 || value === 2) {
          return { itemTop3: true };
        } else {
          return { itemTop3: false };
        }

      case "service":
        if (value === 0 || value === 1 || value === 2) {
          return { serviceTop3: true };
        } else {
          return { serviceTop3: false };
        }
    }
  }

  rankingFontObjectFunc(value) {
    switch (this.rankingObject) {
      case "category":
        if (value === 0 || value === 1 || value === 2) {
          return { categoryFontTop3: true };
        } else {
          return { categoryFontTop3: false };
        }

      case "item":
        if (value === 0 || value === 1 || value === 2) {
          return { itemFontTop3: true };
        } else {
          return { itemFontTop3: false };
        }

      case "service":
        if (value === 0 || value === 1 || value === 2) {
          return { serviceFontTop3: true };
        } else {
          return { serviceFontTop3: false };
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.ranking-style {
  margin-right: 20px;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;

  &:last-child {
    margin-right: 0px;
  }
}

.ranking-title {
  font-size: 20px;
  font-weight: bold;
  margin: 7px 0px;
  padding-bottom: 6px;
  border-bottom: 3px solid #808080;
}

.ranking-content {
  font-size: 15px;
  padding: 11px 0px;
  font-weight: 500;
}

.categoryTop3 {
  width: 26px;
  height: 26px;
  border-radius: 20px;
  background: #05b3b4;
  color: white;
}

.categoryFontTop3 {
  font-weight: bold;
  color: #05b3b4;
}

.itemTop3 {
  width: 26px;
  height: 26px;
  border-radius: 20px;
  background: #f79c18;
  color: white;
}

.itemFontTop3 {
  font-weight: bold;
  color: #f79c18;
}

.serviceTop3 {
  width: 26px;
  height: 26px;
  border-radius: 20px;
  background: #f81d77;
  color: white;
}

.serviceFontTop3 {
  font-weight: bold;
  color: #f81d77;
}

.ranking-item {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
}
</style>
