<template>
  <draggable
    tag="ul"
    role="menu"
    :list="list"
    v-bind="dragOptions"
    @change="listOrderChanged"
    @start="drag = true"
    @end="drag = false"
    :disabled="levelCheck === '1'"
  >
    <transition-group type="transition" :name="!drag ? 'flip-list' : null">
      <li
        class="category-group-item"
        :class="{
          on: selectedKeys.indexOf(item.index) !== -1,
          use: isAlwaysUse ? true : item.status == 1,
        }"
        :key="item.index"
        @click="selectItem(item.index)"
        v-for="item in list"
        df
        fdr
        fic
      >
        <drag-icon v-if="levelCheck !== '1'" style="margin-right:14px" />

        <div flex>
          {{ item.name }}
        </div>
        <small v-if="isStatus">
          {{ item.status === "1" || item.status === true ? $t('components.service.use') : $t('components.service.unuse') }}
        </small>

        <small v-if="isOptionStatus">
          {{
            item.status === "1"
              ? $t('components.service.use')
              : item.status === "2"
              ? $t('components.service.soldout')
              : $t('components.service.unuse')
          }}
        </small>
      </li>
    </transition-group>
  </draggable>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";

import DragIcon from "@/components/DragIcon";

@Component({
  components: { draggable, DragIcon },
})
export default class TitleListDraggable extends Vue {
  @Prop() list;
  @Prop({ default: false }) isStatus;
  @Prop({ default: false }) isAlwaysUse;
  @Prop({ default: false }) isOptionStatus;

  @Prop() listOrderChanged;
  @Prop() selectedKeys;
  @Prop() selectItem;

  levelCheck = localStorage.getItem("level");

  drag = false;
  dragOptions = {
    animation: 200,
    ghostClass: "ghost",
  };
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
