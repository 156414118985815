import client from "./client";

export default class Auth {
  static async Login(data) {
    return await client.post("auth/login", data);
  }

  static async Logout(index) {
    return await client.get(`auth/${index}`);
  }

  static async Update(index, data) {
    return await client.put(`auth/${index}`, data);
  }

  static async Self(index) {
    return await client.get(`auth/self/${index}`);
  }
}
