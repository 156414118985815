import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import antd from "ant-design-vue";
import Geocoder from "@pderas/vue2-geocoder";
import VueClipboard from "vue-clipboard2";
import {i18n} from "../src/i18n";

import "ant-design-vue/dist/antd.min.css";
import "~/styles/flex.scss";

Vue.config.productionTip = false;

Vue.use(antd);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.use(Geocoder, {
  defaultCountryCode: "KR", // e.g. 'CA'
  defaultLanguage: "ko", // e.g. 'en'
  defaultMode: "서울특별시 마포구 서교동 홍익로5길 32", // or 'lat-lng'
  googleMapsApiKey: "AIzaSyDwJqmDtZdOcz1-VEuWoouULOv8ms7nLzk",
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount("#app");
