<template df fdr>
  <div df fdr class="service">
    <a-layout>
      <a-layout-sider width="256" style="background: #fff">
        <a-menu mode="inline" :selected-keys="selectedKeys">
          <a-menu-item v-for="item in menus" :key="item.index">
            <router-link
              :to="{
                name: 'Admin_Service',
                params: { index: item.index },
              }"
            >
              <div df fdr fjb>
                <div df fdr fic>
                  <a-icon
                    type="dashboard"
                    v-if="item.code === 'Dashboard' ? true : false"
                  />

                  <div
                    v-if="item.code === 'Dashboard' ? false : true"
                    class="icon-logo"
                    :style="{
                      backgroundImage: `url('${item.icon}')`,
                    }"
                  />
                  <span>{{ item.name }}</span>
                </div>
                <span
                  v-if="!item.status"
                  style="font-size: 9px; color: #cfcfcf"
                >
                  {{ $t("views.admin.service.disabled") }}
                </span>
              </div>
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>

      <a-layout style="padding: 16px 24px 24px 16px">
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
            height: '100%',
          }"
        >
          <component
            :key="serviceIndex"
            :is="content"
            @childs-event="load"
            :serviceIndex="serviceIndex"
            :serviceCode="serviceCode"
            :serviceTime="serviceTime"
            :serviceMemo="serviceMemo"
            :serviceImages="serviceImage"
          >
          </component>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import ServiceApi from "@/api/admin/service";
import { TimeShowFunc } from "@/utils/timeFunc";

import OverallManagement from "@/views/admin/service/OverallManagement";
import Shop from "@/views/admin/service/Shop";
import SurroundingInfo from "@/views/admin/service/SurroundingInfo";
import UrlService from "@/views/admin/service/UrlService";
import MemberShip from "@/views/admin/service/MemberShip";
import Delivery from "@/views/admin/service/Delivery";
import PickUp from "@/views/admin/service/PickUp";

import Amenity from "@/views/admin/service/Amenity";
import ImageService from "@/views/admin/service/ImageService";
import { i18n } from "@/i18n";

const defaultMenu = [
  {
    code: "Dashboard",
    name: i18n.tc("views.admin.service.IntegratedManage"),
    icon: "dashboard",
    status: true,
    index: "dashboard",
    children: [],
  },
];

@Component({
  components: {
    OverallManagement,
    UrlService,
    MemberShip,
    Delivery,
    PickUp,
    Shop,
    Amenity,
    ImageService,
    SurroundingInfo,
  },
})
export default class Service extends Vue {
  levelCheck = localStorage.getItem("level");
  adminId = localStorage.getItem("adminId");
  loading = false;

  selectedKeys = ["dashboard"];

  menus = [...defaultMenu];
  serviceIndex = "dashboard";
  serviceCode = "";
  exceptionCode = [
    "SV_ALARM",
    "SV_DELIVERY",
    "SV_VALET",
    "SV_CALL_TAXI",
    "SV_FRONT_CALL",
    "SV_EMERGENCY_CALL",
    "SV_CHECKOUT",
    "SV_MAINTENANCE",
    "SV_DELIVERY_ITEM",
    "SV_PICKUP_ITEM",
  ];
  serviceTime = {};
  serviceMemo = "";
  serviceImage = "";

  @Watch("$route")
  routeChanged() {
    const index = this.levelCheck === "0" ? "dashboard" : this.menus[0].index;

    this.selectedKeys = [this.$route.params.index || index];
  }

  get content() {
    this.serviceIndex = this.selectedKeys[0];

    const { code, serviceTime, memo, icon } = this.menus.find(
      (item) => item.index == this.serviceIndex,
    );
    this.serviceTime = serviceTime;
    this.serviceCode = code;
    this.serviceMemo = memo;
    this.serviceImage = icon;

    switch (this.serviceCode) {
      case "SV_HOTEL_AMENITIES": {
        return "amenity";
      }

      case "SV_JOSIK":
      case "SV_ROOM_SERVICE":
      case "SV_SHOP":
        return "shop";

      case "SV_HOTEL_INFO_IMAGE":
        return "image-service";

      case "SV_MEMBERSHIP_QR":
        return "member-ship";

      case "SV_NEARBY_STORES":
        return "surrounding-info";

      case "SV_WEBVIEW":
      case "SV_SURVEY":
      case "SV_WEATHER":
      case "SV_MEMBERSHIP":
        return "url-service";

      case "SV_DELIVERY_GROUP":
        return "delivery";

      case "SV_PICKUP_GROUP":
        return "pickUp";
    }

    return "overall-management";
  }

  mounted() {
    this.load({});
  }

  async load() {
    try {
      this.loading = true;
      var list;
      if (this.levelCheck === "2") {
        const { data } = await ServiceApi.AssignCheck(this.adminId);
        list = data;
      } else {
        list = await ServiceApi.List(this.$route.params.key);
      }

      const updatedList = list.filter(
        (item) => this.exceptionCode.indexOf(item.code) === -1,
      );

      if (
        this.levelCheck === "0" ||
        this.levelCheck === "5" ||
        this.levelCheck === "6"
      ) {
        this.menus = [
          ...defaultMenu,
          ...updatedList.map((item) => {
            const closeTimeValue = TimeShowFunc(item.closeTime);

            return {
              name: item.name,
              code: item.code,
              serviceTime: {
                openTime: item.openTime,
                closeTime: closeTimeValue,
              },
              memo: item.memo,
              status: item.status,
              index: item.index + "",
              icon: item.icon,
            };
          }),
        ];
        this.selectedKeys = [this.$route.params.index || "dashboard"];
      } else {
        this.menus = [
          ...updatedList.map((item) => {
            const closeTimeValue = TimeShowFunc(item.closeTime);

            return {
              name: item.name,
              code: item.code,
              serviceTime: {
                openTime: item.openTime,
                closeTime: closeTimeValue,
              },
              memo: item.memo,
              status: item.status,
              index: item.index + "",
              icon: item.icon,
            };
          }),
        ];

        this.selectedKeys = [this.$route.params.index || this.menus[0].index];
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-logo {
  height: 18px;
  width: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
  background-image: url("../../../public/img/icons/logo-hotelorder.png");
}
</style>
