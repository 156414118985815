import Dashboard from "@/views/agency/Dashboard.vue";
import Hotel from "@/views/agency/Hotel.vue";
import Statics from "@/views/agency/Statics.vue";
import Order from "@/views/agency/Order.vue";
import AgencyHotelEdit from "@/views/agency/hotel/Edit.vue";
import AgencyHotelCreate from "@/views/agency/hotel/Create.vue";

export default [
    {
        path: "/agency/dashboard",
        name: "Agency_Dashboard",
        component: Dashboard,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/agency/hotel",
        name: "Agency_Hotel",
        component: Hotel,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/agency/statics",
        name: "Agency_Statics",
        component: Statics,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/agency/order",
        name: "Agency_Order",
        component: Order,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/agency/hotel/edit",
        name: "AgencyHotelEdit",
        component: AgencyHotelEdit,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/agency/hotel/create",
        name: "AgencyHotelCreate",
        component: AgencyHotelCreate,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    }
];

