import {i18n} from "@/i18n";

export function StaticsColumns(){ 
  return [
  
  {
    width: 20,
    dataIndex: "name",
    title: '순번',
    scopedSlots: { customRender: "name" },
  },
  {
    width: 200,
    align: "center",
    dataIndex: "autDate",
    title: '일시',
    scopedSlots: { customRender: "authDate" },
  },
  {
    width: 120,
    align: "left",
    dataIndex: "cardName",
    title: '카드사',    
  },
  {
    width: 120,
    align: "center",
    title: '카드번호',
    scopedSlots: { customRender: "" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "payType",
    title: '결제상태',
    scopedSlots: { customRender: "payType" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "authNo",
    title: '승인번호',
    scopedSlots: { customRender: "8" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "amount",
    title: '거래금액',
    scopedSlots: { customRender: "amount" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "purchasePrice",
    title: '공급가',
    scopedSlots: { customRender: "purchasePrice" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "commission",
    title: '수익',
    scopedSlots: { customRender: "commission" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "feeCompany",    
    title: '운영사',
    scopedSlots: { customRender: "feeCompany" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "feeSales",
    title: '영업자',
    scopedSlots: { customRender: "3" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "feeRecommend",
    title: '소개자',
    scopedSlots: { customRender: "2" },
  },
  {
    width: 100,
    align: "center",
    dataIndex: "orderId",
    title: '주문번호',
    scopedSlots: { customRender: "1" },
  },
];
}