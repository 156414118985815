import {i18n} from "@/i18n";

export function ImageServiceReadColumns(){
  return [
  {
    align: "center",
    dataIndex: "image",
    title: i18n.tc('js.image.image'),
    scopedSlots: { customRender: "image" },
  },
  {
    width: 440,
    align: "center",
    dataIndex: "description",
    title: i18n.tc('js.image.description'),
    scopedSlots: { customRender: "description" },
  },
];
}