var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout',[_c('a-layout-header',{staticClass:"header",attrs:{"df":""}},[_c('div',{attrs:{"flex":"","fjs":""}},[_c('div',{staticClass:"admin-logo"}),_c('a-menu',{style:({ lineHeight: '64px' }),attrs:{"theme":"dark","mode":"horizontal","selected-keys":_vm.selectedKeys}},_vm._l((_vm.menus),function(item,i){return _c('a-menu-item',{key:i},[(
              item.name === 'Admin_StaffManagement'
                ? _vm.levelCheck === '0' || _vm.levelCheck === '1'
                  ? true
                  : false
                : true
            )?_c(item.click ? 'a' : 'router-link',_vm._g(_vm._b({tag:"component",staticStyle:{"text-align":"center"}},'component',item.click ? {} : { to: { name: item.name } },false),item.click ? { click: item.click } : {}),[_c('a-icon',{attrs:{"type":item.icon}}),_c('span',[_vm._v(_vm._s(item.title))])],1):_vm._e()],1)}),1)],1),_c('div',{staticStyle:{"display":"inline-block","padding-top":"15px","padding-right":"30px"}},[_c('a-form-model-item',[_c('a-select',{attrs:{"placeholder":_vm.$i18n.locale},on:{"change":function($event){return _vm.localeChange()}},model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}},_vm._l((_vm.langs),function(lang,i){return _c('a-select-option',{key:("Lang" + i),attrs:{"value":lang}},[_vm._v(" "+_vm._s(_vm.printLangs[lang])+" ")])}),1)],1)],1),_c('div',{staticStyle:{"font-size":"9px","font-weight":"600","line-height":"0.9","margin-top":"10px"},attrs:{"df":"","fdr":"","fic":"","align":"right"}},[_c('router-link',{staticStyle:{"color":"#ffffff"},attrs:{"to":{
          name: _vm.toRouterName,
        }}},[_c('p',[_vm._v(_vm._s(_vm.storeName))]),_c('p',[_vm._v(_vm._s(_vm.userName))])]),(this.role !== 'Master')?_c('a-icon',{style:({
          color: ("" + (_vm.Colors.grayDark)),
          paddingLeft: "16px",
        }),attrs:{"type":"setting"},on:{"click":_vm.settingLayoutMove}}):_vm._e(),_c('a-icon',{style:({
          color: ("" + (_vm.Colors.red)),
          paddingLeft: "16px",
        }),attrs:{"type":"logout"},on:{"click":_vm.logoutConfirm}})],1)]),_c('a-layout',[_c('a-layout-content',{staticClass:"layout-content",attrs:{"df":"","fdr":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }