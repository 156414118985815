import {i18n} from "@/i18n";
export function OrderColumns(){ 
  return[
  {
    width: "80px",
    align: "right",
    dataIndex: "index",
    title: i18n.tc('js.order.orderId'),
  },
  {
    width: 110,
    align: "left",
    dataIndex: "registeredTime",
    title: i18n.tc('js.order.day'),
    scopedSlots: { customRender: "registeredTime" },
  },
  {
    width: 80,
    align: "left",
    title: i18n.tc('js.order.time'),
    scopedSlots: { customRender: "time" },
  },
  {
    width: "120px",
    dataIndex: "status",
    title: i18n.tc('js.order.status'),
    scopedSlots: { customRender: "status" },
  },
  {
    dataIndex: "hotelName",
    title: i18n.tc('js.order.hotelNm'),
  },
  {
    dataIndex: "roomNumber",
    title: i18n.tc('js.order.room'),
  },
  {
    dataIndex: "serviceName",
    title: i18n.tc('js.order.service'),
    scopedSlots: { customRender: "serviceName" },
  },
  {
    dataIndex: "payStatus",
    title: i18n.tc('js.order.payStatus'),
    scopedSlots: { customRender: "payStatus" },
  },
  {
    align: "right",
    dataIndex: "amount",
    title: i18n.tc('js.order.price'),
    customRender: value => Number(value).toLocaleString(),
  },
  {
    align: "right",
    dataIndex: "fee",
    title: i18n.tc('js.order.fee'),
    scopedSlots: { customRender: "fee" },
  },
  {
    width: "120px",
    align: "center",
    dataIndex: "details",
    title: i18n.tc('js.order.orderDis'),
    scopedSlots: { customRender: "details" },
  },
];
}
