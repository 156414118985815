import client from "../client";

export default class Notice {
  static async List(options) {
    return await client.get("shared/notice", options);
  }

  static async Detail(index) {
    return await client.get(`shared/notice/${index}`);
  }

  static async Create(data) {
    return await client.post("master/notice", data);
  }

  static async Update(index, data) {
    return await client.put(`master/notice/${index}`, data);
  }

  static async Delete(index) {
    return await client.del(`master/notice/${index}`);
  }
}
