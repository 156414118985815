import client from "../client";

export default class Category {
  static async List(options) {
    return await client.get("admin/category", options);
  }

  static async Order(storeIndex, serviceID, data) {
    return await client.put(
      `admin/category/order/${storeIndex}/${serviceID}`,
      data,
    );
  }

  static async Create(serviceID, data) {
    return await client.post(`admin/category/${serviceID}`, data);
  }

  static async Update(categoryID, data) {
    return await client.put(`admin/category/${categoryID}`, data);
  }

  static async Delete(categoryID) {
    return await client.del(`admin/category/${categoryID}`);
  }
}
