<template>
  <a-modal :title="$t('components.modal.service.editServiceNm')" :visible="visible" :closable="false">
    <a-form-model
      v-if="data"
      :key="data.index"
      ref="editServiceForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr fje>
        <small>{{$t('components.modal.service.optWarn')}}</small>
      </div>
      <div class="card-container">
        <language-tab
          ref="languageTabForm"
          :value="data"
          :isLanguageUsed="isLanguageUsed"
          :tabs="tabs"
          :name="$t('components.modal.service.serviceNm')"
        >
        </language-tab>
      </div>
    </a-form-model>
    <div slot="footer">
      <a-button :loading="loading" @click="close">{{$t('components.modal.service.close')}}</a-button>
      <a-button type="primary" :loading="loading" @click="save">{{$t('components.modal.service.ok')}}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import LanguageTab from "@/components/LanguageTab";
import Tabs from "@/utils/languageTab";
import {i18n} from "@/i18n";

@Component({
  components: { LanguageTab },
})
export default class ServiceNameEditModal extends Vue {
  @Prop() data;

  @Prop({ default: false }) visible;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  tabs = Tabs;

  loading = false;

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('components.modal.service.serviceMsg'),
            },
          ],
        }),
        {},
      ),
    };
  }

  async save() {
    this.$refs.editServiceForm.validate(async valid => {
      if (valid) {
        try {
          if (!this.data.information.En.name) {
            this.$refs.languageTabForm.tabDefaultKeyChange();
            return this.$message.warning(i18n.tc('components.modal.service.serviceMsgEng'));
          }

          this.loading = true;
          this.$emit("saveSubmit");
        } catch {
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async close() {
    this.$emit("close");
  }
}
</script>
