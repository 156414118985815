import {i18n} from "@/i18n";
export function ReceptionColumns(){
  return [
  {
    align: "center",
    dataIndex: "name",
    title: i18n.tc('js.staff.name'),
    scopedSlots: { customRender: "name" },
  },
  {
    align: "center",
    dataIndex: "id",
    title: i18n.tc('js.staff.id'),
    scopedSlots: { customRender: "id" },
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}