<template>
  <div class="hotel">
    <div class="main-background">
      <div df fic fje style="height: 80px; margin-bottom: 20px">
        <a-button
          @click="submit"
          :loading="loading"
          style="width: 80px"
          type="primary"
          v-if="levelCheck === '1' ? false : true"
        >
          {{ $t('views.admin.hotel.search') }}
        </a-button>
      </div>

      <a-form-model
        ref="form"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit.prevent="submit"
      >
        <div df fdr>
          <div df fdc flex>
            <div df fdr fje>
              <small>{{ $t('views.admin.hotel.essential') }}</small>
            </div>

            <div class="card-container">
              <language-tab
                :value="data"
                :isLanguageUsed="isLanguageUsed"
                :tabs="tabs"
                :name="$t('views.admin.hotel.hotelName')"
                :address="$t('views.admin.hotel.hotelAddress')"
                :description="$t('views.admin.hotel.hotelDesc')"
                :rank="$t('views.admin.hotel.hotelRank')"
                :rooms="$t('views.admin.hotel.hotelRooms')"
              >
              </language-tab>
            </div>

            <div style="margin-top: 16px">
              <a-form-model-item prop="status" :label="$t('views.admin.hotel.hotelStatus')">
                <a-radio-group v-model="data.status" button-style="solid">
                  <a-radio-button value="1">{{ $t('views.admin.hotel.hotelStatusOn') }}</a-radio-button>
                  <a-radio-button value="0">{{ $t('views.admin.hotel.hotelStatusOff') }}</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </div>
          </div>

          <a-space direction="vertical" flex>
            <!-- <a-form-model-item :label="$t('views.admin.hotel.accountNumberInfo')">
              <a-input v-model="data.account.number" :placeholder="$t('views.admin.hotel.accountNumber')">
                <a-select
                  slot="addonBefore"
                  style="min-width: 110px"
                  :placeholder="$t('views.admin.hotel.bank')"
                  v-model="data.account.bank"
                >
                  <a-select-option
                    v-for="item in banks"
                    :key="item"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-model-item>

            <a-form-model-item :label="$t('views.admin.hotel.accountHolder')">
              <a-input v-model="data.account.holder" />
            </a-form-model-item> -->

            <a-form-model-item :label="$t('views.admin.hotel.email')">
              <a-input v-model="data.email" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.admin.hotel.phone')">
              <a-input v-model="data.phone" />
            </a-form-model-item>
          </a-space>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import HotelApi from "@/api/admin/hotel";
import Tabs from "@/utils/languageTab";
import Banks from "@/utils/banks";

import LanguageTab from "@/components/LanguageTab";

import {i18n} from "@/i18n";

@Component({
  components: { LanguageTab },
})
export default class Hotel extends Vue {
  levelCheck = localStorage.getItem("level");

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  hotelIndex = this.$route.params.key;
  loading = false;

  tabs = Tabs;
  banks = Banks;

  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            address: "",
            description: "",
          },
        }),
        {},
      ),
    },
    status: "",
    account: {
      number: "",
      bank: "국민은행",
      holder: "",
    },
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
    imageFolder: "",
    confHotel: {
      rank: "",
      rooms: "",
    },
  };

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('views.admin.hotel.hotelNameMsg'),
            },
          ],
          [`information.Kr.address`]: [
            {
              required: this.isLanguageUsed("Kr"),
              message: i18n.tc('views.admin.hotel.hotelAddressMsg'),
            },
          ],
          [`information.${key}.description`]: [{}],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc('views.admin.hotel.hotelStatusMsg'),
        },
      ],
    };
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async mounted() {
    this.load();
  }

  async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          this.loading = true;

          const data = {
            ...this.data,
          };

          await HotelApi.Update(this.hotelIndex, data);
          this.$message.success(i18n.tc('views.admin.hotel.updateComplete'));
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.admin.hotel.updateFail');

          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async load() {
    try {
      this.loading = true;
      const { information, ...data } = await HotelApi.Detail(this.hotelIndex);
      this.data = {
        ...data,
        information: {
          ...this.data.information,
          ...information,
        },
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.admin.hotel.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped></style>
