<template>
  <div
    df
    fdc
    style="height: 100%; padding: 24px"
    v-if="levelCheck === '0' || levelCheck === '5' || levelCheck === '6'"
  >
    <div df fje fjb style="margin-bottom: 20px">
      <div>
        <a-select
          default-value="statusManagement"
          style="width: 220px"
          @change="managementHandleChange"
        >
          <a-select-option value="statusManagement">
            {{ $t("views.admin.services.activeManage") }}
          </a-select-option>
          <a-select-option value="timeManagement">
            {{ $t("views.admin.services.serviceManage") }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-popconfirm
          @confirm="dataLoad"
          :title="$t('views.admin.services.alartCancel')"
          ok-text="Yes"
          cancel-text="No"
        >
          <a-icon slot="icon" type="question-circle-o" style="color: red" />
          <a-button :loading="loading" style="margin-right: 6px; width: 80px">
            {{ $t("views.admin.services.cancel") }}
          </a-button>
        </a-popconfirm>
        <a-popconfirm
          @confirm="save"
          :title="$t('views.admin.services.alartSave')"
          ok-text="Yes"
          cancel-text="No"
        >
          <a-button :loading="loading" style="width: 80px" type="primary">
            {{ $t("views.admin.services.save") }}
          </a-button>
        </a-popconfirm>
      </div>
    </div>
    <div style="font-size: 14px; font-weight: bold; margin-bottom: 4px">
      {{ $t("views.admin.services.noticeTb") }}
    </div>
    <div
      df
      fdr
      style="height: 100%"
      v-if="managementType === 'statusManagement'"
    >
      <div flex="3" df fdc fjc fic style="height: 100%; margin-right: 15px">
        <h3 class="title">{{ $t("views.admin.services.activeSc") }}</h3>
        <a-row class="row-style">
          <draggable
            :list="enabledList"
            group="people"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            class="draggable-style"
          >
            <a-col
              df
              fdc
              fic
              fjc
              v-for="element in enabledList"
              :key="element.name"
              :xs="{ span: 6, offset: 2 }"
              :lg="{ span: 5, offset: 2 }"
              style=""
              :name="!drag ? 'flip-list' : null"
              class="col-style"
            >
              <div
                class="icon-logo"
                :style="{
                  backgroundImage: `url('${element.icon}')`,
                }"
              />
              <div class="txt_line">
                {{ element.name }}
              </div>
            </a-col>
          </draggable>
        </a-row>
      </div>

      <div
        flex="1"
        df
        fdc
        fjc
        fic
        style="height: 100%; width: 100%; margin-left: 15px"
      >
        <h3 class="title">{{ $t("views.admin.services.disableSc") }}</h3>

        <a-row class="row-style">
          <draggable
            :list="disabledList"
            group="people"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            class="draggable-style"
          >
            <a-col
              df
              fdc
              fic
              fjc
              v-for="element in disabledList"
              :key="element.name"
              :xs="{ span: 6, offset: 2 }"
              :lg="{ span: 5, offset: 2 }"
              class="col-style disabled"
            >
              <div
                class="icon-logo"
                :style="{
                  backgroundImage: `url('${element.icon}')`,
                }"
              />
              <div class="txt_line">
                {{ element.name }}
              </div>
            </a-col>
          </draggable>
        </a-row>
      </div>
    </div>
    <div style="height: 100%" v-if="managementType === 'timeManagement'" df fdc>
      <div
        v-if="rumyCheck > 0 ? true : false"
        style="font-size: 14px; font-weight: bold; margin-bottom: 4px"
      >
        {{ $t("views.admin.services.noticeRmy") }}
      </div>

      <!-- <div style="margin-bottom: 15px">
        <a-radio-group
          default-value="1"
          button-style="solid"
          @change="changeRadio"
        >
          <a-radio-button value="1"> 일반 </a-radio-button>
          <a-radio-button value="2"> 딜리버리 </a-radio-button>
        </a-radio-group>
      </div> -->

      <!-- <a-table
        v-if="menuTab === '1'"
        rowKey="index"
        :columns="rumyCheck > 0 ? rumyColumns : columns"
        :data-source="list"
        :loading="loading"
        :pagination="false"
      > -->

      <a-table
        rowKey="index"
        :columns="rumyCheck > 0 ? rumyColumns : columns"
        :data-source="list"
        :loading="loading"
        :pagination="false"
      >
        <template slot="icon" slot-scope="_, record">
          <a @click="showServiceIconEditModal(record)">
            <div
              class="icon-mini-logo"
              :style="{
                backgroundImage: `url('${record.icon}')`,
              }"
            />
          </a>
        </template>

        <template slot="name" slot-scope="_, record">
          <a @click="showServiceEditModal(record)">{{ record.name }}</a>
        </template>

        <template
          slot="isRumy"
          slot-scope="_, record"
          v-if="isRumyService(record.code)"
        >
          <a-switch v-model="record.isRumy" />
        </template>

        <template
          slot="departmentIds"
          slot-scope="_, record"
          v-if="isRumyService(record.code)"
        >
          <a-select
            v-if="rumyCheck > 0 ? true : false"
            :disabled="!record.isRumy"
            style="min-width: 250px"
            mode="multiple"
            :placeholder="$t('views.admin.services.selectRmy')"
            v-model="record.departmentIds"
          >
            <a-select-option
              v-for="item in rumyDepartmentList"
              :key="item.objectId"
              :value="item.objectId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </template>

        <template slot="isAlways" slot-scope="_, record">
          <a-switch
            v-model="record.isAlways"
            @change="
              {
                record.openTime = '0000';
                record.closeTime = '0000';
              }
            "
          />
        </template>

        <template slot="openTime" slot-scope="_, record">
          <a-time-picker
            format="HH:mm"
            v-model="record.openTime"
            :disabled="record.isAlways"
          >
          </a-time-picker>
        </template>
        <template slot="closeTime" slot-scope="_, record">
          <a-time-picker
            format="HH:mm"
            v-model="record.closeTime"
            :disabled="record.isAlways"
          >
          </a-time-picker>
        </template>

        <template slot="addText" slot-scope="_, record">
          <a-button
            type="primary"
            icon="plus"
            ghost
            size="small"
            @click="multilingualLoad(record.index)"
          />
        </template>

        <template slot="multilingual" slot-scope="_, record">
          <a-button
            v-if="
              record.code == 'SV_DELIVERY_GROUP' ||
              record.code == 'SV_PICKUP_GROUP'
            "
            type="primary"
            icon="edit"
            ghost
            size="small"
            @click="showDeliveryModal(record.code)"
          />

          <a-button
            v-else
            type="primary"
            icon="edit"
            ghost
            size="small"
            @click="multilingualLoad(record.index)"
          />
        </template>
      </a-table>

      <!-- <a-table
        v-else
        rowKey="index"
        :columns="rumyCheck > 0 ? rumyColumns : columns"
        :data-source="deliveryList"
        :loading="loading"
        :pagination="false"
      >
        <template slot="name" slot-scope="_, record">
          <a @click="showServiceEditModal(record)">{{ record.name }}</a>
        </template>

        <template slot="isAlways" slot-scope="_, record">
          <a-switch
            v-model="record.isAlways"
            @change="
              {
                record.openTime = '0000';
                record.closeTime = '0000';
              }
            "
          />
        </template>

        <template slot="openTime" slot-scope="_, record">
          <a-time-picker
            format="HH:mm"
            v-model="record.openTime"
            :disabled="record.isAlways"
          >
          </a-time-picker>
        </template>
        <template slot="closeTime" slot-scope="_, record">
          <a-time-picker
            format="HH:mm"
            v-model="record.closeTime"
            :disabled="record.isAlways"
          >
          </a-time-picker>
        </template>

        <template slot="addText" slot-scope="_, record">
          <a-button
            type="primary"
            icon="plus"
            ghost
            size="small"
            @click="multilingualLoad(record.index)"
          />
        </template>

        <template slot="multilingual" slot-scope="_, record">
          <a-button
            v-if="record.code == 'SV_DELIVERY_GROUP'"
            type="primary"
            icon="edit"
            ghost
            size="small"
            @click="showDeliveryModal"
          />

          <a-button
            v-else
            type="primary"
            icon="edit"
            ghost
            size="small"
            @click="multilingualLoad(record.index)"
          />
        </template>
      </a-table> -->
    </div>

    <service-name-edit-modal
      :data="selectedService"
      :visible="isServiceEdit"
      @close="closeServiceEdit"
      @saveSubmit="saveServiceEdit"
    />

    <service-icon-edit-modal
      :data="selectedServiceIcon"
      :visible="isServiceIconEdit"
      @close="closeServiceIconEdit"
      @saveSubmit="saveServiceIconEdit"
    />

    <multilingual-add-modal
      :key="multilingualModalKey"
      :index="adminSerivceIndex"
      :data="multilingualData"
      :visible="editMultilingual"
      :adminSerivceIndex="adminSerivceIndex"
      :afterClose="afterCloseEditMultilingual"
      @close="closeEditMultilingual"
    />

    <a-modal
      v-model="deliveryVisible"
      :title="$t('views.admin.services.manageMent')"
    >
      <div style="text-align: center">
        {{ modalText }} {{ $t("views.admin.services.noticeM1") }}
        <span style="color: black; font-size: 18px; font-weight: bold"
          >{{ modalText }}{{ $t("views.admin.services.noticeM2") }}</span
        >
        {{ $t("views.admin.services.notoceM3") }}
      </div>
      <div></div>

      <div slot="footer">
        <a-button @click="deliveryVisible = false" type="primary">{{
          $t("views.admin.services.ok")
        }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import Draggable from "vuedraggable";
import moment from "moment";

import Tabs from "@/utils/languageTab";
import { OverallManagementColumns } from "@/utils/columns/admin/overallManagement";
import { OverallManagementRumyColumns } from "@/utils/columns/admin/overallManagementRumy";
import { TimeSaveFunc, TimeShowFunc } from "@/utils/timeFunc";

import ServiceApi from "@/api/admin/service";
import HotelApi from "@/api/admin/hotel";
import RumyApi from "@/api/shared/rumy";
import { i18n } from "@/i18n";

import ServiceNameEditModal from "@/components/modals/ServiceNameEditModal";
import ServiceIconEditModal from "@/components/modals/ServiceIconEditModal";
import MultilingualAddModal from "@/components/modals/MultilingualAddModal";

@Component({
  components: {
    Draggable,
    ServiceNameEditModal,
    ServiceIconEditModal,
    MultilingualAddModal,
  },
})
export default class OverallManagement extends Vue {
  levelCheck = localStorage.getItem("level");

  get columns() {
    const colums = OverallManagementColumns();
    return colums;
  }
  get rumyColumns() {
    const colums = OverallManagementRumyColumns();
    return colums;
  }

  managementType = "statusManagement";
  tabs = Tabs;
  moment = moment;
  hotelIndex = this.$route.params.key;

  drag = false;
  dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  loading = false;
  isServiceEdit = false;
  isServiceIconEdit = false;

  enabledList = [];
  disabledList = [];
  notHeldList = [];
  list = [];

  rumyDepartmentList = [];
  rumyCheck = false;
  exceptionCode = ["SV_DELIVERY_ITEM", "SV_PICKUP_ITEM"];
  rumyActivationCode = [
    "SV_DELIVERY",
    "SV_SHOP",
    "SV_ROOM_SERVICE",
    "SV_VALET",
    "SV_CALL_TAXI",
    "SV_FRONT_CALL",
    "SV_EMERGENCY_CALL",
    "SV_MAINTENANCE",
    "SV_CHECKOUT",
    "SV_HOTEL_AMENITIES",
  ];

  rumyInfo = {};

  selectedService = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
  };

  selectedServiceIcon = {
    uploadedUrl: [],
  };

  multilingualModalKey = "multilingual_key";
  editMultilingual = false;
  multilingualData = [];
  adminSerivceIndex = 0;

  deliveryVisible = false;

  menuTab = "1";
  modalText = "";

  changeRadio(e) {
    this.menuTab = e.target.value;
  }

  showDeliveryModal(code) {
    console.log(code);
    this.modalText =
      code == "SV_DELIVERY_GROUP"
        ? i18n.tc("views.admin.services.delivery")
        : i18n.tc("views.admin.services.pickup");
    this.deliveryVisible = true;
  }

  moveDelivery() {}

  isRumyService(code) {
    if (this.rumyActivationCode.some((item) => item === code)) {
      return true;
    }

    return false;
  }

  async mounted() {
    await this.dataLoad();
    await this.rumyCheckLoad();
  }

  async dataLoad() {
    try {
      this.loading = true;

      const list = await ServiceApi.List(this.hotelIndex);

      const updatedList = list.filter(
        (item) => this.exceptionCode.indexOf(item.code) === -1,
      );

      const deliveryList = list.filter(
        (item) => item.code == this.exceptionCode,
      );

      this.list = updatedList.map(({ openTime, closeTime, ...item }) => {
        const closeTimeValue = TimeShowFunc(closeTime);

        return {
          ...item,
          isAlways:
            (openTime === "0000" && closeTime === "0000") ||
            openTime == closeTime,
          openTime: moment(openTime || "0000", "HHmm"),
          closeTime: moment(closeTimeValue, "HHmm"),
          departmentIds: item.departmentIds
            ? item.departmentIds.split(",")
            : undefined,
          uploadedUrl: [updatedList.icon],
        };
      });

      this.deliveryList = deliveryList.map(
        ({ openTime, closeTime, ...item }) => {
          const closeTimeValue = TimeShowFunc(closeTime);

          return {
            ...item,
            icon: null,
            isAlways:
              (openTime === "0000" && closeTime === "0000") ||
              openTime == closeTime,
            openTime: moment(openTime || "0000", "HHmm"),
            closeTime: moment(closeTimeValue, "HHmm"),
            departmentIds: item.departmentIds
              ? item.departmentIds.split(",")
              : undefined,
            // uploadedUrl: [updatedList.icon],
          };
        },
      );

      this.enabledList = updatedList.filter((item) => item.status);
      this.disabledList = updatedList.filter((item) => !item.status);
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async rumyCheckLoad() {
    try {
      this.loading = true;
      this.rumyDepartmentList = [];

      const data = await ServiceApi.RumyCheck(this.hotelIndex);

      if (data.data === true) {
        const hotelInfo = await HotelApi.Detail(this.hotelIndex);

        if (hotelInfo.confHotel) {
          this.rumyInfo = {
            url: hotelInfo.confHotel.rumyUrl ? hotelInfo.confHotel.rumyUrl : "",
            id: hotelInfo.confHotel.rumyId ? hotelInfo.confHotel.rumyId : "",
            password: hotelInfo.confHotel.rumyPw
              ? hotelInfo.confHotel.rumyPw
              : "",
          };

          const rumyInfo = await RumyApi.List(this.rumyInfo);
          this.rumyDepartmentList = rumyInfo.data.teams;
        }
      }

      this.rumyCheck = this.rumyDepartmentList?.length > 0 ? true : false;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.admin.services.errorRmy");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  managementHandleChange(value) {
    this.managementType = value;
  }

  async save() {
    try {
      this.loading = true;

      if (this.managementType === "statusManagement") {
        this.enabledList.map(
          (item) => (item.status = item.status === false ? true : item.status),
        );

        this.disabledList.map(
          (item) => (item.status = item.status === true ? false : item.status),
        );

        const model = {
          items: [...this.enabledList, ...this.disabledList],
        };

        await ServiceApi.ListOrderUpdate(this.hotelIndex, model);
      } else {
        const validate = this.list.every((item) =>
          item.isRumy ? (item.departmentIds ? true : false) : true,
        );

        if (!validate) {
          return this.$message.warning(
            i18n.tc("views.admin.services.selectRmy"),
          );
        }

        const data = this.list.map(
          ({ openTime, closeTime, departmentIds, ...item }) => {
            const time = TimeSaveFunc(openTime, closeTime);

            return {
              ...item,
              departmentIds: departmentIds ? departmentIds.join(",") : "",
              openTime: time.openTime,
              closeTime: time.closeTime,
            };
          },
        );

        await ServiceApi.Update(this.hotelIndex, data);
      }

      this.$message.success(i18n.tc("views.admin.services.editComp"));

      await this.dataLoad();
      await this.$emit("childs-event");
    } catch {
      this.$message.error(i18n.tc("views.admin.services.editFail"));
    } finally {
      this.loading = false;
    }
  }

  showServiceEditModal(value) {
    this.isServiceEdit = true;

    const data = JSON.parse(
      JSON.stringify(this.list.find((a) => a.index === value.index)),
    );

    this.selectedService = {
      ...data,
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
        ...data.information,
      },
    };
  }

  async saveServiceEdit() {
    const index = this.list.findIndex(
      (item) => item.index === this.selectedService.index,
    );

    const service = this.list;

    if (index !== -1) {
      service[index] = this.selectedService;
      service[index].name = this.selectedService.information.Kr.name;
    }

    this.list = [...service];

    this.isServiceEdit = false;
  }

  closeServiceEdit() {
    this.selectedService = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
    };

    this.isServiceEdit = false;
  }

  saveServiceIconEdit() {
    const index = this.list.findIndex(
      (item) => item.index === this.selectedServiceIcon.index,
    );

    const service = this.list;

    if (index !== -1) {
      service[index].icon = this.selectedServiceIcon.uploadedUrl[0];
      service[index].color = this.selectedServiceIcon.color;
    }

    this.list = [...service];

    this.isServiceIconEdit = false;
  }

  showServiceIconEditModal(value) {
    this.isServiceIconEdit = true;

    const data = { ...this.list.find((a) => a.index === value.index) };

    this.selectedServiceIcon = {
      ...data,
      uploadedUrl: [data.icon],
    };
  }

  closeServiceIconEdit() {
    this.isServiceIconEdit = false;
  }

  async multilingualLoad(index) {
    try {
      this.loading = true;

      this.multilingualModalKey = "multilingual_key";
      const { data } = await ServiceApi.MultilingualDetail(index);

      this.adminSerivceIndex = index;
      this.multilingualData = data;

      this.editMultilingual = true;
      this.multilingualModalKey = data.length > 0 ? data[0].index : "";
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  closeEditMultilingual() {
    this.editMultilingual = false;
  }

  afterCloseEditMultilingual() {
    this.multilingualData = [];
    this.multilingualModalKey = "multilingual_key2";
  }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
}

.icon-mini-logo {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../../public/img/icons/google-symbol.png");
}

.icon-logo {
  height: 50px;
  width: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../../public/img/icons/google-symbol.png");
}

.row-style {
  border: 1px solid black;
  box-shadow: 5px 3px 3px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.draggable-style {
  height: 100%;
  width: 100%;
}

.col-style {
  margin-top: 50px;
  margin-bottom: 50px;
}

.disabled {
  opacity: 0.4;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.txt_line {
  width: 100px;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
</style>
