import {i18n} from "@/i18n";
export function SurroundingInfoColumns(){
  return [
  {
    width: 40,
    align: "left",
    title: "",
    scopedSlots: { customRender: "drag" },
  },
  {
    align: "left",
    dataIndex: "name",
    title: i18n.tc('js.surround.storeNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    align: "left",
    dataIndex: "address",
    title: i18n.tc('js.surround.address'),
    scopedSlots: { customRender: "address" },
  },
  {
    width: 80,
    align: "center",
    title: i18n.tc('js.surround.delete'),
    scopedSlots: { customRender: "delete" },
  },
];
}