import {i18n} from "@/i18n";
export function SurroundingInfoReadColumns() {
  return [
  {
    align: "left",
    dataIndex: "name",
    title: i18n.tc('js.surround.storeNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    align: "left",
    dataIndex: "address",
    title: i18n.tc('js.surround.address'),
    scopedSlots: { customRender: "address" },
  },
];
}