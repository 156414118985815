<template>
  <a-form-model
    v-if="data"
    :key="data.index"
    ref="editform"
    :model="data"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    @submit.prevent="submit"
  >
    <div df fdr>
      <a-space direction="vertical" flex>
        <div df fdr fje>
          <small>{{ $t("components.hotel.edit.notice") }}</small>
        </div>

        <div class="card-container">
          <language-tab
            :value="data"
            :isLanguageUsed="isLanguageUsed"
            :tabs="tabs"
            :name="$t('components.hotel.edit.name')"
            :address="$t('components.hotel.edit.address')"
            :description="$t('components.hotel.edit.description')"
            :rank="$t('components.hotel.edit.rank')"
            :rooms="$t('components.hotel.edit.rooms')"
          >
          </language-tab>
        </div>

        <a-form-model-item
          prop="status"
          :label="$t('components.hotel.edit.isUse')"
        >
          <a-radio-group v-model="data.status" button-style="solid">
            <a-radio-button value="1">{{
              $t("components.hotel.use")
            }}</a-radio-button>
            <a-radio-button value="0">{{
              $t("components.hotel.unuse")
            }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="호텔코드">
          <a-input v-model="data.confHotel.appCode" />
        </a-form-model-item>

        <a-form-model-item :label="$t('components.hotel.edit.bankInfo')">
          <a-input
            v-model="data.account.number"
            :placeholder="$t('components.hotel.edit.bankAdr')"
          >
            <a-select
              slot="addonBefore"
              style="min-width: 110px"
              :placeholder="$t('components.hotel.edit.bank')"
              v-model="data.account.bank"
            >
              <a-select-option v-for="item in banks" :key="item" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-input>
        </a-form-model-item>

        <a-form-model-item :label="$t('components.hotel.edit.adrManager')">
          <a-input v-model="data.account.holder" />
        </a-form-model-item>

        <a-form-model-item :label="$t('components.hotel.edit.email')">
          <a-input v-model="data.email" />
        </a-form-model-item>

        <a-form-model-item :label="$t('components.hotel.edit.tel')">
          <a-input v-model="data.phone" />
        </a-form-model-item>

        <a-form-model-item
          v-if="data.confHotel"
          :label="$t('components.hotel.edit.country')"
        >
          <a-select
            :placeholder="$t('components.hotel.edit.countryMsg')"
            v-model="data.confHotel.country"
          >
            <a-select-option
              v-for="item in country"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
          v-if="data.confHotel"
          :label="$t('components.hotel.edit.lang')"
        >
          <a-select
            :placeholder="$t('components.hotel.edit.langMsg')"
            v-model="data.confHotel.langCode"
          >
            <a-select-option
              v-for="item in langList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-space>

      <a-space direction="vertical" flex>
        <div df fic fjc class="sub-title">
          {{ $t("views.master.hotel.create.code") }}
        </div>
        <a-form-model-item :label="$t('views.master.hotel.create.agentCode')">
          <a-input v-model="data.agentCode" />
        </a-form-model-item>

        <a-form-model-item :label="$t('views.master.hotel.create.salesCode')">
          <a-input v-model="data.salesCode" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('views.master.hotel.create.recommendCode')"
        >
          <a-input v-model="data.recommendCode" />
        </a-form-model-item>
      </a-space>
    </div>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

import Banks from "@/utils/banks";
import Tabs from "@/utils/languageTab";

import HotelApi from "@/api/master/hotel";
import SHotelApi from "@/api/shared/hotel";
import { i18n } from "@/i18n";
import LanguageTab from "@/components/LanguageTab";

@Component({
  components: { LanguageTab },
})
export default class HotelEditForm extends Vue {
  @Prop({ default: undefined }) hotelIndex;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  tabs = Tabs;
  banks = Banks;

  country = [];
  langList = [];

  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            address: "",
            description: "",
          },
        }),
        {},
      ),
    },
    status: "",
    account: {
      number: "",
      bank: "국민은행",
      holder: "",
    },
    phone: "",
    email: "",
    latitude: "",
    longitude: "",
    imageFolder: "",
    salesCode: "",
    agentCode: "",
    recommendCode: "",
    confHotel: {
      rank: "",
      rooms: "",
      appCode: "",
    },
  };

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("components.hotel.edit.hotelMsg"),
            },
          ],
          [`information.Kr.address`]: [
            {
              required: this.isLanguageUsed("Kr"),
              message: i18n.tc("components.hotel.edit.addrMsg"),
            },
          ],
          [`information.${key}.description`]: [{}],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc("components.hotel.edit.useMsg"),
        },
      ],
    };
  }

  async mounted() {
    await this.countryListLoad();
    await this.langListLoad();
    await this.load();
  }

  async load() {
    try {
      this.loading = true;
      const { information, ...data } = await HotelApi.Detail(this.hotelIndex);

      if (data.salesMan != null) {
        data.salesCode = data.salesMan.salesCode;
      }
      if (data.recomend != null) {
        data.recommendCode = data.recomend.salesCode;
      }
      if (data.agent != null) {
        data.agentCode = data.agent.salesCode;
      }

      this.data = {
        ...data,
        information: {
          ...this.data.information,
          ...information,
        },
      };

      console.log(this.data);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async countryListLoad() {
    try {
      const { data } = await SHotelApi.CountryList();

      this.country = data.map(item => ({
        value: item.Key,
        name: item.Value,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async langListLoad() {
    try {
      const { data } = await SHotelApi.LangList();

      this.langList = data.map(item => ({
        value: item.Key,
        name: item.Value,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async submit() {
    this.$refs.editform.validate(async valid => {
      if (valid) {
        await this.submitUpdate();
      }
    });
  }

  async submitUpdate() {
    try {
      await HotelApi.Update(this.hotelIndex, this.data);

      this.$message.success(i18n.tc("components.hotel.edit.success"));

      await this.$emit("load");
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("components.hotel.edit.fail");

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped>
.rumy-title {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: bold;
}
</style>
