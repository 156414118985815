<template>
  <div class="tablet">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{
          $t("views.master.tablet.title1")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.tablet.title2")
        }}</a-breadcrumb-item>
      </a-breadcrumb>

      <div df fdr>
        <excel-form
          style="margin-right: 10px"
          :excelUpload="excelUpload"
          :openExcelForm="openExcelForm"
        />

        <a-button style="margin-right: 10px">
          <router-link
            :to="{
              name: 'TabletCreate',
              params: { hotelIndex: filter.hotelIndex || null },
            }"
          >
            {{ $t("views.master.tablet.edit.tabletAdd") }}
          </router-link>
        </a-button>

        <a-button>
          <router-link
            :to="{
              name: 'QrCreate',
              params: { hotelIndex: filter.hotelIndex || null },
            }"
          >
            {{ $t("views.master.tablet.edit.qrAdd") }}
          </router-link>
        </a-button>
      </div>
    </div>

    <div class="main-background">
      <div df fdr fjb fic>
        <hotel-list
          v-model="filter.hotelIndex"
          @change="changeFilterType(filter)"
        />
        <a-button @click="statusBatchEditModal">{{
          $t("views.master.tablet.changeStatus")
        }}</a-button>
      </div>
      <div df fdr fjb fic>
        <template>
          <div style="margin-top: 16px; margin-bottom: 16px">
            <a-radio-group
              button-style="solid"
              v-model="filter.type"
              @change="changeFilterType(filter)"
            >
              <a-radio-button :value="99">{{
                $t("views.master.tablet.all")
              }}</a-radio-button>
              <a-radio-button :value="10">{{
                $t("views.master.tablet.qr")
              }}</a-radio-button>
              <a-radio-button :value="0">{{
                $t("views.master.tablet.receptionTablet")
              }}</a-radio-button>
              <a-radio-button :value="1">{{
                $t("views.master.tablet.roomTablet")
              }}</a-radio-button>
            </a-radio-group>
          </div>
        </template>

        <a-input-search
          @change="changeFilterType(filter)"
          v-model="filter.keyword"
          :placeholder="$t('views.master.tablet.selectRoomSerialMsg')"
          style="width: 280px"
        />
      </div>

      <div>
        <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="changePage"
          :loading="loading"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onSelect: onSelectChange,
            onSelectAll: onSelectAllChange,
          }"
        >
          <template slot="index" slot-scope="value">
            <router-link
              :to="{
                name: 'TabletEdit',
                params: { index: value },
              }"
              >{{ value }}</router-link
            >
          </template>

          <template slot="room" slot-scope="value">{{
            value ? value.number : ""
          }}</template>

          <template slot="lastRefreshTime" slot-scope="value">
            <last-refresh-time-item :value="value" />
          </template>

          <template slot="status" slot-scope="_, record">
            <div
              df
              fdr
              fjc
              fic
              :style="{
                color:
                  record.status && record.status === '1'
                    ? `black`
                    : `${Colors.grayDark}`,
              }"
            >
              <div>
                {{
                  record.status && record.status === "1"
                    ? $t("views.master.tablet.use")
                    : $t("views.master.tablet.noUse")
                }}
              </div>
              <a-popconfirm
                :title="$t('views.master.tablet.changeStatusMsg')"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deviceStateChange(record.index)"
              >
                <a>
                  <a-icon
                    click
                    style="margin-left: 4px; color: rgb(9 168 204)"
                    type="retweet"
                  />
                </a>
              </a-popconfirm>
            </div>
          </template>

          <template slot="knoxStatus" slot-scope="_, record">
            <div
              df
              fdr
              fjc
              fic
              :style="{
                color:
                  record.knoxStatus && record.knoxStatus === '1'
                    ? `black`
                    : `${Colors.grayDark}`,
              }"
            >
              <div>
                {{
                  record.knoxStatus
                    ? record.knoxStatus === "1"
                      ? "O"
                      : "X"
                    : ""
                }}
              </div>
              <a-popconfirm
                :title="$t('views.master.tablet.changeKnoxMsg')"
                ok-text="Yes"
                cancel-text="No"
                @confirm="noxStateChange(record.index)"
              >
                <a>
                  <a-icon
                    style="margin-left: 4px; color: rgb(9 168 204)"
                    type="retweet"
                  />
                </a>
              </a-popconfirm>
            </div>
          </template>

          <template slot="display" slot-scope="_, record">
            <display-item :value="record.lastRefreshTime" />
          </template>

          <div
            slot="serialNumber"
            slot-scope="_, record"
            style="font-family: monospace; letter-spacing: 1.5px"
          >
            {{ record.serialNumber }}
          </div>

          <!-- <a-popconfirm
            slot="delete"
            slot-scope="_, record"
            title="해당 태블릿을 삭제하시겠습니까?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteSubmit(record.index)"
          >
            <a-button type="danger" shape="circle" icon="delete" />
          </a-popconfirm> -->
        </a-table>
      </div>
    </div>

    <a-modal
      :title="$t('views.master.tablet.changeStatus')"
      :visible="visible"
      @cancel="statusBatchCloseModal"
      :closable="false"
    >
      <div df fdc fjc fic>
        <a-radio-group
          v-model="statusBatchData.key"
          :options="statusBatchKey"
        />

        <a-radio-group
          style="margin-top: 12px"
          v-model="statusBatchData.status"
          :options="statusBatchStatus"
        />
      </div>
      <template slot="footer">
        <a-button @click="statusBatchCloseModal">
          {{ $t("views.master.tablet.cancel") }}
        </a-button>
        <a-button type="primary" @click="statusBatchChange">
          {{ $t("views.master.tablet.change") }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import Colors from "@/utils/colors";
import { Filter } from "@/utils/filterValue";
import { GmtConversion } from "@/utils/gmtConversion";
import { TabletColumns } from "@/utils/columns/master/tablet";

import DeviceApi from "@/api/master/device";

import HotelList from "@/components/HotelList.vue";
import ExcelForm from "@/components/service/ExcelForm";
import DisplayItem from "@/components/tablet/DisplayItem.vue";
import LastRefreshTimeItem from "@/components/tablet/LastRefreshTimeItem.vue";

import { i18n } from "@/i18n";

@Component({
  components: {
    HotelList,
    DisplayItem,
    LastRefreshTimeItem,
    ExcelForm,
  },
})
export default class Device extends Vue {
  moment = moment;
  Colors = Colors;

  loading = false;
  visible = false;
  sortedInfo = null;

  @Filter
  filter = {
    hotelIndex: "",
    keyword: "",
    type: 99,
    timeSort: 0,
  };

  data = [];
  total = 0;

  selectedRowKeys = [];
  totalTabletKeys = [];
  statusBatchData = {
    key: 0,
    status: true,
    ids: [],
  };

  statusBatchKey = [
    {
      label: i18n.tc("views.master.tablet.useStatus"),
      value: 0,
    },
    {
      label: i18n.tc("views.master.tablet.knoxStatus"),
      value: 1,
    },
  ];

  statusBatchStatus = [
    {
      label: i18n.tc("views.master.tablet.activation"),
      value: true,
    },
    {
      label: i18n.tc("views.master.tablet.noActivation"),
      value: false,
    },
  ];

  get columns() {
    let { sortedInfo } = this;
    sortedInfo = sortedInfo || {};

    const colums = TabletColumns(sortedInfo);
    return colums;
  }

  get pagination() {
    const { current, pageSize } = this.$route.query;
    return {
      current: current ? Number(current) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
      total: this.total,
    };
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = [
      "current",
      "pageSize",
      "hotelIndex",
      "keyword",
      "type",
      "timeSort",
    ];

    if (keys.some(key => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  mounted() {
    this.load();
  }

  changePage({ current, pageSize, total, timeSort }, filters, sorter) {
    this.pagination.total = total;

    this.sortedInfo = sorter;

    timeSort =
      this.sortedInfo.order === "ascend"
        ? 1
        : this.sortedInfo.order === "descend"
        ? 2
        : 0;

    this.$router.push({
      query: {
        ...this.$route.query,
        current,
        pageSize,
        timeSort,
      },
    });
  }

  changeFilterType({ keyword, type, hotelIndex, timeSort }) {
    this.$router.push({
      query: {
        keyword,
        type,
        hotelIndex,
        timeSort,
      },
    });
  }

  async load() {
    try {
      this.loading = true;

      const {
        current,
        pageSize,
        keyword,
        timeSort,
        type,
        hotelIndex,
      } = this.$route.query;

      this.filter = {
        type: type ? Number(type) : 99,
        keyword: keyword ? keyword : "",
        hotelIndex: hotelIndex ? Number(hotelIndex) : "",
        timeSort: timeSort ? Number(timeSort) : 0,
      };

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      const { data, option } = await DeviceApi.List({
        ...pagination,
        ...this.filterValue,
      });

      this.data = data.list.map(({ lastRefreshTime, ...item }) => {
        return {
          ...item,
          lastRefreshTime: GmtConversion(lastRefreshTime),
        };
      });

      this.total = data.total;
      this.totalTabletKeys = option;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteSubmit(index) {
    try {
      this.loading = true;
      await DeviceApi.Delete(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async noxStateChange(index) {
    try {
      this.loading = true;
      await DeviceApi.NoxChange(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deviceStateChange(index) {
    try {
      this.loading = true;
      await DeviceApi.DeviceStateChange(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  onSelectChange(record, selected, selectedRows) {
    if (selected) {
      this.selectedRowKeys = [...this.selectedRowKeys, record.index];
    } else {
      this.selectedRowKeys = this.selectedRowKeys.filter(
        item => item !== record.index,
      );
    }
  }

  onSelectAllChange(selected, selectedRows, changeRows) {
    if (selected) {
      this.selectedRowKeys = this.totalTabletKeys;
    } else {
      this.selectedRowKeys = [];
    }
  }

  async statusBatchChange() {
    try {
      this.statusBatchData.ids = this.selectedRowKeys;

      await DeviceApi.StatusBatchChange(this.statusBatchData);

      this.statusBatchCloseModal();
      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.errorMsg");

      this.$message.error(error);
    }
  }

  statusBatchEditModal() {
    if (this.selectedRowKeys.length > 0) {
      this.visible = true;
    } else {
      this.$message.warning(
        i18n.tc("views.master.tablet.selectChangeTabletMsg"),
      );
    }
  }

  statusBatchCloseModal() {
    this.visible = false;
  }

  async excelUpload({ file, onSuccess, onError }) {
    try {
      await DeviceApi.ExcelUplad(file);

      await this.load();
      this.$message.success(
        i18n.tc("views.master.tablet.successExcelUploadMsg"),
      );
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.tablet.selectChangeTabletMsg");

      this.$message.error(error);
    }
  }

  openExcelForm() {
    window
      .open(
        "https://hihotelorder.s3.ap-northeast-2.amazonaws.com/excel/%EC%95%84%EC%9D%B4%ED%85%9C_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D_240705.xlsx",
        "_blank",
      )
      .focus();
  }
}
</script>

<style lang="scss" scoped></style>
