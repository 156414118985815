<template>
  <div class="dashboard">
    <div
      class="main-background"
      :style="{ backgroundColor: `${Colors.grayLight}` }"
    >
      <div df fdr style="margin-bottom:16px;">
        <a-card df fdc fjc class="card-style" style="flex:2;margin-right:10px;">
          <div style="font-size:14px;font-weight:bold;margin-bottom:12px">
            {{ moment(this.monthlyAverageData.startDate).format("YYYY-MM-DD") }}
            ~
            {{ moment(this.monthlyAverageData.endDate).format("YYYY-MM-DD") }}
          </div>
          <div df fdc>
            <total-card
              :name="$t('views.admin.dashboard.sale')"
              :totalValue="this.monthlyAverageData.accumulateAmount"
              :monthlyAverageValue="this.monthlyAverageData.averageAmount"
              icon="dollar"
              iconColor="#008888"
              isMoney="true"
              marginRight="0px"
              :duration="duration"
            />

            <total-card
              style="margin-top:10px"
              :name="$t('views.admin.dashboard.saleCnt')"
              :totalValue="this.monthlyAverageData.accumulateOrder"
              :monthlyAverageValue="this.monthlyAverageData.averageOrder"
              icon="contacts"
              iconColor="#02495B"
              marginRight="0px"
              :duration="duration"
            />
          </div>
        </a-card>

        <div df fdc style="flex:8;">
          <a-card flex class="card-style" style="flex:8;">
            <div
              df
              flex
              fjb
              fie
              style="font-size:14px;font-weight:bold;margin-bottom:16px"
            >
              <a-radio-group
                name="radioGroup"
                v-model="isServiceTypePeriodAmount"
              >
                <a-radio :value="true">
                  {{$t('views.admin.dashboard.sale')}}
                </a-radio>
                <a-radio :value="false">
                  {{$t('views.admin.dashboard.saleCnt')}}
                </a-radio>
              </a-radio-group>
              <div>
                <a-month-picker
                  v-if="serviceTypePeriodType === '2'"
                  style="margin-right:12px;width:120px"
                  placeholder="Select month"
                  v-model="selectedMonth"
                  @change="serviceTypePeriodLoad"
                >
                  <template slot="monthCellContentRender" slot-scope="date">
                    <div>{{ `${moment(date).format("M")}${$t('views.admin.dashboard.month2')}` }}</div>
                  </template>
                </a-month-picker>
                <a-radio-group
                  @change="serviceTypePeriodLoad"
                  default-value="0"
                  button-style="solid"
                  v-model="serviceTypePeriodType"
                >
                  <a-radio-button value="0">
                    {{$t('views.admin.dashboard.today')}}
                  </a-radio-button>
                  <a-radio-button value="1">
                    {{$t('views.admin.dashboard.week')}}
                  </a-radio-button>
                  <a-radio-button value="2">
                    {{$t('views.admin.dashboard.month')}}
                  </a-radio-button>
                </a-radio-group>
              </div>
            </div>

            <div df fdr flex fjc fic style="height:140px">
              <daily-card
                :name="$t('views.admin.dashboard.all')"
                :dailyValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.total.amount
                    : serviceTypePeriodData.total.count
                "
                :yesterdayValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.total.beforeAmount
                    : serviceTypePeriodData.total.beforeCount
                "
                :totalValue="serviceTypePeriodData.total.percent"
                icon="dashboard"
                :iconColor="Colors.sky"
                :isMoney="isServiceTypePeriodAmount"
                :periodType="serviceTypePeriodType"
                :isServiceTypePeriodAmount="isServiceTypePeriodAmount"
              />

              <daily-card
                :name="$t('views.admin.dashboard.saleItem')"
                :dailyValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.shop.amount
                    : serviceTypePeriodData.shop.count
                "
                :yesterdayValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.shop.beforeAmount
                    : serviceTypePeriodData.shop.beforeCount
                "
                :totalValue="serviceTypePeriodData.shop.percent"
                icon="shopping"
                :iconColor="Colors.blue"
                :isMoney="isServiceTypePeriodAmount"
                :periodType="serviceTypePeriodType"
                :isServiceTypePeriodAmount="isServiceTypePeriodAmount"
              />

              <daily-card
                :name="$t('views.admin.dashboard.orderConcierge')"
                :dailyValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.concierge.amount
                    : serviceTypePeriodData.concierge.count
                "
                :yesterdayValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.concierge.beforeAmount
                    : serviceTypePeriodData.concierge.beforeCount
                "
                :totalValue="serviceTypePeriodData.concierge.percent"
                icon="solution"
                :iconColor="Colors.brown"
                :isMoney="isServiceTypePeriodAmount"
                :periodType="serviceTypePeriodType"
                :isServiceTypePeriodAmount="isServiceTypePeriodAmount"
              />

              <daily-card
                :name="$t('views.admin.dashboard.hotelItem')"
                :dailyValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.amenities.amount
                    : serviceTypePeriodData.amenities.count
                "
                :yesterdayValue="
                  isServiceTypePeriodAmount
                    ? serviceTypePeriodData.amenities.beforeAmount
                    : serviceTypePeriodData.amenities.beforeCount
                "
                :totalValue="serviceTypePeriodData.amenities.percent"
                icon="shopping-cart"
                :iconColor="Colors.gold"
                :isMoney="isServiceTypePeriodAmount"
                marginRight="0px"
                :periodType="serviceTypePeriodType"
                :isServiceTypePeriodAmount="isServiceTypePeriodAmount"
              />
            </div>
          </a-card>

          <a-card class="card-style" style="margin-top:10px">
            <div style="font-size:18px;font-weight:bold">
              {{$t('views.admin.dashboard.summaryForOrderStatus')}}
            </div>
            <div
              df
              fdr
              flex
              fjc
              fic
              v-if="orderAverageLoading"
              style="height:140px"
            >
              <a-card
                class="card-style"
                v-for="(item, key) in orderAverageData"
                :key="key"
                flex
                style="margin:0px 10px"
              >
                <animated-number
                  df
                  fjc
                  fic
                  style="font-size:14px;font-weight:800"
                  :value="item"
                  :formatValue="formatToPrice"
                  :duration="duration"
                />

                <div
                  df
                  fjc
                  fic
                  style="margin-top:4px;font-size:14px;font-weight:800"
                >
                  {{ key }}
                </div>
              </a-card>
            </div>
            <div v-if="!orderAverageLoading" df fjc fic style="height:140px">
              <a-icon type="loading" style="color:blue" />
            </div>
          </a-card>
        </div>
      </div>

      <div df fdr style="margin:16px 0px 20px 0px">
        <a-card style="flex:1;margin-right:10px;">
          <div df fic class="title-style">
            {{$t('views.admin.dashboard.saleWeek')}}
          </div>
          <div df fic class="sub-title-style">
            {{$t('views.admin.dashboard.saleWeekDesc')}}
          </div>

          <line-chart
            v-if="weeklyAverageLoading"
            :chartdata="weeklyAverageData.chartdata"
            :options="weeklyAverageData.options"
          />

          <div v-if="!weeklyAverageLoading" df fjc fic style="height:400px">
            <a-icon type="loading" style="color:blue" />
          </div>
        </a-card>

        <a-card style="flex:1;">
          <div df fic fjb class="title-style" style="margin-bottom:12px">
            <div>
              {{$t('views.admin.dashboard.summaryForService')}}
              <a-radio-group
                style="margin-left:16px"
                name="radioGroup"
                v-model="isServiceTypeAmount"
              >
                <a-radio @click="serviceTypeLoad" :value="true">
                  {{ $t('views.admin.dashboard.sale') }}
                </a-radio>
                <a-radio @click="serviceTypeLoad" :value="false">
                  {{ $t('views.admin.dashboard.saleCnt') }}
                </a-radio>
              </a-radio-group>
            </div>
            <div df fdr fjc fic style="font-size:12px;">
              {{ $t('views.admin.dashboard.desc') }}
              <a-switch
                style="margin-left:6px"
                v-model="isServiceTypeDetail"
                @change="serviceTypeLoad"
              />
            </div>
          </div>

          <bar-chart
            v-if="serviceTypeLoading && isServiceTypeAmount"
            :chartdata="serviceTypeData.chartdata"
          />

          <pie-chart
            v-if="serviceTypeLoading && !isServiceTypeAmount"
            :chartdata="serviceTypeData.chartdata"
          />

          <div v-if="!serviceTypeLoading" df fjc fic style="height:400px">
            <a-icon type="loading" style="color:blue" />
          </div>
        </a-card>
      </div>

      <div df fdr flex style="margin:16px 0px 20px 0px">
        <a-card style="flex:7;margin-right:10px">
          <div df fic fjb class="title-style" style="margin-bottom:20px">
            <div>
              {{ $t('views.admin.dashboard.summaryForYear') }}
              <a-radio-group
                style="margin-left:20px"
                name="radioGroup"
                v-model="isYearlyAverageAmount"
              >
                <a-radio @click="yearlyAverageLoad" :value="true">
                  {{ $t('views.admin.dashboard.sale') }}
                </a-radio>
                <a-radio @click="yearlyAverageLoad" :value="false">
                  {{ $t('views.admin.dashboard.saleCnt') }}
                </a-radio>
              </a-radio-group>
            </div>
            <a-select
              style="width: 100px"
              :placeholder="$t('views.admin.dashboard.selectYear')"
              v-model="selectedYear"
              @change="yearlyAverageLoad"
            >
              <a-select-option
                v-for="item in yearList"
                :key="item"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </div>

          <area-chart
            style="height:500px"
            v-if="yearlyAverageLoading"
            :labels="yearlyAverageData.labels"
            :data="yearlyAverageData.data"
            :dataLabel="yearlyAverageData.dataLabel"
          />

          <div v-if="!yearlyAverageLoading" df fjc fic style="height:400px">
            <a-icon type="loading" style="color:blue" />
          </div>
        </a-card>

        <div style="flex:3" df fdc>
          <a-card style="flex:1">
            <div df fic fjb class="title-style" style="margin-bottom:12px">
              <div>
                {{ $t('views.admin.dashboard.deviceStatus') }}
              </div>
            </div>

            <div df fdr fjb>
              <div flex style="margin-right:20px">
                <div df fdr fjb fic style="margin-top:20px">
                  <div>
                    <a-icon
                      class="icon-style"
                      :style="{ color: `#000000` }"
                      type="tablet"
                    />
                  </div>
                  <div df fdc fie>
                    <div style="font-size:14px;font-weight:bold">
                      ROOM
                    </div>
                    <animated-number
                      style="font-size:18px;font-weight:bold"
                      :value="deviceAverageData.room"
                      :formatValue="formatToPrice"
                      :duration="duration"
                    />
                  </div>
                </div>

                <div df fdr fjb fic style="margin-top:20px">
                  <div>
                    <a-icon
                      class="icon-style"
                      :style="{ color: `#b5b5b5` }"
                      type="control"
                    />
                  </div>
                  <div df fdc fie>
                    <div style="font-size:14px;font-weight:bold">
                      RECEPTION
                    </div>
                    <animated-number
                      style="font-size:18px;font-weight:bold"
                      :value="deviceAverageData.reception"
                      :formatValue="formatToPrice"
                      :duration="duration"
                    />
                  </div>
                </div>
              </div>

              <div df fdc fjc fic>
                <div
                  v-if="!deviceAverageLoading"
                  df
                  fjc
                  fic
                  style="height:120px;width:120px"
                >
                  <a-icon type="loading" style="color:blue" />
                </div>

                <a-tooltip
                  v-if="deviceAverageLoading"
                  :title="deviceProgressTitle"
                >
                  <a-progress
                    type="dashboard"
                    :percent="100"
                    :success-percent="
                      (deviceAverageData.use /
                        (deviceAverageData.nonUse + deviceAverageData.use)) *
                        100
                    "
                    strokeColor="red"
                  >
                    <template #format="">
                      <span
                        style="font-size:11px;font-weight:bold;color:#87D068"
                        >{{ $t('views.admin.dashboard.use') }}
                      </span>
                      /
                      <span
                        style="font-size:11px;font-weight:bold;color:#FF0000"
                        >{{ $t('views.admin.dashboard.noUse') }}
                      </span>
                    </template>
                  </a-progress>
                </a-tooltip>
              </div>
            </div>
          </a-card>

          <a-card style="flex:1;margin-top:10px">
            <div df fic fjb class="title-style" style="margin-bottom:12px">
              <div>
                {{ $t('views.admin.dashboard.serviceCancelStatus') }}
              </div>
            </div>

            <pie-chart
              style="height:260px;"
              v-if="cancelServiceTypeLoading"
              :chartdata="cancelServiceTypeData.chartdata"
            />

            <div
              v-if="!cancelServiceTypeLoading"
              df
              fjc
              fic
              style="height:260px"
            >
              <a-icon type="loading" style="color:blue" />
            </div>
          </a-card>
        </div>
      </div>
    </div>
    <a-modal :visible="visible" :title="$t('views.admin.dashboard.saleCnt')" :closable="false">
      <div v-for="(item, key) in weeklyModalValue" :key="key">
        <div
          df
          flex
          fdr
          fjb
          style="padding: 10px 0px;border-bottom:1px solid #dddddd"
        >
          <div style="font-size:14px;">
            {{ item.name }}
          </div>
          <div style="font-size:16px;font-weight:bold">{{ item.count }} {{$t('views.admin.dashboard.gun')}}</div>
        </div>
      </div>

      <div slot="footer">
        <a-button type="primary" @click="closeModal">{{$t('views.admin.dashboard.ok')}}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import AnimatedNumber from "animated-number-vue";
import moment from "moment";

import StatisticsApi from "@/api/shared/statistics";
import {i18n} from "@/i18n";

import Colors from "@/utils/colors";
import {
  GmtConversionReverse,
  StartDate,
  EndDate,
} from "@/utils/gmtConversion";

import DailyCard from "@/components/dashboard/DailyCard";
import TotalCard from "@/components/dashboard/TotalCard";
import AreaChart from "@/components/charts/AreaChart";
import BarChart from "@/components/charts/BarChart";
import LineChart from "@/components/charts/LineChart";
import PieChart from "@/components/charts/PieChart";

const color = [
  "#A3B7D0",
  "#607EA2",
  "#283D6C",
  "#07174A",
  "#514C48",
  "#2e2b28",
  "#D4C083",
  "#F5EAD4",
  "#b5b5b5",
  "#F0F2F5",
];

const color2 = [
  "#008888",
  "#00A6C6",
  "#008FAD",
  "#02495B",
  "#A6794F",
  "#F1DBCC",
];

@Component({
  components: {
    AnimatedNumber,

    AreaChart,
    BarChart,
    LineChart,
    PieChart,

    DailyCard,
    TotalCard,
  },
})
export default class Dashboard extends Vue {
  Colors = Colors;
  moment = moment;
  duration = 500;

  timezone = localStorage.getItem("timezone");
  hotelIndex = this.$route.params.key;
  selectedYear = moment().format("YYYY");
  visible = false;

  yearList = [];

  yearlyAverageData = {};
  yearlyAverageLoading = false;
  isYearlyAverageAmount = true;

  serviceTypeLoading = false;
  isServiceTypeDetail = false;
  isServiceTypeAmount = false;
  serviceTypeData = {
    chartdata: {},
  };

  serviceTypePeriodType = "0";
  serviceTypePeriodData = {
    amenities: {
      amount: 0,
    },
    concierge: {
      amount: 0,
    },
    shop: {
      amount: 0,
    },
    total: {
      amount: 0,
    },
  };
  selectedMonth = moment().format("YYYY-MM");
  isServiceTypePeriodAmount = true;

  monthlyAverageData = {};

  weeklyModalValue = [];
  weeklyAverageLoading = false;
  weeklyAverageSubValues = [];
  weeklyAverageData = {
    chartdata: {},
    options: {},
  };

  deviceAverageLoading = false;
  deviceAverageData = {};
  deviceProgressTitle = "";

  cancelServiceTypeLoading = false;
  cancelServiceTypeData = {
    chartdata: {},
  };

  orderAverageLoading = false;
  orderAverageData = {};

  formatToPrice(value) {
    const data = Number(value).toFixed(0);

    return `${Number(data).toLocaleString()}`;
  }

  async mounted() {
    await this.yearListLoad();
    await this.byHotelLoad();
  }

  async yearListLoad() {
    try {
      const { data } = await StatisticsApi.YearList();

      this.yearList = data;
    } catch {}
  }

  async byHotelLoad() {
    try {
      this.cancelServiceTypeLoading = false;
      this.weeklyAverageLoading = false;
      this.serviceTypeLoading = false;
      this.orderAverageLoading = false;
      this.yearlyAverageLoading = false;
      this.deviceAverageLoading = false;

      await this.orderAverageLoad();
      await this.monthlyAverageLoad();
      await this.serviceTypePeriodLoad();

      await this.weeklyAverageLoad();
      await this.serviceTypeLoad();

      await this.yearlyAverageLoad();
      await this.deviceAverageLoad();
      await this.cancelServiceTypeLoad();
    } catch {}
  }

  async monthlyAverageLoad() {
    try {
      const query = {
        index: this.hotelIndex,
      };

      const { data } = await StatisticsApi.MonthlyAverage(query);
      this.monthlyAverageData = data;
    } catch {}
  }

  async deviceAverageLoad() {
    try {
      this.deviceAverageLoading = false;
      const query = {
        index: this.hotelIndex,
      };

      const { data } = await StatisticsApi.DeviceAverage(query);
      this.deviceAverageData = data;

      this.deviceProgressTitle = `${this.deviceAverageData.use} / ${this.deviceAverageData.nonUse}`;

      this.deviceAverageLoading = true;
    } catch {}
  }

  async yearlyAverageLoad() {
    try {
      this.yearlyAverageLoading = false;

      let query = [];

      for (let i = 1; i < 13; i++) {
        const valueDay = moment(`${this.selectedYear} ${i}`).format(
          "YYYY-MM-DD HH:mm",
        );

        query = [
          ...query,
          {
            startDate: GmtConversionReverse(
              StartDate(valueDay, "month"),
              this.timezone,
            ),
            endDate: GmtConversionReverse(
              EndDate(valueDay, "month"),
              this.timezone,
            ),
          },
        ];
      }

      const { data } = await StatisticsApi.YearlyAverage(
        this.hotelIndex,
        query,
      );

      if (this.isYearlyAverageAmount) {
        this.yearlyAverageData = {
          labels: data.title,
          data: data.subValues,
          dataLabel: i18n.tc('views.admin.dashboard.sale'),
        };
      } else {
        this.yearlyAverageData = {
          labels: data.title,
          data: data.values,
          dataLabel: i18n.tc('views.admin.dashboard.saleCnt'),
        };
      }

      this.yearlyAverageLoading = true;
    } catch {}
  }

  async serviceTypeLoad() {
    try {
      this.serviceTypeLoading = false;

      const query = {
        index: this.hotelIndex,
        isDetail: this.isServiceTypeDetail,
      };

      const { data } = await StatisticsApi.ServiceType(query);

      if (this.isServiceTypeAmount) {
        this.serviceTypeData = {
          chartdata: {
            labels: data.title,
            datasets: [
              {
                backgroundColor: color,
                data: data.subValues,
              },
            ],
          },
        };
      } else {
        this.serviceTypeData = {
          chartdata: {
            labels: data.title,
            datasets: [
              {
                backgroundColor: color,
                data: data.values,
              },
            ],
          },
        };
      }

      this.serviceTypeLoading = true;
    } catch {}
  }

  async serviceTypePeriodLoad() {
    try {
      let query;

      const today = moment();
      const weekLastDay = moment(today).add("-6", "d");

      switch (this.serviceTypePeriodType) {
        case "0":
          query = {
            index: this.hotelIndex,
            startDate: GmtConversionReverse(
              StartDate(today, "day"),
              this.timezone,
            ),
            endDate: GmtConversionReverse(EndDate(today, "day"), this.timezone),
          };
          break;
        case "1":
          query = {
            index: this.hotelIndex,
            startDate: GmtConversionReverse(
              StartDate(weekLastDay, "day"),
              this.timezone,
            ),
            endDate: GmtConversionReverse(EndDate(today, "day"), this.timezone),
          };
          break;
        case "2":
          query = {
            index: this.hotelIndex,
            startDate: GmtConversionReverse(
              StartDate(this.selectedMonth, "month"),
              this.timezone,
            ),
            endDate: GmtConversionReverse(
              EndDate(this.selectedMonth, "month"),
              this.timezone,
            ),
          };
          break;
      }

      const { data } = await StatisticsApi.ServiceTypePeriod(
        this.serviceTypePeriodType,
        query,
      );

      this.serviceTypePeriodData = data;
    } catch {}
  }

  async weeklyAverageLoad() {
    try {
      this.weeklyAverageLoading = false;

      const weekLastDay = moment().add("-6", "d");

      let query = [];

      for (let i = 0; i < 7; i++) {
        const valueDay = moment(weekLastDay)
          .add(`+${i}`, "d")
          .format("YYYY-MM-DD HH:mm");

        query = [
          ...query,
          {
            title: moment(valueDay).format("YYYY-MM-DD"),
            startDate: GmtConversionReverse(
              StartDate(valueDay, "day"),
              this.timezone,
            ),
            endDate: GmtConversionReverse(
              EndDate(valueDay, "day"),
              this.timezone,
            ),
          },
        ];
      }

      const { data } = await StatisticsApi.WeeklyAverage(
        this.hotelIndex,
        query,
      );

      this.weeklyAverageSubValues = data.subValues;
      this.weeklyAverageData = {
        chartdata: {
          labels: data.title,
          datasets: [
            {
              backgroundColor: "transparent",
              borderColor: "rgba(0, 136, 136,0.7)",
              pointBackgroundColor: "rgba(40, 61, 108,1)",
              data: data.values,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                    return Number(value).toLocaleString();
                  },
                },
                gridLines: {
                  display: true,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
                gridLines: {
                  display: false,
                },
              },
            ],
          },
          onClick: this.handle,
          tooltips: {
            enabled: true,
            mode: "single",
            callbacks: {
              label: function(tooltipItems, data) {
                return Number(tooltipItems.yLabel).toLocaleString();
              },
            },
          },

          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        },
      };

      this.weeklyAverageLoading = true;
    } catch {}
  }

  async cancelServiceTypeLoad() {
    try {
      this.cancelServiceTypeLoading = false;

      const query = {
        index: this.hotelIndex,
      };

      const { data } = await StatisticsApi.CancelServiceType(query);

      const title = data.map(item => item.title);
      const chartData = data.map(item => item.count);

      this.cancelServiceTypeData = {
        chartdata: {
          labels: title,
          datasets: [
            {
              backgroundColor: color2,
              data: chartData,
            },
          ],
        },
      };

      this.cancelServiceTypeLoading = true;
    } catch {}
  }

  async orderAverageLoad() {
    try {
      this.orderAverageLoading = false;
      const query = {
        index: this.hotelIndex,
      };

      const { data } = await StatisticsApi.OrderAverage(query);

      this.orderAverageData = data;
      this.orderAverageLoading = true;
    } catch {}
  }

  handle(point, event) {
    const item = event[0];

    this.showModal(item);
  }

  showModal(item) {
    this.weeklyModalValue = this.weeklyAverageSubValues[item._index];

    this.visible = true;
  }

  closeModal() {
    this.visible = false;
  }
}
</script>

<style lang="scss" scoped>
$icon-color: "black";

.title-style {
  font-size: 20px;
  font-weight: bold;
}

.sub-title-style {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 12px;
}

.icon-style {
  font-size: 40px;
  color: $icon-color;
}
</style>
