<template>
  <div class="login-background">
    <div df fdc fjc fic style="min-height: 100vh">
      <a-card>
        <div class="master-logo" />

        <div style="width: 400px">
          <a-form-model
            ref="form"
            :model="data"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            @submit.prevent="loginSubmit"
          >
            <a-form-model-item label="아이디">
              <a-input v-model="data.id" />
            </a-form-model-item>

            <a-form-model-item label="비밀번호">
              <a-input-password v-model="data.password" />
            </a-form-model-item>

            <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
              <a-button
                block
                size="large"
                :loading="loading"
                type="primary"
                htmlType="submit"
              >
                로그인
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Filter } from "@/utils/filterValue";

import jwt from "jsonwebtoken";

import AuthApi from "@/api/auth";
import HotelApi from "@/api/admin/hotel";
import SalesApi from "@/api/master/salesman";

@Component({
  components: {},
})
export default class Login extends Vue {
  loading = false;

  labelCol = { span: 24 };
  wrapperCol = { span: 24 };

  @Filter
  filter = {
    keyword: "",
  };

  buttonItemLayout() {
    const { formLayout } = this;
    return formLayout === "horizontal"
      ? {
          wrapperCol: { span: 24, offset: 4 },
        }
      : {};
  }

  data = {
    id: "",
    password: "",
  };

  async loginSubmit() {
    try {
      this.loading = true;
      const token = await AuthApi.Login(this.data);

      if (!token) {
        throw "";
      }

      await localStorage.setItem("token", token);

      const decoded = jwt.decode(token);

      await localStorage.setItem("level", decoded.Level);
      await localStorage.setItem("adminId", decoded.nameid);
      await localStorage.setItem("role", decoded.role);
      await localStorage.setItem("storeName", decoded.StoreId);
      await localStorage.setItem("userName", decoded.UserName);

      if (decoded.role == "Agent" || decoded.role == "Sales") {
        this.filter.keyword = decoded.unique_name;
        var salesManData = await SalesApi.List(this.filterValue);
        var list = salesManData.list.filter(
          (a) => a.salesCode == decoded.unique_name,
        );
        if (list[0] != null) {
          var salesMan = list[0];
          await localStorage.setItem("salesCode", salesMan.salesCode);
          await localStorage.setItem("salesId", salesMan.index);
        }
      }

      switch (decoded.role) {
        case "Master":
          await this.$router.push({ name: "Dashboard" });
          break;

        case "Agent":
          await this.$router.replace({ name: "Agency_Dashboard" });
          break;

        case "Sales":
          await this.$router.replace({ name: "Sales_Dashboard" });
          break;

        case "Admin":
          const { information, confHotel } = await HotelApi.Detail(
            decoded.StoreId,
          );

          await localStorage.setItem("timezone", confHotel.timezone);
          await localStorage.setItem("country", confHotel.country);

          await this.$router.replace({
            name: "Admin_Dashboard",
            params: {
              key: Number(decoded.StoreId),
              hotelName: String(information.Kr.name),
            },
          });
          break;
      }
    } catch (e) {
      console.log(e);
      let error = e.response?.data?.data
        ? e.response.data.data
        : "아이디 비밀번호를 확인해주세요.";

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.login-background {
  position: relative;
  background: #000000;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../public/img/images/25101.jpg");
    background-size: 100%;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.master-logo {
  height: 150px;
  margin: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../public/img/icons/logo/05_01.png");
}
</style>
