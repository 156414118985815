import { render, staticRenderFns } from "./ReceptionAddModal.vue?vue&type=template&id=652dc092&scoped=true&"
import script from "./ReceptionAddModal.vue?vue&type=script&lang=js&"
export * from "./ReceptionAddModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652dc092",
  null
  
)

export default component.exports