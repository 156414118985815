<template>
  <a-modal
    :afterClose="afterClose"
    title="서비스추가"
    :visible="visible"
    :closable="false"
  >
    <div df fdr fjb>
      <a-select
        :value="choiceType || undefined"
        :placeholder="$t('components.modal.service.typeService')"
        style="width: 250px; margin-right: 8px"
        :loading="loading"
        @change="typeChange"
      >
        <a-select-option
          v-for="(item, index) in serviceType"
          :key="index"
          :value="index"
        >
          {{ item }}
        </a-select-option>
      </a-select>

      <a-select
        :value="choiceService || undefined"
        :placeholder="$t('components.modal.service.choiceService')"
        style="width: 250px; margin-left: 8px"
        :loading="loading"
        @change="serviceChange"
      >
        <a-select-option
          v-for="item in selectedServices"
          :key="item.index"
          :value="item.index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <div slot="footer">
      <a-button @click="closeModal">취소</a-button>
      <a-button type="primary" :loading="loading" @click="save">
        추가
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";
import EnumInfoApi from "@/api/enumInfo";
import { i18n } from "@/i18n";

@Component({
  components: {},
})
export default class ServiceAddModal extends Vue {
  @Prop({ default: undefined }) hotelIndex;
  @Prop({ default: false }) visible;

  serviceType = [];
  basicServices = [];

  selectedServices = {};
  selectedService = {};

  choiceType = "";
  choiceService = "";

  loading = false;

  async mounted() {
    await this.loadBasicService();
  }

  async loadBasicService() {
    try {
      this.loading = true;

      this.basicServices = await HotelApi.BasicServiceList({ isCall: false });
      const { data } = await EnumInfoApi.List("service_type");
      const parseData = JSON.parse(data);
      this.serviceType = parseData[0].Data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  closeModal() {
    this.choiceType = "";
    this.choiceService = "";

    this.$emit("close");
  }

  async typeChange(value) {
    this.selectedServices = this.basicServices.filter(
      a => a.type === Number(value),
    );

    this.choiceType = value;
  }

  async serviceChange(value) {
    this.selectedService = this.basicServices.find(a => a.index === value);
    console.log(this.selectedService);

    this.choiceService = value;
  }

  async save() {
    try {
      this.loading = true;

      await HotelApi.ServiceCreate(this.hotelIndex, this.selectedService);
      await this.$emit("load");

      this.closeModal();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  afterClose() {
    this.choiceType = "";
    this.choiceService = "";

    this.selectedServices = {};
  }
}
</script>

<style lang="scss" scoped></style>
