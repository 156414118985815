<template>
  <div>
    <div class="main-background">
      <a-space df fdr fjb style="margin-bottom: 16px">
        <div>
          <div style="font-size: 16px; font-weight: bold">
            {{$t('views.admin.promotionBanner.pbNotice1')}}
          </div>
          <div style="font-size: 12px">
            {{$t('views.admin.promotionBanner.pbNotice2')}}
          </div>
          <div style="font-size: 12px">
            {{$t('views.admin.promotionBanner.pbNotice3')}}
          </div>
        </div>

        <div v-if="levelCheck === '0' ? true : false">
          <a-button
            :loading="loading"
            style="margin-right: 12px"
            @click="imageEdit()"
          >
            <a-icon type="upload" /> {{$t('views.admin.promotionBanner.addImg')}}
          </a-button>
        </div>
      </a-space>

      <a-row class="row-style">
        <draggable
          :list="data"
          group="people"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          class="draggable-style"
          @change="imageOrderChanged"
        >
          <a-col
            df
            fdc
            fic
            fjc
            v-for="element in data"
            :key="element.index"
            :xs="{ span: 6, offset: 2 }"
            :lg="{ span: 5, offset: 2 }"
            :name="!drag ? 'flip-list' : null"
            class="col-style"
          >
            <a @click="imageEdit(element)">
              <div
                class="icon-logo"
                :style="{
                  backgroundImage: `url('${element.image}')`,
                }"
              />
            </a>
          </a-col>
        </draggable>
      </a-row>
    </div>
    <a-modal
      width="800px"
      :title="promotionTitle"
      :visible="visible"
      :closable="false"
      :afterClose="afterClose"
    >
      <div>
        <upload
          dir-name="hotelbanner"
          v-model="selectedData"
          listType="picture-card"
          :selected="true"
          :disabled="levelCheck === '0' ? false : true"
        >
          <div class="image-expand">
            <div
              v-if="selectedData.uploadedUrl[0]"
              :style="{
                backgroundImage: `url('${selectedData.uploadedUrl[0]}')`,
              }"
            />
            <div v-else df fdc fjc fic>
              <a-icon :type="'plus'" />
              <div>Upload</div>
              <div>{{$t('views.admin.promotionBanner.uploadEx1')}}</div>
            </div>
          </div>
        </upload>

        <div
          df
          fdr
          fje
          style="margin-bottom: 12px"
          v-if="selectedData.index && levelCheck === '0' ? true : false"
        >
          <a-popconfirm
            :title="$t('views.admin.promotionBanner.askDeleteImg')"
            :ok-text="$t('views.admin.promotionBanner.delete')"
            :cancel-text="$t('views.admin.promotionBanner.cancel')"
            @confirm="imageDelete()"
          >
            <a-button type="danger" icon="delete" ghost size="small">
              {{$t('views.admin.promotionBanner.deleteImg')}}
            </a-button>
          </a-popconfirm>
        </div>

        <div df fdr fjb style="padding: 10px; border-top: 2px solid #eeeeee">
          <div>
            <div>{{$t('views.admin.promotionBanner.service')}}</div>
            <a-select
              style="width: 220px; margin-top: 5px"
              :placeholder="$t('views.admin.promotionBanner.selectNone')"
              @change="categoryLoad(true)"
              v-model="selectedData.serviceId"
            >
              <a-select-option :value="0">{{$t('views.admin.promotionBanner.selectNone')}}</a-select-option>
              <a-select-option
                v-for="item in serviceList"
                :key="item.index"
                :value="item.index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <div>
            <div>{{$t('views.admin.promotionBanner.selectNone')}}</div>
            <a-select
              style="width: 220px; margin-top: 5px"
              :placeholder="$t('views.admin.promotionBanner.none')"
              v-model="selectedData.extraInfo"
            >
              <a-select-option :value="0">{{$t('views.admin.promotionBanner.none')}}</a-select-option>
              <a-select-option
                v-for="item in categoryList"
                :key="item.index"
                :value="String(item.index)"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>

          <div>
            <div>{{$t('views.admin.promotionBanner.link')}}</div>
            <a-input
              v-model="selectedData.link"
              style="width: 220px; margin-top: 5px"
            />
          </div>
        </div>

        <div style="font-size: 12px; color: #db0000; font-weight: bold">
          {{$t('views.admin.promotionBanner.linkNotice')}}
        </div>
      </div>

      <template slot="footer">
        <a-button @click="handleOk"> {{$t('views.admin.promotionBanner.cancel')}} </a-button>
        <a-button
          v-if="levelCheck === '0' ? true : false"
          @click="imageSave"
          type="primary"
        >
          {{$t('views.admin.promotionBanner.save')}}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Draggable from "vuedraggable";
import DraggableTable from "@/components/DraggableTable";
import Upload from "@/components/Upload";

import ServiceApi from "@/api/admin/service";
import CategoryApi from "@/api/admin/category";
import BannerApi from "@/api/admin/banner";
import TypeOfServiceApi from "@/api/admin/typeOfService";
import {i18n} from "@/i18n";

@Component({
  components: { Upload, DraggableTable, Draggable },
})
export default class PromotionBanner extends Vue {
  levelCheck = localStorage.getItem("level");
  visible = false;
  loading = false;

  drag = false;
  dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  data = [];
  selectedData = {
    serviceId: undefined,
    extraInfo: undefined,
    uploadedUrl: [],
    link: "",
  };

  serviceList = [];
  categoryList = [];

  includeCode = [
    "SV_SHOP",
    "SV_ROOM_SERVICE",
    "SV_HOTEL_AMENITIES",
    "SV_HOTEL_INFO_IMAGE",
  ];

  async mounted() {
    await this.serviceLoad();
    await this.categoryLoad();

    await this.load();
  }

  async serviceLoad() {
    try {
      this.loading = true;

      const list = await ServiceApi.List(this.$route.params.key);

      const serviceList = list.filter(
        (item) => this.includeCode.indexOf(item.code) !== -1,
      );

      this.serviceList = serviceList.map(({ index, name, code }) => ({
        index,
        name,
        code,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async categoryLoad(reset) {
    try {
      if (reset) {
        this.selectedData.extraInfo = undefined;
      }
      this.categoryList = [];

      this.loading = true;

      if (!this.selectedData.serviceId) {
        return;
      }

      const service = this.serviceList.find(
        (item) => item.index === this.selectedData.serviceId,
      );

      if (service.code === "SV_HOTEL_INFO_IMAGE") {
        const { data } = await TypeOfServiceApi.CategoryList(
          this.selectedData.serviceId,
        );

        this.categoryList = data.map(({ information, index }) => ({
          index,
          name: information.Kr.name,
        }));
      } else {
        const { data } = await CategoryApi.List({
          "filter.storeIndex": this.$route.params.key,
          "filter.serviceID": this.selectedData.serviceId,
        });
        this.categoryList = data.map(({ information, index }) => ({
          index,
          name: information.Kr.name,
        }));
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async load() {
    try {
      this.loading = true;

      const { data } = await BannerApi.List(this.$route.params.key);
      this.data = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async imageOrderChanged() {
    try {
      if (this.levelCheck === "0") {
        await BannerApi.Order(this.$route.params.key, this.data);

        await this.load();
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async imageDelete() {
    try {
      await BannerApi.Delete(this.selectedData.index);

      await this.load();
      this.handleOk();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async imageEdit(data) {
    try {
      this.visible = true;

      if (!data) {
        return;
      }

      this.selectedData = {
        ...data,
        serviceId: data.serviceId ? data.serviceId : 0,
        extraInfo: data.extraInfo ? data.extraInfo : 0,
        uploadedUrl: [data.image],
        link: data.link,
      };

      await this.categoryLoad();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async imageSave() {
    try {
      if (!this.selectedData.uploadedUrl[0]) {
        return this.$message.warning(i18n.tc('views.admin.promotionBanner.uploadImg'));
      }

      if (this.selectedData.index) {
        const data = {
          index: this.selectedData.index,
          serviceId: this.selectedData.serviceId
            ? Number(this.selectedData.serviceId)
            : null,
          extraInfo: this.selectedData.extraInfo
            ? String(this.selectedData.extraInfo)
            : null,
          image: this.selectedData.uploadedUrl[0],
          link: this.selectedData.link,
        };

        await BannerApi.Update(this.$route.params.key, [data]);
      } else {
        const data = {
          serviceId: this.selectedData.serviceId
            ? Number(this.selectedData.serviceId)
            : null,
          extraInfo: this.selectedData.extraInfo
            ? String(this.selectedData.extraInfo)
            : null,
          image: this.selectedData.uploadedUrl[0],
          link: this.selectedData.link,
        };

        await BannerApi.Create(this.$route.params.key, data);
      }

      await this.load();
      this.handleOk();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  afterClose() {
    this.selectedData = {
      serviceId: undefined,
      extraInfo: undefined,
      uploadedUrl: [],
    };
  }

  handleOk(e) {
    this.visible = false;
  }

  get promotionTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc('views.admin.promotionBanner.promotionInfo');
    } else {
      return this.selectedData.index ? i18n.tc('views.admin.promotionBanner.editPromotion') : i18n.tc('views.admin.promotionBanner.addPromotion');
    }
  }
}
</script>

<style lang="scss" scoped>
.row-style {
  border: 1px solid #eeeeee;
  box-shadow: 5px 3px 3px;
  border-radius: 5px;
  min-height: 750px;
  width: 100%;
}

.draggable-style {
  height: 100%;
  width: 100%;
}

.disabled {
  opacity: 0.4;
}

.icon-logo {
  height: 340px;
  width: 340px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.col-style {
  margin: 20px 35px;
}

.image-expand {
  position: relative;
  height: 540px;
  width: 100%;
  margin-bottom: 16px;
  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
