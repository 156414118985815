<template>
  <div df fdr fjc>
    <div v-if="value" df fdr fjc>
      <div class="battery-style" v-bind:class="batteryClassObject(value)"></div>
    </div>
    <div
      :style="{
        color: value ? `black` : `${Colors.grayDark}`,
      }"
    >
      {{ value ? `${value}%` : $t('components.tablet.none') }}
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import Colors from "@/utils/colors";

@Component({
  components: {},
})
export default class BatteryItem extends Vue {
  @Prop({ default: "0" }) value;

  Colors = Colors;

  batteryClassObject(value) {
    if (value >= 80) {
      return {
        full: true,
        more: false,
        half: false,
        low: false,
        empty: false,
      };
    }

    if (value >= 60 && value < 80) {
      return {
        full: false,
        more: true,
        half: false,
        low: false,
        empty: false,
      };
    }
    if (value >= 30 && value < 60) {
      return {
        full: false,
        more: false,
        half: true,
        low: false,
        empty: false,
      };
    }

    if (value > 0 && value < 30) {
      return {
        full: false,
        more: false,
        half: false,
        low: true,
        empty: false,
      };
    }
    if ((value = 0)) {
      return {
        full: false,
        more: false,
        half: false,
        low: false,
        empty: true,
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.battery-style {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  margin-right: 4px;
}

.full {
  background-image: url("../../../public/img/icons/battery/full-battery.png");
  filter: invert(35%) sepia(68%) saturate(4693%) hue-rotate(108deg)
    brightness(98%) contrast(102%);
}

.more {
  background-image: url("../../../public/img/icons/battery/more-battery.png");
  filter: invert(35%) sepia(68%) saturate(4693%) hue-rotate(108deg)
    brightness(98%) contrast(102%);
}

.half {
  background-image: url("../../../public/img/icons/battery/half-battery.png");
  filter: invert(100%) sepia(43%) saturate(7311%) hue-rotate(322deg)
    brightness(100%) contrast(94%);
}

.low {
  background-image: url("../../../public/img/icons/battery/low-battery.png");
  filter: invert(45%) sepia(20%) saturate(1799%) hue-rotate(314deg)
    brightness(88%) contrast(102%);
}

.empty {
  background-image: url("../../../public/img/icons/battery/empty-battery.png");
  filter: invert(45%) sepia(20%) saturate(1799%) hue-rotate(314deg)
    brightness(88%) contrast(102%);
}
</style>
