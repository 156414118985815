import client from "../client";

export default class Hotel {
  static async List(options) {
    return await client.get("master/hotel", options);
  }

  static async Create(data) {
    return await client.post("master/hotel", data);
  }

  static async SimpleList() {
    return await client.get("master/hotel/simple");
  }

  static async Detail(index) {
    return await client.get(`master/hotel/${index}`);
  }

  static async Delete(index) {
    return await client.del(`master/hotel/${index}`);
  }

  static async Update(index, data) {
    return await client.put(`master/hotel/${index}`, data);
  }

  static async AppCodeCheck(appCode) {
    return await client.get(`master/hotel/appCode/check/${appCode}`);
  }

  static async BasicServiceList(isCall) {
    return await client.get(`master/hotel/basic/service`, isCall);
  }

  static async ServiceCreate(index, data) {
    return await client.post(`master/hotel/add/service/${index}`, data);
  }

  static async ServiceUpdate(index, data) {
    return await client.put(`master/hotel/update/services/${index}`, data);
  }

  static async ServiceDelete(index) {
    return await client.del(`master/hotel/service/${index}`);
  }

  static async LangsUpdate(data) {
    return await client.put(`master/hotel/update/langs`, data);
  }

  static async LangsAdd(index, code) {
    return await client.get(`master/hotel/add/language/${index}`, code);
  }

  static async ReceptionList(index) {
    return await client.get(`master/hotel/reception/admin/${index}`);
  }

  static async ReceptionCreate(index, data) {
    return await client.post(`master/hotel/reception/admin/${index}`, data);
  }

  static async ReceptionUpdate(index, data) {
    return await client.put(`master/hotel/reception/admin/${index}`, data);
  }

  static async ReceptionDelete(index) {
    return await client.del(`master/hotel/reception/admin/${index}`);
  }

  static async gbStore(index) {
    return await client.get(`master/hotel/gb/store/${index}`);
  }

  static async ReceptionStoreDelete(index) {
    return await client.del(`master/hotel/reception/store/${index}`);
  }
}
