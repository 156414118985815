<template>
  <a-modal
    :visible="visible"
    :closable="false"
    :title="optionTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editOptionForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr fje>
        <small>{{ $t("components.modal.uploadCaution") }}</small>
      </div>
      <div class="card-container">
        <language-tab
          ref="languageTabForm"
          :value="data"
          :isLanguageUsed="isLanguageUsed"
          :tabs="tabs"
          :name="$t('components.modal.option.name')"
        >
        </language-tab>
      </div>

      <a-form-model-item
        prop="price"
        :label="$t('components.modal.option.price')"
      >
        <a-input-number
          style="width: 120px"
          v-model="data.price"
          :formatter="
            (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          :min="0"
          :step="1000"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="purchasePrice"
        :label="$t('components.modal.option.purchasePrice')"
      >
        <a-input-number
          style="width: 120px"
          v-model="data.purchasePrice"
          :formatter="
            (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          "
          :min="0"
          :step="1000"
        />
      </a-form-model-item>

      <a-form-model-item
        prop="status"
        :label="$t('components.modal.option.state')"
      >
        <a-radio-group button-style="solid" v-model="data.status">
          <a-radio-button value="1">{{
            $t("components.modal.option.use")
          }}</a-radio-button>
          <a-radio-button value="2">{{
            $t("components.modal.option.soldout")
          }}</a-radio-button>
          <a-radio-button value="0">{{
            $t("components.modal.option.unuse")
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeEditOption">{{
        $t("components.modal.option.cancel")
      }}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loadingEditOption"
        @click="save"
        >{{ $t("components.modal.option.save") }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import LanguageTab from "@/components/LanguageTab";
import Tabs from "@/utils/languageTab";
import ProductApi from "@/api/admin/product";
import { i18n } from "@/i18n";

@Component({
  components: { LanguageTab },
})
export default class OptionAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) groupIndex;

  tabs = Tabs;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loadingEditOption = false;

  get serviceIndex() {
    return this.$route.params.index;
  }

  closeEditOption() {
    this.$emit("close");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async saveEditOption() {
    try {
      this.loadingEditOption = true;

      if (!this.data.information.En.name) {
        this.$refs.languageTabForm.tabDefaultKeyChange();
        return this.$message.warning(i18n.tc("components.modal.option.warn"));
      }

      if (this.data.index) {
        await ProductApi.OptionGroupItemUpdate(this.data);
      } else {
        await ProductApi.OptionGroupItemCreate(this.groupIndex, this.data);
      }

      this.$emit("load");
      this.closeEditOption();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loadingEditOption = false;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("components.modal.option.nameMsg"),
            },
          ],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc("components.modal.option.stateMsg"),
        },
      ],
      price: [
        {
          required: true,
          message: i18n.tc("components.modal.option.priceMsg"),
        },
      ],
    };
  }

  get optionTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc("components.modal.option.info");
    } else {
      return this.data?.index
        ? i18n.tc("components.modal.option.edit")
        : i18n.tc("components.modal.option.add");
    }
  }

  async save() {
    this.$refs.editOptionForm.validate(async (valid) => {
      if (valid) {
        await this.saveEditOption();
      }
    });
  }
}
</script>
