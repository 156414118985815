<template>
  <a-modal
    width="800px"
    :title="$t('components.modal.service.editIcon')"
    :visible="visible"
    :closable="false"
  >
    <div>
      <upload
        dir-name="icon"
        v-model="data"
        listType="picture-card"
        :selected="true"
      >
        <div class="image-expand">
          <div
            v-if="data.uploadedUrl[0]"
            :style="{
              backgroundImage: `url('${data.uploadedUrl}')`,
            }"
          />
          <div v-else df fdc fjc fic>
            <a-icon :type="'plus'" />
            <div>{{$t('components.modal.service.upload')}}</div>
            <div>{{$t('components.modal.service.uploadEx1')}}</div>
          </div>
        </div>
      </upload>
    </div>

    <div df fdr fic style="margin-top: 12px">
      <div style="margin-right: 8px; font-weight: bold; font-size: 12px">
        {{$t('components.modal.service.colorChange')}}
      </div>
      <v-input-colorpicker v-model="data.color" />

      <a-button style="margin-left: 20px" @click="initColor">
        {{$t('components.modal.service.initColor')}}
      </a-button>
    </div>

    <div
      style="
        margin-right: 8px;
        font-weight: bold;
        font-size: 14px;
        margin-top: 12px;
      "
    >
      {{$t('components.modal.service.tempColor')}}
    </div>

    <div slot="footer">
      <a-button :loading="loading" @click="close">{{$t('components.modal.service.close')}}</a-button>
      <a-button type="primary" :loading="loading" @click="save">{{$t('components.modal.service.ok')}}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import InputColorPicker from "vue-native-color-picker";

import Upload from "@/components/Upload";
import {i18n} from "@/i18n";

@Component({
  components: { Upload, "v-input-colorpicker": InputColorPicker },
})
export default class ServiceIconEditModal extends Vue {
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;

  loading = false;

  async save() {
    try {
      this.loading = true;
      if (!this.data.uploadedUrl[0]) {
        return this.$message.warning(i18n.tc('components.modal.service.uploadWarn'));
      }

      this.$emit("saveSubmit");
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async initColor() {
    this.data.color = null;
  }

  async close() {
    this.$emit("close");
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 500px;
  width: 100%;
  margin-bottom: 16px;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
