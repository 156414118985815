import {i18n} from "@/i18n";
export function AccountColumns(){ 
  return [
  {
    width: 40,
    align: "left",
    title: "",
    scopedSlots: { customRender: "levelIcon" },
  },
  {
    dataIndex: "id",
    title: i18n.tc('js.account.id'),
    scopedSlots: { customRender: "id" },
  },
  {
    dataIndex: "hotelName",
    title: i18n.tc('js.account.hotelNm'),
  },
  {
    width: 200,
    dataIndex: "name",
    title: i18n.tc('js.account.name'),
  },
  {
    width: 120,
    dataIndex: "level",
    title: i18n.tc('js.account.level'),
    scopedSlots: { customRender: "level" },
  },
  {
    width: 200,
    align: "right",
    dataIndex: "registeredTime",
    title: i18n.tc('js.account.regiTime'),
    scopedSlots: { customRender: "registeredTime" },
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}