import client from "../client";

export default class Hotel {
  static async Detail(index) {
    return await client.get(`admin/hotel/${index}`);
  }

  static async Update(index, data) {
    return await client.put(`admin/hotel/${index}`, data);
  }

  static async RefreshLoad(index) {
    return await client.get(`admin/hotel/refresh/date/${index}`);
  }

  static async RefreshUpdate(index) {
    return await client.put(`admin/hotel/refresh/date/${index}`);
  }
}
