import client from "../client";

export default class Device {
  static async List(options) {
    return await client.get("master/device", options);
  }

  static async Create(data) {
    return await client.post("master/device", data);
  }

  static async Update(index, data) {
    return await client.put(`master/device/${index}`, data);
  }

  static async Detail(index) {
    return await client.get(`master/device/${index}`);
  }

  static async Delete(index) {
    return await client.del(`master/device/${index}`);
  }

  static async NoxChange(index) {
    return await client.put(`master/device/noxChange/${index}`);
  }

  static async DeviceStateChange(index) {
    return await client.put(`master/device/DeviceStateChange/${index}`);
  }

  static async SerialCheck(serial) {
    return await client.get(`master/device/serial/check/${serial}`);
  }

  static async StatusBatchChange(data) {
    return await client.post(`master/device/status/change`, data);
  }

  static async ExcelUplad(data) {
    return await client.fileForm(`master/device/excelRead`, data, "POST");
  }

  static async AndroidIdReset(index) {
    return await client.put(`master/device/androidId/reset/${index}`);
  }
}
