import moment from "moment";

export function GmtConversion(date, timezone) {
  if (timezone) {
    const timezoneSplit = timezone.split("GMT");

    return moment(date).add(timezoneSplit[1], "h");
  } else {
    return moment(date).add("9", "h");
  }
}

export function GmtConversionReverse(date, timezone) {
  if (timezone) {
    const timezoneSplit = timezone.split("GMT");
    const value = timezoneSplit[1].split("");
    const operator = value[0] === "+" ? "-" : "+";
    const result = operator + value[1];

    return moment(date)
      .add(result, "h")
      .format("YYYY-MM-DDTHH:mm:ss");
  } else {
    return moment(date)
      .add("-9", "h")
      .format("YYYY-MM-DDTHH:mm:ss");
  }
}

export function StartDate(date, type) {
  switch (type) {
    case "day":
      return moment(date).format("YYYY-MM-DD 00:00");

    case "month":
      return moment(date)
        .startOf("month")
        .format("YYYY-MM-DD 00:00");

    case "year":
      return moment(date)
        .startOf("year")
        .format("YYYY-MM-DD 00:00");
  }
}

export function EndDate(date, type) {
  switch (type) {
    case "day":
      return moment(date).format("YYYY-MM-DD 24:00");

    case "month":
      return moment(date)
        .endOf("month")
        .format("YYYY-MM-DD 24:00");

    case "year":
      return moment(date)
        .endOf("year")
        .format("YYYY-MM-DD 24:00");
  }
}
