<template>
  <div class="overallStatistics">
    <div class="main-background">
      <a-space df fdr style="margin-bottom:20px">
        <a-date-picker
          v-model="filter.startDate"
          :disabled-date="disabledStartDate"
        />
        <span>~</span>
        <a-date-picker
          v-model="filter.endDate"
          :disabled-date="disabledEndDate"
        />

        <a-button @click="load()">{{$t('views.admin.connectionLog.search')}}</a-button>
      </a-space>

      <div df fdr fic fjc flex style="margin-bottom:16px">
        <div
          style="background-color: #F0643B;padding: 10px 8px;border-radius: 10px;color: #ffffff;font-size: 14px;font-weight: 500;"
        >
          {{
            filter.startDate
              ? moment(filter.startDate).format("YYYY-MM-DD")
              : ""
          }}
          ~
          {{
            filter.endDate ? moment(filter.endDate).format("YYYY-MM-DD") : ""
          }}
        </div>
      </div>

      <a-timeline mode="alternate" :pending="loading">
        <a-timeline-item
          v-for="item in data"
          :key="item.logId"
          :color="timeLineColor(item.logCode)"
        >
          <a-icon
            :slot="timeLineSlot(item.logCode)"
            :type="timeLineType(item.logCode)"
          />
          <div style="font-size:14px;font-weight:bold">
            {{ item.codeName }}
          </div>
          <div style="font-size:12px;color:#9e9e9e">
            {{ gmtConversion(item.regDt).format("YYYY.MM.DD HH:mm") }}
          </div>
          <div style="font-size:12px">
            {{ item.content }}
          </div>
        </a-timeline-item>
      </a-timeline>

      <div df fdr fic fjc flex>
        <a-button @click="load(true)" df fic fjc type="primary">
          {{$t('views.admin.connectionLog.more')}}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import {i18n} from "@/i18n";

import { Filter } from "@/utils/filterValue";
import {
  GmtConversion,
  GmtConversionReverse,
  StartDate,
  EndDate,
} from "@/utils/gmtConversion";
import LogApi from "@/api/admin/log";

@Component({
  components: {},
})
export default class ConnectionLog extends Vue {
  moment = moment;
  loading = false;
  timezone = localStorage.getItem("timezone");

  @Filter
  filter = {
    startDate: moment().subtract(1, "w"),
    endDate: moment(),
    hotelIndex: this.$route.params.key,
  };

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  data = [];

  disabledStartDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.endDate ? value.isAfter(this.filter.endDate) : false)
    );
  }

  disabledEndDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.startDate ? value.isBefore(this.filter.startDate) : false)
    );
  }

  timeLineColor(value) {
    switch (value) {
      case "LOG_LOGOUT":
      case "LOG_DELETE_ITEM":
      case "LOG_DELETE_CATEGORY":
      case "LOG_DELETE_STATUS":
      case "LOG_ERROR":
      case "LOG_DELETE_NEARBYSTORE":
      case "LOG_DELETE_OPTIONGROUP":
      case "LOG_DELETE_OPTIONITEM":
      case "LOG_DELETE_CATEGORY_IMAGE":
      case "LOG_DELETE_BANNER":
        return "red";

      case "LOG_LOGIN":
      case "LOG_MESSAGE":
      case "LOG_INSERT_ITEM":
      case "LOG_INSERT_CATEGORY":
      case "LOG_INSERT_IMAGE_DOWNLOAD":
      case "LOG_INSERT_NEARBYSTORE":
      case "LOG_INSERT_OPTIONGROUP":
      case "LOG_INSERT_OPTIONITEM":
      case "LOG_INSERT_CATEGORY_IMAGE":
      case "LOG_INSERT_BANNER":
        return "blue";

      case "LOG_UPDATE_OPTIONGROUP":
      case "LOG_UPDATE_OPTIONITEM":
      case "LOG_UPDATE_STATUS":
      case "LOG_UPDATE_NEARBYSTORE":
      case "LOG_UPDATE_CATEGORY":
      case "LOG_UPDATE_ITEM":
      case "LOG_UPDATE_HOTEL":
      case "LOG_UPDATE_CATEGORY_IMAGE":
      case "LOG_UPDATE_SERVICE":
        return "green";
    }

    return "blue";
  }

  timeLineSlot(value) {
    switch (value) {
      case "LOG_LOGOUT":
      case "LOG_LOGIN":
        return "dot";
    }

    return "";
  }

  timeLineType(value) {
    switch (value) {
      case "LOG_LOGOUT":
      case "LOG_LOGIN":
        return "clock-circle-o";
    }

    return "";
  }

  async mounted() {
    await this.load();
  }

  async load(more) {
    try {
      this.loading = true;

      if (more) {
        this.pagination.current = ++this.pagination.current;
      } else {
        this.pagination.current = 1;
      }

      const { data, option } = await LogApi.List({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.filterValue,
        "filter.startDate": GmtConversionReverse(
          StartDate(this.filter.startDate, "day"),
          this.timezone,
        ),
        "filter.endDate": GmtConversionReverse(
          EndDate(this.filter.endDate, "day"),
          this.timezone,
        ),
      });

      if (more) {
        this.data = [...this.data, ...data.list];
      } else {
        this.data = data.list;
      }

      const total = data.total;

      this.pagination = {
        ...this.pagination,
        total,
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  gmtConversion(value) {
    return GmtConversion(value, this.timezone);
  }
}
</script>
