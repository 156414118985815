<template>
  <div df fdr flex>
    <a-table
      flex
      rowKey="index"
      :columns="columns"
      :data-source="services"
      :loading="loading"
    >
      <template slot="name" slot-scope="_, record">
        <a @click="showServiceEditModal(record)">{{ record.name }}</a>
      </template>

      <template slot="receptionInterlock" slot-scope="_, record">
        <a-select
          style="min-width: 300px"
          :placeholder="$t('components.hotel.choiceTablet')"
          v-model="record.storeIndex"
        >
          <a-select-option
            v-for="item in receptionList"
            :key="item.storeId"
            :value="item.storeId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </template>

      <a-popconfirm
        slot="delete"
        slot-scope="_, record"
        :title="$t('components.hotel.askDeleteSc')"
        ok-text="Yes"
        cancel-text="No"
        @confirm="serviceDelete(record.index)"
      >
        <a-button type="danger" shape="circle" icon="delete" />
      </a-popconfirm>
    </a-table>

    <service-name-edit-modal
      :key="serviceNameModalKey"
      :data="selectedService"
      :visible="isServiceEdit"
      @close="closeServiceEdit"
      @saveSubmit="saveServiceEdit"
    />

    <service-add-modal
      :hotelIndex="hotelIndex"
      :visible="isServiceAdd"
      @close="closeServiceAdd"
      @load="load"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

import {ServiceColumns} from "@/utils/columns/master/service";
import Tabs from "@/utils/languageTab";
import HotelApi from "@/api/master/hotel";
import {i18n} from "@/i18n";

import ServiceNameEditModal from "@/components/modals/ServiceNameEditModal";
import ServiceAddModal from "@/components/modals/ServiceAddModal";

@Component({
  components: { ServiceNameEditModal, ServiceAddModal },
})
export default class HotelServiceForm extends Vue {
  @Prop({ default: undefined }) hotelIndex;

  loading = false;

  tabs = Tabs;
  get columns() {
    const colums =ServiceColumns();
    return colums;
  }
  serviceNameModalKey = "service_name_key";

  services = [];
  receptionList = [];

  isServiceAdd = false;

  isServiceEdit = false;

  selectedService = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
  };

  mounted() {
    this.receptionLoad();
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      const data = await HotelApi.Detail(this.hotelIndex);

      this.services = data.services;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async receptionLoad() {
    try {
      const { data } = await HotelApi.ReceptionList(this.hotelIndex);
      this.receptionList = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  showServiceEditModal(value) {
    this.isServiceEdit = true;

    const data = JSON.parse(
      JSON.stringify(this.services.find((a) => a.index === value.index)),
    );

    this.selectedService = {
      ...data,
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
        ...data.information,
      },
    };

    this.serviceNameModalKey = this.selectedService.index;
  }

  closeServiceEdit() {
    this.selectedService = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
    };

    this.isServiceEdit = false;
    this.serviceNameModalKey = "service_name_key2";
  }

  async saveServiceEdit() {
    const index = this.services.findIndex(
      (item) => item.index === this.selectedService.index,
    );

    let service = this.services;

    if (index !== -1) {
      service[index] = this.selectedService;
      service[index].name = this.selectedService.information.Kr.name;
    }

    this.services = [...service];

    this.isServiceEdit = false;
    this.serviceNameModalKey = "service_name_key2";
  }

  async serviceDelete(index) {
    try {
      this.loading = true;

      await HotelApi.ServiceDelete(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async serviceUpdate() {
    try {
      this.loading = true;

      await HotelApi.ServiceUpdate(this.hotelIndex, this.services);
      this.$message.success(i18n.tc('components.hotel.edit.success'));

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data ? e.response.data.data : i18n.tc('components.hotel.edit.fail');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  showServiceAddModal() {
    this.isServiceAdd = true;
  }

  closeServiceAdd() {
    this.isServiceAdd = false;
  }
}
</script>

<style lang="scss" scoped></style>
