import {i18n} from "@/i18n";
export function LangsColumns(){
  return [
  {
    width: 40,
    align: "left",
    title: "",
    scopedSlots: { customRender: "drag" },
  },
  {
    align: "center",
    dataIndex: "langCode",
    title: i18n.tc('js.langs.lang'),
    scopedSlots: { customRender: "langCode" },
  },
  {
    align: "center",
    dataIndex: "isShow",
    title: i18n.tc('js.langs.active'),
    scopedSlots: { customRender: "isShow" },
  },
];
}