import client from "../client";

export default class Service {
  static async List(index) {
    return await client.get(`admin/service/${index}`);
  }

  static async Single(index, serviceIndex) {
    return await client.get(`admin/service/single/${index}/${serviceIndex}`);
  }

  static async Update(index, data) {
    return await client.put(`admin/service/${index}`, data);
  }

  static async ListOrderUpdate(index, data) {
    return await client.put(`admin/service/list/order/${index}`, data);
  }

  static async AssignCheck(index) {
    return await client.get(`admin/service/assign/${index}`);
  }

  static async RumyCheck(index) {
    return await client.get(`admin/service/rumy/use/check/${index}`);
  }

  static async MultilingualDetail(index) {
    return await client.get(`admin/service/text/list/${index}`);
  }

  static async MultilingualUpdate(index, data) {
    return await client.put(`admin/service/text/list/${index}`, data);
  }
}
