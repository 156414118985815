<template>
  <div style="padding: 16px">
    <div df fdr fje style="margin-bottom: 20px">
      <a-button
        style="margin-right: 12px; width: 90px"
        @click="closeItemDetail"
      >
        {{$t('components.shop.cancel')}}
      </a-button>
      <a-button
        v-if="levelCheck !== '1'"
        style="width: 90px"
        type="primary"
        :loading="loadingEditItem"
        @click="saveEditItem"
      >
        {{$t('components.shop.save')}}
      </a-button>
    </div>

    <a-form-model
      v-if="data"
      ref="editItemForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr>
        <a-space
          direction="vertical"
          style="padding: 10px 20px 10px 0px; flex: 0.8"
        >
          <upload
            dir-name="itemService"
            v-model="data.mainImage"
            listType="picture-card"
            :selected="true"
          >
            <div class="image-expand">
              <div
                v-if="data.mainImage.uploadedUrl[0]"
                :style="{
                  backgroundImage: `url('${data.mainImage.uploadedUrl[0]}')`,
                }"
              />
              <div v-else df fdc fjc fic>
                <a-icon :type="'plus'" />
                <div>Upload</div>
                <div>{{$t('components.shop.uploadEx1')}}</div>
              </div>
            </div>
          </upload>

          <div df fje style="margin-bottom: 12px">
            <a-button
              @click="imageLinkOpen(data.mainImage.uploadedUrl[0])"
              style="font-size: 11px; font-weight: bold"
            >
              {{$t('components.shop.mainLinkOpen')}}
            </a-button>
          </div>

          <a-row>
            <a-col
              v-for="item in data.subImage.uploadedUrl"
              :key="item"
              :span="6"
            >
              <upload
                dir-name="itemService"
                v-model="data.subImage"
                listType="picture-card"
                :multiple="true"
                :isAddBtn="true"
                :disabled="item && item !== 'ADD' ? true : false"
              >
                <div v-if="item !== 'ADD'" class="sub-image-expand">
                  <div
                    :style="{
                      backgroundImage: `url('${item}')`,
                    }"
                  >
                    <div df fdr fjc fic>
                      <a-icon
                        class="image-viewer"
                        type="eye"
                        @click="handlePreview(item)"
                      />

                      <a-icon
                        v-if="levelCheck !== '1'"
                        style="font-size: 18px; font-weight: bold; padding: 5px"
                        class="image-close"
                        type="delete"
                        @click="imageDelete(item)"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="item === 'ADD'" class="sub-image-expand">
                  <div df fdc fjc fic>
                    <a-icon :type="'plus'" />
                    <span> Upload </span>
                  </div>
                </div>
              </upload>
            </a-col>
          </a-row>

          <div style="font-size: 14px; color: #db0000; font-weight: bold">
            {{$t('components.shop.content1')}}<br />
            {{$t('components.shop.content2')}}<br />
            {{$t('components.shop.content3')}}
          </div>
        </a-space>

        <a-space direction="vertical" flex>
          <div df fdr fje>
            <small>{{$t('components.shop.korEngWarn')}}</small>
          </div>
          <div class="card-container">
            <language-tab
              ref="languageTabForm"
              :value="data"
              :isLanguageUsed="isLanguageUsed"
              :tabs="tabs"
              :name="$t('components.shop.stuffNm')"
              :description="$t('components.shop.stuffInfo')"
            >
            </language-tab>
          </div>

          <a-form-model-item prop="isFree" :label="$t('components.shop.onceMaxAm')">
            <a-radio-group button-style="solid" v-model="data.isMaxCount">
              <a-radio-button :value="true">{{$t('components.shop.use')}}</a-radio-button>
              <a-radio-button :value="false">{{$t('components.shop.unuse')}}</a-radio-button>
            </a-radio-group>

            <a-input-number
              v-if="data.isMaxCount"
              style="margin-left: 30px"
              v-model="data.maxQty"
              :min="1"
            />
          </a-form-model-item>

          <a-form-model-item prop="maxQty" :label="$t('components.shop.price')">
            <a-input-number
              style="width: 120px"
              v-model="data.salePrice"
              :formatter="
                (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :min="0"
              :step="1000"
            />
          </a-form-model-item>

          <a-form-model-item prop="maxQty" :label="$t('components.shop.cost')">
            <a-input-number
              style="width: 120px"
              v-model="data.purchasePrice"
              :formatter="
                (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :min="0"
              :step="1000"
            />
          </a-form-model-item>

          <a-form-model-item prop="status" :label="$t('components.shop.state')">
            <a-radio-group button-style="solid" v-model="data.status">
              <a-radio-button value="1">{{$t('components.shop.use')}}</a-radio-button>
              <a-radio-button value="0">{{$t('components.shop.unuse')}}</a-radio-button>
              <a-radio-button value="2">{{$t('components.shop.soldout')}}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-space>
      </div>
    </a-form-model>
    <div style="border-top: 1px solid #eeeeee">
      <div df fdr style="height: 100%">
        <div style="width: 360px" class="category-group">
          <div
            df
            fdr
            fic
            fjb
            style="padding: 16px; font-size: 18px; font-weight: bold"
          >
            {{$t('components.shop.stuffOpt')}}
            <a-button
              v-if="levelCheck !== '1'"
              @click="openEditOptionGroup(false)"
            >
              <a-icon type="plus" /> {{$t('components.shop.addOptGroup')}}
            </a-button>
          </div>

          <title-list-draggable
            :list="optionGroups"
            :listOrderChanged="optionGroupOrderChanged"
            :selectedKeys="selectedGroupKeys"
            :selectItem="selectGroupCategory"
            :isOptionStatus="true"
          />
        </div>

        <div flex style="padding: 16px" df fdc>
          <a-space direction="vertical">
            <div df fdr fjb fic v-if="selectedGroupCategoryIndex">
              <h1 style="font-size: 18px">{{ title }}</h1>

              <a-space v-if="levelCheck !== '1'">
                <a-popconfirm
                  :title="
                    optionItems && optionItems.length > 0
                      ? $t('components.shop.askItemDelete')
                      : $t('components.shop.askOptGroupDelete')
                  "
                  ok-text="삭제"
                  cancel-text="취소"
                  @confirm="optionGroupDelete(selectedGroupCategoryIndex)"
                >
                  <a-button type="danger" ghost> {{$t('components.shop.deleteOptGp')}} </a-button>
                </a-popconfirm>
                <a-button @click="openEditOptionGroup(true)">
                  {{$t('components.shop.editOptGp')}}
                </a-button>
                <a-button @click="openEditOption()">{{$t('components.shop.addOpt')}}</a-button>
              </a-space>

              <a-space v-if="levelCheck === '1'">
                <a-button @click="openEditOptionGroup(true)">
                  {{$t('components.shop.optGpInfo')}}
                </a-button>
              </a-space>
            </div>

            <draggable-table
              style="width: 100%"
              v-if="selectedGroupCategoryIndex && levelCheck !== '1'"
              v-model="optionItems"
              :columns="columns"
              :drag-options="dragOptions"
              @dragChanged="optionItemOrderChanged"
            >
              <template v-slot:drag="{}">
                <drag-icon />
              </template>

              <template v-slot:status="{ record }">
                <div
                  df
                  fdr
                  fjc
                  fic
                  :style="{
                    color: tableStatusColor(record.status),
                  }"
                >
                  <div>
                    {{ tableStatusName(record.status) }}
                  </div>
                </div>
              </template>

              <template v-slot:name="{ record }">
                <a @click="openEditOption(record)">
                  {{ record.name }}
                </a>
              </template>

              <template v-slot:delete="{ record }">
                <a-popconfirm
                  :title="$t('components.shop.askDelete')"
                  :ok-text="$t('components.shop.delete')"
                  :cancel-text="$t('components.shop.cancel')"
                  @confirm="optionItemDelete(record.index)"
                >
                  <a-button
                    type="danger"
                    shape="circle"
                    icon="delete"
                    ghost
                    size="small"
                  />
                </a-popconfirm>
              </template>
            </draggable-table>

            <a-table
              rowKey="index"
              style="width: 100%"
              v-if="selectedGroupCategoryIndex && levelCheck === '1'"
              :data-source="optionItems"
              :columns="columns"
            >
              <template slot="status" slot-scope="_, record">
                <div
                  df
                  fdr
                  fjc
                  fic
                  :style="{
                    color: tableStatusColor(record.status),
                  }"
                >
                  <div>
                    {{ tableStatusName(record.status) }}
                  </div>
                </div>
              </template>

              <template slot="name" slot-scope="_, record">
                <a @click="openEditOption(record)">
                  {{ record.name }}
                </a>
              </template>
            </a-table>
          </a-space>
        </div>
      </div>
    </div>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
      <div df fje style="margin-top: 12px">
        <a-button
          @click="imageLinkOpen(previewImage)"
          style="font-size: 11px; font-weight: bold"
        >
          {{$t('components.shop.linkOpen')}}
        </a-button>
      </div>
    </a-modal>

    <option-group-add-modal
      :key="optionGroupsModalKey"
      :data="optionGroupData"
      :visible="editOptionGroup"
      :afterClose="afterCloseEditOptionGroup"
      @close="closeEditOptionGroup"
      :itemId="data.itemId"
      @load="groupLoad()"
    />

    <option-add-modal
      :key="optionsModalKey"
      :data="optionData"
      :visible="editOption"
      :afterClose="afterCloseEditOption"
      :groupIndex="selectedGroupCategoryIndex"
      @close="closeEditOption"
      @load="selectGroupCategory"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import DraggableTable from "@/components/DraggableTable";
import LanguageTab from "@/components/LanguageTab";
import DragIcon from "@/components/DragIcon";
import OptionGroupAddModal from "@/components/modals/OptionGroupAddModal";
import OptionAddModal from "@/components/modals/OptionAddModal";
import Upload from "@/components/Upload";
import TitleListDraggable from "@/components/service/TitleListDraggable";

import Tabs from "@/utils/languageTab";
import {OptionColumns} from "@/utils/columns/admin/option";
import {OptionReadColumns} from "@/utils/columns/admin/optionRead";

import ProductApi from "@/api/admin/product";
import {i18n} from "@/i18n";

@Component({
  components: {
    Upload,
    OptionGroupAddModal,
    OptionAddModal,
    DraggableTable,
    LanguageTab,
    DragIcon,
    TitleListDraggable,
  },
})
export default class ItemDetail extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) categoryIndex;

  get columns() {
    const colums =this.levelCheck === "1" ? OptionReadColumns() : OptionColumns();
    return colums;
  }

  tabs = Tabs;
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  editOptionGroup = false;
  editOption = false;

  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  optionGroupsModalKey = "group_key";
  optionsModalKey = "key";

  loadingEditItem = false;
  previewVisible = false;
  previewImage = "";

  optionGroups = [];
  optionGroupData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
    status: "1",
    optionMin: 1,
    optionMax: 1,
  };

  optionItems = [];
  optionData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
    status: "1",
    price: 0,
  };

  groupCategoryIndex = "";

  get selectedGroupCategoryIndex() {
    return this.groupCategoryIndex && Number(this.groupCategoryIndex);
  }

  get selectedGroupKeys() {
    return this.selectedGroupCategoryIndex
      ? [this.selectedGroupCategoryIndex]
      : [];
  }

  get title() {
    return (
      this.selectedGroupCategoryIndex &&
      this.optionGroups.find((a) => a.index === this.selectedGroupCategoryIndex)
        .name
    );
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('components.shop.writeStNm'),
            },
          ],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc('components.shop.selectState'),
        },
      ],
    };
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  closeItemDetail() {
    this.$emit("closeItemDetail");
  }

  async saveEditItem() {
    try {
      this.loadingEditItem = true;

      if (!this.data.information.En.name) {
        this.$refs.languageTabForm.tabDefaultKeyChange();
        this.$message.warning(i18n.tc('components.shop.writeEngStNm'));

        return;
      }

      const mainImage = {
        type: 0,
        image: this.data.mainImage.uploadedUrl[0],
        imageId: this.data.itemId
          ? this.data.images.find((item) => item.type === 0).imageId
          : 0,
      };

      this.data.images = this.data.images.filter((item) => item.type !== 0);

      const newImages = this.data.subImage.uploadedUrl.map((item) => item);
      const originSubImages = this.data.images.map((item) => item.image);
      const newImagesList = newImages.filter(
        (item) => originSubImages.indexOf(item) === -1,
      );

      let subImage;
      let images;

      if (newImagesList?.length > 0) {
        subImage = newImagesList
          .filter((item) => item !== "ADD")
          .map((image) => ({ image, type: 1 }));

        images = [...this.data.images, ...subImage, mainImage];
      } else {
        images = [...this.data.images, mainImage];
      }

      this.data.isFree = 1;
      this.data.maxQty = this.data.isMaxCount === false ? 0 : this.data.maxQty;

      const data = { ...this.data, images: images };

      delete data.mainImage;
      delete data.subImage;

      if (this.data.itemId) {
        await ProductApi.Update(data);
      } else {
        await ProductApi.Create(this.categoryIndex, data);
      }

      this.$emit("load");
      this.closeItemDetail();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditItem = false;
    }
  }

  async save() {
    this.$refs.editItemForm.validate(async (valid) => {
      if (valid) {
        if (!this.data.mainImage.uploadedUrl[0]) {
          return this.$message.warning(i18n.tc('components.shop.uploadImage'));
        }

        await this.saveEditItem();
      }
    });
  }

  async imageDelete(value) {
    try {
      const image = this.data.images.find((item) => item.image === value);

      if (image) {
        await ProductApi.SubImageDelete(Number(image.imageId));
      }

      this.data.subImage.uploadedUrl = this.data.subImage.uploadedUrl.filter(
        (item) => item !== value,
      );
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async mounted() {
    await this.groupLoad();

    if (this.optionGroups !== null && this.optionGroups.length > 0) {
      this.groupCategoryIndex = this.optionGroups[0].index;
      await this.selectGroupCategory(this.optionGroups[0]?.index);
    }
  }

  async groupLoad(reset) {
    try {
      const { data } = await ProductApi.OptionGroupList(this.data.itemId);

      this.optionGroups = data.map(({ information, ...item }) => ({
        ...item,
        name: information.Kr.name,
        information,
      }));

      if (!reset) {
        return;
      }

      if (!this.optionGroups || !this.optionGroups.length) {
        this.groupCategoryIndex = null;
        return;
      }

      this.groupCategoryIndex = this.optionGroups[0].index;
      await this.selectGroupCategory(this.optionGroups[0]?.index);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async selectGroupCategory(index) {
    try {
      if (index) {
        this.groupCategoryIndex = index;
      }

      const { data } = await ProductApi.OptionGroupItemList(
        Number(this.groupCategoryIndex),
      );

      this.optionItems = data.map(({ information, ...optionItem }) => ({
        ...optionItem,
        name: information.Kr.name,
        information,
        statusVisible: false,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async openEditOptionGroup(isEdit) {
    try {
      this.editOptionGroup = true;
      this.optionGroupsModalKey = "group_key";
      if (!isEdit) {
        return;
      }

      const { data } = await ProductApi.OptionGroupDetail(
        this.groupCategoryIndex,
      );

      this.optionGroupData = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
      };
      this.optionGroupsModalKey = this.optionGroupData.index;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async optionGroupDelete() {
    try {
      await ProductApi.OptionGroupDelete(this.groupCategoryIndex);

      await this.groupLoad(true);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async optionGroupOrderChanged() {
    try {
      await ProductApi.OptionGroupOrder(this.data.itemId, this.optionGroups);

      await this.groupLoad();
    } catch {}
  }

  closeEditOptionGroup() {
    this.editOptionGroup = false;
  }

  afterCloseEditOptionGroup() {
    this.optionGroupData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
      status: "1",
      optionMin: 1,
      optionMax: 1,
    };

    this.optionGroupsModalKey = "group_key2";
  }

  async openEditOption(item) {
    try {
      this.editOption = true;

      this.optionsModalKey = "key";
      if (!item) {
        return;
      }

      const { data } = await ProductApi.OptionGroupItemDetail(item.index);

      this.optionData = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
      };

      this.optionsModalKey = this.optionData.index;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async optionItemDelete(index) {
    try {
      await ProductApi.OptionGroupItemDelete(Number(index));
      await this.selectGroupCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async optionItemOrderChanged() {
    try {
      await ProductApi.OptionGroupItemOrder(
        this.groupCategoryIndex,
        this.optionItems,
      );

      await this.selectGroupCategory();
    } catch {}
  }

  closeEditOption() {
    this.editOption = false;
  }

  afterCloseEditOption() {
    this.optionData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
      status: "1",
      price: 0,
    };

    this.optionsModalKey = "key2";
  }

  handleCancel() {
    this.previewVisible = false;
  }

  async handlePreview(file) {
    this.previewImage = file;
    this.previewVisible = true;
  }

  async imageLinkOpen(url) {
    if (url) {
      window.open(`${url}`, "_blank").focus();
    }
  }

  tableStatusColor(status) {
    return status && status === "1" ? `rgba(0,0,0,.65)` : `#c2c2c2`;
  }

  tableStatusName(status) {
    return status && status === "1"
      ? i18n.tc('components.shop.sale')
      : status === "2"
      ? i18n.tc('components.shop.soldout')
      : i18n.tc('components.shop.noSale');
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 450px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}

.sub-image-expand {
  position: relative;
  height: 80px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;

    > div {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      cursor: pointer;
      opacity: 0;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.image-close {
  font-size: 20px;
  padding: 2px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.image-viewer {
  font-size: 20px;
  padding: 3px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
</style>
