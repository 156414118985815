<template>
  <div df fdc style="height:100%;">
    <div style="padding: 16px 24px;">
      <a-space df fdr fjb style="margin-bottom:16px;">
        <div>
          <div style="font-size: 16px;font-weight: bold;">
            {{$t('views.admin.services.urlNotice')}}
          </div>
        </div>

        <a-button
          @click="submit"
          :loading="loading"
          style="width:80px"
          type="primary"
          v-if="levelCheck === '1' ? false : true"
        >
          {{$t('views.admin.services.save')}}
        </a-button>
      </a-space>

      <a-space df fdr fic style="margin-top:30px">
        <div style="font-weight: 500;">
          {{$t('views.admin.services.urlAdr')}}
        </div>
        <a-input
          v-if="levelCheck === '1' ? false : true"
          style="width:500px"
          v-model="data"
        />
        <div v-if="levelCheck === '1' ? true : false" class="input-viewer">
          {{ data }}
        </div>
      </a-space>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import TypeOfServiceApi from "@/api/admin/typeOfService";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class UrlService extends Vue {
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceMemo;

  levelCheck = localStorage.getItem("level");

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;

  data = "";

  async mounted() {
    this.data = this.serviceMemo ? this.serviceMemo : "";
  }

  async submit() {
    try {
      this.loading = true;

      const data = {
        memo: this.data,
      };

      await TypeOfServiceApi.WebLinkChange(this.serviceIndex, data);
      await this.$emit("childs-event");

      this.$message.success(i18n.tc('views.admin.services.editComp'));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.input-viewer {
  min-width: 400px;
  border: 1px #bababa solid;
  padding: 5px 10px;
  border-radius: 6px;
}
</style>
