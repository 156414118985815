const Banks = [
  "국민은행",
  "우리은행",
  "기업은행",
  "신한은행",
  "새마을금고",
  "KEB 하나",
  "기타",
];

export default Banks;
