import client from "../client";

export default class Hotel {
  static async LangList() {
    return await client.get(`shared/hotel/lang/list`);
  }

  static async CountryList() {
    return await client.get(`shared/hotel/country/list`);
  }

}
