import {i18n} from "@/i18n";
export function TabletOrderColumns(){
  return [
  {
    width: 110,
    align: "left",
    dataIndex: "registeredTime",
    title: i18n.tc('js.tablet.date'),
    scopedSlots: { customRender: "date" },
  },
  {
    width: 70,
    align: "left",
    title: i18n.tc('js.tablet.time2'),
    scopedSlots: { customRender: "time" },
  },
  {
    align: "left",
    dataIndex: "serviceName",
    title: i18n.tc('js.tablet.service'),
    scopedSlots: { customRender: "serviceName" },
  },
  {
    align: "left",
    dataIndex: "orderItems",
    title: i18n.tc('js.tablet.order2'),
    scopedSlots: { customRender: "order" },
  },
];
}