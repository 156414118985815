import client from "../client";

export default class Statistics {
  static async HotelCount() {
    return await client.get("master/statistics/hotel/count");
  }

  static async HotelRanking() {
    return await client.get("master/statistics/hotel/statistics");
  }

  static async DateStatistics(data) {
    return await client.post("master/statistics/date/statistics", data);
  }

  static async TimeStatistics(data) {
    return await client.post("master/statistics/time/statistics", data);
  }
}
