import Vue from "vue";
import VueRouter from "vue-router";
import masterRoutes from "./master";
import adminRoutes from "./admin";
import agencyRoutes from "./agency";
import salesRoutes from "./sales";

import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      title: route => {
        return "스테이오더";
      },
    },
  },
  ...masterRoutes,
  ...adminRoutes,
  ...agencyRoutes,
  ...salesRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to);
  });
});

export default router;
