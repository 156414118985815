<template>
  <a-modal
    :title="data.storeId ? $t('components.modal.reception.editRc') : $t('components.modal.reception.addRc')"
    :visible="visible"
    :closable="false"
  >
    <div df fdr fjb>
      <a-form-model
        flex
        ref="receptionform"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item prop="name" :label="$t('components.modal.reception.rcNm')">
          <a-input v-model="data.name" />
        </a-form-model-item>
        <a-form-model-item prop="id" :label="$t('components.modal.reception.id')">
          <a-input :disabled="data.storeId ? true : false" v-model="data.id" />
        </a-form-model-item>
        <a-form-model-item prop="password" :label="$t('components.modal.reception.pwd')">
          <a-input-password v-model="data.password" />
        </a-form-model-item>
        <a-form-model-item prop="passwordConfirm" :label="$t('components.modal.reception.pwdCheck')">
          <a-input-password v-model="passwordConfirm" />
        </a-form-model-item>

        <div v-if="data.storeId">
          <div df fdr fjb style="padding:12px 0px;border-top:1px solid #eeeeee">
            <div style="font-size: 16px;font-weight: 900;">
              {{$t('components.modal.reception.multiAlarm')}}
            </div>
            <a-button @click="visibleStoreAdd">
              {{$t('components.modal.reception.add')}}
            </a-button>
          </div>
          <div
            df
            fdr
            style="margin:8px 0px;border:1px solid #f5f5f5;background-color:#f5f5f5;padding: 5px 0;"
            v-if="data.receptionStores && data.receptionStores.length > 0"
          >
            <div df flex fjc fic style="">
              {{$t('components.modal.reception.store')}}
            </div>
            <div df flex fjc fic>
              {{$t('components.modal.reception.checkPopup')}}
            </div>
            <div df flex fjc fic>
              {{$t('components.modal.reception.delete')}}
            </div>
          </div>
          <div v-for="item in data.receptionStores" :key="item.storeId">
            <div df fdr style="margin:8px 0px">
              <div df flex fjc fic style="">
                {{ storeNameFind(item.storeId) }}
              </div>
              <div df flex fjc fic>
                <a-switch v-model="item.isConnect" />
              </div>
              <div df flex fjc fic>
                <a-popconfirm
                  :title="$t('components.modal.reception.isDelete')"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteStore(item)"
                >
                  <a-button type="danger" shape="circle" icon="delete" />
                </a-popconfirm>
              </div>
            </div>
          </div>

          <div v-if="isStoreAdd" df fdr fjb style="margin-top:20px">
            <a-select
              :placeholder="$t('components.modal.reception.storeWarn')"
              :loading="loading"
              style="width:260px"
              @change="storeAddChange"
            >
              <a-select-option
                v-for="(item, index) in possibbleStoreList"
                :key="Number(index)"
                :value="item.storeId"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>

            <div>
              <a-button @click="closeStoreAdd" style="margin-right:4px">
                {{$t('components.modal.reception.cancel')}}
              </a-button>
              <a-button type="primary" :loading="loading">
                {{$t('components.modal.reception.add')}}
              </a-button>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
    <div slot="footer">
      <a-button @click="closeModal">{{$t('components.modal.reception.cancel')}}</a-button>
      <a-button type="primary" :loading="loading" @click="submit">
        {{ data.storeId ? $t('components.modal.reception.save') : $t('components.modal.reception.add') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import AccountApi from "@/api/master/account";
import HotelApi from "@/api/master/hotel";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class ReceptionAddModal extends Vue {
  @Prop({ default: undefined }) data;
  @Prop({ default: undefined }) hotelIndex;
  @Prop({ default: false }) visible;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;

  passwordConfirm = null;
  storeList = [];

  possibbleStoreList = [];
  isStoreAdd = false;
  selectStore = "";

  get rules() {
    return {
      name: [
        {
          required: true,
          message: i18n.tc('components.modal.reception.rcWarn'),
        },
      ],
      id: [
        {
          required: true,
          validator: this.data.storeId ? null : this.idCheckValidator,
          trigger: "change",
        },
      ],
      password: [
        {
          required: this.data.storeId ? false : true,
          message: i18n.tc('components.modal.reception.pwdWarn'),
        },
      ],
      passwordConfirm: [
        {
          required:
            this.data.password !== null && this.data.password !== ""
              ? true
              : false,
          validator: this.passwordCheckValidator,
          trigger: "change",
        },
      ],
    };
  }

  async idCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('components.modal.reception.idWarn')));
    } else {
      if (this.data.id !== "") {
        try {
          var idReg = /^[A-za-z0-9]{6,20}$/g;
          if (!idReg.test(value)) {
            callback(
              new Error(i18n.tc('components.modal.reception.idLimit')),
            );
            return;
          }

          const response = await AccountApi.IdCheck(this.data.id);

          if (response == true) {
            callback(new Error(i18n.tc('components.modal.reception.idDup')));
          } else {
            this.$refs.receptionform.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  async passwordCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('components.modal.reception.pwdOne')));
    } else {
      if (this.data.password !== "") {
        try {
          const response =
            this.data.password !== this.passwordConfirm ? true : false;

          if (response == true) {
            callback(new Error(i18n.tc('components.modal.reception.pwdCheckWarn')));
          } else {
            this.$refs.receptionform.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  closeModal() {
    this.isStoreAdd = false;
    this.$emit("close");
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await HotelApi.gbStore(this.hotelIndex);
      this.storeList = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async submit() {
    this.$refs.receptionform.validate(async valid => {
      if (valid) {
        try {
          this.loading = true;

          if (this.data.storeId) {
            await HotelApi.ReceptionUpdate(this.data.adminId, this.data);
          } else {
            await HotelApi.ReceptionCreate(this.hotelIndex, this.data);
            await this.load();
          }

          this.$emit("load");
          this.closeModal();
          this.$message.success(this.data.storeId ? i18n.tc('components.modal.reception.editCom') : i18n.tc('components.modal.reception.addCom'));
          this.isStoreAdd = false;
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        } finally {
          this.loading = false;
          this.passwordConfirm = null;
        }
      }
    });
  }

  storeNameFind(value) {
    const store = this.storeList.find(item => item.storeId === value);

    return store.name;
  }

  visibleStoreAdd() {
    const exceptionStore = this.data.receptionStores.map(item => item.storeId);

    if (exceptionStore?.length > 0) {
      this.possibbleStoreList = this.storeList.filter(
        item => exceptionStore.indexOf(item.storeId) === -1,
      );
    } else {
      this.possibbleStoreList = this.storeList;
    }

    this.isStoreAdd = true;
  }

  closeStoreAdd() {
    this.isStoreAdd = false;
  }

  storeAddChange(value) {
    const store = this.possibbleStoreList.find(item => item.storeId === value);

    const receptionStore = {
      index: 0,
      storeId: store.storeId,
      storeName: store.name,
      isConnect: true,
    };
    this.data.receptionStores.push(receptionStore);

    this.closeStoreAdd();
  }

  async deleteStore(value) {
    try {
      this.data.receptionStores = this.data.receptionStores.filter(
        item => item.storeId !== value.storeId,
      );

      await HotelApi.ReceptionStoreDelete(value.index);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
