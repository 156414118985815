import client from "../client";

export default class Staff {
  static async List(hotelId, options) {
    return await client.get(`admin/staff/list/${hotelId}`, options);
  }
  static async ListByGroupId(hotelId, groupId, options) {
    return await client.get(`admin/staff/list/${hotelId}/${groupId}`, options);
  }
  static async SearchByHotelIdAndLoginId(hotelId, loginId, options) {
    return await client.get(`admin/staff/search/${hotelId}/${loginId}`, options);
  }

  static async Create(data) {
    return await client.post(`admin/staff`, data);
  }

  static async Update(staffId, data) {
    return await client.put(`admin/staff/${staffId}`, data);
  }

  static async Delete(staffId) {
    return await client.del(`admin/staff/${staffId}`);
  }
}
