import {i18n} from "@/i18n";

export function StaffColumns(){ 
  return [
  {
    dataIndex: "staffId",
    width: 60,
    align: "left",
    title: "#",
  },
  {
    width: 120,
    align: "left",
    title: i18n.tc('js.staff.group'),
    scopedSlots: { customRender: "group" },
  },
  {
    title: i18n.tc('js.staff.id'),
    scopedSlots: { customRender: "id" },
  },
  {
    title: i18n.tc('js.staff.name'),
    scopedSlots: { customRender: "name" },
  },
  {
    title: i18n.tc('js.staff.position'),
    scopedSlots: { customRender: "position" },
  },
  {
    title: i18n.tc('js.staff.phone'),
    scopedSlots: { customRender: "phone" },
  },
  {
    width: 100,
    align: "center",
    title: i18n.tc('js.staff.delete'),
    scopedSlots: { customRender: "delete" },
  },
];
}