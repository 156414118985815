<template>
    <div class="sales">
      <div df fdr fjb fic class="bread-crumb-parent">
        <a-breadcrumb class="bread-crumb">
          <a-breadcrumb-item class="fontSub">{{
            $t("views.master.sales.title1")
          }}</a-breadcrumb-item>
          <a-breadcrumb-item class="fontMain">{{
            $t("views.master.sales.title2")
          }}</a-breadcrumb-item>
        </a-breadcrumb>
  
        <a-button>
          <router-link :to="{ name: 'SalesCreate' }">{{
            $t("views.master.sales.create.title")
          }}</router-link>
        </a-button>
      </div>
      <div class="main-background">
        <div df fdr fjb fic style="margin-bottom: 16px">
          <div>
            <div>

                <a-radio-group
                v-model="filter.salesType"
                button-style="solid"
                @change="changeFilterType(filter)"
                >
                <a-radio-button value>{{
                    $t("views.master.sales.all")
                }}</a-radio-button>
                <a-radio-button :value="1">{{ $t('views.master.sales.store') }}</a-radio-button>
                <a-radio-button :value="2">{{ $t('views.master.sales.agency') }}</a-radio-button>
                </a-radio-group>

            </div>
          </div>
          <!-- <a-input-search
            @change="changeFilterType(filter)"
            v-model="filter.keyword"
            :placeholder="$t('views.master.hotel.selectHotelNameAndAddress')"
            style="width: 260px"
          /> -->
        </div>
  
        <div>
          <a-table
            rowKey="index"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            @change="changePage"
            :loading="loading"
          >
          
            <template slot="code" slot-scope="_, record">
                <a @click="moveSalesEdit(record.index)">
                    {{ record.salesCode }}
                </a>
            </template>
            
            <template slot="storeNm" slot-scope="_, record">
                {{ record.name }}
            </template>

            <template slot="storeNm" slot-scope="_, record">
                {{ record.name }}
            </template>
  
            <template slot="salesMan" slot-scope="_, record">
              {{ record.name }}
            </template>
  
            <template slot="salesMan" slot-scope="_, record">
              {{ record.salesMan ? agentView(record.salesMan) : "" }}
            </template>
  
            <template slot="recomend" slot-scope="_, record">
              {{ record.recomend ? agentView(record.recomend) : "" }}
            </template>
  
            <template slot="status" slot-scope="_, record">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color:
                    record.status && record.status === '1'
                      ? ``
                      : `${Colors.grayDark}`,
                }"
              >
                <div>
                  {{
                    record.status && record.status === "1"
                      ? $t("views.master.hotel.use")
                      : $t("views.master.hotel.noUse")
                  }}
                </div>
              </div>
            </template>
  
            <a-popconfirm
              slot="delete"
              slot-scope="_, record"
              :title="$t('views.master.sales.deleteSalesManMsg')"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteSubmit(record.index)"
            >
              <a-button type="danger" shape="circle" icon="delete" />
            </a-popconfirm>
          </a-table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Component, Vue, Watch } from "vue-property-decorator";
  import SalesManApi from "@/api/master/salesman";
  
  import Colors from "@/utils/colors";
  import { Filter } from "@/utils/filterValue";
  import { SalesColumns } from "@/utils/columns/master/sales";
  import { i18n } from "@/i18n";
  
  @Component({
    components: {},
  })
  export default class Sales extends Vue {
    Colors = Colors;
  
    loading = true;
  
    get columns() {
      const colums = SalesColumns();
      return colums;
    }
  
    get salesType() {
      return {
        0: i18n.tc("views.master.sales.store"),
        1: i18n.tc("views.master.sales.agency"),
      };
    }
    @Filter
    filter = {
      keyword: "",
      salesType: "",
    };
  
    data = [];
    total = 0;
  
    get pagination() {
      const { current, pageSize } = this.$route.query;
      return {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
        total: this.total,
      };
    }
  
    @Watch("$route")
    routeChanged(oldValue, newValue) {
        const keys = [
        "current",
        "pageSize",
        "keyword",
        "salesType",
        ];

        if (keys.some((key) => oldValue.query[key] !== newValue.query[key])) {
            this.load();
        }
    }
  
    mounted() {
      this.load();
    }
  
    changePage({ current, pageSize, total }) {
      this.pagination.total = total;
      this.$router.push({
        query: {
          ...this.$route.query,
          current,
          pageSize,
        },
      });
    }
  
    changeFilterType({ keyword, salesType }) {
        console.log(keyword);
        console.log(salesType);
        // this.searchType = keyword;
        this.$router.push({
            query: {
            keyword,
            salesType,
            },
      });
    }
  
    async load() {
      try {
        this.loading = true;
  
        const { current, pageSize, keyword, salesType } = this.$route.query;
  
        const pagination = {
          current: current ? Number(current) : 1,
          pageSize: pageSize ? Number(pageSize) : 10,
        };
  
        this.filter = {
          salesType: salesType ? Number(salesType) : "",
          keyword: keyword ? keyword : "",
        };
  
        const { list, total } = await SalesManApi.List({
          ...pagination,
          ...this.filterValue,
        });
  
        this.data = list;
        this.total = total;
      } catch (e) {
        let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc("views.master.sales.errorMsg");
  
        this.$message.error(error);
      } finally {
        this.loading = false;
      }
    }
  
    async deleteSubmit(index) {
      try {
        this.loading = true;
        await HotelApi.Delete(index);
  
        await this.load();
      } catch (e) {
        let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc("views.master.sales.errorMsg");
  
        this.$message.error(error);
      } finally {
        this.loading = false;
      }
    }
  
    agentView(value) {
      return value.name + "(" + value.salesCode + ")"
    }

    async moveSalesEdit(index) {
        await this.$router.push({
            name: "SalesEdit",
            params: {
                index: String(index),
            },
        });
    }
  }
  </script>
  