<template>
  <div df fdc style="height: 100%; padding: 16px 24px">
    <a-space df fdr fjb style="margin-bottom: 16px">
      <div>
        <div style="font-size: 16px; font-weight: bold">
          {{$t('views.admin.services.enrollQR')}}
        </div>
        <div style="font-size: 12px">
          {{$t('views.admin.services.noticeQR')}}
        </div>
      </div>

      <a-button
        @click="submit"
        :loading="loading"
        style="width: 80px"
        type="primary"
        v-if="levelCheck === '1' ? false : true"
      >
        {{$t('views.admin.services.save')}}
      </a-button>
    </a-space>

    <div df fdc fic fjc flex>
      <div style="width: 700px">
        <div>
          <upload
            dir-name="icon"
            v-model="data"
            listType="picture-card"
            :selected="true"
            :disabled="levelCheck === '1' ? true : false"
          >
            <div class="image-expand">
              <div
                v-if="data.uploadedUrl[0]"
                :style="{
                  backgroundImage: `url('${data.uploadedUrl}')`,
                }"
              />
              <div v-else df fdc fjc fic>
                <a-icon :type="'plus'" />
                <div>Upload</div>
                <div>{{$t('views.admin.services.uploadEx1')}}</div>
              </div>
            </div>
          </upload>
        </div>
        <div>
          <a-textarea
            :disabled="levelCheck === '1' ? true : false"
            :rows="4"
            v-model="data.memo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import TypeOfServiceApi from "@/api/admin/typeOfService";

import Upload from "@/components/Upload";
import {i18n} from "@/i18n";

@Component({
  components: { Upload },
})
export default class MemberShip extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceMemo;
  @Prop({ default: "" }) serviceImages;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;
  data = {
    memo: "",
    uploadedUrl: [],
  };

  async mounted() {
    this.data.uploadedUrl[0] = this.serviceImages ? this.serviceImages : "";
    this.data.memo = this.serviceMemo ? this.serviceMemo : "";
  }

  async submit() {
    try {
      this.loading = true;

      const data = {
        image: this.data.uploadedUrl[0],
        memo: this.data.memo,
      };

      await TypeOfServiceApi.ImageChange(this.serviceIndex, data);
      await this.$emit("childs-event");

      this.$message.success(i18n.tc('views.admin.services.editComp'));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 500px;
  width: 100%;
  margin-bottom: 16px;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
