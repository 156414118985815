import client from "../client";

export default class Service {
  static async List() {
    return await client.get(`master/service/base/list`);
  }

  static async Detail(index) {
    return await client.get(`master/service/base/${index}`);
  }

  static async Update(data) {
    return await client.put(`master/service/base/update`, data);
  }

  static async MultilingualDetail(index) {
    return await client.get(`master/service/text/list/${index}`);
  }

  static async MultilingualUpdate(index, data) {
    return await client.put(`master/service/text/list/${index}`, data);
  }
}
