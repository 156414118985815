import client from "../client";

export default class SalesMan {
  static async List(options) {
    return await client.get("master/salesman", options);
  }

  static async Create(data) {
    return await client.post("master/salesman", data);
  }

  static async Info(index) {
    return await client.get(`master/salesman/${index}`)
  }

  static async InfoHotel(index) {
    return await client.get(`master/salesman/${index}/hotel`)
  }

  static async PayList(options) {
    return await client.get(`master/salesman/pay`, options)
  }
}
