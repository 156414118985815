import {i18n} from "@/i18n";
export function SalesColumns(){ 
  return [
  {
    dataIndex: "salesCode",
    title: i18n.tc('js.sales.salesCode'),
    scopedSlots: { customRender: "code" },
  },
  {
    title: i18n.tc('js.sales.storeNm'),
    dataIndex: "name",
    scopedSlots: { customRender: "storeNm" },
  },
  {
    dataIndex: "recommendCode",
    title: i18n.tc('js.sales.recommendCode'),
  },
  {
    title: i18n.tc('js.sales.area'),
    dataIndex: "area",
    scopedSlots: {customRender: "area"},
  },
  {
    title: i18n.tc('js.sales.status'),
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: i18n.tc('js.sales.phone'),
    align: "center",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: i18n.tc('js.sales.count'),
    align: "center",
    dataIndex: "hotelCount",
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}