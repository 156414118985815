import client from "../client";

export default class Banner {
  static async List(hotelId) {
    return await client.get(`admin/banner/${hotelId}`);
  }

  static async Order(hotelId, data) {
    return await client.put(`admin/banner/order/${hotelId}`, data);
  }

  static async Update(hotelId, data) {
    return await client.put(`admin/banner/update/${hotelId}`, data);
  }

  static async Create(hotelId, data) {
    return await client.post(`admin/banner/create/${hotelId}`, data);
  }

  static async Delete(imageId) {
    return await client.del(`admin/banner/${imageId}`);
  }
}
