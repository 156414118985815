<template>
  <div class="overallStatistics">
    <div class="main-background">
      <div df fdr fjb fic>
        <a-button style="margin: 16px 0px 8px 0px">
          {{ $t("views.admin.qr.printAll") }}
        </a-button>

        <a-input-search
          @change="load({})"
          v-model="filter.keyword"
          :placeholder="$t('views.admin.tablet.selectRoomSerialMsg')"
          style="width: 280px"
        />
      </div>
      <a-table
        rowKey="index"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="load"
        :loading="loading"
      >
        <template slot="lastRefreshTime" slot-scope="value">
          <last-refresh-time-item :value="value" />
        </template>

        <div
          slot="serialNumber"
          slot-scope="_, record"
          style="font-family: monospace; letter-spacing: 1.5px"
        >
          {{ record.serialNumber }}
        </div>

        <template slot="print" slot-scope="_, record">
          <qrcode-vue
            :value="qrValue(record.serialNumber)"
            :size="150"
            level="H"
            :id="record.serialNumber"
          ></qrcode-vue>
        </template>

        <div
          slot="url"
          slot-scope="_, record"
          style="font-family: monospace; letter-spacing: 1.5px"
        >
          <a href="#" @click="goQrUrl(record.serialNumber)">{{
            urlLoad(record.serialNumber)
          }}</a>
        </div>

        <template slot="display" slot-scope="_, record">
          <display-item :value="record.lastRefreshTime" />
        </template>

        <template slot="orderDetail" slot-scope="_, record">
          <a-button @click="openOrderListModal(record)">
            {{ $t("views.admin.tablet.orderHistory") }}
          </a-button>
        </template>
      </a-table>
    </div>

    <a-modal
      width="700px"
      :title="modalTitle"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleOk"
      :closable="false"
    >
      <a-table
        rowKey="index"
        :columns="modalColumns"
        :data-source="modalData"
        :loading="modalLoading"
        @change="orderDetailLoad"
        :pagination="modalPagination"
      >
        <a-tooltip slot="date" slot-scope="_, record">
          <template slot="title">
            {{
              record.registeredTime
                ? moment(record.registeredTime).fromNow()
                : ""
            }}
          </template>
          <div>
            {{ moment(record.registeredTime).format("YYYY-MM-DD") }}
          </div>
        </a-tooltip>

        <template slot="time" slot-scope="_, record">
          <div>
            {{ moment(record.registeredTime).format("HH:mm") }}
          </div>
        </template>

        <template slot="order" slot-scope="_, record">
          <div>
            {{
              record.orderItems.length > 0
                ? `${record.orderItems[0].productName} ${$t(
                    "views.admin.tablet.exclude",
                  )} ${record.orderItems.length}${$t("views.admin.tablet.gun")}`
                : ""
            }}
          </div>
        </template>
      </a-table>

      <div style="font-size: 13px; font-weight: 600; margin-top: 16px">
        <p>
          {{ $t("views.admin.tablet.orderDesc1") }}
        </p>
        <p>{{ $t("views.admin.tablet.orderDesc2") }}</p>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="handleOk">{{
          $t("views.admin.tablet.ok")
        }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import BatteryItem from "@/components/tablet/BatteryItem.vue";
import LastRefreshTimeItem from "@/components/tablet/LastRefreshTimeItem.vue";
import DisplayItem from "@/components/tablet/DisplayItem.vue";
import { QrColumns } from "@/utils/columns/admin/qr";
import { TabletOrderColumns } from "@/utils/columns/admin/tabletOrder";
import QrcodeVue from "qrcode.vue";
import Colors from "@/utils/colors";
import moment from "moment";
import html2canvas from "html2canvas";

import DeviceApi from "@/api/admin/device";
import OrderApi from "@/api/shared/order";
import { Filter } from "@/utils/filterValue";
import { GmtConversion } from "@/utils/gmtConversion";
import { i18n } from "@/i18n";

@Component({
  components: {
    BatteryItem,
    LastRefreshTimeItem,
    DisplayItem,
    QrcodeVue,
  },
})
export default class Tablet extends Vue {
  moment = moment;
  Colors = Colors;
  timezone = localStorage.getItem("timezone");

  get modalColumns() {
    const colums = TabletOrderColumns();
    return colums;
  }
  loading = false;
  modalLoading = false;
  visible = false;
  modalTitle = "";
  sortedInfo = null;

  get status() {
    return {
      normal: i18n.tc("views.admin.tablet.normal"),
      warning: i18n.tc("views.admin.tablet.warning"),
      danger: i18n.tc("views.admin.tablet.defective"),
    };
  }
  @Filter
  filter = {
    hotelIndex: this.$route.params.key,
    status: "",
    keyword: "",
    order: "",
    type: 10,
    timeSort: 0,
    chargingSort: 0,
    capacitySort: 0,
  };

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  modalPagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  get columns() {
    let { sortedInfo, filter } = this;
    sortedInfo = sortedInfo || {};

    const colums = QrColumns(sortedInfo);
    return colums;
  }

  data = [];
  total = "";
  deviceStatus = {};

  modalData = [];

  mounted() {
    this.load({});
  }

  async load({ current = 1, pageSize = 10 }, filters, sorter) {
    try {
      this.loading = true;

      this.sortedInfo = sorter;

      if (this.sortedInfo) {
        switch (this.sortedInfo.columnKey) {
          case "capacity":
            this.sortedInfo.order === "ascend"
              ? (this.filter.capacitySort = 1)
              : this.sortedInfo.order === "descend"
              ? (this.filter.capacitySort = 2)
              : (this.filter.capacitySort = 0);

            this.filter.chargingSort = 0;
            this.filter.timeSort = 0;
            break;

          case "isCharging":
            this.sortedInfo.order === "ascend"
              ? (this.filter.chargingSort = 1)
              : this.sortedInfo.order === "descend"
              ? (this.filter.chargingSort = 2)
              : (this.filter.chargingSort = 0);

            this.filter.capacitySort = 0;
            this.filter.timeSort = 0;
            break;

          case "lastRefreshTime":
            this.sortedInfo.order === "ascend"
              ? (this.filter.timeSort = 1)
              : this.sortedInfo.order === "descend"
              ? (this.filter.timeSort = 2)
              : (this.filter.timeSort = 0);

            this.filter.chargingSort = 0;
            this.filter.capacitySort = 0;
            break;
        }
      }

      const { list, total } = await DeviceApi.List({
        current,
        pageSize,
        ...this.filterValue,
      });

      this.data = list.map(({ lastRefreshTime, ...item }) => {
        return {
          ...item,
          lastRefreshTime: GmtConversion(lastRefreshTime, this.timezone),
        };
      });

      this.deviceStatus = await DeviceApi.StatusList(this.filter.hotelIndex);
      this.total = this.deviceStatus.total;

      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
        total,
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async downloadQr(item) {
    const canvas = await html2canvas(document.getElementById(item));
    var a = canvas.getContext("2d", {
      willReadFrequently: true,
    });

    var image = a.canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    console.log(image);

    document.getElementById(item + "_download").setAttribute("href", image);
    document
      .getElementById(item + "_download")
      .setAttribute("download", item + "-image.png");
  }

  async openOrderListModal(data) {
    this.modalTitle = `ROOM ${data.room.name}`;
    this.deviceId = data.index;

    await this.orderDetailLoad({});
  }

  async orderDetailLoad({ current = 1, pageSize = 10 }) {
    try {
      this.modalLoading = true;

      const { data } = await OrderApi.List({
        current,
        pageSize,
        "filter.hotelIndex": this.$route.params.key,
        "filter.deviceID": this.deviceId,
      });

      this.modalData = data.list.map(({ registeredTime, ...item }) => {
        return {
          ...item,
          registeredTime: GmtConversion(registeredTime, this.timezone),
        };
      });
      const total = data.total;

      this.modalPagination = {
        ...this.modalPagination,
        current,
        pageSize,
        total,
      };

      this.visible = true;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.modalLoading = false;
    }
  }

  qrValue(serial) {
    let url = "";
    if (process.env.NODE_ENV === "development") {
      url = "http://test-qr.hihotelorder.com?serial=" + btoa(serial);
    } else {
      url = "https://qr.stayorder.co.kr?serial=" + btoa(serial);
    }
    return url;
  }

  goQrUrl(serial) {
    let url = "";
    if (process.env.NODE_ENV === "development") {
      url = "http://test-qr.hihotelorder.com?serial=" + btoa(serial);
    } else {
      url = "https://qr.stayorder.co.kr?serial=" + btoa(serial);
    }

    window.location.href = url;
  }

  urlLoad(serial) {
    let url = "";
    if (process.env.NODE_ENV === "development") {
      url = "http://test-qr.hihotelorder.com?serial=" + btoa(serial);
    } else {
      url = "https://qr.stayorder.co.kr?serial=" + btoa(serial);
    }
    return url;
  }

  deviceStatusChange(status) {
    this.filter.status = status;

    this.load({});
  }

  handleOk(e) {
    this.visible = false;
  }
}
</script>

<style lang="scss" scoped>
.card-style {
  background-color: #cbd9f1;
  margin-right: 16px;
  padding: 24px;
  border-radius: 6px;
  height: 150px;

  .title {
    color: black;
    font-size: 24px;
    font-weight: 600;
  }

  .state {
    font-size: 14px;
    color: #5c94f2;
    font-weight: bold;
    margin-top: 6px;
  }
}

.charging-battery-style {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../public/img/icons/battery/charging-battery.png");
  width: 20px;
  margin-right: 4px;
  filter: invert(35%) sepia(68%) saturate(4693%) hue-rotate(108deg)
    brightness(98%) contrast(102%);
}
</style>
