import client from "../client";

export default class TypeOfService {
  static async StoreCreate(hotelId, data) {
    return await client.post(
      `admin/typeOfService/near/store/create/${hotelId}`,
      data,
    );
  }

  static async StoreDetail(storeId) {
    return await client.get(`admin/typeOfService/near/store/detail/${storeId}`);
  }

  static async StoreUpdate(storeId, data) {
    return await client.put(
      `admin/typeOfService/near/store/update/${storeId}`,
      data,
    );
  }

  static async StoreDelete(storeId) {
    return await client.del(`admin/typeOfService/near/store/delete/${storeId}`);
  }

  static async StoreList(hotelId) {
    return await client.get(`admin/typeOfService/near/store/list/${hotelId}`);
  }

  static async StoreOrder(hotelId, data) {
    return await client.put(
      `admin/typeOfService/near/store/order/${hotelId}`,
      data,
    );
  }

  static async WebLinkChange(serviceId, url) {
    return await client.put(`admin/typeOfService/web/link/${serviceId}`, url);
  }

  static async ImageChange(serviceId, data) {
    return await client.put(`admin/typeOfService/image/${serviceId}`, data);
  }

  static async CategoryList(serviceId) {
    return await client.get(
      `admin/typeOfService/service/category/list/${serviceId}`,
    );
  }

  static async CategoryOrder(serviceId, data) {
    return await client.put(
      `admin/typeOfService/service/category/order/${serviceId}`,
      data,
    );
  }

  static async CategoryCreate(serviceId, data) {
    return await client.post(
      `admin/typeOfService/service/category/create/${serviceId}`,
      data,
    );
  }

  static async CategoryUpdate(data) {
    return await client.put(
      `admin/typeOfService/service/category/update`,
      data,
    );
  }

  static async CategoryDetail(categoryId) {
    return await client.get(
      `admin/typeOfService/service/category/detail/${categoryId}`,
    );
  }

  static async CategoryDelete(categoryId) {
    return await client.del(
      `admin/typeOfService/service/category/delete/${categoryId}`,
    );
  }

  static async CategoryImageList(categoryId) {
    return await client.get(
      `admin/typeOfService/category/image/list/${categoryId}`,
    );
  }

  static async CategoryImageOrder(categoryId, data) {
    return await client.put(
      `admin/typeOfService/category/image/order/${categoryId}`,
      data,
    );
  }

  static async CategoryImageCreate(categoryId, data) {
    return await client.post(
      `admin/typeOfService/category/image/create/${categoryId}`,
      data,
    );
  }

  static async CategoryImageUpdate(data) {
    return await client.put(`admin/typeOfService/category/image/update`, data);
  }

  static async CategoryImageDetail(imageId) {
    return await client.get(
      `admin/typeOfService/category/image/detail/${imageId}`,
    );
  }

  static async CategoryImageDelete(imageId) {
    return await client.del(
      `admin/typeOfService/category/image/delete/${imageId}`,
    );
  }
}
