<template>
  <div class="SummaryStatistics" style="max-width: 100%;">
    <div df flex fdc class="main-background">
      <div df fdr fjb>
        <div>
          <a-select
            default-value="date"
            style="width: 220px;margin-right:16px"
            v-model="totalType"
            @change="totalTypeSwitch(true)"
          >
            <a-select-option
              v-for="item in types"
              :key="item.value"
              :value="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>

          <a-space style="margin-right:16px" v-if="totalType === 'room'">
            <a-select
              show-search
              :filter-option="filterOption"
              mode="multiple"
              :placeholder="$t('views.admin.overallStatistics.selectRoom')"
              style="width: 360px"
              v-model="devices"
            >
              <a-select-option
                v-for="item in deviceList"
                :key="item.index"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-space>

          <a-radio-group
            v-if="
              totalType !== 'service' &&
                totalType !== 'ranking' &&
                totalType !== 'menu'
            "
            name="radioGroup"
            v-model="isAmount"
            @change="totalTypeSwitch(false)"
          >
            <a-radio :value="true">
              {{$t('views.admin.overallStatistics.sale')}}
            </a-radio>
            <a-radio :value="false">
              {{$t('views.admin.overallStatistics.orderAm')}}
            </a-radio>
          </a-radio-group>
        </div>
        <a-button v-if="totalType !== 'ranking'" @click="excelDownLoad"
          >{{$t('views.admin.order.downloadExcel')}}</a-button
        >
      </div>

      <div df fdr fic style="margin:16px 0px" v-if="totalType !== 'ranking'">
        <a-radio-group
          default-value="0"
          button-style="solid"
          v-model="periodType"
        >
          <a-radio-button value="2">
            {{$t('views.admin.overallStatistics.selectDur')}}
          </a-radio-button>
          <a-radio-button value="0">
            {{$t('views.admin.overallStatistics.selectMon')}}
          </a-radio-button>
          <a-radio-button value="1">
            {{$t('views.admin.overallStatistics.selectYear')}}
          </a-radio-button>
        </a-radio-group>

        <div df fic fjc style="margin-left:16px;">
          <a-month-picker
            v-if="periodType === '0'"
            style="width:120px"
            placeholder="Select month"
            v-model="selectedMonth"
          >
            <template slot="monthCellContentRender" slot-scope="date">
              <div>{{ `${moment(date).format("M")}${$t('views.admin.overallStatistics.month')}` }}</div>
            </template>
          </a-month-picker>

          <a-select
            v-if="periodType === '1'"
            style="width: 100px;"
            :placeholder="$t('views.admin.overallStatistics.yearSelect')"
            v-model="selectedYear"
          >
            <a-select-option v-for="item in yearList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>

          <a-space v-if="periodType === '2'" df fdr>
            <a-date-picker
              v-model="periodDate.startDate"
              :disabled-date="disabledStartDate"
            />
            <span>~</span>
            <a-date-picker
              v-model="periodDate.endDate"
              :disabled-date="disabledEndDate"
            />
          </a-space>

          <a-button @click="totalTypeSwitch(false)" style="margin-left:8px"
            >{{$t('views.admin.overallStatistics.search')}}</a-button
          >
        </div>
      </div>

      <div
        style="padding:10px 0px"
        v-if="
          totalType !== 'service' &&
            totalType !== 'ranking' &&
            totalType !== 'menu'
        "
      >
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
          <a-checkbox
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            {{$t('views.admin.overallStatistics.all')}}
          </a-checkbox>
        </div>
        <br />
        <a-checkbox-group
          :value="checkedList"
          :options="baseServices"
          @change="onChange"
        />
      </div>

      <div df flex fdc style="margin-top:12px;width:100%">
        <div v-if="loading" df fjc fic style="height:400px">
          <a-icon type="loading" style="color:blue" />
        </div>

        <div
          v-if="
            !loading &&
              (totalType === 'date' ||
                totalType === 'time' ||
                totalType === 'room')
          "
        >
          <div df fdr fje style="color:#c2c2c2;margin-bottom:4px">
            {{ isAmount ? $t('views.admin.overallStatistics.unitWon') : $t('views.admin.overallStatistics.unitGun')}}
          </div>

          <div style="overflow:auto;">
            <div class="title-style" df fdr>
              <div
                v-for="(item, key) in option"
                :key="key"
                class="title-content"
              >
                <div>
                  {{ item }}
                </div>
              </div>
            </div>

            <div df fdc>
              <div
                df
                fic
                class="content-style"
                v-for="(item, key) in data"
                :key="key"
              >
                <div
                  df
                  flex
                  fic
                  style="font-weight:800"
                  class="content-in-style"
                >
                  {{ item.title }}
                </div>
                <div df flex fic fje class="content-in-style">
                  {{
                    isAmount
                      ? Number(item.totalAmount).toLocaleString()
                      : Number(item.totalCount).toLocaleString()
                  }}
                </div>

                <div
                  df
                  flex
                  fic
                  fje
                  class="content-in-style"
                  v-for="(item, key) in item.contents"
                  :key="key"
                >
                  <div>
                    {{
                      isAmount
                        ? Number(item.amount).toLocaleString()
                        : Number(item.count).toLocaleString()
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loading && (totalType === 'service' || totalType === 'menu')"
        >
          <div df fdr fic v-if="totalType === 'menu'">
            <div style="margin-right:20px">
              {{$t('views.admin.overallStatistics.service')}}
              <a-select
                style="width: 240px;;margin-left:8px"
                :placeholder="$t('views.admin.overallStatistics.none')"
                @change="categoryLoad()"
                v-model="serviceId"
              >
                <a-select-option :value="0">{{$t('views.admin.overallStatistics.none')}}</a-select-option>
                <a-select-option
                  v-for="item in baseServices"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
            <div>
              {{$t('views.admin.overallStatistics.category')}}
              <a-select
                show-search
                :filter-option="filterOption"
                mode="multiple"
                style="min-width: 340px;margin-left:8px"
                :placeholder="$t('views.admin.overallStatistics.all')"
                v-model="categories"
              >
                <a-select-option
                  v-for="item in categoryList"
                  :key="item.index"
                  :value="Number(item.index)"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>

          <div
            class="title-style"
            df
            fdr
            :style="{
              marginTop: totalType === 'menu' ? '20px' : '0px',
            }"
          >
            <div class="title-content" v-for="(item, key) in option" :key="key">
              <div>
                {{ item }}
              </div>
              <div class="unit-style">
                {{ unitViewControl(item) }}
              </div>
              <a
                v-if="item === $t('views.admin.overallStatistics.sale') || item === $t('views.admin.overallStatistics.orderAm2')"
                @click="sortChange(item)"
              >
                <a-icon
                  :style="{
                    marginLeft: '10px',
                    color:
                      item === $t('views.admin.overallStatistics.sale') && sorting === 0
                        ? '#1890FF'
                        : item === $t('views.admin.overallStatistics.orderAm2') && sorting === 1
                        ? '#1890FF'
                        : '#919090',
                  }"
                  type="caret-down"
                />
              </a>
            </div>
          </div>

          <div df fdc>
            <div
              df
              fic
              class="content-style"
              v-for="(item, key) in data"
              :key="key"
            >
              <div style="padding:0px 20px;font-weight:800" df flex fic>
                {{ item.name }}
              </div>
              <div style="padding:0px 20px" df flex fic fje>
                {{ Number(item.amount).toLocaleString() }}
              </div>
              <div style="padding:0px 20px" df flex fic fje>
                {{ Number(item.count).toLocaleString() }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading && totalType === 'ranking'">
          <div df flex fdr style="margin-top:20px">
            <ranking-card
              :title="$t('views.admin.overallStatistics.category')"
              color="#05b3b4"
              :data="data.categories"
              rankingObject="category"
              icon="crown"
            />

            <ranking-card
              :title="$t('views.admin.overallStatistics.item')"
              color="#f79c18"
              :data="data.items"
              rankingObject="item"
              icon="shopping-cart"
            />

            <ranking-card
              :title="$t('views.admin.overallStatistics.service')"
              color="#f81d77"
              :data="data.services"
              rankingObject="service"
              icon="solution"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import XLSX from "xlsx";

import StatisticsApi from "@/api/shared/statistics";
import AStatisticsApi from "@/api/admin/statistics";
import ServiceApi from "@/api/admin/service";
import MessageApi from "@/api/admin/message";
import CategoryApi from "@/api/admin/category";
import {i18n} from "@/i18n";

import Colors from "@/utils/colors";
import {
  GmtConversionReverse,
  EndDate,
  StartDate,
} from "@/utils/gmtConversion";

import RankingCard from "@/components/statistics/RankingCard";

@Component({
  components: { RankingCard },
})
export default class SummaryStatistics extends Vue {
  moment = moment;
  Colors = Colors;
  timezone = localStorage.getItem("timezone");

  levelCheck = localStorage.getItem("level");
  adminId = localStorage.getItem("adminId");
  hotelIndex = this.$route.params.key;

  types = [
    { value: "service", name: i18n.tc('views.admin.overallStatistics.perService') },
    { value: "date", name: i18n.tc('views.admin.overallStatistics.perDate') },
    { value: "time", name: i18n.tc('views.admin.overallStatistics.perTime') },
    { value: "room", name: i18n.tc('views.admin.overallStatistics.perRoom') },
    { value: "menu", name: i18n.tc('views.admin.overallStatistics.perMenu') },
    { value: "ranking", name: i18n.tc('views.admin.overallStatistics.menuRank') },
  ];

  excelData = [];
  excelDate = "";
  excelTitle = "";

  loading = false;

  isAmount = true;
  selectedMonth = moment().format("YYYY-MM");
  selectedYear = moment().format("YYYY");
  yearList = [];

  totalType = "service";
  periodType = "2";

  periodDate = {
    startDate: moment(),
    endDate: moment(),
  };

  baseServices = [];
  checkedList = [];
  totalCheckedList = [];
  indeterminate = false;
  checkAll = true;
  exceptionCode = [
    "SV_ALARM",
    "SV_NEARBY_STORES",
    "SV_MEMBERSHIP_QR",
    "SV_HOTEL_INFO_IMAGE",
    "SV_MEMBERSHIP",
    "SV_WEBVIEW",
    "SV_WEATHER",
    "SV_SURVEY",
    "SV_DELIVERY_GROUP",
  ];

  devices = [];
  deviceList = [];

  serviceId = 0;
  categories = [];
  categoryList = [];

  sorting = 0;

  data = [];
  option = [];

  disabledStartDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.periodDate.endDate ? value.isAfter(this.periodDate.endDate) : false)
    );
  }

  disabledEndDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.periodDate.startDate
        ? value.isBefore(this.periodDate.startDate)
        : false)
    );
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  async mounted() {
    await this.yearListLoad();
    await this.deviceLoad();
    await this.loadBasicService();
    await this.totalTypeSwitch(false);
  }

  async deviceLoad() {
    try {
      this.loading = true;

      const { data } = await MessageApi.DeviceList(this.hotelIndex);

      this.deviceList = data.map(item => ({
        index: item.index,
        name: item.room?.number,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async yearListLoad() {
    try {
      const { data } = await StatisticsApi.YearList();

      this.yearList = data;
    } catch {}
  }

  async loadBasicService() {
    try {
      this.loading = true;

      var list;
      if (this.levelCheck === "2") {
        const { data } = await ServiceApi.AssignCheck(this.adminId);
        list = data;
      } else {
        list = await ServiceApi.List(this.hotelIndex);
      }

      const serviceList = list.filter(
        item => this.exceptionCode.indexOf(item.code) === -1,
      );

      this.baseServices = serviceList.map(item => ({
        value: item.index,
        label: item.name,
      }));

      this.checkedList = this.baseServices.map(item => item.value);
      this.totalCheckedList = this.baseServices.map(item => item.value);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async categoryLoad() {
    try {
      this.categoryList = [];
      this.categories = [];
      if (!this.serviceId) {
        return;
      }

      const { data } = await CategoryApi.List({
        "filter.storeIndex": this.hotelIndex,
        "filter.serviceID": this.serviceId,
      });

      this.categoryList = data.map(({ information, index }) => ({
        index,
        name: information.Kr.name,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async onChange(checkedList) {
    if (checkedList.length === 0) {
      this.$message.warning(i18n.tc('views.admin.overallStatistics.serviceWarn'));
      return;
    }

    this.checkedList = checkedList;
    this.indeterminate =
      !!checkedList.length && checkedList.length < this.baseServices.length;
    this.checkAll = checkedList.length === this.baseServices.length;
  }

  async sortChange(value) {
    this.sorting = value === i18n.tc('views.admin.overallStatistics.sale') ? 0 : 1;

    if (this.totalType === "menu") {
      await this.menuStatisticsLoad();
    } else {
      await this.serviceStatisticsLoad();
    }
  }

  onCheckAllChange(e) {
    Object.assign(this, {
      checkedList: e.target.checked
        ? this.totalCheckedList
        : this.totalCheckedList.length > 0
        ? [this.totalCheckedList[0]]
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }

  totalTypeSwitch(bool) {
    if (bool) {
      this.periodType = "2";
      this.devices = [];
      this.selectedMonth = moment().format("YYYY-MM");
      this.serviceId = 0;
      this.categories = [];
      this.sorting = 0;
    }

    switch (this.totalType) {
      case "date":
        this.dateStatisticsLoad();
        break;

      case "time":
        this.timeStatisticsLoad();
        break;

      case "room":
        this.roomStatisticsLoad();
        break;

      case "menu":
        this.menuStatisticsLoad();
        break;

      case "ranking":
        this.menuRankingStatisticsLoad();
        break;

      case "service":
        this.serviceStatisticsLoad();
        break;
    }
  }

  async dateStatisticsLoad() {
    try {
      this.loading = true;

      const data = await AStatisticsApi.DateStatistics(this.periodTypeSwitch);

      this.data = data.data;
      this.excelTitle = i18n.tc('views.admin.overallStatistics.perDateStatistics');

      if (this.isAmount) {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.sale'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, true);
        });
      } else {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.orderAm2'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, false);
        });
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async timeStatisticsLoad() {
    try {
      this.loading = true;

      const data = await AStatisticsApi.TimeStatistics(this.periodTypeSwitch);

      this.data = data.data.map(({ title, ...item }) => {
        let titleValue = title;

        const timezoneSplit = this.timezone.split("GMT");

        let operators = "+";
        let number = "9";

        if (timezoneSplit[1] !== "0") {
          operators = timezoneSplit[1].charAt(0);
          number = timezoneSplit[1].charAt(1);
        }

        if (title !== i18n.tc('views.admin.overallStatistics.sum')) {
          let time = title.split("~");
          let firstTime = time[0].split("시");
          let lastTime = time[1].split("시");
          let firstTimeValue = 0;
          let lastTimeValue = 0;

          if (operators === "+") {
            firstTimeValue =
              Number(firstTime[0]) + Number(number) >= 24
                ? Number(firstTime[0]) + Number(number) - 24
                : Number(firstTime[0]) + Number(number);
            lastTimeValue =
              Number(lastTime[0]) + Number(number) > 24
                ? Number(lastTime[0]) + Number(number) - 24
                : Number(lastTime[0]) + Number(number);
          } else {
            firstTimeValue =
              Number(firstTime[0]) - Number(number) < 0
                ? Number(firstTime[0]) - Number(number) + 24
                : Number(firstTime[0]) - Number(number);
            lastTimeValue =
              Number(lastTime[0]) - Number(number) <= 0
                ? Number(lastTime[0]) - Number(number) + 24
                : Number(lastTime[0]) - Number(number);
          }

          titleValue = `${firstTimeValue} ~ ${lastTimeValue}`;
        }

        return {
          ...item,
          title: titleValue,
        };
      });
      this.excelTitle = i18n.tc('views.admin.overallStatistics.perTimeStatistics');
      this.data.sort((a, b) => {
        let aTimeValue;
        let bTimeValue;
        if (a.title !== i18n.tc('views.admin.overallStatistics.sum') || b.title !== i18n.tc('views.admin.overallStatistics.sum')) {
          let aTime = a.title.split("~");
          let bTime = b.title.split("~");
          aTimeValue = aTime[0].split("시");
          bTimeValue = bTime[0].split("시");
        }

        {
          return Number(aTimeValue[0]) - Number(bTimeValue[0]);
        }
      });

      if (this.isAmount) {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.sale'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, true);
        });
      } else {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.orderAm2'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, false);
        });
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async roomStatisticsLoad() {
    try {
      this.loading = true;

      const data = await AStatisticsApi.RoomStatistics(this.periodTypeSwitch);

      this.data = data.data;
      this.excelTitle = i18n.tc('views.admin.overallStatistics.perRoomStatistics');

      if (this.isAmount) {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.sale'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, true);
        });
      } else {
        this.option = [i18n.tc('views.admin.overallStatistics.date'), i18n.tc('views.admin.overallStatistics.orderAm2'), ...data.option];

        this.excelData = this.data.map(item => {
          return this.excelDataManager(data.option, item, false);
        });
      }
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async menuStatisticsLoad() {
    try {
      this.loading = true;

      const { data } = await AStatisticsApi.MenuStatistics(
        this.periodTypeSwitch,
      );

      this.excelTitle = i18n.tc('views.admin.overallStatistics.perMenuStatistics');
      const name = i18n.tc('views.admin.overallStatistics.serviceNm');
      const sale = i18n.tc('views.admin.overallStatistics.sale');
      const orderNum = i18n.tc('views.admin.overallStatistics.orderAm2');
      this.excelData = data.map(item => {
        return {
          [name]: item.name,
          [sale]: item.amount,
          [orderNum]: item.count,
        };
      });

      this.data = data;
      this.option = [i18n.tc('views.admin.overallStatistics.serviceNm'), i18n.tc('views.admin.overallStatistics.sale'), i18n.tc('views.admin.overallStatistics.orderAm2')];
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async menuRankingStatisticsLoad() {
    try {
      this.loading = true;

      const data = await AStatisticsApi.MenuRankingStatistics(this.hotelIndex);
      this.data = data.data;
    } catch {
    } finally {
      this.loading = false;
    }
  }

  async serviceStatisticsLoad() {
    try {
      this.loading = true;

      const { data } = await StatisticsApi.ServiceStatistics(
        this.periodTypeSwitch,
      );

      this.excelTitle = i18n.tc('views.admin.overallStatistics.perServiceStatistics');
      const name = i18n.tc('views.admin.overallStatistics.serviceNm');
      const sale = i18n.tc('views.admin.overallStatistics.sale');
      const orderNum = i18n.tc('views.admin.overallStatistics.orderAm2');
      this.excelData = data.map(item => {
        return {
          [name]: item.name,
          [sale]: item.amount,
          [orderNum]: item.count,
        };
      });

      this.data = data;
      this.option = [i18n.tc('views.admin.overallStatistics.serviceNm'), i18n.tc('views.admin.overallStatistics.sale'), i18n.tc('views.admin.overallStatistics.orderAm2')];
    } catch {
    } finally {
      this.loading = false;
    }
  }

  get periodTypeSwitch() {
    let params = {
      type: Number(this.periodType),
      hotelId: Number(this.hotelIndex),
    };

    if (this.totalType === "service") {
      params = {
        ...params,
        sorting: this.sorting,
      };
    } else if (this.totalType === "menu") {
      params = {
        ...params,
        serviceId: this.serviceId,
        categories: this.categories,
        sorting: this.sorting,
      };
    } else {
      const serviceList = this.baseServices
        .filter(item => this.checkedList.includes(item.value))
        .map(item => ({
          id: Number(item.value),
          name: item.label,
        }));

      params = {
        ...params,
        serviceList: serviceList,
        devices: this.devices,
        timezone: this.timezone,
      };
    }

    switch (this.periodType) {
      case "0":
        this.excelDate = `${moment(this.selectedMonth).format("YYYY-MM")} `;

        return {
          ...params,
          startDate: GmtConversionReverse(
            StartDate(this.selectedMonth, "month"),
            this.timezone,
          ),
          endDate: GmtConversionReverse(
            EndDate(this.selectedMonth, "month"),
            this.timezone,
          ),
        };

      case "1":
        this.excelDate = `${this.selectedYear} `;
        return {
          ...params,
          year: Number(this.selectedYear),
          startDate: GmtConversionReverse(
            StartDate(String(this.selectedYear), "year"),
            this.timezone,
          ),
          endDate: GmtConversionReverse(
            EndDate(String(this.selectedYear), "year"),
            this.timezone,
          ),
        };

      case "2":
        this.excelDate = `${moment(this.periodDate.startDate).format(
          "YYYY-MM-DD",
        )}∼${moment(this.periodDate.endDate).format("YYYY-MM-DD")}`;

        return {
          ...params,
          startDate: GmtConversionReverse(
            StartDate(this.periodDate.startDate, "day"),
            this.timezone,
          ),
          endDate: GmtConversionReverse(
            EndDate(this.periodDate.endDate, "day"),
            this.timezone,
          ),
        };
    }
  }

  async excelDownLoad() {
    try {
      if (this.excelData.length > 0) {
        const title = this.excelDate + " " + this.excelTitle;
        var dataWS = XLSX.utils.json_to_sheet(this.excelData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS, this.excelTitle);
        XLSX.writeFile(wb, `${title}.xlsx`);
      } else {
        this.$message.warning(i18n.tc('views.admin.overallStatistics.noData'));
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.admin.overallStatistics.failDownload');

      this.$message.error(error);
    }
  }

  excelDataManager(key, data, isAmount) {
    let excelData;
    if (isAmount) {
      const date = i18n.tc('views.admin.overallStatistics.date');
      const sale = i18n.tc('views.admin.overallStatistics.sale');
      excelData = { [date]: data.title, [sale]: data.totalAmount };
      if (key.length > 0) {
        for (let i = 0; i < key.length; i++) {
          excelData = { ...excelData, [`${key[i]}`]: data.contents[i].amount };
        }
      }
    } else {
      const date = i18n.tc('views.admin.overallStatistics.date');
      const ordrNum = i18n.tc('views.admin.overallStatistics.orderAm2');
      excelData = { [date]: data.title, [ordrNum]: data.totalCount };
      if (key.length > 0) {
        for (let i = 0; i < key.length; i++) {
          excelData = { ...excelData, [`${key[i]}`]: data.contents[i].count };
        }
      }
    }
    return excelData;
  }

  unitViewControl(value) {
    return value === i18n.tc('views.admin.overallStatistics.sale')
      ? i18n.tc('views.admin.overallStatistics.unitWon')
      : value === i18n.tc('views.admin.overallStatistics.orderAm')
      ? i18n.tc('views.admin.overallStatistics.unitGun')
      : "";
  }
}
</script>

<style lang="scss" scoped>
.title-style {
  border-bottom: 1px solid #e8e8e8;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
}

.title-content {
  padding: 0px 20px;
  min-width: 180px;
  background: #fafafa;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;

  &:first-child {
    justify-content: flex-start;
  }
}

.content-style {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  height: 52px;
  font-size: 14px;
  font-weight: 500;

  &:first-child {
    font-weight: bold;
    border-bottom: 3px solid #e8e8e8;
  }
}

.content-in-style {
  padding: 0px 20px;
  min-width: 180px;
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8;
  height: 52px;
}

.row-scroll-style {
  overflow-x: scroll;
}

.unit-style {
  margin-left: 8px;
  font-size: 11px;
  color: #919090;
  margin-top: 2px;
}
</style>
