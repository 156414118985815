<template>
  <div df fdc>
    <div>
      {{ value ? moment(value).format("YYYY-MM-DD") : "" }}
    </div>
    <div
      style="font-size: 14px;font-weight: bold;"
      v-bind:class="deviceStatusClassObject(value)"
      v-if="value && diffDateMinute(value).toFixed(0) > 1"
    >
      {{
        value
          ? diffDateMinute(value).toFixed(0) > 60
            ? `${(diffDateMinute(value).toFixed(0) / 60).toFixed(0)} ${$t('components.tablet.beforeH')}`
            : `${diffDateMinute(value).toFixed(0)} ${$t('components.tablet.beforeM')}`
          : ""
      }}
    </div>
    <div
      style="font-size: 14px;font-weight: bold;"
      v-if="value && diffDateMinute(value).toFixed(0) < 1"
    >
      {{$t('components.tablet.before')}}
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import Colors from "@/utils/colors";
import moment from "moment";

@Component({
  components: {},
})
export default class LastRefreshTimeItem extends Vue {
  @Prop({ default: "0" }) value;

  Colors = Colors;
  moment = moment;

  currentTime = moment();

  deviceStatusClassObject(value) {
    const diffTime = this.diffDateMinute(value);
    if (diffTime >= 60) {
      return {
        normal: false,
        warning: false,
        danger: true,
      };
    }

    if (diffTime < 60 && diffTime > 1) {
      return {
        normal: false,
        warning: true,
        danger: false,
      };
    }

    if (diffTime <= 1) {
      return {
        normal: true,
        warning: false,
        danger: false,
      };
    }
  }

  diffDateMinute(value) {
    if (value) {
      return Number(moment.duration(this.currentTime.diff(value)).asMinutes());
    }
  }
}
</script>

<style lang="scss" scoped>
.normal {
  color: #5c94f2;
}

.warning {
  color: #f7be40;
}

.danger {
  color: #d44646;
}
</style>
