<template>
  <div class="tablet">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.master.tablet.title1') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.tablet.title2') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">
          {{ index ? $t('views.master.tablet.edit.tabletUpdate') : $t('views.master.tablet.edit.tabletAdd') }}
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <a-form-model
          ref="form"
          :model="data"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <div df fdr>
          <div flex fjc fic>
            <a-form-model-item prop="hotelIndex" :label="$t('views.master.tablet.hotel')">
              <hotel-list
                  v-model="data.hotelIndex"
                  :isEmitName="true"
                  @name="hotelNameLoad"
                  :all="false"
              />
            </a-form-model-item>

            <div v-if="index ? false : true">
              <a-form-model-item prop="type" :label="$t('views.master.tablet.title1')">
                <a-radio-group v-model="data.type" button-style="solid">
                  <a-radio-button :value="0">{{ $t('views.master.tablet.receptionTablet') }}</a-radio-button>
                  <a-radio-button :value="1">{{ $t('views.master.tablet.roomTablet') }}</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </div>

            <div v-if="index ? true : false">
              <a-form-model-item prop="type" :label="$t('views.master.tablet.edit.tablet')">
                <div class="tablet-input">
                  {{ data.type === 0 ? $t('views.master.tablet.receptionTablet') : $t('views.master.tablet.roomTablet') }}
                </div>
              </a-form-model-item>
            </div>

            <a-form-model-item prop="status" :label="$t('views.master.tablet.edit.status')">
              <a-radio-group v-model="data.status" button-style="solid">
                <a-radio-button value="1">{{ $t('views.master.tablet.use') }}</a-radio-button>
                <a-radio-button value="0">{{ $t('views.master.tablet.noUse') }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item prop="knoxStatus" :label="$t('views.master.tablet.edit.knoxStatus')">
              <a-radio-group v-model="data.knoxStatus" button-style="solid">
                <a-radio-button value="1">O</a-radio-button>
                <a-radio-button value="0">X</a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item prop="serialNumber" :label="$t('views.master.tablet.edit.serialNumber')">
              <a-input v-model="data.serialNumber"/>
            </a-form-model-item>

            <a-form-model-item
                v-if="data.type === 1"
                prop="roomNumber"
                :label="$t('views.master.tablet.edit.roomNumber')"
            >
              <a-input v-model="data.roomNumber"/>
            </a-form-model-item>

            <a-form-model-item props="description" :label="$t('views.master.tablet.edit.memo')">
              <a-textarea :rows="4" v-model="data.description"/>
            </a-form-model-item>

            <a-form-model-item
                v-if="index ? true : false"
                props="androidIdReset"
                :label="$t('views.master.tablet.edit.androidIdReset')"
            >
              <a-popconfirm
                  :title="$t('views.master.tablet.edit.androidIdResetMsg')"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="androidIdResetSubmit"
              >
                <a-button>
                  {{ $t('views.master.tablet.edit.reset') }}
                </a-button>
              </a-popconfirm>
            </a-form-model-item>

            <div df fje>
              <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
                <a-button
                    style="width:100px"
                    :loading="loading"
                    type="primary"
                    @click="submit"
                >{{ $t('views.master.tablet.edit.save') }}
                </a-button
                >
              </a-form-model-item>
            </div>
          </div>

          <div flex/>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import HotelList from "@/components/HotelList.vue";
import DeviceApi from "@/api/master/device";

import {i18n} from "@/i18n";

@Component({
  components: {
    HotelList,
  },
})
export default class TabletEdit extends Vue {
  index = this.$route.params.index ? this.$route.params.index : null;
  labelCol = {span: 6};
  wrapperCol = {span: 18};

  loading = false;

  rules = {
    hotelIndex: [
      {
        required: true,
        validator: this.hotelIndexValidator,
        trigger: "change",
      },
    ],
    type: [
      {
        required: true,
        message: i18n.tc('views.master.tablet.edit.selectTypeMsg'),
      },
    ],
    serialNumber: [
      {
        required: true,
        validator: this.serialCheckValidator,
        trigger: "change",
      },
    ],
    status: [
      {
        required: true,
        message: i18n.tc('views.master.tablet.edit.selectStatusMsg'),
      },
    ],
    knoxStatus: [
      {
        required: true,
        message: i18n.tc('views.master.tablet.edit.selectKnoxMsg'),
      },
    ],
  };

  data = {
    type: 0,
    status: "0",
    knoxStatus: "0",
    roomNumber: "",
    serialNumber: "",
    description: "",
    hotelIndex: "",
    hotelName: "",
  };

  originalSerial = "";

  hotelIndexValidator(rule, value, callback) {
    if (!value) {
      callback(new Error(i18n.tc('views.master.tablet.edit.selectHotelMsg')));
    } else {
      if (this.data.hotelIndex !== "") {
        this.$refs.form.validateField("checkPass");
      }
      callback();
    }
  }

  async serialCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('views.master.tablet.edit.inputSerialNumberMsg')));
    } else {
      if (
          this.data.serialNumber !== "" &&
          this.data.serialNumber !== this.originalSerial
      ) {
        try {
          const response = await DeviceApi.SerialCheck(this.data.serialNumber);

          if (response == true) {
            callback(new Error(i18n.tc('views.master.tablet.edit.duplicateSerialNumberMsg')));
          } else {
            this.$refs.form.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
              ? e.response.data.data
              : i18n.tc('views.master.tablet.errorMsg');

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  hotelNameLoad(value) {
    this.data.hotelName = value;
  }

  mounted() {
    if (this.index) {
      this.load(this.index);
    }

    this.data.hotelIndex = this.$route.params.hotelIndex
        ? Number(this.$route.params.hotelIndex)
        : undefined;
  }

  buttonItemLayout() {
    const {formLayout} = this;
    return formLayout === "horizontal"
        ? {
          wrapperCol: {span: 14, offset: 4},
        }
        : {};
  }

  submit() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        try {
          this.loading = true;

          if (this.index) {
            await DeviceApi.Update(this.index, this.data);
          } else {
            await DeviceApi.Create(this.data);
          }

          this.$router.back();
        } catch (e) {
          let error = e.response?.data?.data
              ? e.response.data.data
              : i18n.tc('views.master.tablet.errorMsg');

          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async load(index) {
    try {
      this.loading = true;

      const {data} = await DeviceApi.Detail(index);

      this.data = data;

      this.data.roomNumber = data.room.number;
      this.data.description = data.name;

      this.originalSerial = data.serialNumber;
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.tablet.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async androidIdResetSubmit() {
    try {
      this.loading = true;

      const response = await DeviceApi.AndroidIdReset(this.index);

      if (response.status) {
        this.$message.success(i18n.tc('views.master.tablet.resetCompleteMsg'));
      }
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.tablet.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.tablet-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 50%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-image: none;
  transition: all 0.3s;
}
</style>
