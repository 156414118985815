<template>
  <a-radio-group v-model="data" button-style="solid" @change="change()">
    <a-radio-button value>{{$t('components.service.all')}} {{ statusCount("total") }}</a-radio-button>
    <a-radio-button v-for="(item, key) in itemStatus" :key="key" :value="key">
      {{ item }} {{ statusCount(key) }}
    </a-radio-button>
  </a-radio-group>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class StatusRadioForm extends Vue {
  @Prop() itemStatusCount;

  data = "";

  itemStatus = {
    "1": i18n.tc('components.service.sale'),
    "2": i18n.tc('components.service.soldout'),
    "0": i18n.tc('components.service.noSale'),
  };

  statusCount(value) {
    switch (value) {
      case "total":
        return this.itemStatusCount.total;

      case "0":
        return this.itemStatusCount.nonSell;

      case "1":
        return this.itemStatusCount.sell;

      case "2":
        return this.itemStatusCount.soldOut;
    }
  }

  change() {
    this.$emit("statusRadioFilter", this.data);
    this.$emit("change");
  }
}
</script>

<style lang="scss" scoped></style>
