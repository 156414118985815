<template>
    <div class="sales">
      <div df fdr fjb fic class="bread-crumb-parent">
        <a-breadcrumb class="bread-crumb">
          <a-breadcrumb-item class="fontSub">{{
            $t("views.master.sales.title1")
          }}</a-breadcrumb-item>
          <a-breadcrumb-item class="fontMain">{{
            $t("views.master.sales.title2")
          }}</a-breadcrumb-item>
          <a-breadcrumb-item class="fontMain">{{
            $t("views.master.sales.create.title")
          }}</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
  
      <div class="main-background">
        <a-form-model
          ref="form"
          :model="data"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div  style="margin: 4px 0px 0px 50px;">
            <div  class="service">
              {{ $t("views.master.sales.create.salesInfo") }}
            </div>
          </div>
          <div df fdr style="padding: 10px">
            <div style="margin: 0px 20px">
                <a-form-model-item
                    :label="$t('views.master.sales.create.type')"
                >
                    <a-radio-group
                        v-model="data.salesType"
                        button-style="solid"
                        >
                        <a-radio-button :value="'1'">
                            대리점
                        </a-radio-button>
                        <a-radio-button
                            :value="'2'"
                        >
                            영업자
                        </a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.name')"
              >
                <a-input v-model="data.name" />
              </a-form-model-item>

              <a-form-model-item
              :label="$t('views.master.sales.create.pw')"
            >
              <a-input-password v-model="data.password" />
            </a-form-model-item>

              <a-form-model-item
                    :label="$t('views.master.sales.create.status')"
                >
                    <a-radio-group
                        v-model="data.status"
                        button-style="solid"
                        >
                        <a-radio-button :value="'1'">
                            운영
                        </a-radio-button>
                        <a-radio-button
                            :value="'0'"
                        >
                            미운영
                        </a-radio-button>
                    </a-radio-group>
                </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.salesCode')"
              >
                <a-input v-model="data.salesCode" />
              </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.recommendCode')"
              >
                <a-input v-model="data.recommendCode" />
              </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.phone')"
              >
                <a-input v-model="data.phone" />
              </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.area')"
              >
                <a-input v-model="data.area" />
              </a-form-model-item>

              <a-form-model-item
                :label="$t('views.master.sales.create.email')"
              >
                <a-input v-model="data.email" />
              </a-form-model-item>
            </div>
          </div>
  
          <div  style="margin-top: 24px;margin-left: 40px;">
            <a-form-model-item>
              <a-button
                @click="submit"
                type="primary"
                :loading="loading"
                size="large"
                style="width: 120px"
              >
                {{ $t("views.master.hotel.create.register") }}
              </a-button>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </div>
  </template>
  
  <script>
  import { Component, Vue } from "vue-property-decorator";
  import LanguageTab from "@/components/LanguageTab";
  
  import SalesManApi from "@/api/master/salesman";
  
  import Tabs from "@/utils/languageTab";
  
  import { i18n } from "@/i18n";
  
  @Component({
    components: { LanguageTab },
  })
  export default class HotelCreate extends Vue {
    labelCol = { span: 6 };
    wrapperCol = { span: 18 };
    loading = false;
    tabs = Tabs;
  
    data = {
      status:'1',
      salesType:'1',
      name:"",
      salesCode:"",
      recommendCode:"",
      phone:"",
      area:"",
      email:"",
    };
  
  
    get rules() {
      return {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [`information.${key}.name`]: [
              {
                required: this.isLanguageUsed(key),
                message: i18n.tc("views.master.hotel.create.hotelNameMsg"),
              },
            ],
            [`information.Kr.address`]: [
              {
                required: this.isLanguageUsed("Kr"),
                message: i18n.tc("views.master.hotel.create.hotelAddressMsg"),
              },
            ],
            [`information.${key}.description`]: [{}],
          }),
          {},
        ),
        status: [
          {
            required: true,
            message: i18n.tc("views.master.hotel.create.hotelStatusMsg"),
          },
        ],
        id: [
          {
            required: true,
            validator: this.idCheckValidator,
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: i18n.tc("views.master.hotel.create.passwordMsg"),
          },
        ],
        passwordConfirm: [
          {
            required:
              this.data.password !== null && this.data.password !== ""
                ? true
                : false,
            validator: this.passwordCheckValidator,
            trigger: "change",
          },
        ],
      };
    }
    
    isLanguageUsed(key) {
      if (key === "Kr" || key === "En") {
        return true;
      }
    }
  
    async mounted() {
    }
  
    async submit() {
        await this.createSubmit();
    }
  
    async createSubmit() {
      try {
        this.loading = true;
        const data = {
          ...this.data,
        };
  
        console.log(data);
        await SalesManApi.Create(data);
  
        this.$router.go(-2);
      } catch (e) {
        let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc("views.master.sales.errorMsg");
  
        this.$message.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .service {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sub-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    margin-left: 20px;
  }
  
  .service-table {
    background-color: #efefef;
    height: 40px;
    font-size: 15px;
    font-weight: bold;
    margin-top: 18px;
  }
  </style>
  