import client from "../client";

export default class Account {
  static async List(options) {
    return await client.get("master/account", options);
  }

  static async Create(data) {
    return await client.post("master/account", data);
  }

  static async Update(index, data) {
    return await client.put(`master/account/${index}`, data);
  }

  static async Detail(index) {
    return await client.get(`master/account/${index}`);
  }

  static async IdCheck(id) {
    return await client.get(`master/account/id/check/${id}`);
  }

  static async ShopList(index) {
    return await client.get(`master/account/shop/list/${index}`);
  }

  static async Delete(index) {
    return await client.del(`master/account/${index}`);
  }
}
