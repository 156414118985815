<template>
  <div
    df
    fdr
    fjc
    fic
    :style="{
      color: value
        ? diffDateMinute(value) > 10
          ? `${Colors.grayDark}`
          : `black`
        : `${Colors.grayDark}`,
    }"
  >
    <div>
      {{ value ? (diffDateMinute(value) > 10 ? "OFF" : "ON") : "OFF" }}
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import Colors from "@/utils/colors";
import moment from "moment";

@Component({
  components: {},
})
export default class DisplayItem extends Vue {
  @Prop({ default: "0" }) value;

  Colors = Colors;
  moment = moment;

  currentTime = moment();

  diffDateMinute(value) {
    if (value) {
      return Number(moment.duration(this.currentTime.diff(value)).asMinutes());
    }
  }
}
</script>

<style lang="scss" scoped></style>
