import Dashboard from "@/views/admin/Dashboard.vue";
import Hotel from "@/views/admin/Hotel.vue";
import PromotionBanner from "@/views/admin/PromotionBanner.vue";
import Tablet from "@/views/admin/Tablet.vue";
import QR from "@/views/admin/qr.vue";
import Message from "@/views/admin/Message.vue";
import Service from "@/views/admin/Service.vue";
import Order from "@/views/admin/Order.vue";
import ConnectionLog from "@/views/admin/ConnectionLog.vue";
import OverallStatistics from "@/views/admin/OverallStatistics.vue";
import Setting from "@/views/admin/Setting.vue";
import Notice from "@/views/admin/Notice.vue";
import Staff from "@/views/admin/StaffManagement.vue";

export default [
  {
    path: "/admin/:hotelName/:key/dashboard",
    name: "Admin_Dashboard",
    component: Dashboard,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/hotel",
    name: "Admin_Hotel",
    component: Hotel,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/promotionBanner",
    name: "Admin_PromotionBanner",
    component: PromotionBanner,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/tablet",
    name: "Admin_Tablet",
    component: Tablet,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/qr",
    name: "Admin_QR",
    component: QR,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/message",
    name: "Admin_Message",
    component: Message,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/service/:index?",
    name: "Admin_Service",
    component: Service,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/order",
    name: "Admin_Order",
    component: Order,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/overallStatistics",
    name: "Admin_OverallStatistics",
    component: OverallStatistics,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/connectionLog",
    name: "Admin_ConnectionLog",
    component: ConnectionLog,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
    path: "/admin/:hotelName/:key/setting",
    name: "Admin_Setting",
    component: Setting,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
  path: "/admin/:hotelName/:key/Notice",
    name: "Admin_Notice",
    component: Notice,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
  {
  path: "/admin/:hotelName/:key/StaffManagement",
    name: "Admin_StaffManagement",
    component: Staff,
    meta: {
      title: route => {
        return route.params.hotelName
          ? route.params.hotelName
          : "스테이오더 관리자";
      },
    },
  },
];
