<template>
  <a-modal
    :afterClose="afterClose"
    :title="$t('components.modal.lang.title')"
    :visible="visible"
    :closable="false"
  >
    <div df fdr fjb>
      <a-select
        v-if="data.length > 0 ? true : false"
        :placeholder="$t('components.modal.lang.choiceLang')"
        style="width: 100%;"
        :loading="loading"
        @change="langsChange"
      >
        <a-select-option v-for="item in data" :key="item" :value="item">
          {{ langsValue(item) }}
        </a-select-option>
      </a-select>
      <div
        v-if="data.length > 0 ? false : true"
        style="font-size:14px;color:#db0000;font-weight:bold"
      >
        {{  $t('components.modal.lang.warn') }}
      </div>
    </div>
    <div slot="footer">
      <a-button @click="closeModal">{{ $t('components.modal.lang.cancel') }}</a-button>
      <a-button
        v-if="data.length > 0 ? true : false"
        type="primary"
        :loading="loading"
        @click="save"
        >{{ $t('components.modal.lang.add') }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class LangsAddModal extends Vue {
  @Prop({ default: undefined }) hotelIndex;
  @Prop({ default: [] }) data;
  @Prop({ default: false }) visible;

  selectedLangs = "";

  loading = false;

  langsValue(value) {
    const lang_code = [
      { key: "LANG_KR", value: i18n.tc('components.modal.lang.ko') },
      { key: "LANG_EN", value: i18n.tc('components.modal.lang.en') },
      { key: "LANG_JP", value: i18n.tc('components.modal.lang.jp') },
      { key: "LANG_CN", value: i18n.tc('components.modal.lang.cn') },
      { key: "LANG_VN", value: i18n.tc('components.modal.lang.vn') },
    ];

    const code = lang_code.filter(item => item.key === value);

    return code[0].value;
  }

  closeModal() {
    this.selectedlangs = "";
    this.$emit("close");
  }

  langsChange(value) {
    this.selectedlangs = value;
  }

  async save() {
    try {
      this.loading = true;

      await HotelApi.LangsAdd(this.hotelIndex, { code: this.selectedlangs });
      await this.$emit("load");

      await this.closeModal();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  afterClose() {
    this.selectedlangs = "";
  }
}
</script>

<style lang="scss" scoped></style>
