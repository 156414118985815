<template>
  <div class="account">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.account.manageAccount') }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-button @click="accountEditModal">
        {{ $t('views.master.account.addAccount') }}
      </a-button>
    </div>

    <div class="main-background">
      <div df fdr fjb fic style="margin-bottom: 12px">
        <hotel-list
          v-model="filter.hotelIndex"
          @change="changeFilterType(filter)"
        />

        <a-input-search
          @change="changeFilterType(filter)"
          v-model="filter.keyword"
          :placeholder= "$t('views.master.account.searchInput')"
          style="width: 260px" 
        />
      </div>

      <a-radio-group
        button-style="solid"
        v-model="filter.type"
        @change="changeFilterType(filter)"
        style="margin-bottom:16px"
      >
        <a-radio-button value>{{ $t('views.master.account.toggle.all') }}</a-radio-button>
        <a-radio-button :value="0">{{ $t('views.master.account.toggle.hotelMaster') }}</a-radio-button>
        <a-radio-button :value="1">{{ $t('views.master.account.toggle.hotelViewer') }}</a-radio-button>
        <a-radio-button :value="2">{{ $t('views.master.account.toggle.external') }}</a-radio-button>
        <a-radio-button :value="4">{{ $t('views.master.account.toggle.hotelOrderMaster') }}</a-radio-button>
      </a-radio-group>

      <template>
        <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="changePage"
          :loading="loading"
        >
          <template slot="levelIcon" slot-scope="_, record">
            <a-icon :type="levelStatusIcon(record.level)" />
          </template>
          
          <template slot="id" slot-scope="_, record">
            <router-link
              :to="{
                name: 'AccountEdit',
                params: { index: record.index },
              }"
              >{{ record.id }}</router-link
            >
          </template>

          <template slot="registeredTime" slot-scope="value">
            <a-tooltip>
              <template slot="title">{{
                value ? moment(value).fromNow() : ""
              }}</template>
              {{ value ? moment(value).format("YYYY-MM-DD") : "" }}
            </a-tooltip>
          </template>

          <template slot="level" slot-scope="_, record">
            {{ levelStatusView(record.level) }}
          </template>

          <a-popconfirm
            slot="delete"
            slot-scope="_, record"
            :title="$t('views.master.account.askDelete')"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteSubmit(record.index)"
          >
            <a-button type="danger" shape="circle" icon="delete" />
          </a-popconfirm>
        </a-table>
      </template>
    </div>

    <a-modal
      width="700px"
      :title="$t('views.master.account.addAccount')"
      :visible="visible"
      :closable="false"
      @cancel="accountCloseModal"
    >
      <div df fdr>
        <div flex df fdc style="margin-right:40px">
          <div df class="account-level-style">
            <router-link
              :to="{
                name: 'AccountCreate',
                params: { level: 4, hotelIndex: filter.hotelIndex || null },
              }"
              df
              flex
              fjc
              fic
              fdc
            >
              <a-icon type="crown" class="level-icon" />
              <div class="level-title">
                {{$t('views.master.account.toggle.hotelOrderMaster')}}
              </div>
            </router-link>
          </div>
        </div>
        <div flex df fdc style="margin-right:40px">
          <div df class="account-level-style">
            <router-link
              :to="{
                name: 'AccountCreate',
                params: { level: 0, hotelIndex: filter.hotelIndex || null },
              }"
              df
              flex
              fjc
              fic
              fdc
            >
              <a-icon type="form" class="level-icon" />
              <div class="level-title">
                {{$t('views.master.account.toggle.hotelMaster')}}
              </div>
            </router-link>
          </div>
        </div>

        <div flex df fdc style="margin-right:40px">
          <div df class="account-level-style">
            <router-link
              df
              flex
              fjc
              fic
              fdc
              :to="{
                name: 'AccountCreate',
                params: { level: 1, hotelIndex: filter.hotelIndex || null },
              }"
            >
              <a-icon type="usergroup-add" class="level-icon" />
              <div class="level-title">
                {{ $t('views.master.account.toggle.hotelViewer') }}
              </div>
            </router-link>
          </div>
        </div>

        <div flex df fdc>
          <div df class="account-level-style">
            <router-link
              df
              flex
              fjc
              fic
              fdc
              :to="{
                name: 'AccountCreate',
                params: { level: 2, hotelIndex: filter.hotelIndex || null },
              }"
            >
              <a-icon type="shop" class="level-icon" />
              <div class="level-title">
                {{ $t('views.master.account.toggle.external') }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="accountCloseModal">
          {{ $t('views.master.account.cancel') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import HotelList from "@/components/HotelList";
import AccountApi from "@/api/master/account";
import { Filter } from "@/utils/filterValue";
import {AccountColumns} from "@/utils/columns/master/account";
import {i18n} from "@/i18n";


@Component({
  components: {
    HotelList,
  },
})
export default class Hotel extends Vue {
  moment = moment;

  loading = true;
  visible = false;

  get columns() {
    const colums =AccountColumns();
    return colums;
  }

  types = {
    0: i18n.tc('views.master.account.toggle.hotelMaster'),
    1: i18n.tc('views.master.account.toggle.hotelViewer'),
    2: i18n.tc('views.master.account.toggle.external'),
    4: i18n.tc('views.master.account.toggle.hotelOrderMaster'),
  };

  data = [];
  total = 0;

  @Filter
  filter = {
    hotelIndex: "",
    keyword: "",
    type: "",
  };

  get pagination() {
    const { current, pageSize } = this.$route.query;
    return {
      current: current ? Number(current) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
      total: this.total,
    };
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = ["current", "pageSize", "hotelIndex", "keyword", "type"];

    if (keys.some(key => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  mounted() {
    this.load();
  }

  changePage({ current, pageSize, total }) {
    this.pagination.total = total;
    this.$router.push({
      query: {
        ...this.$route.query,
        current,
        pageSize,
      },
    });
  }

  changeFilterType({ keyword, type, hotelIndex }) {
    this.$router.push({
      query: {
        keyword,
        type,
        hotelIndex,
      },
    });
  }

  async load() {
    try {
      this.loading = true;

      const {
        current,
        pageSize,
        keyword,
        type,
        hotelIndex,
      } = this.$route.query;

      this.filter = {
        keyword: keyword ? keyword : "",
        type: type ? Number(type) : "",
        hotelIndex: hotelIndex ? Number(hotelIndex) : "",
      };

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      const { list, total } = await AccountApi.List({
        ...pagination,
        ...this.filterValue,
      });

      this.data = list;

      this.total = total;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteSubmit(index) {
    try {
      this.loading = true;
      await AccountApi.Delete(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  accountEditModal() {
    this.visible = true;
  }

  accountCloseModal() {
    this.visible = false;
  }

  levelStatusView(value) {
    return this.types[value];
  }

  levelStatusIcon(value) {
    switch (value) {
      case 4:
        return "form";
      case 0:
        return "crown";
      case 1:
        return "usergroup-add";
      case 2:
        return "shop";

    }
  }
}
</script>

<style lang="scss" scoped>
.account-level-style {
  border: 1px solid #9c9c9c;
  box-shadow: 3px 2px 2px;
  border-radius: 5px;
  height: 160px;
  width: 100%;
}

.level-icon {
  font-size: 40px;
  color: #404040;
}

.level-title {
  font-size: 16px;
  color: #404040;
  margin-top: 10px;
  font-weight: bold;
}
</style>
