import {i18n} from "@/i18n";
export function AmenityReadColumns(){ 
  return [
  {
    dataIndex: "name",
    title: i18n.tc('js.amenity.name'),
    scopedSlots: { customRender: "name" },
  },
  {
    width: 90,
    align: "right",
    dataIndex: "salePrice",
    title: i18n.tc('js.amenity.price'),
    customRender: value => Number(value).toLocaleString(),
  },
  {
    width: 120,
    align: "right",
    title: i18n.tc('js.amenity.type'),
    scopedSlots: { customRender: "type" },
  },
  {
    width: 120,
    align: "center",
    dataIndex: "status",
    title: i18n.tc('js.amenity.status'),
    scopedSlots: { customRender: "status" },
  },
];
}