import {i18n} from "@/i18n";
export function HotelColumns(){ 
  return [
  {
    dataIndex: "index",
    title: i18n.tc('js.hotel.hotelId'),
  },
  {
    title: i18n.tc('js.hotel.hotelNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    title: i18n.tc('js.hotel.agentNm'),
    dataIndex: "agent",
    scopedSlots: {customRender: "agent"},
  },
  {
    title: i18n.tc('js.hotel.salesNm'),
    dataIndex: "salesMan",
    scopedSlots: {customRender: "salesMan"},
  },
  {
    title: i18n.tc('js.hotel.recomendNm'),
    dataIndex: "recomend",
    scopedSlots: { customRender: "recomend" },
  },
  {
    width: 80,
    align: "center",
    dataIndex: "status",
    title: i18n.tc('js.hotel.status'),
    scopedSlots: { customRender: "status" },
  },
  {
    align: "center",
    dataIndex: "phone",
    title: i18n.tc('js.hotel.phone'),
  },
  {
    width: 120,
    align: "right",
    dataIndex: "tabletCount",
    title: i18n.tc('js.hotel.tabletNum'),
  },
  // {
  //   width: 100,
  //   align: "center",
  //   dataIndex: "detail",
  //   title: i18n.tc('js.hotel.detail'),
  //   scopedSlots: { customRender: "detail" },
  // },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}