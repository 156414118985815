<template>
  <a-modal :title="$t('components.modal.searchAddress')" :visible="visible" :closable="false">
    <template>
      <vue-daum-postcode @complete="resultData($event)" />
    </template>

    <template slot="footer">
      <a-button @click="closeModal">{{ $t('components.modal.cancel') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueDaumPostcode } from "vue-daum-postcode";
import {i18n} from "@/i18n";

@Component({
  components: { VueDaumPostcode },
})
export default class DaumPostcode extends Vue {
  @Prop({ default: false }) visible;

  closeModal() {
    this.$emit("close");
  }

  async resultData(event) {
    try {
      var addressObj = {
        address_line_1: event.autoJibunAddress,
        address_line_2: event.address,
      };

      await this.$geocoder.send(addressObj, response => {
        const data = {
          address: event.address,
          location: response.results[0].geometry.location,
        };

        this.$emit("save", data);
        this.closeModal();
      });
    } catch {
      let error = i18n.tc('components.modal.addressErr');

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
