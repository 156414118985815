<template>
  <div style="height: 100%">
    <div df fdr style="height: 100%">
      <div flex style="padding: 16px" df fdc>
        <a-space direction="vertical">
          <div df fdr fjb v-if="levelCheck !== '1'">
            <h1 style="font-size: 20px">
              <a @click="openEditCategory(true)">
                {{ title }}
              </a>
            </h1>

            <a-space>
              <div v-if="roleCheck === 'Master'" df fdr fic>
                <excel-form
                  :excelUpload="excelUpload"
                  :openExcelForm="openExcelForm"
                />

                <a-button @click="openImageUpload()" type="dashed">
                  <a-icon type="picture" />{{
                    $t("views.admin.services.uploadImg")
                  }}
                </a-button>
              </div>

              <a-button @click="openEditItem()">{{
                $t("views.admin.services.addStuff")
              }}</a-button>
            </a-space>
          </div>

          <div df fdr fjb v-if="levelCheck === '1'">
            <h1 style="font-size: 20px">{{ title }}</h1>
          </div>

          <status-radio-form
            :itemStatusCount="itemStatusCount"
            @change="selectCategory()"
            @statusRadioFilter="statusRadioFilter"
          />

          <draggable-table
            v-if="levelCheck !== '1'"
            style="width: 100%"
            v-model="items"
            :columns="columns"
            @dragChanged="itemOrderChanged"
            :drag-options="dragOptions"
          >
            <template v-slot:drag="{}">
              <drag-icon />
            </template>

            <template v-slot:status="{ record }">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>

                <item-status-popover
                  @load="selectCategory"
                  :itemIndex="record.index"
                />
              </div>
            </template>

            <template v-slot:name="{ record }">
              <a @click="openEditItem(record)">
                {{ record.name }}
              </a>
            </template>

            <template v-slot:type="{ record }">
              {{ tableTypeName(record) }}
            </template>

            <template v-slot:delete="{ record }">
              <item-delete-popconfirm
                @load="selectCategory"
                :itemIndex="record.index"
              />
            </template>
          </draggable-table>

          <a-table
            v-if="levelCheck === '1'"
            rowKey="index"
            style="width: 100%"
            :data-source="items"
            :columns="columns"
            :loading="loading"
          >
            <template slot="status" slot-scope="_, record">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>
              </div>
            </template>

            <template slot="name" slot-scope="_, record">
              <a @click="openEditItem(record)">
                {{ record.name }}
              </a>
            </template>

            <template slot="type" slot-scope="_, record">
              {{ tableTypeName(record) }}
            </template>
          </a-table>
        </a-space>
      </div>
    </div>

    <shop-category-add-modal
      :key="categoryModalKey"
      :data="data"
      :visible="editCategory"
      :afterClose="afterCloseEditCategory"
      @close="closeEditCategory"
      :serviceIndex="serviceIndex"
      :serviceTime="serviceTime"
    />

    <shop-item-add-modal
      :key="itemModalKey"
      :data="itemData"
      :visible="editItem"
      :afterClose="afterCloseEditItem"
      :categoryIndex="selectedCategoryIndex"
      serviceType="amenity"
      @close="closeEditItem"
      @load="selectCategory"
    />

    <image-upload-modal
      :data="imageList"
      :visible="isImageUpload"
      :afterClose="afterCloseImageUpload"
      @close="closeImageUpload"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import moment from "moment";

import CategoryApi from "@/api/admin/category";
import ProductApi from "@/api/admin/product";
import Tabs from "@/utils/languageTab";
import Colors from "@/utils/colors";
import { Filter } from "@/utils/filterValue";
import { AmenityColumns } from "@/utils/columns/admin/amenity";
import { AmenityReadColumns } from "@/utils/columns/admin/amenityRead";

import { TimeShowFunc } from "@/utils/timeFunc";
import { i18n } from "@/i18n";

import DraggableTable from "@/components/DraggableTable";
import DragIcon from "@/components/DragIcon";
import ExcelForm from "@/components/service/ExcelForm";
import StatusRadioForm from "@/components/service/StatusRadioForm";
import ItemStatusPopover from "@/components/service/ItemStatusPopover";
import ItemDeletePopconfirm from "@/components/service/ItemDeletePopconfirm";

import ShopItemAddModal from "@/components/modals/ShopItemAddModal";
import ImageUploadModal from "@/components/modals/ImageUploadModal";
import ShopCategoryAddModal from "@/components/modals/ShopCategoryAddModal";

@Component({
  components: {
    draggable,
    DraggableTable,
    ExcelForm,
    StatusRadioForm,
    ItemStatusPopover,
    ItemDeletePopconfirm,
    DragIcon,

    ShopCategoryAddModal,
    ShopItemAddModal,
    ImageUploadModal,
  },
})
export default class Amenity extends Vue {
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceCode;
  @Prop({ default: "" }) serviceTime;

  levelCheck = localStorage.getItem("level");
  roleCheck = localStorage.getItem("role");

  get columns() {
    const colums =
      this.levelCheck === "1" ? AmenityReadColumns() : AmenityColumns();
    return colums;
  }

  itemStatusCount = {};
  loading = false;

  @Filter
  filter = {
    status: "",
  };

  tabs = Tabs;
  Colors = Colors;
  moment = moment;

  drag = false;
  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  itemModalKey = "item_key";
  categoryModalKey = "category_key";

  categories = [];
  categoryIndex = "";
  editCategory = false;
  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
    status: "1",
    isServiceTime: true,
    openTime: "0000",
    closeTime: "0000",
  };

  items = [];
  editItem = false;
  itemData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    images: [],
    status: "1",
    maxQty: 0,
    salePrice: 0,
    purchasePrice: 0,
    isFree: 0,
    mainImage: {
      type: "0",
      uploadedUrl: [],
    },
    subImage: {
      type: "1",
      uploadedUrl: ["ADD"],
      imageLengthLimit: 7,
    },
  };

  isImageUpload = false;
  imageList = {
    uploadedUrl: ["ADD"],
    imageLengthLimit: 8,
  };

  get selectedCategoryIndex() {
    return this.categoryIndex && Number(this.categoryIndex);
  }

  get title() {
    return (
      this.selectedCategoryIndex &&
      this.categories.find(a => a.index === this.selectedCategoryIndex).name
    );
  }

  async mounted() {
    await this.load();

    if (this.categories !== null) {
      this.categoryIndex = this.categories[0].index;
      await this.selectCategory();
    }
  }

  async load() {
    try {
      this.loading = true;
      const { data } = await CategoryApi.List({
        "filter.storeIndex": this.$route.params.key,
        "filter.serviceID": this.serviceIndex,
      });

      this.categories = data.map(({ information, ...item }) => ({
        ...item,
        name: information.Kr.name,
        information,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async selectCategory() {
    try {
      const { data, option } = await ProductApi.ProductList(
        Number(this.categoryIndex),
        this.filterValue,
      );

      this.itemStatusCount = option;

      this.items = data.list;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  openEditCategory(isEdit) {
    this.editCategory = true;
    this.categoryModalKey = "category_key";

    if (!isEdit) {
      this.data = {
        ...this.data,
        openTime: moment(this.serviceTime.openTime || "0000", "HHmm"),
        closeTime: moment(this.serviceTime.closeTime || "0000", "HHmm"),
      };
      return;
    }

    const { information, ...data } = this.categories.find(
      a => a.index == this.selectedCategoryIndex,
    );

    const isServiceTime =
      data.openTime !== null &&
      data.closeTime !== null &&
      data.openTime === this.serviceTime.openTime &&
      data.closeTime === this.serviceTime.closeTime;

    const closeTimeValue = TimeShowFunc(data.closeTime);

    this.data = {
      ...data,
      openTime: moment(data.openTime || "0000", "HHmm"),
      closeTime: moment(closeTimeValue, "HHmm"),
      information: {
        ...this.data.information,
        ...information,
      },
      isServiceTime: isServiceTime,
    };

    this.categoryModalKey = this.data.index;
  }

  closeEditCategory() {
    this.editCategory = false;
  }

  afterCloseEditCategory() {
    this.load();

    this.data = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
      status: "1",
      isServiceTime: true,
      openTime: "0000",
      closeTime: "0000",
    };

    this.categoryModalKey = "category_key2";
  }

  async itemOrderChanged() {
    try {
      await ProductApi.ProductOrder(Number(this.categoryIndex), {
        itemList: [...this.items],
      });

      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async openEditItem(item) {
    try {
      this.itemModalKey = "item_key";

      if (!item) {
        return (this.editItem = true);
      }

      const { data } = await ProductApi.Detail(item.index);

      const mainImage = data.images.find(mainItem => mainItem.type === 0);
      const subImage = data.images
        .filter(subItem => subItem.type !== 0)
        .map(image => image.image);

      this.itemData = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                description: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
        isFree:
          data.isFree === 1
            ? 0
            : data.isFree === 0 && data.maxQty === 0
            ? 1
            : 2,
        mainImage: {
          type: "0",
          uploadedUrl: [mainImage.image],
          imageId: mainImage.imageId,
        },
        subImage: {
          type: "1",
          uploadedUrl: [...subImage, "ADD"],
          imageLengthLimit: 7,
        },
      };

      this.itemModalKey = this.itemData.index;
      this.editItem = true;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeEditItem() {
    this.editItem = false;
  }

  afterCloseEditItem() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 0,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 0,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.itemModalKey = "item_key2";
  }

  async excelUpload({ file, onSuccess, onError }) {
    try {
      await ProductApi.ExcelUplad(this.categoryIndex, file);

      await this.selectCategory();
      this.$message.success(i18n.tc("views.admin.services.excelSuc"));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async openImageUpload() {
    this.isImageUpload = true;
  }

  closeImageUpload() {
    this.isImageUpload = false;
  }

  afterCloseImageUpload() {
    this.imageList = {
      uploadedUrl: ["ADD"],
      imageLengthLimit: 8,
    };
  }

  openExcelForm() {
    window
      .open(
        "https://hihotelorder.s3.ap-northeast-2.amazonaws.com/excel/%EC%95%84%EC%9D%B4%ED%85%9C_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D_240705.xlsx",
        "_blank",
      )
      .focus();
  }

  statusRadioFilter(value) {
    this.filter.status = value;
  }

  tableStatusColor(status) {
    return status && status === "1" ? `rgba(0,0,0,.65)` : `${Colors.grayDark}`;
  }

  tableStatusName(status) {
    return status && status === "1"
      ? i18n.tc("views.admin.services.sale")
      : status === "2"
      ? i18n.tc("views.admin.services.soldout")
      : i18n.tc("views.admin.services.noSale");
  }

  tableTypeName(record) {
    return record.isFree === 1
      ? i18n.tc("views.admin.services.free")
      : record.maxQty > 0
      ? i18n.tc("views.admin.services.partFree")
      : i18n.tc("views.admin.services.cost");
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
