<template>
  <a>
    <a-icon type="swap" :rotate="90" style="color:#a8a8a8" />
  </a>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DragIcon extends Vue {}
</script>

<style lang="scss" scoped></style>
