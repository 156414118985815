import client from "../client";

export default class Statistics {
  static async YearList() {
    return await client.get(`shared/statistics/year/list`);
  }

  static async MonthlyAverage(index) {
    return await client.get(`shared/statistics/accum/aver`, index);
  }

  static async WeeklyAverage(index, data) {
    return await client.post(
      `shared/statistics/week/statistics/${index}`,
      data,
    );
  }

  static async DeviceAverage(index) {
    return await client.get(`shared/statistics/device/statistics`, index);
  }

  static async YearlyAverage(index, data) {
    return await client.post(
      `shared/statistics/year/statistics/${index}`,
      data,
    );
  }

  static async ServiceType(data) {
    return await client.get(`shared/statistics/service/type`, data);
  }

  static async CancelServiceType(index) {
    return await client.get(`shared/statistics/cancel/service`, index);
  }

  static async ServiceTypePeriod(type, data) {
    return await client.get(
      `shared/statistics/service/type/date/${type}`,
      data,
    );
  }

  static async OrderAverage(index) {
    return await client.get(`shared/statistics/status/order`, index);
  }

  static async ServiceStatistics(data) {
    return await client.post("shared/statistics/service/statistics", data);
  }
}
