<template>
  <div class="tablet">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.master.basicService.title1') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.basicService.title2') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.basicService.edit.title') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <a-form-model
          ref="form"
          :model="data"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <div df fdr>
          <div flex fjc fic>
            <div df fdr fje>
              <small>{{ $t('views.master.basicService.edit.essential') }}</small>
            </div>

            <div class="card-container">
              <language-tab
                  :value="data"
                  :isLanguageUsed="isLanguageUsed"
                  :tabs="tabs"
                  :name="$t('views.master.basicService.edit.serviceName')"
                  :description="$t('views.master.basicService.edit.serviceDesc')"
              >
              </language-tab>
            </div>

            <a-form-model-item prop="memo" :label="$t('views.master.basicService.edit.memo')">
              <a-textarea :rows="2" v-model="data.memo"/>
            </a-form-model-item>

            <a-form-model-item df fic prop="icon" :label="$t('views.master.basicService.edit.icon')">
              <a @click="showServiceIconEditModal(data)">
                <div
                    class="icon-mini-logo"
                    :style="{
                    backgroundImage: `url('${data.icon}')`,
                  }"
                />
              </a>
            </a-form-model-item>

            <a-form-model-item prop="isAlways" :label="$t('views.master.basicService.edit.open')">
              <a-switch
                  v-model="data.isAlways"
                  @change="
                  {
                    data.openTime = '0000';
                    data.closeTime = '0000';
                  }
                "
              />
            </a-form-model-item>

            <a-form-model-item prop="openTime" :label="$t('views.master.basicService.edit.openTime')">
              <a-time-picker
                  format="HH:mm"
                  v-model="data.openTime"
                  :disabled="data.isAlways"
              />
            </a-form-model-item>
            <a-form-model-item prop="closeTime" :label="$t('views.master.basicService.edit.closeTime')">
              <a-time-picker
                  format="HH:mm"
                  v-model="data.closeTime"
                  :disabled="data.isAlways"
              />
            </a-form-model-item>

            <a-form-model-item prop="status" :label="$t('views.master.basicService.edit.status')">
              <a-radio-group v-model="data.status" button-style="solid">
                <a-radio-button :value="true">{{ $t('views.master.basicService.edit.activateOn') }}</a-radio-button>
                <a-radio-button :value="false">{{ $t('views.master.basicService.edit.activateOff') }}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <div df fje>
              <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
                <a-button
                    style="width:100px"
                    :loading="loading"
                    type="primary"
                    @click="submit"
                >{{ $t('views.master.basicService.edit.save') }}
                </a-button>
              </a-form-model-item>
            </div>
          </div>

          <div flex/>
        </div>
      </a-form-model>
    </div>

    <service-icon-edit-modal
        :data="selectedServiceIcon"
        :visible="isServiceIconEdit"
        @close="closeServiceIconEdit"
        @saveSubmit="saveServiceIconEdit"
    />
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import moment from "moment";

import Tabs from "@/utils/languageTab";
import {TimeShowFunc, TimeSaveFunc} from "@/utils/timeFunc";

import ServiceApi from "@/api/master/service";
import ServiceIconEditModal from "@/components/modals/ServiceIconEditModal";
import LanguageTab from "@/components/LanguageTab";

import {i18n} from "@/i18n";

@Component({
  components: {
    LanguageTab,
    ServiceIconEditModal,
  },
})
export default class BasicServiceEdit extends Vue {
  labelCol = {span: 6};
  wrapperCol = {span: 18};

  moment = moment;
  tabs = Tabs;

  loading = false;

  data = {
    information: {
      ...this.tabs.reduce(
          (result, {key}) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
      ),
    },
  };

  isServiceIconEdit = false;
  selectedServiceIcon = {
    uploadedUrl: [],
  };

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
          (result, {key}) => ({
            ...result,
            [`information.${key}.name`]: [
              {
                required: this.isLanguageUsed(key),
                message: i18n.tc('views.master.basicService.serviceMsg'),
              },
            ],
          }),
          {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc('views.master.basicService.statusMsg'),
        },
      ],
    };
  }

  buttonItemLayout() {
    const {formLayout} = this;
    return formLayout === "horizontal"
        ? {
          wrapperCol: {span: 14, offset: 4},
        }
        : {};
  }

  mounted() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      const index = this.$route.params.index;

      const {data} = await ServiceApi.Detail(index);

      const closeTimeValue = TimeShowFunc(data.closeTime);

      this.data = {
        ...data,
        isAlways: data.openTime === "0000" && data.closeTime === "0000",
        openTime: moment(data.openTime || "0000", "HHmm"),
        closeTime: moment(closeTimeValue, "HHmm"),
      };
    } catch (e) {
      let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc('views.master.tablet.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  submit() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        try {
          this.loading = true;

          const time = TimeSaveFunc(this.data.openTime, this.data.closeTime);

          const data = {
            ...this.data,
            openTime: time.openTime,
            closeTime: time.closeTime,
          };

          await ServiceApi.Update(data);

          this.$router.back();
        } catch (e) {
          let error = e.response?.data?.data
              ? e.response.data.data
              : i18n.tc('views.master.tablet.errorMsg');

          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  saveServiceIconEdit() {
    this.data.icon = this.selectedServiceIcon.uploadedUrl[0];
    this.data.color = this.selectedServiceIcon.color;
    this.isServiceIconEdit = false;
  }

  showServiceIconEditModal(value) {
    this.isServiceIconEdit = true;

    const data = value;

    this.selectedServiceIcon = {
      ...data,
      uploadedUrl: [data.icon],
    };
  }

  closeServiceIconEdit() {
    this.isServiceIconEdit = false;
  }
}
</script>

<style lang="scss" scoped>
.icon-mini-logo {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../../public/img/icons/google-symbol.png");
}
</style>
