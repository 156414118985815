import {i18n} from "@/i18n";
export function DeliveryColumns(){
  return [
  {
    width: 40,
    align: "left",
    title: "",
    scopedSlots: { customRender: "drag" },
  },
  {
    align: "left",
    dataIndex: "name",
    title: i18n.tc('js.delivery.storeNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    align: "center",
    dataIndex: "phone",
    title: i18n.tc('js.delivery.phone'),
  },
  {
    align: "center",
    dataIndex: "status",
    title: i18n.tc('js.delivery.status'),
    scopedSlots: { customRender: "status" },
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}