const Colors = {
  grayLight: "#F0F2F5",
  grayDark: "#c2c2c2",

  pink: "#F3D1C9",

  red: "#d44646",
  redLight: "#f27f63",

  yelloDark: "#f7be40",

  blueLight: "#5c94f2",

  sky: "#A3B7D0",
  skyDark: "#607EA2",
  blue: "#283D6C",
  blueDark: "#07174A",
  brown: "#514C48",
  brownDark: "#2e2b28",
  gold: "#D4C083",
  goldLight: "#F5EAD4",
  gray: "#b5b5b5",
};

export default Colors;
