import {i18n} from "@/i18n";
export function NoticeColumns(){ 
  return[
  // {
  //   width: 80,
  //   align: "center",
  //   dataIndex: "typeSt",
  //   title: "타입",
  // },
  {
    // width: 300,
    align: "left",
    dataIndex: "noticeTitle",
    title: i18n.tc('js.notice.title'),
    scopedSlots: { customRender: "noticeTitle" },
  },
  // {
  //   align: "left",
  //   dataIndex: "noticeContent",
  //   title: "내용",
  //   scopedSlots: { customRender: "noticeContent" },
  // },
  // {
  //   align: "center",
  //   dataIndex: "isRead",
  //   title: "읽음",
  //   scopedSlots: { customRender: "isRead" },
  // },
  {
    width: 200,
    align: "center",
    dataIndex: "registeredDate",
    title: i18n.tc('js.notice.day'),
    scopedSlots: { customRender: "registeredDate" },
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}