import {i18n} from "@/i18n";
export function ImageServiceColumns(){
  return [
  {
    width: 40,
    align: "left",
    title: "",
    scopedSlots: { customRender: "drag" },
  },
  {
    align: "center",
    dataIndex: "image",
    title: i18n.tc('js.image.image'),
    scopedSlots: { customRender: "image" },
  },
  {
    width: 440,
    align: "center",
    dataIndex: "description",
    title: i18n.tc('js.image.description'),
    scopedSlots: { customRender: "description" },
  },
  {
    width: 80,
    align: "center",
    title: i18n.tc('js.image.delete'),
    scopedSlots: { customRender: "delete" },
  },
];
}