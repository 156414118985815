<template>
  <a-popconfirm
    :title="$t('components.service.deleteFormTitle')"
    :ok-text="$t('components.service.delete')"
    :cancel-text="$t('components.service.cancel')"
    @confirm="itemDelete()"
  >
    <a-button type="danger" shape="circle" icon="delete" ghost size="small" />
  </a-popconfirm>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import ProductApi from "@/api/admin/product";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class ItemDeletePopconfirm extends Vue {
  @Prop() itemIndex;

  async itemDelete() {
    try {
      await ProductApi.Delete(Number(this.itemIndex));
      await this.$emit("load");
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
