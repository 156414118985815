<template>
  <a-modal
    width="700px"
    :visible="visible"
    :closable="false"
    :title="$t('components.modal.multi.title')"
    :afterClose="afterClose"
  >
    <div>
      <div v-if="!dataEmpty">
        <a-form-model
          v-if="item"
          ref="editMultilingualForm"
          :model="item"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="key" :label="$t('components.modal.multi.list')">
            <a-select v-model="multilingualKey" @change="multilingualSwitch">
              <a-select-option
                v-for="item in data"
                :key="item.name"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item prop="name" :label="$t('components.modal.multi.name')">
            <a-input v-model="item.name" />
          </a-form-model-item>

          <a-form-model-item prop="description" :label="$t('components.modal.multi.desc')">
            <a-input v-model="item.description" />
          </a-form-model-item>

          <div df fdr fje>
            <small>{{ $t('components.modal.uploadCaution')}}</small>
          </div>
          <div class="card-container">
            <a-tabs size="small" v-if="multilingualKey">
              <a-tab-pane v-for="tab in tabs" :key="tab.key">
                <div
                  df
                  fdr
                  fic
                  slot="tab"
                  class="hotel-edit-language-tab"
                  :class="{ on: isLanguageUsed(tab.key) }"
                >
                  {{ tab.title }}
                </div>

                <div class="example" v-if="item.type === 'html'">
                  <quill-editor
                    :options="editorOption"
                    v-if="tab.key"
                    v-model="item.content[tab.key]"
                  />
                </div>

                <a-form-model-item
                  :label-col="{ span: 0 }"
                  :wrapper-col="{ span: 24 }"
                  :prop="`content.${tab.key}`"
                  v-if="item.type === 'string'"
                >
                  <a-input v-model="item.content[tab.key]" />
                </a-form-model-item>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-form-model>
      </div>
      <div v-if="dataEmpty" style="height: 300px" df fdc fjc fic>
        <a-icon
          type="exclamation-circle"
          style="font-size: 40px; color: #d44646"
        />
        <div style="margin-top: 20px; font-weight: bold">
          {{ $t('components.modal.multi.noData') }}
        </div>
      </div>
    </div>

    <div slot="footer">
      <a-button @click="closeEditMultilingual">{{ $t('components.modal.cancel') }}</a-button>
      <a-button type="primary" :loading="loadingEditMultilingual" @click="save"
        >{{ $t('components.modal.save') }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import ServiceApi from "@/api/master/service";
import AdminServiceApi from "@/api/admin/service";
import {i18n} from "@/i18n";

@Component({
  components: { quillEditor },
})
export default class MultilingualAddModal extends Vue {
  @Prop({ default: undefined }) index;
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: 0 }) baseSerivceIndex;
  @Prop({ default: 0 }) adminSerivceIndex;

  dataEmpty = false;

  tabs = [
    { key: "kr", title: i18n.tc('components.modal.lang.ko') },
    { key: "en", title: i18n.tc('components.modal.lang.en') },
    { key: "jp", title: i18n.tc('components.modal.lang.jp') },
    { key: "cn", title: i18n.tc('components.modal.lang.cn') },
    { key: "vn", title: i18n.tc('components.modal.lang.vn') },
  ];

  labelCol = { span: 4 };
  wrapperCol = { span: 20 };

  loadingEditMultilingual = false;
  multilingualKey = "";
  item = {};

  editorOption = {
    placeholder: i18n.tc('components.modal.multi.contentMsg'),
    modules: {
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
        ],
      },
    },
  };

  isLanguageUsed(key) {
    if (key === "kr" || key === "en") {
      return true;
    }
  }

  get rules() {
    return {
      name: [
        {
          required: true,
          message: i18n.tc('components.modal.multi.titleMsg'),
        },
      ],
    };
  }

  mounted() {
    this.$nextTick().then(() => {
      if (this.data.length > 0) {
        this.multilingualKey = this.data[0].name;
        this.multilingualSwitch(this.multilingualKey);
      } else {
        this.dataEmpty = true;
      }
    });
  }

  closeEditMultilingual() {
    this.$emit("close");
  }

  multilingualSwitch(value) {
    this.item = this.data.find((item) => item.name === value);
  }

  async saveEditMultilingual() {
    try {
      this.loadingEditMultilingual = true;

      if (this.baseSerivceIndex) {
        await ServiceApi.MultilingualUpdate(this.baseSerivceIndex, this.data);
      } else {
        await AdminServiceApi.MultilingualUpdate(
          this.adminSerivceIndex,
          this.data,
        );
      }

      this.closeEditMultilingual();
      this.$message.success(i18n.tc('components.modal.multi.sucEdit'));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditMultilingual = false;
    }
  }

  async save() {
    this.$refs.editMultilingualForm.validate(async (valid) => {
      if (valid) {
        if (
          this.data.every(
            (item) => item.content.kr !== "" && item.content.en !== "",
          )
        ) {
          await this.saveEditMultilingual();
        } else {
          this.$message.warning(
            i18n.tc('components.modal.multi.warn'),
          );
        }
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  min-height: 330px;
  max-height: 500px;
  overflow: hidden;
  padding-bottom: 60px;

  .editor,
  .output {
    width: 50%;
  }

  $toolbar-height: 41px;

  .editor {
    padding-bottom: $toolbar-height;

    .ql-custom-button {
      width: 100px;
    }
  }

  .output {
    border: 1px solid #ccc;
    border-left: none;

    .title {
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>
