<template>
  <div df fdc style="height: 100%; padding: 16px 24px">
    <a-space df fdr fjb style="margin-bottom: 16px">
      <div>
        <div style="font-size: 16px; font-weight: bold">
          {{ $t("views.admin.services.nearStore") }}
        </div>
        <div style="font-size: 12px">
          {{ $t("views.admin.services.noticeNs") }}
        </div>
      </div>

      <a-button v-if="levelCheck !== '1'" @click="openEditStore()">
        {{ $t("views.admin.services.addNearInfo") }}
      </a-button>
    </a-space>

    <draggable-table
      v-if="levelCheck !== '1'"
      style="width: 100%"
      v-model="list"
      :columns="columns"
      :loading="loading"
      :drag-options="dragOptions"
      @dragChanged="storeOrderChanged"
    >
      <template v-slot:drag="{}">
        <drag-icon />
      </template>
      <template v-slot:name="{ record }">
        <a @click="openEditStore(record)">
          {{ record.information.Kr.name }}
        </a>
      </template>

      <template v-slot:address="{ record }">
        {{ record.information.Kr.address }}
      </template>

      <template v-slot:delete="{ record }">
        <a-popconfirm
          :title="$t('views.admin.services.deleteStore')"
          :ok-text="$t('views.admin.services.delete')"
          :cancel-text="$t('views.admin.services.cancel')"
          @confirm="storeDelete(record.index)"
        >
          <a-button
            type="danger"
            shape="circle"
            icon="delete"
            ghost
            size="small"
          />
        </a-popconfirm>
      </template>
    </draggable-table>

    <a-table
      v-if="levelCheck === '1'"
      rowKey="index"
      style="width: 100%"
      :data-source="list"
      :columns="columns"
      :loading="loading"
    >
      <template slot="name" slot-scope="_, record">
        <a @click="openEditStore(record)">
          {{ record.information.Kr.name }}
        </a>
      </template>

      <template slot="address" slot-scope="_, record">
        {{ record.information.Kr.address }}
      </template>
    </a-table>

    <a-modal
      width="1200px"
      :visible="visible"
      :title="optionTitle"
      :closable="false"
      :afterClose="afterClose"
    >
      <a-form-model
        v-if="data"
        :key="modalKey"
        ref="editItemForm"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div df fdr v-if="!loadingEditStore">
          <a-space direction="vertical" flex style="padding: 50px 20px 0px">
            <upload
              v-if="data.imageData"
              dir-name="nearStore"
              v-model="data.imageData"
              listType="picture-card"
              :selected="true"
              :disabled="levelCheck === '1' ? true : false"
            >
              <div class="image-expand">
                <div
                  v-if="data.imageData.uploadedUrl[0]"
                  :style="{
                    backgroundImage: `url('${data.imageData.uploadedUrl[0]}')`,
                  }"
                />
                <div v-else df fdc fjc fic>
                  <a-icon :type="'plus'" />
                  <div>Upload</div>
                  <div>{{ $t("views.admin.services.uploadEx1") }}</div>
                </div>
              </div>
            </upload>
          </a-space>

          <a-space direction="vertical" style="width: 560px">
            <div df fdr fje>
              <small>{{ $t("views.admin.services.korEngWarn") }}</small>
            </div>
            <div class="card-container">
              <language-tab
                ref="languageTabForm"
                :value="data"
                :isLanguageUsed="isLanguageUsed"
                :tabs="tabs"
                :name="$t('views.admin.services.storeNm')"
                :address="$t('views.admin.services.storeAdr')"
                :editorDescription="$t('views.admin.services.storeDisc')"
              >
              </language-tab>
            </div>
          </a-space>
        </div>

        <div df fdr fjc fic v-if="loadingEditStore" style="height: 600px">
          <a-icon type="loading" style="color: blue" />
        </div>
      </a-form-model>

      <div slot="footer">
        <a-button @click="closeEditStore">{{
          $t("views.admin.services.cancel")
        }}</a-button>
        <a-button
          v-if="levelCheck !== '1'"
          type="primary"
          :loading="loadingEditStore"
          @click="save"
          >{{ $t("views.admin.services.save") }}</a-button
        >
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import TypeOfServiceApi from "@/api/admin/typeOfService";
import Colors from "@/utils/colors";
import Tabs from "@/utils/languageTab";
import { SurroundingInfoColumns } from "@/utils/columns/admin/surroundingInfo";
import { SurroundingInfoReadColumns } from "@/utils/columns/admin/surroundingInfoRead";

import LanguageTab from "@/components/LanguageTab";
import DraggableTable from "@/components/DraggableTable";
import Upload from "@/components/Upload";
import DragIcon from "@/components/DragIcon";
import { i18n } from "@/i18n";

@Component({
  components: { DraggableTable, Upload, LanguageTab, DragIcon },
})
export default class SurroundingInfo extends Vue {
  levelCheck = localStorage.getItem("level");
  Colors = Colors;
  tabs = Tabs;

  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  get columns() {
    const colums =
      this.levelCheck === "1"
        ? SurroundingInfoReadColumns()
        : SurroundingInfoColumns();
    return colums;
  }

  drag = false;
  dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  modalKey = "key";

  visible = false;

  loading = false;
  loadingEditStore = false;

  list = [];
  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            address: "",
            description: "",
          },
        }),
        {},
      ),
    },
    latitude: "37.553780",
    longitude: "126.920535",
    imageName: "",
    imageData: {
      uploadedUrl: [],
    },
  };

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;

      const { data } = await TypeOfServiceApi.StoreList(this.$route.params.key);

      this.list = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async storeOrderChanged() {
    try {
      await TypeOfServiceApi.StoreOrder(this.$route.params.key, this.list);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async storeDelete(index) {
    try {
      await TypeOfServiceApi.StoreDelete(Number(index));
      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  get optionTitle() {
    return this.levelCheck === "1"
      ? i18n.tc("views.admin.services.nearStInfo")
      : this.data?.index
      ? i18n.tc("views.admin.services.editNearSt")
      : i18n.tc("views.admin.services.addNearSt");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("views.admin.services.writeStNm"),
            },
          ],
          [`information.Kr.address`]: [
            {
              required: this.isLanguageUsed("Kr"),
              message: i18n.tc("views.admin.services.writeStAdr"),
            },
          ],
          [`information.${key}.description`]: [{}],
        }),
        {},
      ),
    };
  }

  async openEditStore(item) {
    try {
      this.data = {
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                address: "",
                description: "",
              },
            }),
            {},
          ),
        },
        latitude: "37.553780",
        longitude: "126.920535",
        imageName: "",
        imageData: {
          uploadedUrl: [],
        },
      };

      this.modalKey = "key";
      this.visible = true;

      if (!item) {
        return;
      }

      const { data } = await TypeOfServiceApi.StoreDetail(item.index);
      this.data = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                address: "",
                description: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
        imageData: {
          uploadedUrl: [data.imageName],
        },
      };

      this.modalKey = this.data.index;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeEditStore() {
    this.visible = false;
  }

  async save() {
    this.$refs.editItemForm.validate(async valid => {
      if (valid) {
        if (!this.data.imageData.uploadedUrl[0]) {
          return this.$message.warning("이미지를 업로드해주세요.1111");
        }

        if (!this.data.information.En.name) {
          this.$refs.languageTabForm.tabDefaultKeyChange();
          return this.$message.warning(
            i18n.tc("views.admin.services.EngStNmMsg"),
          );
        }

        await this.saveStore();
      }
    });
  }

  async saveStore() {
    try {
      this.loadingEditStore = true;

      this.data.imageName = this.data.imageData.uploadedUrl[0];

      delete this.data.imageData;

      if (this.data.index) {
        await TypeOfServiceApi.StoreUpdate(this.data.index, this.data);
      } else {
        await TypeOfServiceApi.StoreCreate(this.$route.params.key, this.data);
      }

      await this.load();
      this.visible = false;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loadingEditStore = false;
    }
  }

  afterClose() {
    this.data = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              address: "",
              description: "",
            },
          }),
          {},
        ),
      },
      latitude: "37.553780",
      longitude: "126.920535",
      imageName: "",
      imageData: {
        uploadedUrl: [],
      },
    };

    this.modalKey = "key2";
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 450px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
