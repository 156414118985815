import {i18n} from "@/i18n";
export function MessageColumns(){
  return [
  {
    width: 80,
    align: "center",
    dataIndex: "typeSt",
    title: i18n.tc('js.message.type'),
  },
  {
    width: 140,
    align: "center",
    dataIndex: "roomNumber",
    title: i18n.tc('js.message.room'),
    scopedSlots: { customRender: "roomNumber" },
  },
  {
    width: 800,
    align: "left",
    dataIndex: "messageTitle",
    title: i18n.tc('js.message.title'),
    scopedSlots: { customRender: "messageTitle" },
  },
  {
    align: "center",
    dataIndex: "isRead",
    title: i18n.tc('js.message.read'),
    scopedSlots: { customRender: "isRead" },
  },
  {
    width: 200,
    align: "center",
    dataIndex: "createDate",
    title: i18n.tc('js.message.time'),
    scopedSlots: { customRender: "createDate" },
  },
];
}