<template>
  <div style="height: 100%; margin-top: 50px">
    <div v-if="itemData && itemData.itemId">
      <component
        style="height: 100%"
        v-if="detail && itemIndex != 0"
        :is="content"
        :data="itemData"
        :afterClose="afterCloseEditItem"
        :categoryIndex="selectedCategoryIndex"
        @closeItemDetail="closeItemDetail"
        @load="selectCategory"
      >
      </component>
    </div>

    <div df fdr style="height: 100%; padding: 16px">
      <div
        class="category-group"
        style="background-color: white; padding: 16px"
      >
        <span style="font-size: 18px; font-weight: bold">호텔 리스트</span>
        <title-list-draggable
          :list="DIList"
          :listOrderChanged="DIOrderChanged"
          :selectedKeys="selectedDIKeys"
          :selectItem="triggerSelectDI"
          :isStatus="true"
        />
      </div>

      <div flex df fdc style="padding: 16px; background-color: white">
        <span style="font-size: 18px; font-weight: bold">주문 리스트</span>
        <a-space direction="vertical">
          <a-space style="float: right; margin-right: 30px">
            <a-date-picker
              @change="load({})"
              v-model="filter.startDate"
              :disabled-date="disabledStartDate"
            />
            <span>~</span>
            <a-date-picker
              @change="load({})"
              v-model="filter.endDate"
              :disabled-date="disabledEndDate"
            />
            <a-button @click="load({})">검색</a-button>
          </a-space>

          <draggable-table
            style="width: 100%"
            v-if="selectedCategoryIndex"
            v-model="items"
            :pagination="pagination"
            :columns="columns"
          >
            <template v-slot:drag="{}">
              <drag-icon />
            </template>

            <template v-slot:status="{ record }">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <item-status-popover
                  @load="selectCategory"
                  :itemIndex="record.index"
                />
              </div>
            </template>

            <template v-slot:purchasePrice="{ record }">
              {{ calPurchasePrice(record) }}
            </template>

            <template v-slot:authDate="{ record }">
              {{ printAuthDate(record) }}<br />
              {{ printAuthTime(record) }}
            </template>

            <template v-slot:payType="{ record }">
              {{ printPayType(record) }}
            </template>
          </draggable-table>
        </a-space>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";

import CategoryApi from "@/api/admin/category";
import ProductApi from "@/api/admin/product";
import SalesApi from "@/api/master/salesman";

import Tabs from "@/utils/languageTab";

import { TimeSaveFunc, TimeShowFunc } from "@/utils/timeFunc";
import HotelApi from "@/api/agency/hotel";
import { StaticsColumns } from "@/utils/columns/agency/Statics";
import { Filter } from "@/utils/filterValue";
import {
  GmtConversion,
  GmtConversionReverse,
  StartDate,
  EndDate,
} from "@/utils/gmtConversion";

import DraggableTable from "@/components/DraggableTable";
import ItemStatusPopover from "@/components/service/ItemStatusPopover";
import ItemDeletePopconfirm from "@/components/service/ItemDeletePopconfirm";
import TitleListDraggable from "@/components/service/TitleListDraggable";
import DragIcon from "@/components/DragIcon";

import ShopCategoryAddModal from "@/components/modals/ShopCategoryAddModal";
import ShopItemAddModal from "@/components/modals/ShopItemAddModal";
import { i18n } from "@/i18n";

@Component({
  components: {
    DraggableTable,
    ItemStatusPopover,
    ItemDeletePopconfirm,
    DragIcon,
    TitleListDraggable,
    ShopCategoryAddModal,
    ShopItemAddModal,
  },
})
export default class Delivery extends Vue {
  moment = moment;

  roleCheck = localStorage.getItem("role");
  adminId = localStorage.getItem("adminId");
  salesId = localStorage.getItem("salesId");

  get content() {
    return "item-detail";
  }

  get columns() {
    const colums = StaticsColumns();
    return colums;
  }
  categoryModalKey = "category_key";
  itemModalKey = "item_key";

  @Filter
  filter = {
    startDate: moment().subtract(1, "w"),
    endDate: moment(),
    hotelIndex: 0,
    salesIndex: "",
    serviceIndex: "",
  };

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  detail = false;
  tabs = Tabs;

  itemStatusCount = {};

  serviceTime = {};
  deliveryItemIndex = "";
  DIList = [];

  get selectedDIIndex() {
    return this.deliveryItemIndex && Number(this.deliveryItemIndex);
  }

  get selectedDIKeys() {
    return this.selectedDIIndex ? [this.selectedDIIndex] : [];
  }

  isDIEdit = false;

  selectedDI = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    uploadedUrl: [],
  };

  categories = [];
  categoryIndex = "";
  editCategory = false;

  itemIndex = 0;
  items = [];
  editItem = false;
  itemData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    images: [],
    status: "1",
    maxQty: 1,
    salePrice: 0,
    purchasePrice: 0,
    isFree: 1,
    mainImage: {
      type: "0",
      uploadedUrl: [],
    },
    subImage: {
      type: "1",
      uploadedUrl: ["ADD"],
      imageLengthLimit: 7,
    },
    isMaxCount: false,
  };

  get selectedCategoryIndex() {
    return this.categoryIndex && Number(this.categoryIndex);
  }

  get selectedKeys() {
    return this.selectedCategoryIndex ? [this.selectedCategoryIndex] : [];
  }

  get title() {
    return (
      this.selectedCategoryIndex &&
      this.categories.find((a) => a.index === this.selectedCategoryIndex)?.name
    );
  }

  async mounted() {
    console.log(this.filterValue);
    await this.load();
    if (
      this.DIList !== null &&
      this.DIList.length > 0 &&
      this.$route.query.deliveryItem === undefined
    ) {
      this.deliveryItemIndex = this.DIList[0].index;
      await this.triggerSelectDI();
    } else {
      await this.setRouter();
    }
  }

  async load() {
    try {
      var DIList;

      const { current, pageSize, salesIndex, HotelIndex, ServiceId } =
        this.$route.query;

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      this.filter.salesIndex = this.salesId;
      this.filter.hotelIndex = HotelIndex ? HotelIndex : 336;
      this.filter.serviceIndex = ServiceId ? ServiceId : "";

      console.log(this.filterValue);
      //호텔 불러오는 부분
      const { list, total } = await HotelApi.List({
        ...pagination,
        ...this.filterValue,
      });

      for (let i = 0; i < list.length; i++) {
        // console.log(list[i]);
        list[i]["name"] = list[i]["information"]["Kr"]["name"];
      }
      this.DIList = list;

      console.log(DIList);
      console.log(this.data);

      // const { data, option } = await SalesApi.getPayList({
      //   current,
      //   pageSize,
      //   ...this.filterValue,
      //   "filter.startDate": GmtConversionReverse(
      //     StartDate(this.filter.startDate, "day"),
      //   ),
      //   "filter.endDate": GmtConversionReverse(
      //     EndDate(this.filter.endDate, "day"),
      //   ),
      // });

      // 호텔 필터 쓸일있으면 이부분 주석해제
      //   this.DIList = DIList.filter((item) => item.code === "SV_DELIVERY_ITEM");
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async selectDI(index) {
    try {
      if (this.$route.query.deliveryItem !== index) {
        let query = Object.assign({}, this.$route.query);
        delete query.category;
        this.$router.replace({ query });
      }

      this.categories = [];

      this.$router
        .push({
          query: {
            ...this.$route.query,
            deliveryItem: index ? index : this.deliveryItemIndex,
          },
        })
        .catch(() => {});

      if (index) {
        this.filter.hotelIndex = index;
        this.deliveryItemIndex = index;
      }

      const DI = this.DIList.find(
        (item) => item.index === Number(this.deliveryItemIndex),
      );

      //   const {data} = await HotelApi.gbStore(this.deliveryItemIndex)
      //   console.log(data);
      //   console.log("32323");

      const { data } = await CategoryApi.List({
        "filter.storeIndex": 336,
        "filter.serviceID": 1,
      });

      this.categories = data.map(({ ...item }) => ({
        ...item,
      }));

      if (
        this.categories !== null &&
        this.categories.length > 0 &&
        this.$route.query.category === undefined
      ) {
        this.categoryIndex = this.categories[0].index;
      } else {
        this.categoryIndex = this.$route.query.category;
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async triggerSelectDI(index) {
    await this.selectDI(index);
    await this.selectCategory();
  }

  async DIOrderChanged() {}

  async categoryOrderChanged() {}

  disabledStartDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.endDate ? value.isAfter(this.filter.endDate) : false)
    );
  }

  disabledEndDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.startDate ? value.isBefore(this.filter.startDate) : false)
    );
  }

  async selectCategory(index, current = 1, pageSize = 10) {
    try {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            category: index ? index : this.categoryIndex,
          },
        })
        .catch(() => {});

      if (index) {
        this.categoryIndex = index;
      }

      if (!this.categoryIndex) {
        return;
      }

      console.log("--------");
      console.log(this.filterValue);

      //주문 리스트 불러오는 api로 변경
      const { data, option } = await SalesApi.PayList({
        current,
        pageSize,
        ...this.filterValue,
        "filter.startDate": GmtConversionReverse(
          StartDate(this.filter.startDate, "day"),
        ),
        "filter.endDate": GmtConversionReverse(
          EndDate(this.filter.endDate, "day"),
        ),
      });

      console.log(data);

      //주문 합계 계산
      let totAmount = 0; //거래금액 총액
      let totSupplyPrice = 0; //공급가 총액
      let totRealAmount = 0; //수익 총액
      let storeAmount = 0; //운영사 총액
      let salesAmount = 0; //영업자 총액
      let recomendAmount = 0; //소개자 총액

      for (let i = 0; i < data.list.length; i++) {
        //합계 계산
        totAmount += data.list[i].amount;
        totRealAmount += data.list[i].commission;
        storeAmount += data.list[i].feeCompany;
        salesAmount += data.list[i].feeSales;
        recomendAmount += data.list[i].feeRecommend;
      }

      //컬럼명 맞게 수정
      data.list.unshift({
        name: "합계",
        commission: totRealAmount,
        amount: totAmount,
        purchasePrice: "",
        feeCompany: storeAmount,
        feeSales: salesAmount,
        feeRecommend: recomendAmount,
      });

      this.itemStatusCount = option;
      this.items = data.list;
    } catch (e) {
      console.log(e);
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }
  async itemOrderChanged() {
    try {
      await ProductApi.ProductOrder(Number(this.categoryIndex), {
        itemList: [...this.items],
      });

      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  printAuthDate(record) {
    if (record.authDate == null) return "";

    return moment(record.authDate).format("YYYY-MM-DD");
  }

  printAuthTime(record) {
    if (record.authDate == null) return "";

    return moment(record.authDate).format("HH:mm");
  }

  printPayType(record) {
    if (record == null) return "";
    if (record.payType == "NORMAL") {
      return "결제완료";
    } else if (record.payType == "ORI_CANCEL") {
      return "결제취소";
    } else if (record.payType == "CANCEL") {
      return "결제취소";
    } else return record.payType;
  }

  calPurchasePrice(record) {
    if (record.amount == null) return "";
    return record.amount - record.commission;
  }

  async setRouter() {
    if (this.$route.query.deliveryItem !== undefined) {
      await this.selectDI(this.$route.query.deliveryItem);
    }

    if (this.$route.query.category !== undefined) {
      await this.selectCategory(this.$route.query.category);
    }

    if (this.$route.query.item == undefined) {
      this.itemIndex = 0;
    } else {
      this.detail = true;
      this.itemIndex = Number(this.$route.query.item);
      await this.openItemDetail(this.itemIndex);
    }
  }

  statusRadioFilter(value) {
    this.filter.status = value;
  }

  tableStatusColor(status) {
    return status && status === "1" ? `rgba(0,0,0,.65)` : `#c2c2c2`;
  }

  tableStatusName(status) {
    return status && status === "1"
      ? i18n.tc("views.admin.services.sale")
      : status === "2"
      ? i18n.tc("views.admin.services.soldout")
      : i18n.tc("views.admin.services.noSale");
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
