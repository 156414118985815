import moment from "moment";

export function TimeSaveFunc(openTime, closeTime) {
  const stringOpenTime = moment(openTime || "0000").format("HH:mm");
  const stringCloseTime = moment(closeTime || "0000").format("HH:mm");

  const numberOpenTime = stringOpenTime.split(":");
  const numberCloseTime = stringCloseTime.split(":");

  if (
    stringOpenTime != numberCloseTime &&
    Number(numberOpenTime[0]) >= Number(numberCloseTime[0])
  ) {
    if (
      Number(numberOpenTime[0]) > Number(numberCloseTime[0]) ||
      Number(numberOpenTime[1]) > Number(numberCloseTime[1])
    ) {
      numberCloseTime[0] = Number(numberCloseTime[0]) + 24;
    }
  }

  return {
    openTime: String(numberOpenTime[0] + numberOpenTime[1]),
    closeTime: String(numberCloseTime[0] + numberCloseTime[1]),
  };
}

export function TimeShowFunc(closeTime) {
  let closeTimeValue = "";

  if (closeTime) {
    let numberCloseTimeH = closeTime.substring(0, 2);
    let numberCloseTimeM = closeTime.substring(2, 4);

    if (Number(numberCloseTimeH) >= 24) {
      numberCloseTimeH = Number(numberCloseTimeH) - 24;

      if (String(numberCloseTimeH).length == 1) {
        numberCloseTimeH = `0${numberCloseTimeH}`;
      }
    }

    closeTimeValue = String(numberCloseTimeH + numberCloseTimeM);
  }

  return closeTimeValue ? closeTimeValue : "0000";
}
