<template>
  <a-modal
    width="800px"
    :visible="visible"
    :closable="false"
    :title="itemTitle"
    :afterClose="afterClose"
  >
    <div>
      <upload
        dir-name="imageService"
        v-model="data"
        listType="picture-card"
        :selected="true"
        :disabled="levelCheck === '1' ? true : false"
      >
        <div class="image-expand">
          <div
            v-if="data.uploadedUrl[0]"
            :style="{
              backgroundImage: `url('${data.uploadedUrl}')`,
            }"
          />
          <div v-else df fdc fjc fic>
            <a-icon :type="'plus'" />
            <div>{{ $t('components.modal.upload') }}</div>
            <div>{{ $t('components.modal.uploadEx') }}</div>
          </div>
        </div>
      </upload>
    </div>
    <div>
      <div style="font-weight: bold; margin-bottom: 12px">{{ $t('components.modal.item.link') }}</div>
      <a-input v-model="data.linkUrl" />

      <div style="font-weight: bold; margin: 12px 0px">{{ $t('components.modal.item.serviceEx') }}</div>
      <a-textarea :rows="4" v-model="data.description" />
    </div>

    <div slot="footer">
      <a-button @click="closeEditItem">{{ $t('components.modal.cancel') }}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loadingEditItem"
        @click="save"
        >{{ $t('components.modal.save') }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import TypeOfServiceApi from "@/api/admin/typeOfService";

import Upload from "@/components/Upload";
import {i18n} from "@/i18n";

@Component({
  components: { Upload },
})
export default class ImageItemAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) categoryIndex;

  loadingEditItem = false;

  closeEditItem() {
    this.$emit("close");
  }

  async saveEditItem() {
    try {
      this.loadingEditItem = true;

      const data = {
        index: this.data.index ? this.data.index : 0,
        image: this.data.uploadedUrl[0],
        description: this.data.description,
        linkUrl: this.data.linkUrl,
      };

      if (this.data.index) {
        await TypeOfServiceApi.CategoryImageUpdate(data);
      } else {
        await TypeOfServiceApi.CategoryImageCreate(this.categoryIndex, data);
      }

      this.$emit("load");
      this.closeEditItem();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditItem = false;
    }
  }

  async save() {
    if (!this.data.uploadedUrl[0]) {
      return this.$message.warning(i18n.tc('components.modal.item.uploadImgWarn'));
    }

    await this.saveEditItem();
  }

  get itemTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc('components.modal.item.imgInfo');
    } else {
      return this.data.index ? i18n.tc('components.modal.item.imgEdit') : i18n.tc('components.modal.item.imgAdd');
    }
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 500px;
  width: 100%;
  margin-bottom: 16px;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}
</style>
