<template>
  <div class="setting">
    <div class="main-background">
      <div style="margin-bottom: 40px">
        <div style="font-size: 20px; font-weight: bold">
          {{ $t("views.admin.setting.setting") }}
        </div>
      </div>

      <a-form-model
        ref="form"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        @submit.prevent="submit"
      >
        <div df fdr>
          <div flex>
            <a-form-model-item :label="$t('views.admin.service.id')">
              <a-input :disabled="true" v-model="data.id" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.admin.service.name')">
              <a-input v-model="data.name" />
            </a-form-model-item>

            <a-form-model-item
              prop="password"
              :label="$t('views.admin.service.pwd')"
            >
              <a-input-password v-model="data.password" />
            </a-form-model-item>
            <a-form-model-item
              prop="passwordConfirm"
              :label="$t('views.admin.service.pwdCheck')"
            >
              <a-input-password v-model="passwordConfirm" />
            </a-form-model-item>
          </div>

          <div flex></div>
        </div>
      </a-form-model>

      <div df fdr>
        <div df flex fic fje style="margin-top: 50px">
          <a-popconfirm
            :title="$t('views.admin.service.cancelNotice')"
            ok-text="Yes"
            cancel-text="No"
            @confirm="load"
          >
            <a-icon slot="icon" type="question-circle-o" style="color: red" />
            <a-button :loading="loading" style="margin-right: 6px; width: 80px">
              {{ $t("views.admin.service.cancel") }}
            </a-button>
          </a-popconfirm>

          <a-button
            :loading="loading"
            style="width: 80px"
            type="primary"
            @click="submit"
          >
            {{ $t("views.admin.service.save") }}
          </a-button>
        </div>
        <div flex />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import jwt from "jsonwebtoken";

import AuthApi from "@/api/auth";
import { i18n } from "@/i18n";

@Component({
  components: {},
})
export default class Setting extends Vue {
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;

  passwordConfirm = "";
  data = {
    name: "",
    id: "",
    password: "",
  };

  get rules() {
    return {
      passwordConfirm: [
        {
          required: this.data.password ? true : false,
          validator: this.passwordCheckValidator,
          trigger: "change",
        },
      ],
    };
  }

  async passwordCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc("views.admin.service.pwdMsg")));
    } else {
      if (this.data.password || this.passwordConfirm) {
        try {
          const response =
            this.data.password !== this.passwordConfirm ? true : false;

          if (response == true) {
            callback(new Error(i18n.tc("views.admin.service.wrongPwd")));
          } else {
            this.$refs.receptionform.validateField("checkPass");
          }
        } catch {}
      }
      callback();
    }
  }

  async mounted() {
    await this.load();
  }

  async submit() {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        try {
          this.loading = true;
          const adminId = await localStorage.getItem("adminId");

          const data = {
            name: this.data.name,
            password: this.data.password,
          };

          await AuthApi.Update(adminId, data);

          await this.load();

          if (data.password) {
            this.$message.success(i18n.tc("views.admin.service.changePwdMsg"));
            await this.logout();
          } else {
            this.$message.success(i18n.tc("views.admin.service.editComp"));
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc("views.layout.admin.responseError");

          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async load() {
    try {
      this.loading = true;

      const adminId = await localStorage.getItem("adminId");
      const { data } = await AuthApi.Self(adminId);

      this.data = {
        id: data.id,
        name: data.name,
      };
      this.passwordConfirm = "";
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async logout() {
    try {
      const token = await localStorage.getItem("token");

      const decoded = jwt.decode(token);

      await AuthApi.Logout(decoded.nameid);
      await localStorage.removeItem("token");

      this.$router.replace({ name: "Login" });
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
