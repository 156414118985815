<template>
  <a-modal
    :visible="visible"
    :closable="false"
    :title="categoryTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editCategoryForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr fje>
        <small>{{ $t('components.modal.uploadCaution') }}</small>
      </div>
      <div class="card-container">
        <language-tab
          ref="languageTabForm"
          :value="data"
          :isLanguageUsed="isLanguageUsed"
          :tabs="tabs"
          :name="$t('components.modal.category.name')"
        >
        </language-tab>
      </div>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeEditCategory">{{ $t('components.modal.cancel') }}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loadingEditCategory"
        @click="save"
        >{{ $t('components.modal.save') }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import LanguageTab from "@/components/LanguageTab";
import Tabs from "@/utils/languageTab";

import TypeOfServiceApi from "@/api/admin/typeOfService";
import {i18n} from "@/i18n";

@Component({
  components: { LanguageTab },
})
export default class ImageCategoryAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) serviceIndex;

  tabs = Tabs;
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loadingEditCategory = false;

  closeEditCategory() {
    this.$emit("close");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async saveEditCategory() {
    try {
      this.loadingEditCategory = true;

      if (!this.data.information.En.name) {
        this.$refs.languageTabForm.tabDefaultKeyChange();
        return this.$message.warning(i18n.tc('components.modal.category.warn'));
      }

      if (this.data.index) {
        await TypeOfServiceApi.CategoryUpdate(this.data);
      } else {
        await TypeOfServiceApi.CategoryCreate(this.serviceIndex, this.data);
      }

      this.closeEditCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditCategory = false;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('components.modal.category.msg'),
            },
          ],
        }),
        {},
      ),
    };
  }

  get categoryTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc('components.modal.category.info');
    } else {
      return this.data?.index ? i18n.tc('components.modal.category.edit') : i18n.tc('components.modal.category.add');
    }
  }

  async save() {
    this.$refs.editCategoryForm.validate(async valid => {
      if (valid) {
        await this.saveEditCategory();
      }
    });
  }
}
</script>
