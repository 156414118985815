import client from "./client";

export default class Image {
  static async SignedUrl(dir, filename) {
    return await client.get(`shared/image/signedUrl/${dir}/${filename}`);
  }

  static async Create(hotelId,data) {
    return await client.post(`shared/image/${hotelId}`,data);
  }

  static async ImageList(hotelId) {
    return await client.get(`shared/image/basket/list/${hotelId}`);
  }

  static async Delete(data) {
    return await client.put(`shared/image/basket/delete`, data);
  }
}
