<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.master.basicService.title1') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.basicService.title2') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="main-background">
      <div>
        <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :loading="loading"
          :pagination="false"
        >
          <template slot="color" slot-scope="_, record">
            <div df fdr>
              <v-input-colorpicker
                :disabled="true"
                style="width:14px;height:14px;cursor:unset"
                v-model="record.color"
              />
            </div>
          </template>

          <template slot="icon" slot-scope="_, record">
            <div
              class="icon-mini-logo"
              :style="{
                backgroundImage: `url('${record.icon}')`,
              }"
            />
          </template>

          <template slot="status" slot-scope="_, record">
            <div
              df
              fdr
              fjc
              fic
              :style="{
                color: record.status ? `black` : `${Colors.grayDark}`,
              }"
            >
              <div>
                {{ record.status ? "O" : "X" }}
              </div>
            </div>
          </template>

          <template slot="name" slot-scope="_, record">
            <router-link
              :to="{
                name: 'BasicServiceEdit',
                params: { index: record.index },
              }"
              >{{ record.name }}</router-link
            >
          </template>

          <template slot="code" slot-scope="_, record">
            {{ record.code }}
          </template>

          <template slot="isAlways" slot-scope="_, record">
            <a-switch :disabled="true" v-model="record.isAlways" />
          </template>
          <template slot="openTime" slot-scope="_, record">
            {{ moment(record.openTime).format("HH:mm") }}
          </template>
          <template slot="closeTime" slot-scope="_, record">
            {{ moment(record.closeTime).format("HH:mm") }}
          </template>

          <template slot="multilingual" slot-scope="_, record">
            <a-button
              type="primary"
              icon="edit"
              ghost
              size="small"
              @click="multilingualLoad(record.index)"
            />
          </template>
        </a-table>
      </div>
    </div>

    <multilingual-add-modal
      :key="multilingualModalKey"
      :data="multilingualData"
      :visible="editMultilingual"
      :baseSerivceIndex="baseSerivceIndex"
      :afterClose="afterCloseEditMultilingual"
      @close="closeEditMultilingual"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import InputColorPicker from "vue-native-color-picker";
import moment from "moment";

import Colors from "@/utils/colors";
import ServiceApi from "@/api/master/service";
import {BasicServiceColumns} from "@/utils/columns/master/basicService";
import { TimeShowFunc } from "@/utils/timeFunc";

import MultilingualAddModal from "@/components/modals/MultilingualAddModal";

import {i18n} from "@/i18n";

@Component({
  components: {
    "v-input-colorpicker": InputColorPicker,
    MultilingualAddModal,
  },
})
export default class BasicService extends Vue {
  loading = true;
  Colors = Colors;

  get columns() {
    const colums =BasicServiceColumns();
    return colums;
  }
  moment = moment;

  data = [];
  multilingualModalKey = "multilingual_key";
  editMultilingual = false;
  multilingualData = [];
  baseSerivceIndex = 0;

  mounted() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;

      const { data } = await ServiceApi.List();

      this.data = data.map(({ openTime, closeTime, ...item }) => {
        const closeTimeValue = TimeShowFunc(closeTime);

        return {
          ...item,
          isAlways: openTime === "0000" && closeTime === "0000",
          openTime: moment(openTime || "0000", "HHmm"),
          closeTime: moment(closeTimeValue, "HHmm"),
        };
      });
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.master.basicService.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async multilingualLoad(index) {
    try {
      this.loading = true;

      this.multilingualModalKey = "multilingual_key";
      const { data } = await ServiceApi.MultilingualDetail(index);

      this.baseSerivceIndex = index;
      this.multilingualData = data;

      this.editMultilingual = true;
      this.multilingualModalKey = data.length > 0 ? data[0].index : "";
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        :  i18n.tc('views.master.basicService.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  closeEditMultilingual() {
    this.editMultilingual = false;
  }

  afterCloseEditMultilingual() {
    this.multilingualData = [];
    this.multilingualModalKey = "multilingual_key2";
  }
}
</script>

<style lang="scss" scoped>
.icon-mini-logo {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../public/img/icons/google-symbol.png");
}
</style>
