<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{
          $t("views.master.hotel.title1")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.hotel.title2")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.hotel.create.title")
        }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <a-form-model
        ref="form"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div df fdr style="margin: 4px 0px">
          <div df fic fjc class="service" style="flex: 2">
            {{ $t("views.master.hotel.create.hotelInfo") }}
          </div>

          <div df fic fjc flex class="service">
            {{ $t("views.master.hotel.create.selectService") }}
          </div>
        </div>
        <div df fdr style="padding: 10px">
          <a-space df flex direction="vertical">
            <div df fdr fje>
              <small>{{ $t("views.master.hotel.create.essential") }}</small>
            </div>

            <div class="card-container">
              <language-tab
                ref="languageTabForm"
                :value="data"
                :isLanguageUsed="isLanguageUsed"
                :tabs="tabs"
                :name="$t('views.master.hotel.create.hotelName')"
                :address="$t('views.master.hotel.create.hotelAddress')"
                :description="$t('views.master.hotel.create.hotelDesc')"
                :rank="$t('views.master.hotel.create.hotelRank')"
                :rooms="$t('views.master.hotel.create.hotelRooms')"
              >
              </language-tab>
            </div>

            <div>
              <a-form-model-item
                prop="status"
                :label="$t('views.master.hotel.create.hotelStatus')"
              >
                <a-radio-group v-model="data.status" button-style="solid">
                  <a-radio-button value="1">{{
                    $t("views.master.hotel.use")
                  }}</a-radio-button>
                  <a-radio-button value="0">{{
                    $t("views.master.hotel.noUse")
                  }}</a-radio-button>
                </a-radio-group>
              </a-form-model-item>
            </div>

            <!-- <a-form-model-item :label="$t('views.master.hotel.create.accountNumberInfo')">
              <a-input v-model="data.account.number" :placeholder="$t('views.master.hotel.create.accountNumber')">
                <a-select
                    slot="addonBefore"
                    style="min-width: 110px"
                    :placeholder="$t('views.master.hotel.create.bank')"
                    v-model="data.account.bank"
                >
                  <a-select-option
                      v-for="item in banks"
                      :key="item"
                      :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </a-input>
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.hotel.create.accountHolder')">
              <a-input v-model="data.account.holder"/>
            </a-form-model-item> -->

            <a-form-model-item :label="$t('views.master.hotel.create.email')">
              <a-input v-model="data.email" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.hotel.create.phone')">
              <a-input v-model="data.phone" />
            </a-form-model-item>

            <a-form-model-item
              v-if="data.confHotel"
              :label="$t('views.master.hotel.create.nationality')"
            >
              <a-select
                :placeholder="$t('views.master.hotel.create.nationalityMsg')"
                v-model="data.confHotel.country"
              >
                <a-select-option
                  v-for="item in country"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              v-if="data.confHotel"
              :label="$t('views.master.hotel.create.language')"
            >
              <a-select
                :placeholder="$t('views.master.hotel.create.languageMsg')"
                v-model="data.confHotel.langCode"
              >
                <a-select-option
                  v-for="item in langList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-space>

          <div df fdc flex style="margin: 0px 20px">
            <div df fic fjc class="sub-title">RUMY</div>
            <a-form-model-item v-if="data.confHotel" label="URL">
              <a-input v-model="data.confHotel.rumyUrl" />
            </a-form-model-item>
            <a-form-model-item
              v-if="data.confHotel"
              :label="$t('views.master.hotel.create.id')"
            >
              <a-input v-model="data.confHotel.rumyId" />
            </a-form-model-item>
            <a-form-model-item
              v-if="data.confHotel"
              :label="$t('views.master.hotel.create.password')"
            >
              <a-input-password v-model="data.confHotel.rumyPw" />
            </a-form-model-item>

            <div df fic fjc class="sub-title">
              {{ $t("views.master.hotel.create.receptionAccount") }}
            </div>
            <a-form-model-item
              prop="id"
              :label="$t('views.master.hotel.create.id')"
            >
              <a-input v-model="data.id" />
            </a-form-model-item>
            <a-form-model-item
              prop="password"
              :label="$t('views.master.hotel.create.password')"
            >
              <a-input-password v-model="data.password" />
            </a-form-model-item>
            <a-form-model-item
              prop="passwordConfirm"
              :label="$t('views.master.hotel.create.passwordConfirm')"
            >
              <a-input-password v-model="passwordConfirm" />
            </a-form-model-item>

            <div df fic fjc class="sub-title">
              {{ $t("views.master.hotel.create.code") }}
            </div>
            <a-form-model-item
              :label="$t('views.master.hotel.create.agentCode')"
            >
              <a-input v-model="data.agentCode" />
            </a-form-model-item>

            <a-form-model-item
              :label="$t('views.master.hotel.create.salesCode')"
            >
              <a-input v-model="data.salesCode" />
            </a-form-model-item>

            <a-form-model-item
              :label="$t('views.master.hotel.create.recommendCode')"
            >
              <a-input v-model="data.recommendCode" />
            </a-form-model-item>
          </div>

          <div df fdc flex style="margin-left: 20px">
            <div class="service-table" df fdr fic>
              <div df flex fjc>
                {{ $t("views.master.hotel.create.serviceName") }}
              </div>
              <div df flex fjc>
                {{ $t("views.master.hotel.create.serviceReg") }}
              </div>
              <div df flex fjc>
                {{ $t("views.master.hotel.create.serviceType") }}
              </div>
            </div>

            <div v-for="(value, key) in services" :key="key" df fdr flex>
              <div df flex fjc fic style="font-weight: bold">
                {{ value.name }}
              </div>
              <div df flex fjc fic>
                <a-switch v-model="value.status" />
              </div>
              <div df flex fjc fic style="font-weight: 500; font-size: 12px">
                {{ value.typeSt }}
              </div>
            </div>
          </div>
        </div>

        <div df fdr fje style="margin-top: 24px">
          <a-form-model-item>
            <a-button
              @click="submit"
              type="primary"
              :loading="loading"
              size="large"
              style="width: 120px"
            >
              {{ $t("views.master.hotel.create.register") }}
            </a-button>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import LanguageTab from "@/components/LanguageTab";

import HotelApi from "@/api/master/hotel";
import SHotelApi from "@/api/shared/hotel";
import EnumInfoApi from "@/api/enumInfo";
import AccountApi from "@/api/master/account";

import Tabs from "@/utils/languageTab";
import Banks from "@/utils/banks";

import { i18n } from "@/i18n";

@Component({
  components: { LanguageTab },
})
export default class HotelCreate extends Vue {
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };
  loading = false;

  tabs = Tabs;
  banks = Banks;
  country = [];
  langList = [];

  passwordConfirm = null;

  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            address: "",
            description: "",
          },
        }),
        {},
      ),
    },
    status: "",
    account: {
      number: "",
      bank: "",
      holder: "",
    },
    phone: "",
    email: "",
    latitude: "37.553780",
    longitude: "126.920535",
    imageFolder: "",
    id: "",
    password: "",
    confHotel: {
      langCode: "LANG_KR",
      country: "KR",
      rumyId: "",
      rumyPw: "",
      rumyUrl: "",
      rank: "",
      rooms: "",
    },
  };

  services = [];
  serviceType = [];

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc("views.master.hotel.create.hotelNameMsg"),
            },
          ],
          [`information.Kr.address`]: [
            {
              required: this.isLanguageUsed("Kr"),
              message: i18n.tc("views.master.hotel.create.hotelAddressMsg"),
            },
          ],
          [`information.${key}.description`]: [{}],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc("views.master.hotel.create.hotelStatusMsg"),
        },
      ],
      appCode: [
        {
          required: true,
          validator: this.appCodeCheckValidator,
          trigger: "change",
        },
      ],
      id: [
        {
          required: true,
          validator: this.idCheckValidator,
          trigger: "change",
        },
      ],
      password: [
        {
          required: true,
          message: i18n.tc("views.master.hotel.create.passwordMsg"),
        },
      ],
      passwordConfirm: [
        {
          required:
            this.data.password !== null && this.data.password !== ""
              ? true
              : false,
          validator: this.passwordCheckValidator,
          trigger: "change",
        },
      ],
    };
  }

  async idCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc("views.master.hotel.create.idBlankMsg")));
    } else {
      if (this.data.id !== "") {
        try {
          var idReg = /^[A-za-z0-9]{6,20}$/g;
          if (!idReg.test(value)) {
            callback(new Error(i18n.tc("views.master.hotel.create.idRuleMsg")));
            return;
          }

          const response = await AccountApi.IdCheck(this.data.id);

          if (response == true) {
            callback(
              new Error(i18n.tc("views.master.hotel.create.idDuplicateMsg")),
            );
          } else {
            this.$refs.form.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc("views.master.hotel.errorMsg");

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  async appCodeCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error("호텔코드를 입력해주세요."));
    } else {
      if (this.data.confHotel.appCode !== "") {
        try {
          var appCodeReg = /^[A-za-z0-9]{6,20}$/g;

          const response = await HotelApi.appCodeCheck(
            this.data.confHotel.appCode,
          );

          if (response == true) {
            callback(new Error(i18n.tc("호텔코드 중복되었습니다.")));
          } else {
            this.$refs.form.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc("views.master.hotel.errorMsg");

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  async passwordCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(
        new Error(i18n.tc("views.master.hotel.create.passwordConfirmMsg")),
      );
    } else {
      if (this.data.password !== "") {
        try {
          const response =
            this.data.password !== this.passwordConfirm ? true : false;

          if (response == true) {
            callback(
              new Error(
                i18n.tc("views.master.hotel.create.passwordInvalidateMsg"),
              ),
            );
          } else {
            this.$refs.receptionform.validateField("checkPass");
          }
        } catch {}
      }
      callback();
    }
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async mounted() {
    await this.loadBasicService();
    await this.countryListLoad();
    await this.langListLoad();
  }

  async loadBasicService() {
    try {
      this.loading = true;
      this.services = await HotelApi.BasicServiceList({ isCall: false });

      const { data } = await EnumInfoApi.List("service_type");
      const parseData = JSON.parse(data);
      this.serviceType = parseData[0].Data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async countryListLoad() {
    try {
      const { data } = await SHotelApi.CountryList();
      this.country = data.map(item => ({
        value: item.Key,
        name: item.Value,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    }
  }

  async langListLoad() {
    try {
      const { data } = await SHotelApi.LangList();

      this.langList = data.map(item => ({
        value: item.Key,
        name: item.Value,
      }));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    }
  }

  async submit() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        if (!this.data.information.En.name) {
          this.$refs.languageTabForm.tabDefaultKeyChange();

          this.$message.warning(
            i18n.tc("views.master.hotel.create.hotelNameEngMsg"),
          );

          return;
        }

        await this.createSubmit();
      }
    });
  }

  async createSubmit() {
    try {
      this.loading = true;

      const data = {
        ...this.data,
        services: this.services,
      };

      console.log(data);
      await HotelApi.Create(data);

      this.$router.go(-2);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.service {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-left: 20px;
}

.service-table {
  background-color: #efefef;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 18px;
}
</style>
