var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"height":"100%"},attrs:{"df":"","fdr":""}},[_c('div',{staticStyle:{"padding":"16px"},attrs:{"flex":"","df":"","fdc":""}},[_c('a-space',{attrs:{"direction":"vertical"}},[(_vm.levelCheck !== '1')?_c('div',{attrs:{"df":"","fdr":"","fjb":""}},[_c('h1',{staticStyle:{"font-size":"20px"}},[_c('a',{on:{"click":function($event){return _vm.openEditCategory(true)}}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('a-space',[(_vm.roleCheck === 'Master')?_c('div',{attrs:{"df":"","fdr":"","fic":""}},[_c('excel-form',{attrs:{"excelUpload":_vm.excelUpload,"openExcelForm":_vm.openExcelForm}}),_c('a-button',{attrs:{"type":"dashed"},on:{"click":function($event){return _vm.openImageUpload()}}},[_c('a-icon',{attrs:{"type":"picture"}}),_vm._v(_vm._s(_vm.$t("views.admin.services.uploadImg"))+" ")],1)],1):_vm._e(),_c('a-button',{on:{"click":function($event){return _vm.openEditItem()}}},[_vm._v(_vm._s(_vm.$t("views.admin.services.addStuff")))])],1)],1):_vm._e(),(_vm.levelCheck === '1')?_c('div',{attrs:{"df":"","fdr":"","fjb":""}},[_c('h1',{staticStyle:{"font-size":"20px"}},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('status-radio-form',{attrs:{"itemStatusCount":_vm.itemStatusCount},on:{"change":function($event){return _vm.selectCategory()},"statusRadioFilter":_vm.statusRadioFilter}}),(_vm.levelCheck !== '1')?_c('draggable-table',{staticStyle:{"width":"100%"},attrs:{"columns":_vm.columns,"drag-options":_vm.dragOptions},on:{"dragChanged":_vm.itemOrderChanged},scopedSlots:_vm._u([{key:"drag",fn:function(ref){return [_c('drag-icon')]}},{key:"status",fn:function(ref){
var record = ref.record;
return [_c('div',{style:({
                color: _vm.tableStatusColor(record.status),
              }),attrs:{"df":"","fdr":"","fjc":"","fic":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.tableStatusName(record.status))+" ")]),_c('item-status-popover',{attrs:{"itemIndex":record.index},on:{"load":_vm.selectCategory}})],1)]}},{key:"name",fn:function(ref){
              var record = ref.record;
return [_c('a',{on:{"click":function($event){return _vm.openEditItem(record)}}},[_vm._v(" "+_vm._s(record.name)+" ")])]}},{key:"type",fn:function(ref){
              var record = ref.record;
return [_vm._v(" "+_vm._s(_vm.tableTypeName(record))+" ")]}},{key:"delete",fn:function(ref){
              var record = ref.record;
return [_c('item-delete-popconfirm',{attrs:{"itemIndex":record.index},on:{"load":_vm.selectCategory}})]}}],null,false,2264469733),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}):_vm._e(),(_vm.levelCheck === '1')?_c('a-table',{staticStyle:{"width":"100%"},attrs:{"rowKey":"index","data-source":_vm.items,"columns":_vm.columns,"loading":_vm.loading},scopedSlots:_vm._u([{key:"status",fn:function(_, record){return [_c('div',{style:({
                color: _vm.tableStatusColor(record.status),
              }),attrs:{"df":"","fdr":"","fjc":"","fic":""}},[_c('div',[_vm._v(" "+_vm._s(_vm.tableStatusName(record.status))+" ")])])]}},{key:"name",fn:function(_, record){return [_c('a',{on:{"click":function($event){return _vm.openEditItem(record)}}},[_vm._v(" "+_vm._s(record.name)+" ")])]}},{key:"type",fn:function(_, record){return [_vm._v(" "+_vm._s(_vm.tableTypeName(record))+" ")]}}],null,false,2045118271)}):_vm._e()],1)],1)]),_c('shop-category-add-modal',{key:_vm.categoryModalKey,attrs:{"data":_vm.data,"visible":_vm.editCategory,"afterClose":_vm.afterCloseEditCategory,"serviceIndex":_vm.serviceIndex,"serviceTime":_vm.serviceTime},on:{"close":_vm.closeEditCategory}}),_c('shop-item-add-modal',{key:_vm.itemModalKey,attrs:{"data":_vm.itemData,"visible":_vm.editItem,"afterClose":_vm.afterCloseEditItem,"categoryIndex":_vm.selectedCategoryIndex,"serviceType":"amenity"},on:{"close":_vm.closeEditItem,"load":_vm.selectCategory}}),_c('image-upload-modal',{attrs:{"data":_vm.imageList,"visible":_vm.isImageUpload,"afterClose":_vm.afterCloseImageUpload},on:{"close":_vm.closeImageUpload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }