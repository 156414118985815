<template>
  <a-modal
    width="800px"
    :visible="visible"
    :closable="false"
    :title="itemTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="loginId" :label="$t('components.modal.staff.id')">
        <template v-if="!data.staffId">
          <a-input v-model="data.loginId" @change="(e) => data.loginId = formatInput(e.target.value)"/>
        </template>
        <template v-else>
          <a-input v-model="data.loginId" disabled/>
        </template>
      </a-form-model-item>
      <template v-if="!data.staffId">
        <a-form-model-item prop="loginPwd" :label="$t('components.modal.staff.pwd')">
          <a-input-password v-model="data.loginPwd" @change="(e) => data.loginPwd = formatInput(e.target.value)"/>
        </a-form-model-item>
        <a-form-model-item prop="passwordConfirm" :label="$t('components.modal.staff.pwdCheck')">
          <a-input-password v-model="passwordConfirm" @change="(e) => data.passwordConfirm = formatInput(e.target.value)"/>
        </a-form-model-item>
      </template>
      <template v-else>
        <a-form-model-item :label="$t('components.modal.staff.initPwd')">
          <a-button type="danger" @click="resetPwd">{{$t('components.modal.staff.init')}}</a-button>
        </a-form-model-item>
      </template>
      <a-form-model-item prop="staffName" :label="$t('components.modal.staff.staffNm')">
        <a-input v-model="data.staffName"/>
      </a-form-model-item>
      <a-form-model-item prop="staffPosition" :label="$t('components.modal.staff.rank')">
        <a-input v-model="data.staffPosition"/>
      </a-form-model-item>
      <a-form-model-item prop="staffPhone" :label="$t('components.modal.staff.phoneNum')">
        <a-input v-model="data.staffPhone"/>
      </a-form-model-item>
      <a-form-model-item prop="groupId" :label="$t('components.modal.staff.group')">
        <a-select
          :value="data.groupId"
          @change="changeItemGroup">
          <a-select-option value="">{{$t('components.modal.staff.none')}}</a-select-option>
          <a-select-option v-for="g in groups" :key="g.groupId" :value="g.groupId">
            {{ g.groupName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="appService" :label="$t('components.modal.staff.alarmSc')">
        <a-select
          mode="multiple"
          allowClear
          :placeholder="$t('components.modal.staff.selectSc')"
          v-model="data.appService"
          @change="(e) => onChangeAppService(e)"
        >
          <a-select-option v-for="(item, i) in services" :key="i" :value="item.index">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <div slot="footer">
      <a-button @click="close">{{$t('components.modal.staff.cancel')}}</a-button>
      <a-button
        type="primary"
        :loading="loadingEditItem"
        @click="save"
        >{{$t('components.modal.staff.save')}}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import axios from "axios";
import StaffApi from "@/api/admin/staff";

import Upload from "@/components/Upload";
import {i18n} from "@/i18n";

@Component({
  components: { Upload },
})
export default class StaffAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) groups;
  @Prop({ default: undefined }) data;
  @Prop({ default: undefined }) originData;
  @Prop({ default: undefined }) services;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;

  @Prop({ default: "shop" }) serviceType;

  passwordConfirm = "";
  labelCol = { span: 5 };
  wrapperCol = { span: 19 };

  loadingEditItem = false;
  previewVisible = false;
  previewImage = "";

  close() {
    this.$emit("close");
  }

  formatInput(text) {
    // text에 부모 input에 넣는 값이 들어옵니다.
    // 한글 테스트 정규식
    const notPhoneticSymbolExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (!notPhoneticSymbolExp.test(text)) {
      return text;
    } else {
      // 한글이 빠른 시간에 여러개 들어오는 경우도 있으니,한글이 없을 때까지 삭제하고, 검사
      text = text.slice(0, -1);
      let condition = notPhoneticSymbolExp.test(text);
      while (condition) {
        text = text.slice(0, -1);
        condition = notPhoneticSymbolExp.test(text);
      }
      return text;
    }
  }

  async doSave() {
    try {
      const data = {
        ...this.data,
        storeId: Number(this.$route.params.key),
        groupId: this.data.groupId ? this.data.groupId : null,
        appService: this.data.appService ? JSON.stringify(this.data.appService) : ""
      };

      if (data.staffId) {
        await StaffApi.Update(data.staffId, data);
      } else {
        await StaffApi.Create(data);
      }

      if (data.staffId && data.pushToken && data.appService) {
        const _axios = axios.create({
          baseURL: "http://localhost:8090/api/v1/"
        });
        // await

        for (let i=0; i<this.data.appService.length; i++) {    
          let data = {
            tokens: [this.data.pushToken],
            topic: "service_" + this.data.appService[i]
          };

          let param = {
            method: "POST",
            data,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "JWT": "h@te1Ord2rstaFF"
            },
          }
          
          let unsub = await _axios.request({
            url: "fcm/unsubscribe",
            ...param
          });
          
          let sub = await _axios.request({
            url: "fcm/subscribe",
            ...param
          });
        }
      }

      this.$emit("load");
      this.close();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditItem = false;
    }
  }

  get rules() {
    return {
      loginId: [
        {
          required: true,
          message: i18n.tc('components.modal.staff.writeId'),
        },
      ],
      loginPwd: [
        {
          required: true,
          message: i18n.tc('components.modal.staff.writePwd'),
        },
      ],
      passwordConfirm: [
        {
          required: true,
          validator: this.passwordCheckValidator,
          trigger: "change",
          message: i18n.tc('components.modal.staff.checkPwd'),
        },
      ],
      staffName: [
        {
          required: true,
          message: i18n.tc('components.modal.staff.writeNm'),
        },
      ],
    };
  }

  async passwordCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('components.modal.staff.oneMorePwd')));
    } else {
      if (this.data.loginPwd !== "") {
        try {
          const response =
            this.data.loginPwd !== this.passwordConfirm ? true : false;

          if (response == true) {
            callback(new Error(i18n.tc('components.modal.staff.wrongPwd')));
          } else {
            this.$refs.editForm.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  async save() {
    this.$refs.editForm.validate(async (valid) => {
      if (valid) {
        let result = await StaffApi.SearchByHotelIdAndLoginId(Number(this.$route.params.key), this.data.loginId);
        console.log(result);
        if (this.data.staffId) {
          await this.doSave();
        }
        else {
          if (result.data.length == 0) {
            await this.doSave();
          }
          else {
            this.$message.info(i18n.tc('components.modal.staff.changeId'));
          }
        }
      }
    });
  }

  handleCancel() {
    this.previewVisible = false;
  }

  async handlePreview(file) {
    this.previewImage = file;
    this.previewVisible = true;
  }

  get itemTitle() {
    // if (this.levelCheck === "1") {
    //   return "직원 정보";
    // } else {
    //   return this.data.staffId ? "직원 정보 수정" : "직원 정보 추가";
    // }
    return this.data.staffId ? i18n.tc('components.modal.staff.editStaff') : i18n.tc('components.modal.staff.addStaff');
  }

  async imageLinkOpen(url) {
    if (url) {
      window.open(`${url}`, "_blank").focus();
    }
  }

  async changeItemGroup(value) {
    this.data.groupId = Number(value);
  }

  resetPwd() {
    this.data.loginPwd = "0000";
    this.$message.info(i18n.tc('components.modal.staff.initPwdMsg'));
  }

  onChangeAppService(e) {
    console.log(this.data);
    console.log(this.originData);
    console.log(e);
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 400px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}

.sub-image-expand {
  position: relative;
  height: 80px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;

    > div {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      cursor: pointer;
      opacity: 0;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.image-close {
  font-size: 20px;
  padding: 2px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.image-viewer {
  font-size: 20px;
  padding: 3px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
</style>
