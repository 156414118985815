<template>
  <div>
    <login v-if="layout === 'login'" />
    <master-layout
      class="ho-layout"
      v-if="layout === 'master'"
      :selected-keys="selectedKeys"
    />
    <admin-layout
      class="ho-layout"
      v-if="layout === 'admin'"
      :selected-keys="selectedKeys"
    />
    <agency-layout
      class="ho-layout"
      v-if="layout === 'agency'"
      :selected-keys="selectedKeys"
    />
    <sales-layout
      class="ho-layout"
      v-if="layout === 'sales'"
      :selected-keys="selectedKeys"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import jwt from "jsonwebtoken";
import Login from "@/views/Login.vue";

import Client from "@/api/client";
import AuthApi from "@/api/auth";

import AdminLayout, {
  menus as adminMenus,
  level2Menus,
} from "@/views/layout/AdminLayout";
import MasterLayout, {
  menus as masterMenus,
} from "@/views/layout/MasterLayout";
import AgencyLayout, {
  menus as agencyMenus,
} from "@/views/layout/AgencyLayout";
import SalesLayout, {
  menus as salesMenus,
} from "@/views/layout/SalesLayout";

@Component({
  components: {
    Login,
    AdminLayout,
    MasterLayout,
    AgencyLayout,
    SalesLayout,
  },
})
export default class App extends Vue {
  layout = "login";

  selectedKeys = [0];

  role = "";

  @Watch("$route", { deep: true })
  async routeChange() {
    const level = await localStorage.getItem("level");

    const masterIndex = masterMenus.findIndex(
      item =>
        item.name === this.$route.name ||
        item.children?.indexOf(this.$route.name) !== -1,
    );

    if (masterIndex !== -1) {
      this.selectedKeys = [masterIndex];
      this.layout = "master";
      Client.storeId = null;
      return;
    }

    const salesIndex = salesMenus.findIndex(
      item =>
        item.name === this.$route.name ||
        item.children?.indexOf(this.$route.name) !== -1,
    );

    if (salesIndex !== -1) {
      this.selectedKeys = [salesIndex];
      this.layout = "sales";
      Client.storeId = null;
      return;
    }


    const agencyIndex = agencyMenus.findIndex(
      item =>
        item.name === this.$route.name ||
        item.children?.indexOf(this.$route.name) !== -1,
    );

    if (agencyIndex !== -1) {
      this.selectedKeys = [agencyIndex];
      this.layout = "agency";
      Client.storeId = null;
      return;
    }

    let adminIndex;


    if (level === "2") {
      adminIndex = level2Menus.findIndex(
        item =>
          item.name === this.$route.name ||
          item.children?.indexOf(this.$route.name) !== -1,
      );
    } else {
      adminIndex = adminMenus.findIndex(
        item =>
          item.name === this.$route.name ||
          item.children?.indexOf(this.$route.name) !== -1,
      );
    }

    if (adminIndex !== -1) {
      this.selectedKeys = [adminIndex];
      this.layout = "admin";

      Client.storeId = Number(this.$route.params.key);

      return;
    }

    if (this.$route.name === "Admin_Setting") {
      this.selectedKeys = null;
      this.layout = "admin";

      Client.storeId = Number(this.$route.params.key);

      return;
    }

    this.selectedKeys = [];

    this.layout = "login";
    Client.storeId = null;
  }

  async mounted() {
    this.checkLogin();
    this.routeChange();
  }

  async checkLogin() {
    try {
      const token = await localStorage.getItem("token");
      const adminId = localStorage.getItem("adminId");

      const data = await AuthApi.Self(adminId);
      if (!data.status) {
        await localStorage.removeItem("token");
        this.$router.replace({ name: "Login" });
      }

      if (token) {
        const decoded = jwt.decode(token);

        this.role = decoded.role;

        switch (this.role) {
          case "Master":
            if (this.layout !== "master" && this.layout !== "admin") {
              this.$router.replace({ name: "Dashboard" });
            }
            break;

          case "Agent":
            if (this.layout !== "agency") {
              this.$router.replace({
                name: "Agency_Dashboard",
              });
            }
            break;

          case "Sales":
            if (this.layout !== "sales") {
              this.$router.replace({
                name: "Sales_Dashboard",
              });
            }
            break;

          case "Admin":
            if (this.layout !== "admin") {
              this.$router.replace({
                name: "Admin_Dashboard",
                params: { key: Number(decoded.StoreId) },
              });
            }
            break;
        }
      } else {
        if (this.layout !== "login") {
          this.$router.replace({ name: "Login" });
        }
      }
    } catch {
      await localStorage.removeItem("token");
      this.$router.replace({ name: "Login" });
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.layout-content {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 auto;

    .bread-crumb-parent {
      flex: 0;
    }

    .main-background {
      margin: 24px 16px;
      padding: 16px;
      background: #fff;
      flex: 1 1 auto;
    }
  }
}

.ho-layout {
  min-height: 100vh !important;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }
}

.bread-crumb-parent {
  flex: 1;
  justify-content: center;
  margin: 24px 16px 0px 16px;
  .bread-crumb {
    font-weight: 900;
    .fontSub {
      font-size: 16px;
    }
    .fontMain {
      font-size: 17px;
    }
  }
}

.ant-form-item {
  &.form-item-inline {
    margin-bottom: 0;

    .ant-form-item-children {
      display: flex;
      flex-direction: row;

      .ant-form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;

        &:not(:last-child) {
          margin-right: 0.5em;
        }
      }
    }
  }
}

.card-container {
  border-bottom: rgb(217, 217, 217) solid 1px;
  margin-bottom: 10px;
}

.hotel-edit-language-tab {
  &.on {
    &::after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: red;
      margin-left: 0.5em;
    }
  }
}
</style>
