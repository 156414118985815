<template>
    <a-layout>
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <div class="master-logo" />
        <a-menu theme="dark" :selected-keys="selectedKeys">
          <a-menu-item v-for="(item, i) in menus" :key="i">
            <component
              :is="item.click ? 'a' : 'router-link'"
              v-bind="item.click ? {} : { to: { name: item.name } }"
              v-on="item.click ? { click: item.click } : {}"
            >
              <a-icon :type="item.icon" />
              <span>{{ item.title }}</span>
            </component>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
  
      <a-layout>
        <a-layout-header df style="background: #fff; padding: 0">
          <div flex fjs>
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
          </div>
          <div
            style="display: inline-block; padding-top: 15px; padding-right: 30px"
          >
            <a-form-model-item>
              <a-select
                v-model="locale"
                @change="localeChange()"
                :placeholder="$i18n.locale"
              >
                <a-select-option
                  v-for="(lang, i) in langs"
                  :key="`Lang${i}`"
                  :value="lang"
                >
                  {{ printLangs[lang] }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div
            df
            fdr
            fic
            style="font-size: 18px; font-weight: 600; padding-right: 30px"
          >
            <!-- <div
              style="font-size:11px;margin-right:10px;font-weight:bold;margin-top:5px;color:#8f8f8f"
            >
              GMT+9 ( KR )
            </div> -->
            {{ userName }}
            <a-icon
              style="padding-left: 16px; color: #d44646"
              type="logout"
              @click="logoutConfirm"
            />
          </div>
        </a-layout-header>
  
        <a-layout-content class="layout-content" df fdc>
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </template>
  
  <script>
  import { Component, Vue, Prop } from "vue-property-decorator";
  import jwt from "jsonwebtoken";
  import AuthApi from "@/api/auth";
  import { i18n } from "@/i18n";
  
  export const menus = [
    {
        name: "Agency_Dashboard",
        title: i18n.tc("views.layout.agency.menus.dashboard"),
        icon: "dashboard",
        children: [],
    },
    {
        name: "Agency_Hotel",
        title: i18n.tc("views.layout.agency.menus.hotel"),
        icon: "container",
        children: ["AgencyHotelCreate", "AgencyHotelEdit"],
    },
    {
        name: "Agency_Statics",
        title: i18n.tc("views.layout.agency.menus.statics"),
        icon: "unordered-list",
        children: [],
    },
    {
        name: "Agency_Order",
        title: i18n.tc("views.layout.admin.menus.orders"),
        icon: "unordered-list",
        children: [],
    },
  ];
  
  @Component({
    components: {},
  })
  export default class AgencyLayout extends Vue {
    @Prop({ default: () => [] })
    selectedKeys;
  
    role = "";
    userName = "";
    collapsed = false;
    country = [];
    locale = "ko";
    langs = ["ko", "en"];
    printLangs = {
      ko: "한국어",
      en: "English",
    };
  
    localeChange() {
      localStorage.setItem("locale", this.locale);
      this.$i18n.locale = this.locale;
      this.setMenus();
    }
  
    menus = menus;
  
    async mounted() {
      const token = localStorage.getItem("token");
      this.locale = localStorage.getItem("locale") ?? "ko";
      const decoded = jwt.decode(token);
  
      this.role = decoded.role;
      this.setMenus();
      this.userName = decoded.UserName;
      console.log(this.$i18n.locale);
    }
  
    setMenus() {
      this.menus = [
        {
          name: "Agency_Dashboard",
          title: i18n.tc("views.layout.agency.menus.dashboard"),
          icon: "dashboard",
          children: [],
        },
        {
            name: "Agency_Hotel",
            title: i18n.tc("views.layout.agency.menus.hotel"),
            icon: "container",
            children: ["AgencyHotelCreate", "AgencyHotelEdit"],
        },
        {
            name: "Agency_Statics",
            title: i18n.tc("views.layout.agency.menus.statics"),
            icon: "unordered-list",
            children: [],
        },
        {
            name: "Agency_Order",
            title: i18n.tc("views.layout.agency.menus.orders"),
            icon: "unordered-list",
            children: [],
        },
      ];
    }
  
    async logoutConfirm() {
      this.$confirm({
        title: i18n.tc("views.layout.admin.logout"),
        onOk: this.logout,
      });
    }
  
    async logout() {
      try {
        const token = await localStorage.getItem("token");
        const decoded = jwt.decode(token);
  
        await AuthApi.Logout(decoded.nameid);
  
        await localStorage.removeItem("token");
  
        this.$router.replace({ name: "Login" });
      } catch (e) {
        let error = e.response?.data?.data
          ? e.response.data.data
          : i18n.tc("views.layout.admin.menus.reponseError");
  
        this.$message.error(error);
      }
    }
  }
  </script>
  
  <style lang="scss">
  .master-logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/img/icons/logo/05_02.png");
    height: 60px;
    margin: 16px;
    margin: 15px 30px 15px 30px;
  }
  </style>
  