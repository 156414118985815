import client from "../client";

export default class Device {
  static async StatusList(index) {
    return await client.get(`admin/device/status/list/${index}`);
  }

  static async List(options) {
    return await client.get("admin/device", options);
  }
}
