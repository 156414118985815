import client from "../client";

export default class Order {
  static async List(options) {
    return await client.get("shared/order", options);
  }

  static async Detail(index) {
    return await client.get(`shared/order/${index}`);
  }

  static async ExcelDownload(data) {
    return await client.post(`shared/order/excel`, data);
  }
}
