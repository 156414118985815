<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{
          $t("views.master.sales.title1")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.sales.title2")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.sales.edit.title")
        }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <a-form-model
        ref="form"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div df fdr style="margin: 4px 0px">
          <div df fic fjc flex class="service">
            {{ $t("views.master.sales.edit.info2") }}
          </div>
        </div>
        <div df fdr style="padding: 10px">
          <div df fdc flex style="margin: 0px 20px">
            <a-form-model-item :label="$t('views.master.sales.create.type')">
              <a-radio-group v-model="data.salesType" button-style="solid">
                <a-radio-button :value="'1'"> 대리점 </a-radio-button>
                <a-radio-button :value="'2'"> 영업자 </a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.sales.create.name')">
              <a-input v-model="data.name" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.sales.create.status')">
              <a-radio-group v-model="data.status" button-style="solid">
                <a-radio-button :value="'1'"> 운영 </a-radio-button>
                <a-radio-button :value="'0'"> 미운영 </a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item
              :label="$t('views.master.sales.create.salesCode')"
            >
              <a-input v-model="data.salesCode" />
            </a-form-model-item>

            <a-form-model-item
              :label="$t('views.master.sales.create.recommendCode')"
            >
              <a-input v-model="data.recommendCode" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.sales.create.phone')">
              <a-input v-model="data.phone" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.sales.create.area')">
              <a-input v-model="data.area" />
            </a-form-model-item>

            <a-form-model-item :label="$t('views.master.sales.create.email')">
              <a-input v-model="data.email" />
            </a-form-model-item>
          </div>

          <div df fdc flex style="margin-left: 20px">
            <div class="service-table" df fdr fic>
              <div df flex fjc style="font-weight: bold">
                {{ $t("views.master.sales.edit.no2") }}
              </div>
              <div df flex fjc style="font-weight: bold">
                {{ $t("views.master.sales.edit.no3") }}
              </div>
              <div df flex fjc style="font-weight: bold">
                {{ $t("views.master.sales.edit.no4") }}
              </div>
            </div>

            <div
              v-for="(value, key) in hotelData"
              :key="key"
              df
              fdr
              style="margin-top: 20px"
            >
              <div df flex fjc fic>
                {{ value.name }}
              </div>
              <div df flex fjc fic style="font-weight: 300; font-size: 12px">
                {{ value.tabletCount }}
              </div>
              <div df flex fjc fic style="font-weight: 400; font-size: 12px">
                {{ value.phone }}
              </div>
            </div>
          </div>

          <!-- 영업자 리스트 -->
          <div df fdc flex style="margin-left: 20px">
            <div class="service-table" df fdr fic>
              <div df flex fjc style="font-weight: bold">
                {{ $t("views.master.sales.edit.recommendName") }}
              </div>
              <div df flex fjc style="font-weight: bold">
                {{ $t("views.master.sales.edit.recommendCode") }}
              </div>
            </div>

            <div
              v-for="(value, key) in salesList"
              :key="key"
              df
              fdr
              style="margin-top: 20px"
            >
              <div df flex fjc fic>
                {{ value.name }}
              </div>
              <div df flex fjc fic style="font-weight: 500; font-size: 12px">
                {{ value.salesCode }}
              </div>
            </div>
          </div>
        </div>

        <!-- <div df fdr fje style="margin-top: 24px">
          <a-form-model-item>
            <a-button
                @click="submit"
                type="primary"
                :loading="loading"
                size="large"
                style="width: 120px"
              >
                {{ $t("views.master.hotel.create.register") }}
              </a-button>
          </a-form-model-item>
        </div> -->
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";
import SalesApi from "@/api/master/salesman";

import Tabs from "@/utils/languageTab";

import HotelEditForm from "@/components/hotel/HotelEditForm";
import HotelServiceForm from "@/components/hotel/HotelServiceForm";
import HotelLangsForm from "@/components/hotel/HotelLangsForm";
import HotelReceptionForm from "@/components/hotel/HotelReceptionForm";
import HotelDeliveryForm from "@/components/hotel/HotelDeliveryForm";
import HotelPickUpForm from "@/components/hotel/HotelPickUpForm";

import { i18n } from "@/i18n";
import { Filter } from "@/utils/filterValue";

@Component({
  components: {
    HotelEditForm,
    HotelServiceForm,
    HotelLangsForm,
    HotelReceptionForm,
    HotelDeliveryForm,
    HotelPickUpForm,
  },
})
export default class HotelEdit extends Vue {
  tabs = Tabs;
  hotelIndex = this.$route.params.index;
  salesCode = "";

  loading = false;
  type = "hotel";

  data = {
    status: "1",
    salesType: "1",
    name: "",
    salesCode: "",
    recommendCode: "",
    phone: "",
    area: "",
    email: "",
  };

  @Filter
  filter = {
    Recommend: "",
  };

  hotelData = [];

  salesList = [];

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      this.loading = true;
      const data = await SalesApi.Info(this.hotelIndex);
      this.data = data;

      const hotelData = await SalesApi.InfoHotel(this.hotelIndex);
      this.hotelData = hotelData;
      console.log(this.hotelData);

      console.log(this.data.salesCode);
      this.filter.Recommend = this.data.salesCode;
      const salesData = await SalesApi.List(this.filterValue);
      this.salesList = salesData.list;

      console.log(this.salesList);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async moveHotelAdmin() {
    try {
      const { information, confHotel } = await HotelApi.Detail(this.hotelIndex);

      await localStorage.setItem("timezone", confHotel.timezone);
      await localStorage.setItem("country", confHotel.country);

      await this.$router.replace({
        name: "Admin_Dashboard",
        params: {
          key: Number(this.hotelIndex),
          hotelName: String(information.Kr.name),
        },
      });
    } catch {}
  }
}
</script>

<style lang="scss" scoped></style>
