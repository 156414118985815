<template>
  <div style="height: 100%">
    <div v-if="itemData && itemData.itemId">
      <component
        style="height: 100%"
        v-if="detail && itemIndex != 0"
        :is="content"
        :data="itemData"
        :afterClose="afterCloseEditItem"
        :categoryIndex="selectedCategoryIndex"
        @closeItemDetail="closeItemDetail"
        @load="selectCategory"
      >
      </component>
    </div>

    <div df fdr v-if="!detail || !itemData.itemId" style="height: 100%">
      <div class="category-group">
        <div style="padding: 16px" df fdr fjb>
          <a-button type="primary" @click="multilingualLoad(deliveryItemIndex)">
            {{ $t("views.admin.services.manageMent") }}
          </a-button>

          <a-button @click="showDIEditModal">
            {{
              levelCheck === "1"
                ? $t("views.admin.services.storeInfo")
                : $t("views.admin.services.editStore")
            }}
          </a-button>
        </div>

        <title-list-draggable
          :list="DIList"
          :listOrderChanged="DIOrderChanged"
          :selectedKeys="selectedDIKeys"
          :selectItem="triggerSelectDI"
          :isStatus="true"
        />
      </div>

      <div class="category-group">
        <div v-if="levelCheck !== '1'" style="padding: 16px" df fdr fjb fic>
          <div>{{ $t("views.admin.services.category") }}</div>
          <div>
            <a-button
              @click="openEditCategory(false)"
              icon="plus"
              style="margin-right: 5px"
            />
            <a-button
              @click="openEditCategory(true)"
              icon="edit"
              style="margin-right: 5px"
            />
            <a-popconfirm
              :title="
                items && items.length > 0
                  ? $t('views.admin.services.askDeleteCt')
                  : $t('views.admin.services.askDeleteCt2')
              "
              :ok-text="$t('views.admin.services.delete')"
              :cancel-text="$t('views.admin.services.cancel')"
              @confirm="deleteCategory(selectedCategoryIndex)"
            >
              <a-button type="danger" ghost icon="delete" />
            </a-popconfirm>
          </div>
        </div>

        <title-list-draggable
          v-if="categories.length > 0"
          :list="categories"
          :listOrderChanged="categoryOrderChanged"
          :selectedKeys="selectedKeys"
          :selectItem="selectCategory"
          :isStatus="true"
        />
      </div>

      <div flex style="padding: 16px" df fdc>
        <a-space direction="vertical">
          <div df fdr fjb>
            <h1 style="font-size: 20px">{{ title }}</h1>

            <a-space v-if="selectedCategoryIndex && levelCheck !== '1'">
              <div v-if="roleCheck === 'Master'" df fdr fic>
                <excel-form
                  :excelUpload="excelUpload"
                  :openExcelForm="openExcelForm"
                />

                <a-button @click="openImageUpload()" type="dashed">
                  <a-icon type="picture" />{{
                    $t("views.admin.services.uploadImg")
                  }}
                </a-button>
              </div>

              <a-button @click="openEditItem()">{{
                $t("views.admin.services.addStuff")
              }}</a-button>
            </a-space>

            <a-space v-if="selectedCategoryIndex && levelCheck === '1'">
              <a-button @click="openEditCategory(true)">{{
                $t("views.admin.services.categoryInfo")
              }}</a-button>
            </a-space>
          </div>

          <status-radio-form
            v-if="selectedCategoryIndex"
            :itemStatusCount="itemStatusCount"
            @change="selectCategory()"
            @statusRadioFilter="statusRadioFilter"
          />

          <draggable-table
            style="width: 100%"
            v-if="selectedCategoryIndex && levelCheck !== '1'"
            v-model="items"
            :columns="columns"
            @dragChanged="itemOrderChanged"
            :drag-options="dragOptions"
          >
            <template v-slot:drag="{}">
              <drag-icon />
            </template>

            <template v-slot:status="{ record }">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>

                <item-status-popover
                  @load="selectCategory"
                  :itemIndex="record.index"
                />
              </div>
            </template>

            <template v-slot:name="{ record }">
              <a @click="openItemDetail(record.index)">
                {{ record.name }}
              </a>
            </template>

            <template v-slot:delete="{ record }">
              <item-delete-popconfirm
                @load="selectCategory"
                :itemIndex="record.index"
              />
            </template>
          </draggable-table>

          <a-table
            rowKey="index"
            style="width: 100%"
            v-if="selectedCategoryIndex && levelCheck === '1'"
            :data-source="items"
            :columns="columns"
          >
            <template slot="status" slot-scope="_, record">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>
              </div>
            </template>

            <template slot="name" slot-scope="_, record">
              <a @click="openItemDetail(record.index)">
                {{ record.name }}
              </a>
            </template>
          </a-table>
        </a-space>
      </div>
    </div>

    <shop-category-add-modal
      :key="categoryModalKey"
      :data="data"
      :visible="editCategory"
      :afterClose="afterCloseEditCategory"
      @close="closeEditCategory"
      :serviceIndex="deliveryItemIndex"
      :serviceTime="serviceTime"
    />

    <shop-item-add-modal
      :key="itemModalKey"
      :data="itemData"
      :visible="editItem"
      :afterClose="afterCloseEditItem"
      :categoryIndex="selectedCategoryIndex"
      serviceType="shop"
      @close="closeEditItem"
      @load="selectCategory"
    />

    <delivery-item-edit-modal
      :key="selectedDI.index"
      :data="selectedDI"
      :visible="isDIEdit"
      @close="closeDIEdit"
      @saveSubmit="saveDIEdit"
    />

    <image-upload-modal
      :data="imageList"
      :visible="isImageUpload"
      :afterClose="afterCloseImageUpload"
      @close="closeImageUpload"
    />

    <multilingual-add-modal
      :key="multilingualModalKey"
      :index="deliveryItemIndex"
      :data="multilingualData"
      :visible="editMultilingual"
      :adminSerivceIndex="deliveryItemIndex"
      :afterClose="afterCloseEditMultilingual"
      @close="closeEditMultilingual"
    />
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import CategoryApi from "@/api/admin/category";
import ServiceApi from "@/api/admin/service";
import ProductApi from "@/api/admin/product";
import DeliveryApi from "@/api/shared/delivery";
import PickUpApi from "@/api/shared/pickUp";

import Tabs from "@/utils/languageTab";
import { ShopColumns } from "@/utils/columns/admin/shop";
import { ShopReadColumns } from "@/utils/columns/admin/shopRead";
import { Filter } from "@/utils/filterValue";
import { TimeSaveFunc, TimeShowFunc } from "@/utils/timeFunc";

import DraggableTable from "@/components/DraggableTable";
import ExcelForm from "@/components/service/ExcelForm";
import StatusRadioForm from "@/components/service/StatusRadioForm";
import ItemStatusPopover from "@/components/service/ItemStatusPopover";
import ItemDeletePopconfirm from "@/components/service/ItemDeletePopconfirm";
import TitleListDraggable from "@/components/service/TitleListDraggable";
import DragIcon from "@/components/DragIcon";

import ShopCategoryAddModal from "@/components/modals/ShopCategoryAddModal";
import ShopItemAddModal from "@/components/modals/ShopItemAddModal";
import DeliveryItemEditModal from "@/components/modals/DeliveryItemEditModal";
import ImageUploadModal from "@/components/modals/ImageUploadModal";
import MultilingualAddModal from "@/components/modals/MultilingualAddModal";

import ItemDetail from "@/components/shop/ItemDetail";
import { i18n } from "@/i18n";

@Component({
  components: {
    DraggableTable,
    ExcelForm,
    StatusRadioForm,
    ItemStatusPopover,
    ItemDeletePopconfirm,
    DragIcon,
    TitleListDraggable,
    ShopCategoryAddModal,
    ShopItemAddModal,
    ItemDetail,
    DeliveryItemEditModal,
    ImageUploadModal,
    MultilingualAddModal,
  },
})
export default class Delivery extends Vue {
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceCode;

  levelCheck = localStorage.getItem("level");
  roleCheck = localStorage.getItem("role");
  adminId = localStorage.getItem("adminId");

  get content() {
    return "item-detail";
  }

  get columns() {
    const colums = this.levelCheck === "1" ? ShopReadColumns() : ShopColumns();
    return colums;
  }
  categoryModalKey = "category_key";
  itemModalKey = "item_key";

  detail = false;
  tabs = Tabs;
  moment = moment;

  itemStatusCount = {};

  @Filter
  filter = {
    status: "",
  };

  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  serviceTime = {};
  deliveryItemIndex = "";
  DIList = [];

  get selectedDIIndex() {
    return this.deliveryItemIndex && Number(this.deliveryItemIndex);
  }

  get selectedDIKeys() {
    return this.selectedDIIndex ? [this.selectedDIIndex] : [];
  }

  isDIEdit = false;

  selectedDI = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    uploadedUrl: [],
  };

  categories = [];
  categoryIndex = "";
  editCategory = false;
  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
    status: "1",
    isServiceTime: true,
    openTime: "0000",
    closeTime: "0000",
  };

  itemIndex = 0;
  items = [];
  editItem = false;
  itemData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    images: [],
    status: "1",
    maxQty: 1,
    salePrice: 0,
    purchasePrice: 0,
    isFree: 1,
    mainImage: {
      type: "0",
      uploadedUrl: [],
    },
    subImage: {
      type: "1",
      uploadedUrl: ["ADD"],
      imageLengthLimit: 7,
    },
    isMaxCount: false,
  };

  isImageUpload = false;
  imageList = {
    uploadedUrl: ["ADD"],
    imageLengthLimit: 8,
  };

  get selectedCategoryIndex() {
    return this.categoryIndex && Number(this.categoryIndex);
  }

  get selectedKeys() {
    return this.selectedCategoryIndex ? [this.selectedCategoryIndex] : [];
  }

  get title() {
    return (
      this.selectedCategoryIndex &&
      this.categories.find(a => a.index === this.selectedCategoryIndex)?.name
    );
  }

  multilingualModalKey = "";
  editMultilingual = false;
  multilingualData = [];

  async multilingualLoad(index) {
    try {
      this.loading = true;

      this.multilingualModalKey = "multilingual_key";
      const { data } = await ServiceApi.MultilingualDetail(index);
      console.log(data);

      this.multilingualData = data;

      console.log(this.multilingualData);

      this.editMultilingual = true;
      this.multilingualModalKey = data.length > 0 ? data[0].index : "";
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  closeEditMultilingual() {
    this.editMultilingual = false;
  }

  afterCloseEditMultilingual() {
    this.multilingualData = [];
    this.multilingualModalKey = "multilingual_key2";
  }

  async mounted() {
    await this.load();

    if (
      this.DIList !== null &&
      this.DIList.length > 0 &&
      this.$route.query.deliveryItem === undefined
    ) {
      this.deliveryItemIndex = this.DIList[0].index;
      await this.triggerSelectDI();
    } else {
      await this.setRouter();
    }
  }

  async load() {
    try {
      var DIList;

      if (this.levelCheck === "2") {
        const { data } = await ServiceApi.AssignCheck(this.adminId);
        DIList = data;
      } else {
        // switch (this.serviceCode) {
        //   case "SV_DELIVERY_GROUP":
        //     {
        const { data } = await DeliveryApi.List(this.$route.params.key);
        DIList = data;
        //     }
        //     break;

        //   case "SV_PICKUP_GROUP":
        //     {
        //       const { data } = await PickUpApi.List(this.$route.params.key);
        //       DIList = data;
        //     }
        //     break;
        // }
      }

      // switch (this.serviceCode) {
      //   case "SV_DELIVERY_GROUP":
      this.DIList = DIList.filter(item => item.code === "SV_DELIVERY_ITEM");
      //     break;

      //   case "SV_PICKUP_GROUP":
      //     this.DIList = DIList.filter((item) => item.code === "SV_PICKUP_ITEM");
      //     break;
      // }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async selectDI(index) {
    try {
      if (this.$route.query.deliveryItem !== index) {
        let query = Object.assign({}, this.$route.query);
        delete query.category;
        this.$router.replace({ query });
      }

      this.categories = [];

      this.$router
        .push({
          query: {
            ...this.$route.query,
            deliveryItem: index ? index : this.deliveryItemIndex,
          },
        })
        .catch(() => {});

      if (index) {
        this.deliveryItemIndex = index;
      }

      const DI = this.DIList.find(
        item => item.index === Number(this.deliveryItemIndex),
      );

      const closeTimeValue = TimeShowFunc(DI.closeTime);

      this.serviceTime = {
        openTime: DI.openTime,
        closeTime: closeTimeValue,
      };

      const { data } = await CategoryApi.List({
        "filter.storeIndex": this.$route.params.key,
        "filter.serviceID": this.deliveryItemIndex,
      });

      this.categories = data.map(({ information, ...item }) => ({
        ...item,
        name: information.Kr.name,
        information,
      }));

      if (
        this.categories !== null &&
        this.categories.length > 0 &&
        this.$route.query.category === undefined
      ) {
        this.categoryIndex = this.categories[0].index;
      } else {
        this.categoryIndex = this.$route.query.category;
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async triggerSelectDI(index) {
    await this.selectDI(index);
    await this.selectCategory();
  }

  async DIOrderChanged() {
    try {
      await DeliveryApi.Order(this.$route.params.key, this.DIList);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async categoryOrderChanged() {
    try {
      const model = {
        categoryList: this.categories,
      };

      await CategoryApi.Order(
        this.$route.params.key,
        this.deliveryItemIndex,
        model,
      );

      await this.triggerSelectDI(this.$route.query.deliveryItem);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async selectCategory(index) {
    try {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            category: index ? index : this.categoryIndex,
          },
        })
        .catch(() => {});

      if (index) {
        this.categoryIndex = index;
      }

      if (!this.categoryIndex) {
        return;
      }

      const { data, option } = await ProductApi.ProductList(
        Number(this.categoryIndex),
        this.filterValue,
      );

      this.itemStatusCount = option;
      this.items = data.list;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async itemOrderChanged() {
    try {
      await ProductApi.ProductOrder(Number(this.categoryIndex), {
        itemList: [...this.items],
      });

      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeEditCategory() {
    this.editCategory = false;
  }

  afterCloseEditCategory() {
    this.triggerSelectDI(this.$route.query.deliveryItem);

    this.data = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
      status: "1",
      isServiceTime: true,
      openTime: "0000",
      closeTime: "0000",
    };

    this.categoryModalKey = "category_key2";
  }

  openEditCategory(isEdit) {
    this.editCategory = true;

    this.categoryModalKey = "category_key";
    if (!isEdit) {
      this.data = {
        ...this.data,
        openTime: moment(this.serviceTime.openTime || "0000", "HHmm"),
        closeTime: moment(this.serviceTime.closeTime || "0000", "HHmm"),
      };
      return;
    }

    const { information, ...data } = this.categories.find(
      a => a.index == this.selectedCategoryIndex,
    );

    const isServiceTime =
      data.openTime !== null &&
      data.closeTime !== null &&
      data.openTime === this.serviceTime.openTime &&
      data.closeTime === this.serviceTime.closeTime;

    const closeTimeValue = TimeShowFunc(data.closeTime);

    this.data = {
      ...data,
      openTime: moment(data.openTime || "0000", "HHmm"),
      closeTime: moment(closeTimeValue, "HHmm"),
      information: {
        ...this.data.information,
        ...information,
      },
      isServiceTime: isServiceTime,
    };
    this.categoryModalKey = this.data.index;
  }

  async deleteCategory(index) {
    try {
      await CategoryApi.Delete(index);

      await this.triggerSelectDI();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  openEditItem() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.editItem = true;
    this.itemModalKey = "item_key";
  }

  closeEditItem() {
    this.editItem = false;
  }

  afterCloseEditItem() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.itemModalKey = "item_key2";
  }

  @Watch("$route", { deep: true })
  async routeChange() {
    await this.setRouter();
  }

  async setRouter() {
    if (this.$route.query.deliveryItem !== undefined) {
      await this.selectDI(this.$route.query.deliveryItem);
    }

    if (this.$route.query.category !== undefined) {
      await this.selectCategory(this.$route.query.category);
    }

    if (this.$route.query.item == undefined) {
      this.itemIndex = 0;
      this.detail = false;
    } else {
      this.detail = true;
      this.itemIndex = Number(this.$route.query.item);
      await this.openItemDetail(this.itemIndex);
    }
  }

  async openItemDetail(index) {
    try {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            item: index,
          },
        })
        .catch(() => {});

      const { data } = await ProductApi.Detail(index);

      const mainImage = data.images.find(item => item.type === 0);
      const subImage = data.images
        .filter(item => item.type !== 0)
        .map(image => image.image);

      this.itemData = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                description: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
        isFree: 1,
        mainImage: {
          type: "0",
          uploadedUrl: [mainImage.image],
          imageId: mainImage.imageId,
        },
        subImage: {
          type: "1",
          uploadedUrl: [...subImage, "ADD"],
          imageLengthLimit: 7,
        },
        isMaxCount: data.maxQty > 0 ? true : false,
      };

      this.detail = true;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeItemDetail() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.detail = false;

    let query = Object.assign({}, this.$route.query);
    delete query.item;
    this.$router.replace({ query });
  }

  async excelUpload({ file, onSuccess, onError }) {
    try {
      await ProductApi.ExcelUplad(this.categoryIndex, file);

      await this.selectCategory();
      this.$message.success(i18n.tc("views.admin.services.excelSuc"));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  showDIEditModal() {
    this.isDIEdit = true;

    this.selectedDI = this.DIList.find(a => a.index === this.selectedDIIndex);

    const closeTimeValue = TimeShowFunc(this.selectedDI.closeTime);

    this.selectedDI = {
      ...this.selectedDI,
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
        ...this.selectedDI.information,
      },
      isAlways:
        this.selectedDI.openTime === "0000" &&
        this.selectedDI.closeTime === "0000",
      openTime: moment(this.selectedDI.openTime || "0000", "HHmm"),
      closeTime: moment(closeTimeValue, "HHmm"),
      departmentIds: this.selectedDI.departmentIds
        ? this.selectedDI.departmentIds.split(",")
        : undefined,
      uploadedUrl: [this.selectedDI.icon],
      estdTime: this.selectedDI.estdTime,
    };
  }

  async saveDIEdit() {
    try {
      const validate = this.selectedDI.isRumy
        ? this.selectedDI.departmentIds.length > 0
          ? true
          : false
        : true;

      if (!validate) {
        return this.$message.warning(
          i18n.tc("views.admin.services.chooseRumy"),
        );
      }

      const time = TimeSaveFunc(
        this.selectedDI.openTime,
        this.selectedDI.closeTime,
      );

      const deliveryItem = {
        ...this.selectedDI,
        departmentIds: this.selectedDI.departmentIds
          ? this.selectedDI.departmentIds.join(",")
          : "",
        openTime: time.openTime,
        closeTime: time.closeTime,
        icon: this.selectedDI.uploadedUrl[0],
      };
      delete deliveryItem.uploadedUrl;

      await DeliveryApi.Update(this.$route.params.key, deliveryItem);
      await this.load();
      await this.triggerSelectDI();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async closeDIEdit() {
    this.isDIEdit = false;

    await this.load();
  }

  async openImageUpload() {
    this.isImageUpload = true;
  }

  closeImageUpload() {
    this.isImageUpload = false;
  }

  afterCloseImageUpload() {
    this.imageList = {
      uploadedUrl: ["ADD"],
      imageLengthLimit: 8,
    };
  }

  openExcelForm() {
    window
      .open(
        "https://hihotelorder.s3.ap-northeast-2.amazonaws.com/excel/%EC%95%84%EC%9D%B4%ED%85%9C_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D_240705.xlsx",
        "_blank",
      )
      .focus();
  }

  statusRadioFilter(value) {
    this.filter.status = value;
  }

  tableStatusColor(status) {
    return status && status === "1" ? `rgba(0,0,0,.65)` : `#c2c2c2`;
  }

  tableStatusName(status) {
    return status && status === "1"
      ? i18n.tc("views.admin.services.sale")
      : status === "2"
      ? i18n.tc("views.admin.services.soldout")
      : i18n.tc("views.admin.services.noSale");
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
