import client from "../client";

export default class StaffGroup {
  static async List(hotelId, options) {
    return await client.get(`admin/staff/group/list/${hotelId}`, options);
  }

  static async Create(data) {
    return await client.post(`admin/staff/group`, data);
  }

  static async Update(staffGroupId, data) {
    return await client.put(`admin/staff/group/${staffGroupId}`, data);
  }

  static async Delete(staffGroupId) {
    return await client.del(`admin/staff/group/${staffGroupId}`);
  }
}
