<template>
  <a-card
    flex
    df
    fdc
    class="card-style"
    :style="{ marginRight: `${marginRight}` }"
  >
    <div class="top-style" df fdr fjb fic>
      <a-icon
        class="icon-style"
        :style="{ color: `${iconColor}` }"
        :type="icon"
      />
      <div df fdc fie>
        <animated-number
          :value="dailyValue"
          :formatValue="formatToPrice"
          :duration="duration"
        />

        <div class="name-style">
          {{ name }}
        </div>
      </div>
    </div>
    <div df fdr fic fjb class="yesterday-style">
      <div>
        {{ getPeriodType() }}
      </div>
      <animated-number
        :value="yesterdayValue"
        :formatValue="formatToPrice"
        :duration="duration"
      />
    </div>
    <div df fdr fic fjb class="total-style">
      <div>
        {{ getPeriodTypePercent() }}
      </div>

      {{ totalValue }} %
    </div>
  </a-card>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import AnimatedNumber from "animated-number-vue";
import {i18n} from "@/i18n";

@Component({
  components: { AnimatedNumber },
})
export default class DailyCard extends Vue {
  @Prop({ default: "0" }) dailyValue;
  @Prop({ default: "" }) name;
  @Prop({ default: "0" }) yesterdayValue;
  @Prop({ default: "0" }) totalValue;
  @Prop({ default: "" }) icon;
  @Prop({ default: "black" }) iconColor;
  @Prop({ default: false }) isMoney;
  @Prop({ default: "20px" }) marginRight;
  @Prop({ default: 500 }) duration;
  @Prop({ default: undefined }) periodType;
  @Prop({ default: undefined }) isServiceTypePeriodAmount;

  formatToPrice(value) {
    const data = Number(value).toFixed(0);

    if (this.isMoney) {
      return `￦ ${Number(data).toLocaleString()}`;
    } else {
      return `${Number(data).toLocaleString()}`;
    }
  }

  getPeriodType() {
    switch (this.periodType) {
      case "0":
        if (this.isServiceTypePeriodAmount) {
          return i18n.tc('components.dashboard.ySales');
        } else {
          return i18n.tc('components.dashboard.yOrders');
        }
      case "1":
        if (this.isServiceTypePeriodAmount) {
          return i18n.tc('components.dashboard.wSales');
        } else {
          return i18n.tc('components.dashboard.wOrders');
        }
      case "2":
        if (this.isServiceTypePeriodAmount) {
          return i18n.tc('components.dashboard.mSales');
        } else {
          return i18n.tc('components.dashboard.mOrders');
        }
    }
  }

  getPeriodTypePercent() {
    switch (this.periodType) {
      case "0":
        return i18n.tc('components.dashboard.yCompare');
      case "1":
        return i18n.tc('components.dashboard.wCompare');
      case "2":
        return i18n.tc('components.dashboard.mCompare');
    }
  }
}
</script>

<style lang="scss" scoped>
$icon-color: "black";

.card-style {
  border-radius: 6px;

  .top-style {
    font-size: 14px;
    font-weight: bold;

    .icon-style {
      font-size: 30px;
      color: $icon-color;
    }

    .name-style {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .yesterday-style {
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
  }

  .total-style {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
  }
}
</style>
