import client from "../client";

export default class Message {
  static async DeviceList(hotelId) {
    return await client.get(`admin/message/device/list/${hotelId}`);
  }

  static async List(hotelId, data) {
    return await client.post(`admin/message/list/${hotelId}`, data);
  }

  static async Detail(index) {
    return await client.get(`admin/message/${index}`);
  }

  static async Create(hotelId, data) {
    return await client.post(`admin/message/send/${hotelId}`, data);
  }

  static async Delete(data) {
    return await client.del(`admin/message/delete`, "", data);
  }
}
