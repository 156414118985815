<template>
  <a-card
    flex
    df
    fdc
    class="card-style"
    :style="{ marginRight: `${marginRight}` }"
  >
    <div style="font-size:18px;font-weight:bold;margin-bottom:12px">
      {{ name }}
    </div>
    <div class="top-style" df fdr fjb fic>
      <a-icon
        class="icon-style"
        :style="{ color: `${iconColor}` }"
        :type="icon"
      />
      <div df fdc fie>
        {{ $t('components.dashboard.total') }}
        <animated-number
          :value="totalValue"
          :formatValue="formatToPrice"
          :duration="duration"
        />

        <div class="total-style">
          {{ $t('components.dashboard.monthAvg') }}
          <animated-number
            :value="monthlyAverageValue"
            :formatValue="formatToPrice"
            :duration="duration"
          />
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import AnimatedNumber from "animated-number-vue";

@Component({
  components: { AnimatedNumber },
})
export default class TotalCard extends Vue {
  @Prop({ default: "" }) name;
  @Prop({ default: "0" }) totalValue;
  @Prop({ default: "0" }) monthlyAverageValue;
  @Prop({ default: "" }) icon;
  @Prop({ default: "black" }) iconColor;
  @Prop({ default: false }) isMoney;
  @Prop({ default: "20px" }) marginRight;
  @Prop({ default: 500 }) duration;

  formatToPrice(value) {
    const data = Number(value).toFixed(0);

    if (this.isMoney) {
      return `￦ ${Number(data).toLocaleString()}`;
    } else {
      return `${Number(data).toLocaleString()}`;
    }
  }
}
</script>

<style lang="scss" scoped>
$icon-color: "black";

.card-style {
  border-radius: 6px;
  padding: 16px 0px;

  .top-style {
    font-size: 17px;
    font-weight: bold;

    .icon-style {
      font-size: 30px;
      color: $icon-color;
    }

    .name-style {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .yesterday-style {
    font-size: 12px;
    font-weight: 500;
    margin-top: 16px;
  }

  .total-style {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
  }
}
</style>
