<template>
  <a-modal
    width="1200px"
    :visible="visible"
    :closable="false"
    :title="itemTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editItemForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr v-if="!loadingEditItem">
        <a-space direction="vertical" flex style="padding: 10px 20px 10px 0px">
          <upload
            dir-name="itemService"
            v-model="data.mainImage"
            listType="picture-card"
            :selected="true"
          >
            <div class="image-expand">
              <div
                v-if="data.mainImage.uploadedUrl[0]"
                :style="{
                  backgroundImage: `url('${data.mainImage.uploadedUrl[0]}')`,
                }"
              />
              <div v-else df fdc fjc fic>
                <a-icon :type="'plus'" />
                <div>{{$t('components.modal.shop.upload')}}</div>
                <div>{{$t('components.modal.shop.uploadEx1')}}</div>
              </div>
            </div>
          </upload>

          <div
            df
            fje
            style="margin-bottom: 12px"
            v-if="data.mainImage.uploadedUrl[0]"
          >
            <a-button
              @click="imageLinkOpen(data.mainImage.uploadedUrl[0])"
              style="font-size: 11px; font-weight: bold"
            >
              {{$t('components.modal.shop.mainLinkOpen')}}
            </a-button>
          </div>

          <a-row>
            <a-col
              v-for="item in data.subImage.uploadedUrl"
              :key="item"
              :span="6"
            >
              <upload
                dir-name="itemService"
                v-model="data.subImage"
                listType="picture-card"
                :multiple="true"
                :isAddBtn="true"
                :disabled="item && item !== 'ADD' ? true : false"
              >
                <div v-if="item !== 'ADD'" class="sub-image-expand">
                  <div
                    :style="{
                      backgroundImage: `url('${item}')`,
                    }"
                  >
                    <div df fdr fjc fic>
                      <a-icon
                        class="image-viewer"
                        type="eye"
                        @click="handlePreview(item)"
                      />

                      <a-icon
                        v-if="levelCheck === '1' ? false : true"
                        style="font-size: 18px; font-weight: bold; padding: 5px"
                        class="image-close"
                        type="delete"
                        @click="imageDelete(item)"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="item === 'ADD'" class="sub-image-expand">
                  <div df fdc fjc fic>
                    <a-icon :type="'plus'" />
                    <span> {{$t('components.modal.shop.upload')}} </span>
                  </div>
                </div>
              </upload>
            </a-col>
          </a-row>

          <div style="font-size: 14px; color: #db0000; font-weight: bold">
            {{$t('components.modal.shop.content1')}}<br />
            {{$t('components.modal.shop.content2')}}<br />
            {{$t('components.modal.shop.content3')}}
          </div>
        </a-space>

        <a-space direction="vertical" flex>
          <div df fdr fje>
            <small>{{$t('components.modal.shop.korEngWarn')}}</small>
          </div>
          <div class="card-container">
            <language-tab
              ref="languageTabForm"
              :value="data"
              :isLanguageUsed="isLanguageUsed"
              :tabs="tabs"
              :name="$t('components.modal.shop.stuffNm')"
              :description="$t('components.modal.shop.stuffInfo')"
            >
            </language-tab>
          </div>

          <div v-if="serviceType !== 'shop'">
            <a-form-model-item :label="$t('components.modal.shop.stuffType')">
              <a-radio-group button-style="solid" v-model="data.isFree">
                <a-radio-button :value="0">{{$t('components.modal.shop.freeStuff')}}</a-radio-button>
                <a-radio-button :value="1">{{$t('components.modal.shop.payStuff')}}</a-radio-button>
                <a-radio-button :value="2">{{$t('components.modal.shop.partFree')}}</a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item
              v-if="data.isFree !== 1"
              :label="data.isFree !== 0 ? $t('components.modal.shop.maxFree') : $t('components.modal.shop.maxOrder')"
            >+
              <a-input-number v-model="data.maxQty" :min="0" />

              <div
                v-if="data.isFree === 0"
                style="font-size: 12px; color: #db0000; font-weight: bold"
              >
                {{$t('components.modal.shop.orderWarn')}}
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="data.isFree !== 0" :label="$t('components.modal.shop.price')">
              <a-input-number
                style="width: 120px"
                v-model="data.salePrice"
                :formatter="
                  (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :min="0"
                :step="1000"
              />
            </a-form-model-item>

            <a-form-model-item v-if="data.isFree !== 0" :label="$t('components.modal.shop.cost')">
              <a-input-number
                style="width: 120px"
                v-model="data.purchasePrice"
                :formatter="
                  (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :min="0"
                :step="1000"
              />
            </a-form-model-item>
          </div>

          <div v-if="serviceType === 'shop'">
            <a-form-model-item :label="$t('components.modal.shop.onceMaxAm')">
              <a-radio-group button-style="solid" v-model="data.isMaxCount">
                <a-radio-button :value="true">{{$t('components.modal.shop.use')}}</a-radio-button>
                <a-radio-button :value="false">{{$t('components.modal.shop.unuse')}}</a-radio-button>
              </a-radio-group>

              <a-input-number
                v-if="data.isMaxCount"
                style="margin-left: 30px"
                v-model="data.maxQty"
                :min="1"
              />
            </a-form-model-item>

            <a-form-model-item :label="$t('components.modal.shop.price')">
              <a-input-number
                style="width: 120px"
                v-model="data.salePrice"
                :formatter="
                  (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :min="0"
                :step="1000"
              />
            </a-form-model-item>

            <a-form-model-item :label="$t('components.modal.shop.cost')">
              <a-input-number
                style="width: 120px"
                v-model="data.purchasePrice"
                :formatter="
                  (value) => `₩ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                :min="0"
                :step="1000"
              />
            </a-form-model-item>
          </div>

          <a-form-model-item prop="status" :label="$t('components.modal.shop.state')">
            <a-radio-group button-style="solid" v-model="data.status">
              <a-radio-button value="1">{{$t('components.modal.shop.use')}}</a-radio-button>
              <a-radio-button value="0">{{$t('components.modal.shop.unuse')}}</a-radio-button>
              <a-radio-button value="2">{{$t('components.modal.shop.soldout')}}</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-space>
      </div>

      <div df fdr fjc fic v-if="loadingEditItem" style="height: 600px">
        <a-icon type="loading" style="color: blue" />
      </div>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeEditItem">{{$t('components.modal.shop.cancel')}}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loadingEditItem"
        @click="save"
        >{{$t('components.modal.shop.save')}}</a-button
      >
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
      <div df fje style="margin-top: 12px">
        <a-button
          @click="imageLinkOpen(previewImage)"
          style="font-size: 11px; font-weight: bold"
        >
          {{$t('components.modal.shop.linkOpen')}}
        </a-button>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import ProductApi from "@/api/admin/product";

import LanguageTab from "@/components/LanguageTab";
import Upload from "@/components/Upload";
import Tabs from "@/utils/languageTab";
import {i18n} from "@/i18n";

@Component({
  components: { Upload, LanguageTab },
})
export default class ShopItemAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) categoryIndex;

  @Prop({ default: "shop" }) serviceType;

  tabs = Tabs;
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loadingEditItem = false;
  previewVisible = false;
  previewImage = "";

  closeEditItem() {
    this.$emit("close");
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async saveEditItem() {
    try {
      this.loadingEditItem = true;

      if (!this.data.information.En.name) {
        this.$refs.languageTabForm.tabDefaultKeyChange();
        return this.$message.warning(i18n.tc('components.modal.shop.writeEngStNm'));
      }

      const mainImage = {
        type: 0,
        image: this.data.mainImage.uploadedUrl[0],
        imageId: this.data.itemId
          ? this.data.images.find((item) => item.type === 0).imageId
          : 0,
      };

      this.data.images = this.data.images.filter((item) => item.type !== 0);

      const newImages = this.data.subImage.uploadedUrl.map((item) => item);
      const originSubImages = this.data.images.map((item) => item.image);
      const newImagesList = newImages.filter(
        (item) => originSubImages.indexOf(item) === -1,
      );

      let subImage;
      let images;

      if (newImagesList?.length > 0) {
        subImage = newImagesList
          .filter((item) => item !== "ADD")
          .map((image) => ({ image, type: 1 }));

        images = [...this.data.images, ...subImage, mainImage];
      } else {
        images = [...this.data.images, mainImage];
      }

      if (this.serviceType === "shop") {
        this.data.isFree = 1;
        this.data.maxQty =
          this.data.isMaxCount === false ? 0 : this.data.maxQty;
      } else {
        this.data.maxQty = this.data.isFree === 1 ? 0 : this.data.maxQty;
        this.data.isFree = this.data.isFree === 0 ? 1 : 0;

        if (this.data.isFree === 1) {
          this.data.salePrice = 0;
          this.data.purchasePrice = 0;
        }
      }

      const data = { ...this.data, images: images };

      delete data.mainImage;
      delete data.subImage;

      if (this.data.itemId) {
        await ProductApi.Update(data);
      } else {
        await ProductApi.Create(this.categoryIndex, data);
      }

      this.$emit("load");
      this.closeEditItem();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditItem = false;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('components.modal.shop.writeStNm'),
            },
          ],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc('components.modal.shop.selectState'),
        },
      ],
    };
  }

  async save() {
    this.$refs.editItemForm.validate(async (valid) => {
      if (valid) {
        if (!this.data.mainImage.uploadedUrl[0]) {
          return this.$message.warning(i18n.tc('components.modal.shop.uploadImage'));
        }

        await this.saveEditItem();
      }
    });
  }

  async imageDelete(value) {
    try {
      const image = this.data.images.find((item) => item.image === value);

      if (image) {
        await ProductApi.SubImageDelete(Number(image.imageId));
      }

      this.data.subImage.uploadedUrl = this.data.subImage.uploadedUrl.filter(
        (item) => item !== value,
      );
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  handleCancel() {
    this.previewVisible = false;
  }

  async handlePreview(file) {
    this.previewImage = file;
    this.previewVisible = true;
  }

  get itemTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc('components.modal.shop.stuffInfo2');
    } else {
      return this.data.itemId ? i18n.tc('components.modal.shop.editStuffInfo') : i18n.tc('components.modal.shop.addStuffinfo');
    }
  }

  async imageLinkOpen(url) {
    if (url) {
      window.open(`${url}`, "_blank").focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.image-expand {
  position: relative;
  height: 400px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}

.sub-image-expand {
  position: relative;
  height: 80px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;

    > div {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      cursor: pointer;
      opacity: 0;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.image-close {
  font-size: 20px;
  padding: 2px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.image-viewer {
  font-size: 20px;
  padding: 3px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}
</style>
