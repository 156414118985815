<template>
  <div df fdr fic class="button-style">
    <a-upload
      style=""
      name="file"
      :multiple="true"
      :custom-request="excelUpload"
      :showUploadList="false"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    >
      <a-button type="link" style="color:rgba(0, 0, 0, 0.65);">
        <a-icon type="upload" /> {{ $t("components.service.allUpload") }}
      </a-button>
    </a-upload>

    <a-button
      type="link"
      style="color:rgba(0, 0, 0, 0.65);"
      @click="openExcelForm()"
    >
      <a-icon type="download" /> 일괄 업로드 양식
    </a-button>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ExcelForm extends Vue {
  @Prop() excelUpload;
  @Prop() openExcelForm;
}
</script>

<style lang="scss" scoped>
.button-style {
  border: 1px solid #d9d9d9;
  border-style: dashed;
  line-height: 1.499;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  margin-right: 4px;
}
</style>
