<template>
  <div df fdr flex>
    <draggable-table
      flex
      :loading="loading"
      :columns="langsColumns"
      v-model="langs"
      @change="load"
      @dragChanged="langsOrderChanged"
      :drag-options="dragOptions"
    >
      <template v-slot:drag="{}">
        <drag-icon />
      </template>

      <template v-slot:langCode="{ value }">
        {{ langsValue(value) }}
      </template>

      <template v-slot:isShow="{ record }">
        <a-switch v-model="record.isShow" />
      </template>
    </draggable-table>

    <langs-add-modal
      :data="langsList"
      :visible="isLangsAdd"
      :hotelIndex="hotelIndex"
      @close="closeLangsAdd"
      @load="load"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";
import EnumInfoApi from "@/api/enumInfo";

import DraggableTable from "@/components/DraggableTable";
import DragIcon from "@/components/DragIcon";
import LangsAddModal from "@/components/modals/LangsAddModal";

import {LangsColumns} from "@/utils/columns/master/langs";
import {i18n} from "@/i18n";

@Component({
  components: { DraggableTable, LangsAddModal, DragIcon },
})
export default class HotelLangsForm extends Vue {
  @Prop({ default: undefined }) hotelIndex;

  drag = false;
  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  loading = false;

  isLangsAdd = false;

  langs = [];
  langsList = [];

  get langsColumns() {
    const colums =LangsColumns();
    return colums;
  }

  langsValue(value) {
    const lang_code = [
      { key: "LANG_KR", value: i18n.tc('components.hotel.lang.ko') },
      { key: "LANG_EN", value: i18n.tc('components.hotel.lang.en') },
      { key: "LANG_JP", value: i18n.tc('components.hotel.lang.jp') },
      { key: "LANG_CN", value: i18n.tc('components.hotel.lang.cn') },
      { key: "LANG_VN", value: i18n.tc('components.hotel.lang.vn') },
    ];

    const code = lang_code.filter(item => item.key === value);

    return code[0].value;
  }

  mounted() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      const data = await HotelApi.Detail(this.hotelIndex);

      this.langs = data.langs;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async langsSubmit() {
    try {
      this.loading = true;

      await HotelApi.LangsUpdate(this.langs);
      this.$message.success(i18n.tc('components.hotel.edit.success'));

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data ? e.response.data.data : i18n.tc('components.hotel.edit.fail');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  langsOrderChanged() {
    var count = 1;

    this.langs.map(item => (item.langOrd = count++));
  }

  async showLangsAddModal() {
    try {
      this.langsList = [];
      const { data } = await EnumInfoApi.List("language_code");
      const parseData = JSON.parse(data);

      let filterData = [];
      const hasLangs = this.langs.map(item => item.langCode);

      for (let item in parseData[0].Data) {
        filterData.push(parseData[0].Data[item]);
      }

      if (hasLangs.length > 0) {
        this.langsList = filterData.filter(
          item => hasLangs.indexOf(item) === -1,
        );
      } else {
        this.langsList = filterData;
      }

      this.isLangsAdd = true;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  closeLangsAdd() {
    this.isLangsAdd = false;
  }
}
</script>

<style lang="scss" scoped></style>
