<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    labels: {
      type: Array,
      default: null,
    },
    data: {
      type: Array,
      default: null,
    },
    dataLabel: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      gradient: null,
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(7, 23, 74, 0.9)");
    this.gradient.addColorStop(0.5, "rgba(7, 23, 74, 0.5)");
    this.gradient.addColorStop(1, "rgba(7, 23, 74, 0.4)");

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: this.dataLabel,
            borderColor: "#A3B7D0",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#02495B",
            backgroundColor: this.gradient,
            data: this.data,
          },
        ],
      },
      {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                  return Number(value).toLocaleString();
                },
              },

              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: function(tooltipItems, data) {
              return Number(tooltipItems.yLabel).toLocaleString();
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
    );
  },
};
</script>
