var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overallStatistics"},[_c('div',{staticClass:"main-background"},[_c('div',{attrs:{"df":"","fdr":""}},[_c('a',{staticClass:"card-style",attrs:{"df":"","fdr":"","flex":"","fjb":"","fic":""},on:{"click":function($event){return _vm.deviceStatusChange('normal')}}},[_c('a-icon',{staticClass:"state-title",staticStyle:{"font-size":"50px"},attrs:{"theme":"twoTone","two-tone-color":_vm.Colors.blueLight,"type":"check-circle"}}),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.deviceStatus.normal ? _vm.deviceStatus.normal : "0")+"/"+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"state",attrs:{"df":"","fje":""}},[_vm._v(" "+_vm._s(_vm.$t("views.admin.tablet.normal"))+" ")])])],1),_c('a',{staticClass:"card-style",style:({
          backgroundColor: ("" + (_vm.Colors.goldLight)),
        }),attrs:{"df":"","fdr":"","flex":"","fjb":"","fic":""},on:{"click":function($event){return _vm.deviceStatusChange('warning')}}},[_c('a-icon',{staticStyle:{"font-size":"50px"},attrs:{"theme":"twoTone","two-tone-color":_vm.Colors.yelloDark,"type":"exclamation-circle"}}),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.deviceStatus.warning ? _vm.deviceStatus.warning : "0")+"/"+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"state",style:({
              color: ("" + (_vm.Colors.yelloDark)),
            }),attrs:{"df":"","fje":""}},[_vm._v(" "+_vm._s(_vm.$t("views.admin.tablet.warning"))+" ")])])],1),_c('a',{staticClass:"card-style",style:({
          backgroundColor: ("" + (_vm.Colors.pink)),
          marginRight: '0px',
        }),attrs:{"df":"","fdr":"","flex":"","fjb":"","fic":""},on:{"click":function($event){return _vm.deviceStatusChange('danger')}}},[_c('a-icon',{staticStyle:{"font-size":"50px"},attrs:{"theme":"twoTone","two-tone-color":_vm.Colors.redLight,"type":"close-circle"}}),_c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.deviceStatus.danger ? _vm.deviceStatus.danger : "0")+"/"+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"state",style:({
              color: ("" + (_vm.Colors.redLight)),
            }),attrs:{"df":"","fje":""}},[_vm._v(" "+_vm._s(_vm.$t("views.admin.tablet.defective"))+" ")])])],1)]),_c('div',{attrs:{"df":"","fdr":"","fjb":"","fic":""}},[_c('a-radio-group',{staticStyle:{"margin":"16px 0px 8px 0px"},attrs:{"button-style":"solid"},on:{"change":function($event){return _vm.load({})}},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}},[_c('a-radio-button',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t("views.admin.tablet.all")))]),_vm._l((_vm.status),function(value,key){return _c('a-radio-button',{key:key,attrs:{"value":key}},[_vm._v(" "+_vm._s(value)+" ")])})],2),_c('a-input-search',{staticStyle:{"width":"280px"},attrs:{"placeholder":_vm.$t('views.admin.tablet.selectRoomSerialMsg')},on:{"change":function($event){return _vm.load({})}},model:{value:(_vm.filter.keyword),callback:function ($$v) {_vm.$set(_vm.filter, "keyword", $$v)},expression:"filter.keyword"}})],1),_c('a-table',{attrs:{"rowKey":"index","columns":_vm.columns,"data-source":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading},on:{"change":_vm.load},scopedSlots:_vm._u([{key:"lastRefreshTime",fn:function(value){return [_c('last-refresh-time-item',{attrs:{"value":value}})]}},{key:"serialNumber",fn:function(_, record){return _c('div',{staticStyle:{"font-family":"monospace","letter-spacing":"1.5px"}},[_vm._v(" "+_vm._s(record.serialNumber)+" ")])}},{key:"capacity",fn:function(_, record){return [_c('battery-item',{attrs:{"value":record.capacity}})]}},{key:"isCharging",fn:function(_, record){return [_c('div',{attrs:{"df":"","fdr":"","fjc":""}},[(record.isCharging === 1)?_c('div',{attrs:{"df":"","fdr":"","fjc":""}},[_c('div',{staticClass:"charging-battery-style"})]):_vm._e(),_c('div',{style:({
              color:
                record.isCharging && record.isCharging === 1
                  ? "black"
                  : ("" + (_vm.Colors.grayDark)),
            })},[_vm._v(" "+_vm._s(record.isCharging ? record.isCharging === 1 ? _vm.$t("views.admin.tablet.charge") : _vm.$t("views.admin.tablet.noCharge") : _vm.$t("views.admin.tablet.none"))+" ")])])]}},{key:"display",fn:function(_, record){return [_c('display-item',{attrs:{"value":record.lastRefreshTime}})]}},{key:"orderDetail",fn:function(_, record){return [_c('a-button',{on:{"click":function($event){return _vm.openOrderListModal(record)}}},[_vm._v(" "+_vm._s(_vm.$t("views.admin.tablet.orderHistory"))+" ")])]}}])})],1),_c('a-modal',{attrs:{"width":"700px","title":_vm.modalTitle,"visible":_vm.visible,"closable":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleOk}},[_c('a-table',{attrs:{"rowKey":"index","columns":_vm.modalColumns,"data-source":_vm.modalData,"loading":_vm.modalLoading,"pagination":_vm.modalPagination},on:{"change":_vm.orderDetailLoad},scopedSlots:_vm._u([{key:"date",fn:function(_, record){return _c('a-tooltip',{},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(record.registeredTime ? _vm.moment(record.registeredTime).fromNow() : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.moment(record.registeredTime).format("YYYY-MM-DD"))+" ")])],2)}},{key:"time",fn:function(_, record){return [_c('div',[_vm._v(" "+_vm._s(_vm.moment(record.registeredTime).format("HH:mm"))+" ")])]}},{key:"order",fn:function(_, record){return [_c('div',[_vm._v(" "+_vm._s(record.orderItems.length > 0 ? ((record.orderItems[0].productName) + " " + (_vm.$t( "views.admin.tablet.exclude" )) + " " + (record.orderItems.length) + (_vm.$t("views.admin.tablet.gun"))) : "")+" ")])]}}])}),_c('div',{staticStyle:{"font-size":"13px","font-weight":"600","margin-top":"16px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("views.admin.tablet.orderDesc1"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("views.admin.tablet.orderDesc2")))])]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(_vm._s(_vm.$t("views.admin.tablet.ok")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }