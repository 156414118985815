<template>
  <div style="height: 100%">
    <div v-if="itemData && itemData.itemId">
      <component
        style="height: 100%"
        v-if="detail && itemIndex != 0"
        :is="content"
        :data="itemData"
        :afterClose="afterCloseEditItem"
        :categoryIndex="selectedCategoryIndex"
        @closeItemDetail="closeItemDetail"
        @load="selectCategory"
      >
      </component>
    </div>

    <div df fdr v-if="!detail || !itemData.itemId" style="height: 100%">
      <div class="category-group">
        <div v-if="levelCheck !== '1'" style="padding: 16px" df fdr fjb fic>
          <div>{{ $t("views.admin.services.category") }}</div>
          <div>
            <a-button
              @click="openEditCategory(false)"
              icon="plus"
              style="margin-right: 5px"
            />
            <a-button
              @click="openEditCategory(true)"
              icon="edit"
              style="margin-right: 5px"
            />
            <a-popconfirm
              :title="
                items && items.length > 0
                  ? $t('views.admin.services.askDeleteCt')
                  : $t('views.admin.services.askDeleteCt2')
              "
              :ok-text="$t('views.admin.services.delete')"
              :cancel-text="$t('views.admin.services.cancel')"
              @confirm="deleteCategory(selectedCategoryIndex)"
            >
              <a-button type="danger" ghost icon="delete" />
            </a-popconfirm>
          </div>
        </div>

        <title-list-draggable
          :list="categories"
          :listOrderChanged="categoryOrderChanged"
          :selectedKeys="selectedKeys"
          :selectItem="selectCategory"
          :isStatus="true"
        />
      </div>

      <div flex style="padding: 16px" df fdc>
        <a-space direction="vertical">
          <div df fdr fjb>
            <h1 style="font-size: 20px">{{ title }}</h1>

            <a-space v-if="selectedCategoryIndex && levelCheck !== '1'">
              <div v-if="roleCheck === 'Master'" df fdr fic>
                <excel-form
                  :excelUpload="excelUpload"
                  :openExcelForm="openExcelForm"
                />

                <a-button @click="openImageUpload()" type="dashed">
                  <a-icon type="picture" />{{
                    $t("views.admin.services.uploadImg")
                  }}
                </a-button>
              </div>
              <a-button @click="openEditItem()">{{
                $t("views.admin.services.addStuff")
              }}</a-button>
            </a-space>

            <a-space v-if="selectedCategoryIndex && levelCheck === '1'">
              <a-button @click="openEditCategory(true)">{{
                $t("views.admin.services.categoryInfo")
              }}</a-button>
            </a-space>
          </div>

          <status-radio-form
            :itemStatusCount="itemStatusCount"
            @change="selectCategory()"
            @statusRadioFilter="statusRadioFilter"
          />

          <draggable-table
            style="width: 100%"
            v-if="selectedCategoryIndex && levelCheck !== '1'"
            v-model="items"
            :columns="columns"
            @dragChanged="itemOrderChanged"
            :drag-options="dragOptions"
          >
            <template v-slot:drag="{}">
              <drag-icon />
            </template>

            <template v-slot:status="{ record }">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>

                <item-status-popover
                  @load="selectCategory"
                  :itemIndex="record.index"
                />
              </div>
            </template>

            <template v-slot:name="{ record }">
              <a @click="openItemDetail(record.index)">
                {{ record.name }}
              </a>
            </template>

            <template v-slot:delete="{ record }">
              <item-delete-popconfirm
                @load="selectCategory"
                :itemIndex="record.index"
              />
            </template>
          </draggable-table>

          <a-table
            rowKey="index"
            style="width: 100%"
            v-if="selectedCategoryIndex && levelCheck === '1'"
            :data-source="items"
            :columns="columns"
          >
            <template slot="status" slot-scope="_, record">
              <div
                df
                fdr
                fjc
                fic
                :style="{
                  color: tableStatusColor(record.status),
                }"
              >
                <div>
                  {{ tableStatusName(record.status) }}
                </div>
              </div>
            </template>

            <template slot="name" slot-scope="_, record">
              <a @click="openItemDetail(record.index)">
                {{ record.name }}
              </a>
            </template>
          </a-table>
        </a-space>
      </div>
    </div>

    <shop-category-add-modal
      :key="categoryModalKey"
      :data="data"
      :visible="editCategory"
      :afterClose="afterCloseEditCategory"
      @close="closeEditCategory"
      :serviceIndex="serviceIndex"
      :serviceTime="serviceTime"
    />

    <shop-item-add-modal
      :key="itemModalKey"
      :data="itemData"
      :visible="editItem"
      :afterClose="afterCloseEditItem"
      :categoryIndex="selectedCategoryIndex"
      serviceType="shop"
      @close="closeEditItem"
      @load="selectCategory"
    />

    <image-upload-modal
      :data="imageList"
      :visible="isImageUpload"
      :afterClose="afterCloseImageUpload"
      @close="closeImageUpload"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import CategoryApi from "@/api/admin/category";
import ProductApi from "@/api/admin/product";

import Tabs from "@/utils/languageTab";
import { Filter } from "@/utils/filterValue";
import { TimeShowFunc } from "@/utils/timeFunc";
import { ShopColumns } from "@/utils/columns/admin/shop";
import { ShopReadColumns } from "@/utils/columns/admin/shopRead";

import DraggableTable from "@/components/DraggableTable";
import ExcelForm from "@/components/service/ExcelForm";
import StatusRadioForm from "@/components/service/StatusRadioForm";
import ItemStatusPopover from "@/components/service/ItemStatusPopover";
import ItemDeletePopconfirm from "@/components/service/ItemDeletePopconfirm";
import TitleListDraggable from "@/components/service/TitleListDraggable";
import DragIcon from "@/components/DragIcon";

import ShopCategoryAddModal from "@/components/modals/ShopCategoryAddModal";
import ShopItemAddModal from "@/components/modals/ShopItemAddModal";
import ImageUploadModal from "@/components/modals/ImageUploadModal";

import ItemDetail from "@/components/shop/ItemDetail";
import { i18n } from "@/i18n";

@Component({
  components: {
    DraggableTable,
    ExcelForm,
    StatusRadioForm,
    ItemStatusPopover,
    ItemDeletePopconfirm,
    TitleListDraggable,
    DragIcon,
    ShopCategoryAddModal,
    ShopItemAddModal,
    ItemDetail,
    ImageUploadModal,
  },
})
export default class Shop extends Vue {
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceCode;
  @Prop({ default: "" }) serviceTime;

  levelCheck = localStorage.getItem("level");
  roleCheck = localStorage.getItem("role");

  get content() {
    return "item-detail";
  }

  get columns() {
    const colums = this.levelCheck === "1" ? ShopReadColumns() : ShopColumns();
    return colums;
  }
  categoryModalKey = "category_key";
  itemModalKey = "item_key";

  detail = false;
  tabs = Tabs;
  moment = moment;

  itemStatusCount = {};

  @Filter
  filter = {
    status: "",
  };

  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  categories = [];
  categoryIndex = "";
  editCategory = false;
  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
    status: "1",
    isServiceTime: true,
    openTime: "0000",
    closeTime: "0000",
  };

  itemIndex = 0;
  items = [];
  editItem = false;
  itemData = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    images: [],
    status: "1",
    maxQty: 1,
    salePrice: 0,
    purchasePrice: 0,
    isFree: 1,
    mainImage: {
      type: "0",
      uploadedUrl: [],
    },
    subImage: {
      type: "1",
      uploadedUrl: ["ADD"],
      imageLengthLimit: 7,
    },
    isMaxCount: false,
  };

  isImageUpload = false;
  imageList = {
    uploadedUrl: ["ADD"],
    imageLengthLimit: 8,
  };

  get selectedCategoryIndex() {
    return this.categoryIndex && Number(this.categoryIndex);
  }

  get selectedKeys() {
    return this.selectedCategoryIndex ? [this.selectedCategoryIndex] : [];
  }

  get title() {
    return (
      this.selectedCategoryIndex &&
      this.categories.find(a => a.index === this.selectedCategoryIndex).name
    );
  }

  async mounted() {
    await this.load();

    if (
      this.categories !== null &&
      this.categories.length > 0 &&
      this.$route.query.category === undefined
    ) {
      this.categoryIndex = this.categories[0].index;
      await this.selectCategory();
    } else {
      await this.setRouter();
    }
  }

  async load(reset) {
    try {
      const { data } = await CategoryApi.List({
        "filter.storeIndex": this.$route.params.key,
        "filter.serviceID": this.serviceIndex,
      });

      this.categories = data.map(({ information, ...item }) => ({
        ...item,
        name: information.Kr.name,
        information,
      }));

      if (!reset) {
        return;
      }

      if (!this.categories || !this.categories.length) {
        this.categoryIndex = null;
        return;
      }

      this.categoryIndex = this.categories[0].index;
      await this.selectCategory();
    } catch {}
  }

  async categoryOrderChanged() {
    try {
      const model = {
        categoryList: this.categories,
      };

      await CategoryApi.Order(this.$route.params.key, this.serviceIndex, model);

      await this.load();
    } catch {}
  }

  async selectCategory(index) {
    try {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            category: index ? index : this.categoryIndex,
          },
        })
        .catch(() => {});

      if (index) {
        this.categoryIndex = index;
      }

      const { data, option } = await ProductApi.ProductList(
        Number(this.categoryIndex),
        this.filterValue,
      );

      this.itemStatusCount = option;
      this.items = data.list;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async itemOrderChanged() {
    try {
      await ProductApi.ProductOrder(Number(this.categoryIndex), {
        itemList: [...this.items],
      });

      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeEditCategory() {
    this.editCategory = false;
  }

  afterCloseEditCategory() {
    this.load();

    this.data = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
      status: "1",
      isServiceTime: true,
      openTime: "0000",
      closeTime: "0000",
    };

    this.categoryModalKey = "category_key2";
  }

  openEditCategory(isEdit) {
    this.editCategory = true;

    this.categoryModalKey = "category_key";
    if (!isEdit) {
      this.data = {
        ...this.data,
        openTime: moment(this.serviceTime.openTime || "0000", "HHmm"),
        closeTime: moment(this.serviceTime.closeTime || "0000", "HHmm"),
      };
      return;
    }

    const { information, ...data } = this.categories.find(
      a => a.index == this.selectedCategoryIndex,
    );

    const isServiceTime =
      data.openTime !== null &&
      data.closeTime !== null &&
      data.openTime === this.serviceTime.openTime &&
      data.closeTime === this.serviceTime.closeTime;

    const closeTimeValue = TimeShowFunc(data.closeTime);

    this.data = {
      ...data,
      openTime: moment(data.openTime || "0000", "HHmm"),
      closeTime: moment(closeTimeValue, "HHmm"),
      information: {
        ...this.data.information,
        ...information,
      },
      isServiceTime: isServiceTime,
    };
    this.categoryModalKey = this.data.index;
  }

  async deleteCategory(index) {
    try {
      await CategoryApi.Delete(index);

      await this.load(true);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async openEditItem() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.editItem = true;
    this.itemModalKey = "item_key";
  }

  closeEditItem() {
    this.editItem = false;
  }

  afterCloseEditItem() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.itemModalKey = "item_key2";
  }

  @Watch("$route", { deep: true })
  async routeChange() {
    await this.setRouter();
  }

  async setRouter() {
    if (this.$route.query.category !== undefined) {
      await this.selectCategory(this.$route.query.category);
    }

    if (this.$route.query.item == undefined) {
      this.itemIndex = 0;
      this.detail = false;
    } else {
      this.detail = true;
      this.itemIndex = Number(this.$route.query.item);
      await this.openItemDetail(this.itemIndex);
    }
  }

  async openItemDetail(index) {
    try {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            item: index,
          },
        })
        .catch(() => {});

      const { data } = await ProductApi.Detail(index);

      const mainImage = data.images.find(item => item.type === 0);
      const subImage = data.images
        .filter(item => item.type !== 0)
        .map(image => image.image);

      this.itemData = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                description: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
        isFree: 1,
        mainImage: {
          type: "0",
          uploadedUrl: [mainImage.image],
          imageId: mainImage.imageId,
        },
        subImage: {
          type: "1",
          uploadedUrl: [...subImage, "ADD"],
          imageLengthLimit: 7,
        },
        isMaxCount: data.maxQty > 0 ? true : false,
      };

      this.detail = true;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  closeItemDetail() {
    this.itemData = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      images: [],
      status: "1",
      maxQty: 1,
      salePrice: 0,
      purchasePrice: 0,
      isFree: 1,
      isMaxCount: false,
      mainImage: {
        type: "0",
        uploadedUrl: [],
      },
      subImage: {
        type: "1",
        uploadedUrl: ["ADD"],
        imageLengthLimit: 7,
      },
    };

    this.detail = false;

    let query = Object.assign({}, this.$route.query);
    delete query.item;
    this.$router.replace({ query });
  }

  async excelUpload({ file, onSuccess, onError }) {
    try {
      await ProductApi.ExcelUplad(this.categoryIndex, file);

      await this.selectCategory();
      this.$message.success(i18n.tc("views.layout.admin.excelSuc"));
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async openImageUpload() {
    this.isImageUpload = true;
  }

  closeImageUpload() {
    this.isImageUpload = false;
  }

  afterCloseImageUpload() {
    this.imageList = {
      uploadedUrl: ["ADD"],
      imageLengthLimit: 8,
    };
  }

  openExcelForm() {
    window
      .open(
        "https://hihotelorder.s3.ap-northeast-2.amazonaws.com/excel/%EC%95%84%EC%9D%B4%ED%85%9C_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D_240705.xlsx",
        "_blank",
      )
      .focus();
  }

  statusRadioFilter(value) {
    this.filter.status = value;
  }

  tableStatusColor(status) {
    return status && status === "1" ? `rgba(0,0,0,.65)` : `#c2c2c2`;
  }

  tableStatusName(status) {
    return status && status === "1"
      ? i18n.tc("views.admin.services.sale")
      : status === "2"
      ? i18n.tc("views.admin.services.soldout")
      : i18n.tc("views.admin.services.noSale");
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
