import client from "../client";

export default class Statistics {
  static async DateStatistics(data) {
    return await client.post("admin/statistics/date/statistics", data);
  }

  static async TimeStatistics(data) {
    return await client.post("admin/statistics/time/statistics", data);
  }

  static async RoomStatistics(data) {
    return await client.post("admin/statistics/room/statistics", data);
  }

  static async MenuStatistics(data) {
    return await client.post(`admin/statistics/menu/statistics`, data);
  }

  static async MenuRankingStatistics(index) {
    return await client.post(`admin/statistics/menu/top/statistics/${index}`);
  }
}
