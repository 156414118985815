<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.layout.master.account') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.account.manageAccount') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">
          {{ this.$route.params.index ? $t('views.master.account.updateAccount') : $t('views.master.account.enrollAccount') }}
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <a-form-model
        ref="form"
        :model="data"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div df fdr>
          <div flex>
            <a-form-model-item prop="hotelIndex" :label="$t('views.master.account.edit.hotel')">
              <hotel-list
                @change="shopListLoad(true)"
                v-model="data.hotelIndex"
                :all="false"
              />

              <a-select
                v-if="
                  Number(data.level) === 2 && shopList.length > 0 ? true : false
                "
                mode="multiple"
                :placeholder="$t('views.master.account.edit.service')"
                style="width: 350px"
                v-model="data.serviceIds"
              >
                <a-select-option
                  v-for="item in shopList"
                  :key="item.index"
                  :value="item.index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item prop="id" :label="$t('views.master.account.edit.id')">
              <a-input
                :disabled="this.$route.params.index ? true : false"
                v-model="data.id"
              />
            </a-form-model-item>

            <a-form-model-item prop="password" :label="$t('views.master.account.edit.pwd')">
              <a-input-password v-model="data.password" />
            </a-form-model-item>

            <a-form-model-item prop="passwordConfirm" :label="$t('views.master.account.edit.pwdCheck')">
              <a-input-password v-model="passwordConfirm" />
            </a-form-model-item>

            <a-form-model-item prop="name" :label="$t('views.master.account.edit.name')">
              <a-input v-model="data.name" />
            </a-form-model-item>

            <a-form-model-item prop="level" :label="$t('views.master.account.edit.auth')">
              <div v-if="Number(data.level) === 0">
                <a-icon type="form" />
                {{ $t('views.master.account.toggle.hotelMaster') }}
              </div>
              <div v-if="Number(data.level) === 4">
                <a-icon type="crown" />
                {{ $t('views.master.account.toggle.hotelOrderMaster') }}
              </div>
              <div v-if="Number(data.level) === 1">
                <a-icon type="usergroup-add" />
                {{ $t('views.master.account.toggle.hotelViewer') }}
              </div>
              <div v-if="Number(data.level) === 2">
                <a-icon type="shop" />
                {{ $t('views.master.account.toggle.external') }}
              </div>
            </a-form-model-item>

            <div df fje>
              <a-form-model-item :wrapper-col="buttonItemLayout.wrapperCol">
                <a-button
                  style="width:100px"
                  :loading="loading"
                  type="primary"
                  @click="submit"
                  >{{ $t('views.master.account.edit.save') }}</a-button
                >
              </a-form-model-item>
            </div>
          </div>

          <div flex />
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import HotelList from "@/components/HotelList";
import AccountApi from "@/api/master/account";
import {i18n} from "@/i18n";

@Component({
  components: {
    HotelList,
  },
})
export default class AccountEdit extends Vue {
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loading = false;
  edit = false;
  passwordConfirm = "";
  shopList = [];
  deliveryGroupId = "";

  get rules() {
    return {
      hotelIndex: [
        {
          required: true,
          validator: this.hotelIndexValidator,
          trigger: "change",
        },
      ],
      id: [
        {
          required: true,
          validator: this.idCheckValidator,
          trigger: "change",
        },
      ],
      password: [
        {
          required: !this.edit,
          message: i18n.tc('views.master.account.edit.pwdMessage'),
        },
      ],
      name: [
        {
          required: true,
          message: i18n.tc('views.master.account.edit.nameMessage'),
        },
      ],
      passwordConfirm: [
        {
          required: this.data.password ? true : false,
          validator: this.passwordCheckValidator,
          trigger: "change",
        },
      ],
    };
  }

  data = {
    name: "",
    id: "",
    password: "",
    level: this.$route.params.level ? this.$route.params.level : 0,
    type: this.$route.params.level==4? "ADMIN_TYPE_MASTER" : "ADMIN_TYPE_HOTEL",
    hotelIndex: undefined,
    serviceIds: [],
  };

  buttonItemLayout() {
    const { formLayout } = this;
    return formLayout === "horizontal"
      ? {
          wrapperCol: { span: 14, offset: 4 },
        }
      : {};
  }

  hotelIndexValidator(rule, value, callback) {
    if (!value) {
      callback(new Error(i18n.tc('views.master.account.edit.hotelMessage')));
    } else {
      if (this.data.hotelIndex !== "") {
        this.$refs.form.validateField("checkPass");
      }
      callback();
    }
  }

  async idCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('views.master.account.edit.idMessage')));
    } else {
      if (this.data.id !== "" && !this.$route.params.index) {
        try {
          var idReg = /^[A-za-z0-9]{6,20}$/g;
          if (!idReg.test(value)) {
            callback(
              new Error(i18n.tc('views.master.account.edit.idCheck')),
            );
            return;
          }

          const response = await AccountApi.IdCheck(this.data.id);

          if (response == true) {
            callback(new Error(i18n.tc('views.master.account.edit.idError')));
          } else {
            this.$refs.form.validateField("checkPass");
          }
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        }
      }
      callback();
    }
  }

  async passwordCheckValidator(rule, value, callback) {
    if (value === "") {
      callback(new Error(i18n.tc('views.master.account.edit.pwdOneMore')));
    } else {
      if (this.data.password || this.passwordConfirm) {
        try {
          const response =
            this.data.password !== this.passwordConfirm ? true : false;

          if (response == true) {
            callback(new Error(i18n.tc('views.master.account.edit.pwdNotSame')));
          } else {
            this.$refs.receptionform.validateField("checkPass");
          }
        } catch {}
      }
      callback();
    }
  }

  async mounted() {
    const hotelIndex = this.$route.params.hotelIndex;
    if (hotelIndex) {
      this.data.hotelIndex = hotelIndex;
      await this.shopListLoad(false);
    }

    const index = this.$route.params.index;

    if (index) {
      this.edit = true;
      await this.load(index);
      await this.shopListLoad(false);
    }
  }

  submit() {
    this.$refs.form.validate(async valid => {
      if (valid) {
        try {
          const index = this.$route.params.index;

          this.loading = true;

          const data = {
            ...this.data,
            level: Number(this.data.level),
          };

          const serviceList = this.shopList.filter(
            item => data.serviceIds.indexOf(item.index) !== -1,
          );

          if (serviceList.some(item => item.code === "SV_DELIVERY_ITEM")) {
            if (!data.serviceIds.some(item => item === this.deliveryGroupId)) {
              data.serviceIds = [...data.serviceIds, this.deliveryGroupId];
            }
          }

          if (index) {
            await AccountApi.Update(index, data);
          } else {
            await AccountApi.Create(data);
          }

          this.$router.back();
        } catch (e) {
          let error = e.response?.data?.data
            ? e.response.data.data
            : i18n.tc('views.layout.admin.responseError');

          this.$message.error(error);
        } finally {
          this.loading = false;
        }
      }
    });
  }

  async load(index) {
    try {
      this.loading = true;

      this.data = await AccountApi.Detail(index);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async shopListLoad(reset) {
    try {
      if (Number(this.data.level) !== 2) {
        return;
      }

      if (reset) {
        this.data.serviceIds = [];
      }

      const { data } = await AccountApi.ShopList(this.data.hotelIndex);

      const deliveryGroupId = data.find(
        item => item.code === "SV_DELIVERY_GROUP",
      );

      if (deliveryGroupId) {
        this.deliveryGroupId = deliveryGroupId.index;

        this.data.serviceIds = this.data.serviceIds.filter(
          item => item !== this.deliveryGroupId,
        );
      }

      const list = data.filter(item => item.code !== "SV_DELIVERY_GROUP");

      this.shopList = list;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
