<template>
  <a-layout>
    <a-layout-header df class="header">
      <div flex fjs>
        <div class="admin-logo" />

        <a-menu
          theme="dark"
          mode="horizontal"
          :selected-keys="selectedKeys"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item v-for="(item, i) in menus" :key="i">
            <component
              style="text-align: center"
              :is="item.click ? 'a' : 'router-link'"
              v-bind="item.click ? {} : { to: { name: item.name } }"
              v-on="item.click ? { click: item.click } : {}"
              v-if="
                item.name === 'Admin_StaffManagement'
                  ? levelCheck === '0' || levelCheck === '1'
                    ? true
                    : false
                  : true
              "
            >
              <a-icon :type="item.icon" />
              <span>{{ item.title }}</span>
            </component>
          </a-menu-item>
        </a-menu>
      </div>
      <div
        style="display: inline-block; padding-top: 15px; padding-right: 30px"
      >
        <a-form-model-item>
          <a-select
            v-model="$i18n.locale"
            @change="localeChange()"
            :placeholder="$i18n.locale"
          >
            <a-select-option
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang"
            >
              <!-- {{ lang.toLowerCase() }} -->
              {{ printLangs[lang] }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </div>
      <div
        df
        fdr
        fic
        align="right"
        style="
          font-size: 9px;
          font-weight: 600;
          line-height: 0.9;
          margin-top: 10px;
        "
      >
        <!-- <div
          style="font-size:11px;margin-right:10px;font-weight:bold;margin-top:5px;color:#8f8f8f"
        >
          {{ timezone }} ( {{ country }} )
        </div> -->
        <router-link
          :to="{
            name: toRouterName,
          }"
          style="color: #ffffff"
        >
          <p>{{ storeName }}</p>
          <p>{{ userName }}</p>
        </router-link>
        <!-- <a-popover placement="bottom">
          <template slot="content">
            <p>{{ updateDate }}</p>
          </template>
          <a-icon
            v-if="levelCheck === '0'"
            :style="{
              color: `${Colors.sky}`,
              paddingLeft: `16px`,
            }"
            type="sync"
            @click="updateRefreshConfirm"
          />
        </a-popover> -->
        <a-icon
          v-if="this.role !== 'Master'"
          :style="{
            color: `${Colors.grayDark}`,
            paddingLeft: `16px`,
          }"
          type="setting"
          @click="settingLayoutMove"
        />

        <a-icon
          :style="{
            color: `${Colors.red}`,
            paddingLeft: `16px`,
          }"
          type="logout"
          @click="logoutConfirm"
        />
      </div>
    </a-layout-header>

    <a-layout>
      <a-layout-content class="layout-content" df fdr>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import Colors from "@/utils/colors";

import AuthApi from "@/api/auth";
import HotelApi from "@/api/admin/hotel";

import jwt from "jsonwebtoken";
import { i18n } from "@/i18n";

export const menus = [
  {
    name: "Admin_Dashboard",
    title: i18n.tc("views.layout.admin.menus.dashboard"),
    icon: "dashboard",
    children: [],
  },
  {
    name: "Admin_Hotel",
    title: i18n.tc("views.layout.admin.menus.hotel"),
    icon: "container",
    children: [],
  },
  {
    name: "Admin_PromotionBanner",
    title: i18n.tc("views.layout.admin.menus.promotion"),
    icon: "profile",
    children: [],
  },
  {
    name: "Admin_Tablet",
    title: i18n.tc("views.layout.admin.menus.devices"),
    icon: "tablet",
    children: [],
  },
  {
    name: "Admin_QR",
    title: i18n.tc("views.layout.admin.menus.qr"),
    icon: "qrcode",
    children: [],
  },
  {
    name: "Admin_Service",
    title: i18n.tc("views.layout.admin.menus.services"),
    icon: "appstore",
    children: [],
  },
  {
    name: "Admin_Order",
    title: i18n.tc("views.layout.admin.menus.orders"),
    icon: "shopping",
    children: [],
  },
  {
    name: "Admin_OverallStatistics",
    title: i18n.tc("views.layout.admin.menus.statistics"),
    icon: "unordered-list",
    children: [],
  },
  {
    name: "Admin_StaffManagement",
    title: i18n.tc("views.layout.admin.menus.staff"),
    icon: "user",
    children: [],
  },
  {
    name: "Admin_ConnectionLog",
    title: i18n.tc("views.layout.admin.menus.userLog"),
    icon: "bar-chart",
    children: [],
  },
  {
    name: "Admin_Message",
    title: i18n.tc("views.layout.admin.menus.messages"),
    icon: "message",
    children: [],
  },

  {
    name: "Admin_Notice",
    title: i18n.tc("views.layout.admin.menus.notice"),
    icon: "form",
    children: [],
  },
];

export const level2Menus = [
  {
    name: "Admin_Dashboard",
    title: i18n.tc("views.layout.admin.menus.dashboard"),
    icon: "dashboard",
    children: [],
  },
  {
    name: "Admin_Service",
    title: i18n.tc("views.layout.admin.menus.services"),
    icon: "appstore",
    children: [],
  },
  {
    name: "Admin_Order",
    title: i18n.tc("views.layout.admin.menus.orders"),
    icon: "shopping",
    children: [],
  },
  {
    name: "Admin_OverallStatistics",
    title: i18n.tc("views.layout.admin.menus.statistics"),
    icon: "unordered-list",
    children: [],
  },
];

@Component({
  components: {},
})
export default class AdminLayout extends Vue {
  @Prop({ default: () => [] })
  selectedKeys;
  timezone = localStorage.getItem("timezone");
  country = localStorage.getItem("country");

  levelCheck = localStorage.getItem("level");
  hotelIndex = this.$route.params.key;
  Colors = Colors;

  role = "";
  storeName = "";
  userName = "";
  collapsed = false;

  menus = menus;
  toRouterName = "";

  updateDate = "";

  langs = ["ko", "en"];
  printLangs = {
    ko: "한국어",
    en: "English",
  };

  setMenus() {
    this.menus = [
      {
        name: "Admin_Dashboard",
        title: i18n.tc("views.layout.admin.menus.dashboard"),
        icon: "dashboard",
        children: [],
      },
      {
        name: "Admin_Hotel",
        title: i18n.tc("views.layout.admin.menus.hotel"),
        icon: "container",
        children: [],
      },
      {
        name: "Admin_PromotionBanner",
        title: i18n.tc("views.layout.admin.menus.promotion"),
        icon: "profile",
        children: [],
      },
      {
        name: "Admin_Tablet",
        title: i18n.tc("views.layout.admin.menus.devices"),
        icon: "tablet",
        children: [],
      },
      {
        name: "Admin_QR",
        title: i18n.tc("views.layout.admin.menus.qr"),
        icon: "qrcode",
        children: [],
      },
      {
        name: "Admin_Service",
        title: i18n.tc("views.layout.admin.menus.services"),
        icon: "appstore",
        children: [],
      },
      {
        name: "Admin_Order",
        title: i18n.tc("views.layout.admin.menus.orders"),
        icon: "shopping",
        children: [],
      },
      {
        name: "Admin_OverallStatistics",
        title: i18n.tc("views.layout.admin.menus.statistics"),
        icon: "unordered-list",
        children: [],
      },
      {
        name: "Admin_StaffManagement",
        title: i18n.tc("views.layout.admin.menus.staff"),
        icon: "user",
        children: [],
      },
      {
        name: "Admin_ConnectionLog",
        title: i18n.tc("views.layout.admin.menus.userLog"),
        icon: "bar-chart",
        children: [],
      },
      {
        name: "Admin_Message",
        title: i18n.tc("views.layout.admin.menus.messages"),
        icon: "message",
        children: [],
      },
      {
        name: "Admin_Notice",
        title: i18n.tc("views.layout.admin.menus.notice"),
        icon: "form",
        children: [],
      },
    ];
  }

  setL2Menus() {
    this.menus = [
      {
        name: "Admin_Dashboard",
        title: i18n.tc("views.layout.admin.menus.dashboard"),
        icon: "dashboard",
        children: [],
      },
      {
        name: "Admin_Service",
        title: i18n.tc("views.layout.admin.menus.services"),
        icon: "appstore",
        children: [],
      },
      {
        name: "Admin_Order",
        title: i18n.tc("views.layout.admin.menus.orders"),
        icon: "shopping",
        children: [],
      },
      {
        name: "Admin_OverallStatistics",
        title: i18n.tc("views.layout.admin.menus.statistics"),
        icon: "unordered-list",
        children: [],
      },
    ];
  }

  async mounted() {
    const token = await localStorage.getItem("token");
    //this.$i18n.locale = await localStorage.getItem("locale");
    this.locale = localStorage.getItem("locale") ?? "ko";
    if (this.levelCheck === "2") {
      this.setL2Menus();
    }
    if (this.levelCheck === "0" || this.levelCheck === "1") {
      this.setMenus();
      // this.menus.push({
      //   name: "Admin_StaffManagement",
      //   title: i18n.tc("views.layout.admin.menus.staff"),
      //   icon: "user",
      //   children: [],
      // });
    }
    const decoded = jwt.decode(token);

    await this.loadRefresh();

    this.role = decoded.role;
    this.userName = decoded.UserName;
    this.storeName = decoded.StoreName;

    if (this.role === "Master") {
      this.toRouterName = "Dashboard";
    } else {
      this.toRouterName = "Admin_Dashboard";
    }
  }

  settingLayoutMove() {
    this.$router.push({ name: "Admin_Setting" });
  }

  logoutConfirm() {
    this.$confirm({
      title: i18n.tc("views.layout.admin.logout"),
      onOk: this.logout,
    });
  }

  async localeChange() {
    await localStorage.setItem("locale", this.$i18n.locale);
    if (this.levelCheck === "2") {
      this.setL2Menus();
    }
    if (this.levelCheck === "0" || this.levelCheck === "1") {
      this.setMenus();
      // this.menus.push({
      //   name: "Admin_StaffManagement",
      //   title: i18n.tc('views.layout.admin.menus.staff'),
      //   icon: "user",
      // });
    }
  }

  async logout() {
    try {
      const token = await localStorage.getItem("token");

      const decoded = jwt.decode(token);

      await AuthApi.Logout(decoded.nameid);
      await localStorage.removeItem("token");

      this.$router.replace({ name: "Login" });
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async loadRefresh() {
    try {
      const { data } = await HotelApi.RefreshLoad(this.hotelIndex);

      this.updateDate = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  updateRefreshConfirm() {
    this.$confirm({
      title: i18n.tc("views.layout.admin.appReboot"),
      content: i18n.tc("views.layout.admin.appRebootContent"),
      onOk: this.updateRefresh,
    });
  }

  async updateRefresh() {
    try {
      await HotelApi.RefreshUpdate(this.hotelIndex);

      await this.loadRefresh();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss">
.ant-modal-confirm-content {
  white-space: pre-line;
}

.admin-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../public/img/icons/logo/05_02.png");
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}

.category-group {
  width: 256px;
  border-right: #efefef 1px solid;

  .category-group-item {
    padding: 16px 12px;
    cursor: pointer;
    transition: all 0.275s;

    &:not(.use) {
      color: #cfcfcf;
    }

    &:hover {
      color: #1890ff;
    }

    &.on {
      background-color: #e6f7ff;
      color: #1890ff;
    }
  }
}
</style>
