<template>
  <div class="order">
    <div class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.order.title")
        }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background" df fdc>
      <a-space direction="vertical">
        <div df fdr fjb>
          <a-space>
            <a-space>
              <a-date-picker
                @change="load({})"
                v-model="filter.startDate"
                :disabled-date="disabledStartDate"
              />
              <span>~</span>
              <a-date-picker
                @change="load({})"
                v-model="filter.endDate"
                :disabled-date="disabledEndDate"
              />
            </a-space>

            <hotel-list @change="load({})" v-model="filter.hotelIndex" />

            <a-button @click="load({})">{{
              $t("views.master.order.search")
            }}</a-button>
          </a-space>

          <a-button @click="excelDownLoad">{{
            $t("views.master.order.downloadExcel")
          }}</a-button>
        </div>

        <div style="padding: 10px 0px">
          <div :style="{ borderBottom: '1px solid #E9E9E9' }">
            <a-checkbox
              :indeterminate="indeterminate"
              :checked="checkAll"
              @change="onCheckAllChange"
            >
              {{ $t("views.master.order.all") }}
            </a-checkbox>
          </div>
          <br />
          <a-checkbox-group
            :value="checkedList"
            :options="baseServices"
            @change="onChange"
          />
        </div>

        <a-radio-group
          v-model="filter.status"
          button-style="solid"
          @change="load({})"
        >
          <a-radio-button value>{{
            $t("views.master.order.all")
          }}</a-radio-button>
          <a-radio-button
            v-for="(value, key) in status"
            :key="key"
            :value="key"
          >
            {{ value }}
          </a-radio-button>
        </a-radio-group>

        <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="load"
          :loading="loading"
        >
          <a-tooltip slot="registeredTime" slot-scope="_, record">
            <template slot="title">
              {{
                record.registeredTime
                  ? moment(record.registeredTime).fromNow()
                  : ""
              }}
            </template>
            <div>
              {{ moment(record.registeredTime).format("YYYY-MM-DD") }}
            </div>
          </a-tooltip>

          <template slot="time" slot-scope="_, record">
            <div>
              {{ moment(record.registeredTime).format("HH:mm") }}
            </div>
          </template>

          <template slot="status" slot-scope="_, record">
            {{ orderStatusView(record.status) }}
          </template>

          <template slot="serviceName" slot-scope="_, record">
            <div>
              {{ serviceNameFilter(record.serviceCode) }}
              ({{ record.serviceName }})
            </div>
          </template>

          <template slot="payStatus" slot-scope="_, record">
            <div>
              {{ payStatusView(record.payStatus) }}
            </div>
          </template>

          <template slot="details" slot-scope="_, record">
            <a-button @click="detailLoad(record.index)">
              {{ $t("views.master.order.detail") }}
            </a-button>
          </template>
        </a-table>
      </a-space>
    </div>

    <order-detail-modal
      :visible="visible"
      :detailData="detailData"
      :orderDetailData="orderDetailData"
      :handleOk="handleOk"
      :status="status"
    />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import XLSX from "xlsx";

import OrderApi from "@/api/shared/order";
import HotelApi from "@/api/master/hotel";
import EnumInfoApi from "@/api/enumInfo";

import { Filter } from "@/utils/filterValue";
import {
  GmtConversion,
  GmtConversionReverse,
  StartDate,
  EndDate,
} from "@/utils/gmtConversion";

import HotelList from "@/components/HotelList";
import OrderDetailModal from "@/components/modals/OrderDetailModal";
import { OrderColumns } from "@/utils/columns/master/order";

import { i18n } from "@/i18n";

@Component({
  components: {
    HotelList,
    OrderDetailModal,
  },
})
export default class Order extends Vue {
  moment = moment;

  loading = false;

  totalOrderKeys = [];

  get columns() {
    const colums = OrderColumns();
    return colums;
  }
  status = {};

  @Filter
  filter = {
    startDate: moment().subtract(1, "w"),
    endDate: moment(),
    hotelIndex: "",
    status: "",
    serviceCodes: [],
  };

  data = [];

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  };

  visible = false;

  detailData = [];
  orderDetailData = [];

  baseServices = [];
  checkedList = [];
  totalCheckedList = [];
  indeterminate = false;
  checkAll = true;
  
  async mounted() {
    await this.loadBasicService();

    await this.load({});
    await this.enumInfoLoad();
  }

  async load({ current = 1, pageSize = 10 }) {
    try {
      this.loading = true;

      this.filter.serviceCodes = this.checkedList.join(",");

      const { data, option } = await OrderApi.List({
        current,
        pageSize,
        ...this.filterValue,
        "filter.startDate": GmtConversionReverse(
          StartDate(this.filter.startDate, "day"),
        ),
        "filter.endDate": GmtConversionReverse(
          EndDate(this.filter.endDate, "day"),
        ),
      });

      this.data = data.list.map(({ registeredTime, ...item }) => {
        return {
          ...item,
          registeredTime: GmtConversion(registeredTime),
        };
      });

      const total = data.total;
      this.totalOrderKeys = option;

      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
        total,
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.order.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async enumInfoLoad() {
    try {
      const status = await EnumInfoApi.List("order_status");
      const parseData = JSON.parse(status.data);

      this.status = parseData[0].Data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.order.errorMsg");

      this.$message.error(error);
    }
  }

  async loadBasicService() {
    try {
      this.loading = true;

      const list = await HotelApi.BasicServiceList({ isCall: true });

      this.baseServices = list.map((item) => ({
        value: item.code,
        label: item.name,
      }));

      this.baseServices.push({
        value: "SV_DELIVERY_ITEM",
        label: i18n.tc("views.master.order.roomDelivery"),
      });

      this.checkedList = this.baseServices.map((item) => item.value);
      this.totalCheckedList = this.baseServices.map((item) => item.value);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.order.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async detailLoad(index) {
    try {
      this.loading = true;

      this.detailData = await OrderApi.Detail(index);

      this.visible = true;
      if (this.detailData.orderItems && this.detailData.orderItems.length > 0) {
        this.orderDetailData = this.detailData.orderItems;
      } else {
        this.orderDetailData = [];
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.order.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  disabledStartDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.endDate ? value.isAfter(this.filter.endDate) : false)
    );
  }

  disabledEndDate(value) {
    return (
      value.isAfter(moment()) ||
      (this.filter.startDate ? value.isBefore(this.filter.startDate) : false)
    );
  }

  handleOk(e) {
    this.visible = false;
  }

  async onChange(checkedList) {
    if (checkedList.length === 0) {
      this.$message.warning(i18n.tc("views.master.order.selectServiceMsg"));
      return;
    }

    this.checkedList = checkedList;
    this.indeterminate =
      !!checkedList.length && checkedList.length < this.baseServices.length;
    this.checkAll = checkedList.length === this.baseServices.length;

    await this.load({});
  }

  onCheckAllChange(e) {
    Object.assign(this, {
      checkedList: e.target.checked
        ? this.totalCheckedList
        : this.totalCheckedList.length > 0
        ? [this.totalCheckedList[0]]
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });

    this.load({});
  }

  async excelDownLoad() {
    try {
      if (this.totalOrderKeys.length > 0) {
        const { data } = await OrderApi.ExcelDownload(this.totalOrderKeys);
        const ex1 = i18n.tc("views.master.order.excel1");
        const ex2 = i18n.tc("views.master.order.excel2");
        const ex3 = i18n.tc("views.master.order.excel3");
        const ex4 = i18n.tc("views.master.order.excel4");
        const ex5 = i18n.tc("views.master.order.excel5");
        const ex6 = i18n.tc("views.master.order.excel6");
        const ex7 = i18n.tc("views.master.order.excel7");
        const ex8 = i18n.tc("views.master.order.excel8");
        const excelData = data.map((item) => {
          return {
            [ex1]: item.index,
            [ex2]: GmtConversion(item.registeredTime).format(
              "YYYY-MM-DD HH:mm",
            ),
            [ex3]: this.orderStatusView(item.status),
            [ex4]: item.hotelName,
            [ex5]: item.roomNumber,
            [ex6]: item.serviceName,
            [ex7]:
              // item.orderContent != null
              //   ? item.orderCount > 1
              //     ? item.orderContent + `외 ${item.orderCount - 1} 건`
              //     : item.orderContent
              item.orderContent != null
                ? item.orderCount > 1
                  ? item.orderContent +
                    i18n.tc("views.master.order.etc") +
                    " " +
                    `${item.orderCount - 1}` +
                    " " +
                    i18n.tc("views.master.order.ea")
                  : item.orderContent
                : "",
            [ex8]: item.amount,
          };
        });

        const today = `${moment(this.filter.startDate).format(
          "YYYY-MM-DD",
        )}∼${moment(this.filter.endDate).format("YYYY-MM-DD")} `;

        var dataWS = XLSX.utils.json_to_sheet(excelData);
        var wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(
          wb,
          dataWS,
          i18n.tc("views.master.order.excel7"),
        );
        XLSX.writeFile(
          wb,
          `${today + i18n.tc("views.master.order.excel7")}.xlsx`,
        );
      } else {
        this.$message.warning(i18n.tc("views.master.order.noOrderMsg"));
      }
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.order.failDownloadExcelMsg");

      this.$message.error(error);
    }
  }

  serviceNameFilter(value) {
    const serviceCode = this.baseServices.find((item) => item.value === value);

    return serviceCode.label;
  }

  orderStatusView(value) {
    return this.status[value];
  }

  payStatusView(value) {
    if(value == 0){
      return "미결제";
    }else if(value == 1){
      return "결제완료";
    }
  }
}
</script>
