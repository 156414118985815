import {i18n} from "@/i18n";
export function TabletColumns(sortedInfo) {
  return [
    {
      width: 100,
      align: "left",
      dataIndex: "room.number",
      title: i18n.tc('js.tablet.room'),
    },
    {
      width: 200,
      align: "right",
      dataIndex: "serialNumber",
      title: i18n.tc('js.tablet.serial'),
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      key: "capacity",
      align: "center",
      dataIndex: "capacity",
      title: i18n.tc('js.tablet.battery'),
      scopedSlots: { customRender: "capacity" },
      sortOrder: sortedInfo.columnKey === "capacity" && sortedInfo.order,
      sorter: true,
    },
    {
      key: "isCharging",
      align: "center",
      dataIndex: "isCharging",
      title: i18n.tc('js.tablet.charge'),
      scopedSlots: { customRender: "isCharging" },
      sortOrder: sortedInfo.columnKey === "isCharging" && sortedInfo.order,
      sorter: true,
    },
    {
      key: "lastRefreshTime",
      align: "right",
      dataIndex: "lastRefreshTime",
      title: i18n.tc('js.tablet.time'),
      scopedSlots: { customRender: "lastRefreshTime" },
      sortOrder: sortedInfo.columnKey === "lastRefreshTime" && sortedInfo.order,
      sorter: true,
    },
    {
      width: 120,
      dataIndex: "display",
      align: "center",
      title: i18n.tc('js.tablet.display'),
      scopedSlots: { customRender: "display" },
    },
    {
      width: 100,
      dataIndex: "orderTotal",
      align: "center",
      title: i18n.tc('js.tablet.order'),
    },
    {
      width: 140,
      align: "center",
      title: i18n.tc('js.tablet.orderHis'),
      dataIndex: "orderDetail",
      scopedSlots: { customRender: "orderDetail" },
    },
  ];
}
