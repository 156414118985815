import Dashboard from "@/views/sales/Dashboard.vue";
import Hotel from "@/views/sales/Hotel.vue";
import Order from "@/views/sales/Order.vue";
import SalesHotelEdit from "@/views/sales/hotel/Edit.vue";
import SalesHotelCreate from "@/views/sales/hotel/Create.vue";

export default [
    {
        path: "/sales/dashboard",
        name: "Sales_Dashboard",
        component: Dashboard,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/sales/hotel",
        name: "Sales_Hotel",
        component: Hotel,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/sales/order",
        name: "Sales_Order",
        component: Order,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/sales/hotel/edit",
        name: "SalesHotelEdit",
        component: SalesHotelEdit,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    },
    {
        path: "/sales/hotel/create",
        name: "SalesHotelCreate",
        component: SalesHotelCreate,
        meta: {
            title: route => {
            return "스테이오더 대리점";
            },
        },
    }
];

