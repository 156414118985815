import {i18n} from "@/i18n";
export function BasicServiceColumns(){ 
return [
  {
    width: "30px",
    dataIndex: "color",
    scopedSlots: { customRender: "color" },
  },
  {
    dataIndex: "icon",
    scopedSlots: { customRender: "icon" },
  },
  {
    dataIndex: "name",
    title: i18n.tc('js.basicService.serviceNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    dataIndex: "code",
    title: i18n.tc('js.basicService.code'),
    scopedSlots: { customRender: "code" },
  },
  {
    align: "center",
    dataIndex: "status",
    title: i18n.tc('js.basicService.status'),
    scopedSlots: { customRender: "status" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "isAlways",
    title: i18n.tc('js.basicService.alwaysOpen'),
    scopedSlots: { customRender: "isAlways" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "openTime",
    title: i18n.tc('js.basicService.openTime'),
    scopedSlots: { customRender: "openTime" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "closeTime",
    title: i18n.tc('js.basicService.closeTime'),
    scopedSlots: { customRender: "closeTime" },
  },
  {
    width: "120px",
    align: "center",
    title: i18n.tc('js.basicService.manageMent'),
    scopedSlots: { customRender: "multilingual" },
  },
];
}