var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service",attrs:{"df":"","fdr":""}},[_c('a-layout',[_c('a-layout-sider',{staticStyle:{"background":"#fff"},attrs:{"width":"256"}},[_c('a-menu',{attrs:{"mode":"inline","selected-keys":_vm.selectedKeys}},_vm._l((_vm.menus),function(item){return _c('a-menu-item',{key:item.index},[_c('router-link',{attrs:{"to":{
              name: 'Admin_Service',
              params: { index: item.index },
            }}},[_c('div',{attrs:{"df":"","fdr":"","fjb":""}},[_c('div',{attrs:{"df":"","fdr":"","fic":""}},[(item.code === 'Dashboard' ? true : false)?_c('a-icon',{attrs:{"type":"dashboard"}}):_vm._e(),(item.code === 'Dashboard' ? false : true)?_c('div',{staticClass:"icon-logo",style:({
                    backgroundImage: ("url('" + (item.icon) + "')"),
                  })}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])],1),(!item.status)?_c('span',{staticStyle:{"font-size":"9px","color":"#cfcfcf"}},[_vm._v(" "+_vm._s(_vm.$t("views.admin.service.disabled"))+" ")]):_vm._e()])])],1)}),1)],1),_c('a-layout',{staticStyle:{"padding":"16px 24px 24px 16px"}},[_c('a-layout-content',{style:({
          background: '#fff',
          margin: 0,
          height: '100%',
        })},[_c(_vm.content,{key:_vm.serviceIndex,tag:"component",attrs:{"serviceIndex":_vm.serviceIndex,"serviceCode":_vm.serviceCode,"serviceTime":_vm.serviceTime,"serviceMemo":_vm.serviceMemo,"serviceImages":_vm.serviceImage},on:{"childs-event":_vm.load}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }