<template>
  <a-modal
    :visible="visible"
    :closable="false"
    :title="categoryTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editCategoryForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div df fdr fje>
        <small>{{$t('components.modal.shop.korEngWarn')}}</small>
      </div>
      <div class="card-container">
        <language-tab
          ref="languageTabForm"
          :value="data"
          :isLanguageUsed="isLanguageUsed"
          :tabs="tabs"
          :name="$t('components.modal.shop.categoryNm')"
        >
        </language-tab>
      </div>

      <a-form-model-item prop="isServiceTime" :label="$t('components.modal.shop.timeSetting')">
        <a-radio-group button-style="solid" v-model="data.isServiceTime">
          <a-radio-button @click="operatingTypeInit(true)" :value="true"
            >{{$t('components.modal.shop.timeContents')}}</a-radio-button
          >
          <a-radio-button @click="operatingTypeInit(false)" :value="false"
            >{{$t('components.modal.shop.individualTime')}}</a-radio-button
          >
        </a-radio-group>

        <div df fdr fjb style="margin-top:6px;margin-bottom:6px">
          <div df fdc fic>
            {{$t('components.modal.shop.openTime')}}
            <a-time-picker
              format="HH:mm"
              v-model="data.openTime"
              :disabled="data.isServiceTime"
            >
            </a-time-picker>
          </div>
          <div df fjc fic>
            ~
          </div>
          <div df fdc fic>
            {{$t('components.modal.shop.endTime')}}
            <a-time-picker
              format="HH:mm"
              v-model="data.closeTime"
              :disabled="data.isServiceTime"
            >
            </a-time-picker>
          </div>
        </div>
      </a-form-model-item>

      <a-form-model-item prop="status" :label="$t('components.modal.shop.state')">
        <a-radio-group button-style="solid" v-model="data.status">
          <a-radio-button value="1">{{$t('components.modal.shop.use')}}</a-radio-button>
          <a-radio-button value="0">{{$t('components.modal.shop.unuse')}}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeEditCategory">{{$t('components.modal.shop.cancel')}}</a-button>
      <a-button
        v-if="levelCheck === '1' ? false : true"
        type="primary"
        :loading="loadingEditCategory"
        @click="save"
        >{{$t('components.modal.shop.save')}}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import Tabs from "@/utils/languageTab";
import LanguageTab from "@/components/LanguageTab";
import { TimeSaveFunc } from "@/utils/timeFunc";
import {i18n} from "@/i18n";
import CategoryApi from "@/api/admin/category";

@Component({
  components: { LanguageTab },
})
export default class ShopCategoryAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;
  @Prop({ default: undefined }) serviceIndex;
  @Prop({ default: undefined }) serviceTime;

  tabs = Tabs;
  moment = moment;
  labelCol = { span: 6 };
  wrapperCol = { span: 18 };

  loadingEditCategory = false;

  closeEditCategory() {
    this.$emit("close");
  }

  operatingTypeInit(isServiceTime) {
    if (isServiceTime) {
      this.data.isServiceTime = true;

      this.data.openTime = moment(this.serviceTime.openTime || "0000", "HHmm");
      this.data.closeTime = moment(
        this.serviceTime.closeTime || "0000",
        "HHmm",
      );
    } else {
      this.data.isServiceTime = false;

      this.data.openTime = "0000";
      this.data.closeTime = "0000";
    }
  }

  isLanguageUsed(key) {
    if (key === "Kr" || key === "En") {
      return true;
    }
  }

  async saveEditCategory() {
    try {
      this.loadingEditCategory = true;
      if (!this.data.information.En.name) {
        this.$refs.languageTabForm.tabDefaultKeyChange();
        return this.$message.warning(i18n.tc('components.modal.shop.writeEngCtNm'));
      }

      const time = TimeSaveFunc(this.data.openTime, this.data.closeTime);

      const category = {
        status: this.data.status,
        storeIndex: Number(this.$route.params.key),
        openTime: time.openTime,
        closeTime: time.closeTime,
        information: this.data.information,
      };

      if (this.data.index) {
        await CategoryApi.Update(this.data.index, category);
      } else {
        await CategoryApi.Create(this.serviceIndex, category);
      }

      this.closeEditCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditCategory = false;
    }
  }

  get rules() {
    return {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [`information.${key}.name`]: [
            {
              required: this.isLanguageUsed(key),
              message: i18n.tc('components.modal.shop.writeCtNm'),
            },
          ],
        }),
        {},
      ),
      status: [
        {
          required: true,
          message: i18n.tc('components.modal.shop.selectState'),
        },
      ],
    };
  }

  get categoryTitle() {
    if (this.levelCheck === "1") {
      return i18n.tc('components.modal.shop.categoryInfo');
    } else {
      return this.data?.index ? i18n.tc('components.modal.shop.editCtInfo') : i18n.tc('components.modal.shop.addCtInfo');
    }
  }

  async save() {
    this.$refs.editCategoryForm.validate(async valid => {
      if (valid) {
        await this.saveEditCategory();
      }
    });
  }
}
</script>
