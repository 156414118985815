<template>
  <a-popover placement="bottom" v-model="statusVisible" trigger="click">
    <div slot="content" df fdc>
      <a @click="itemStatusChange('1')">{{$t('components.service.sale')}}</a>
      <a @click="itemStatusChange('0')">{{$t('components.service.noSale')}}</a>
      <a @click="itemStatusChange('2')">{{$t('components.service.soldout')}}</a>
    </div>
    <a>
      <a-icon style="margin-left:6px;color:rgb(9 168 204)" type="down-square" />
    </a>
  </a-popover>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

import ProductApi from "@/api/admin/product";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class ItemStatusPopover extends Vue {
  @Prop() itemIndex;

  statusVisible = false;

  async itemStatusChange(status) {
    try {
      await ProductApi.ProductStatusChange(Number(this.itemIndex), {
        status: status,
      });

      await this.$emit("load");
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.statusVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped></style>
