<template>
  <a-modal
    width="1200px"
    height="300px"
    :visible="visible"
    :closable="false"
    :title="$t('components.modal.item.manageImgUpload')"
    :afterClose="afterClose"
  >
    <div class="all-box" :class="isAll ? 'blue' : ''" @click="handleAll">
      <a-icon type="check-circle" /> {{ $t('components.modal.item.all') }}
    </div>
    <div df fdr>
      <a-space direction="vertical" flex style="padding: 10px 20px 10px 0px">
        <a-row>
          <a-col
            v-for="(item, index) in data.uploadedUrl"
            :key="index"
            :span="6"
          >
            <upload
              dir-name="itemService"
              v-model="data"
              listType="picture-card"
              :multiple="true"
              :isAddBtn="true"
              :disabled="item && item !== 'ADD' ? true : false"
            >
              <div v-if="item !== 'ADD'" class="sub-image-expand">
                <div
                  :style="{
                    backgroundImage: `url('${item}')`,
                  }"
                >
                  <div df fdr fjc fic>
                    <div class="background" @click="itemClick(item)"></div>
                    <div style="z-index: 100">
                      <a-icon
                        class="image-viewer"
                        type="eye"
                        @click="handlePreview(item)"
                      />

                      <a-icon
                        style="font-size: 18px; font-weight: bold; padding: 5px"
                        class="image-close"
                        type="delete"
                        @click="imageDelete(item)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="item !== 'ADD'"
                class="check-container"
                :class="
                  checkItem(item)
                    ? isMain(item)
                      ? 'main-active'
                      : 'active'
                    : ''
                "
              >
                <a-icon type="check-circle" />
              </div>

              <div v-if="item === 'ADD'" class="sub-image-expand">
                <div df fdc fjc fic>
                  <a-icon :type="'plus'" />
                  <span> {{$t('components.modal.upload')}} </span>
                </div>
              </div>
            </upload>
          </a-col>
        </a-row>
      </a-space>
    </div>

    <div style="font-size: 14px; color: #db0000; font-weight: bold">
      {{ $t('components.modal.item.uploadEx1') }}
    </div>
    <div style="font-size: 14px; color: #db0000; font-weight: bold">
      {{ $t('components.modal.item.uploadEx2') }}
    </div>

    <div slot="footer">
      <a-button @click="doCopy" type="primary">
        <a-icon type="copy" />{{ $t('components.modal.item.clipboard') }}
      </a-button>
      <a-button @click="closeEditItem" type="primary" :loading="loadingEditItem"
        >{{ $t('components.modal.item.close') }}</a-button
      >
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Draggable from "vuedraggable";
import ImageApi from "@/api/image";
import {i18n} from "@/i18n";

import Upload from "@/components/Upload";

@Component({
  components: { Upload, Draggable },
})
export default class ImageUploadModal extends Vue {
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;

  drag = false;
  dragOptions = {
    animation: 200,
    group: "description",
    disabled: false,
    ghostClass: "ghost",
  };

  loadingEditItem = false;
  previewVisible = false;
  previewImage = "";
  selectImages = [];
  isAll = false;

  handleAll() {
    if (this.data.uploadedUrl.length == 1) {
      this.$message.warning(i18n.tc('components.modal.item.uploadImgWarn'));
      return;
    }

    this.isAll = !this.isAll;
    if (this.isAll) {
      this.selectImages = this.data.uploadedUrl.filter((a) => a !== "ADD");
    } else {
      this.selectImages = [];
    }
  }

  closeEditItem() {
    this.isAll = false;
    this.selectImages = [];
    this.$emit("close");
  }

  checkItem(value) {
    return this.selectImages.some((a) => a == value);
  }

  isMain(value) {
    return this.selectImages.indexOf(value) == 0;
  }

  itemClick(value) {
    if (this.checkItem(value)) {
      this.selectImages = this.selectImages.filter((item) => item !== value);
    } else {
      this.selectImages.push(value);
    }

    this.isAll =
      this.selectImages.length ==
      this.data.uploadedUrl.filter((a) => a !== "ADD").length
        ? true
        : false;
  }

  handleCancel() {
    this.previewVisible = false;
  }

  async handlePreview(file) {
    this.previewImage = file;
    this.previewVisible = true;
  }

  async doCopy() {
    try {
      if (this.selectImages.length > 0) {
        await this.$copyText(this.selectImages);
        this.selectImages = [];
        this.isAll = false;
        this.$message.success(i18n.tc('components.modal.item.succCopy'));
      } else {
        this.$message.warning(i18n.tc('components.modal.item.selectImg'));
      }
    } catch (e) {
      this.$message.warning(i18n.tc('components.modal.item.failCopy'));
    }
  }

  async imageDelete(value) {
    try {
      this.data.uploadedUrl = this.data.uploadedUrl.filter(
        (item) => item !== value,
      );

      this.selectImages = this.selectImages.filter((a) => a !== value);
    } catch (e) {}
  }
}
</script>

<style lang="scss" scoped>
.all-box {
  cursor: pointer;

  &.blue {
    color: #1890ff;
  }
}
.sub-image-expand {
  position: relative;
  height: 200px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;

    > div {
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      cursor: pointer;
      opacity: 0;

      &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.image-close {
  font-size: 20px;
  padding: 2px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.image-viewer {
  font-size: 20px;
  padding: 3px;
  color: #adcdff;

  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.ant-space-item {
  overflow: auto;
  height: 550px;
}

.ant-upload-select-picture-card {
  .ant-upload {
    .check-container {
      position: absolute;
      left: 10px;
      top: 10px;
      color: lightgray;
      &.main-active {
        color: red;
      }

      &.active {
        color: #1890ff;
      }
    }
  }
}
</style>
