<template>
  <a-upload
    :list-type="listType"
    :file-list="files"
    :custom-request="customRequest"
    @change="handleChange"
    :multiple="multiple"
    :showUploadList="false"
    class="upload-list-inline"
    accept="image/*"
    :before-upload="beforeUpload"
    :disabled="disabled"
  >
    <slot />
  </a-upload>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import Client from "@/api/client";
import ImageApi from "@/api/image";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class Upload extends Vue {
  @Prop({ default: "Icon" }) dirName;
  @Prop({ default: "picture" }) listType;
  @Prop({ default: false }) multiple;
  @Prop({ default: false }) selected;
  @Prop({ default: false }) isAddBtn;
  @Prop({ default: false }) disabled;
  @Prop({ default: "" }) value;

  files = [];
  async customRequest({ file, onSuccess, onError }) {
    try {
      const { uploadUrl, uploadedUrl } = await ImageApi.SignedUrl(
        this.dirName,
        file.name,
      );

      await Client.form(uploadUrl, file);

      onSuccess(uploadedUrl);
    } catch (ex) {
      console.log(ex);
      onError(ex);
    }
  }

  async handleChange(info) {
    const status = info.file.status;

    if (status === "uploading") {
      if (this.selected) {
        this.files = [info.file];
      } else {
        this.files = [info.file, ...this.files];
        this.files = this.files.slice(-this.value.imageLengthLimit);
      }
    }

    if (status === "done") {
      this.$message.success(`${info.file.name} file uploaded successfully.`);

      if (this.selected) {
        this.value.uploadedUrl = [info.file.response];
      } else {
        this.value.uploadedUrl = [
          ...this.value.uploadedUrl,
          info.file.response,
        ];

        if (this.isAddBtn) {
          this.value.uploadedUrl = this.value.uploadedUrl.filter(
            (item) => item !== "ADD",
          );

          this.value.uploadedUrl.push("ADD");
        }
      }
      await this.$emit("input", this.value);
    } else if (status === "error") {
      this.$message.error(`${info.file.name} file upload failed.`);
    }
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      this.$message.error("You can only upload JPG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.$message.error(i18n.tc('components.etc.uploadEx1'));
    }

    return isJpgOrPng && isLt2M;
  }
}
</script>

<style scoped>
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.upload-list-inline >>> .ant-upload.ant-upload-select-picture-card {
  width: 100%;
}
</style>
