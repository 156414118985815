import Dashboard from "@/views/master/Dashboard.vue";
import Hotel from "@/views/master/Hotel.vue";
import Tablet from "@/views/master/Tablet.vue";
import Account from "@/views/master/Account.vue";
import Order from "@/views/master/Order.vue";
import SummaryStatistics from "@/views/master/SummaryStatistics.vue";
import BasicService from "@/views/master/BasicService.vue";
import Notice from "@/views/master/Notice.vue";
import Sales from "@/views/master/Sales.vue";

import HotelCreate from "@/views/master/hotel/Create.vue";
import HotelEdit from "@/views/master/hotel/Edit.vue";
import SalesEdit from "@/views/master/sales/Edit.vue";
import SalesCreate from "@/views/master/sales/Create.vue";
import TabletEdit from "@/views/master/tablet/Edit.vue";
import QrEdit from "@/views/master/qr/Edit.vue";
import AccountEdit from "@/views/master/account/Edit.vue";
import BasicServiceEdit from "@/views/master/basicService/Edit.vue";
import NoticeCreate from "@/views/master/notice/Create.vue";
import NoticeEdit from "@/views/master/notice/Edit.vue";

export default [
  {
    path: "/master/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/sales",
    name: "Sales",
    component: Sales,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/sales/create",
    name: "SalesCreate",
    component: SalesCreate,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },

  {
    path: "/master/hotel",
    name: "Hotel",
    component: Hotel,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/hotel/create",
    name: "HotelCreate",
    component: HotelCreate,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/hotel/edit/:index",
    name: "HotelEdit",
    component: HotelEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/sales/edit/:index",
    name: "SalesEdit",
    component: SalesEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/basicService",
    name: "BasicService",
    component: BasicService,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/basicService/edit/:index",
    name: "BasicServiceEdit",
    component: BasicServiceEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/tablet",
    name: "Tablet",
    component: Tablet,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/tablet/create/:hotelIndex?",
    name: "TabletCreate",
    component: TabletEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/qr/create/:hotelIndex?",
    name: "QrCreate",
    component: QrEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/tablet/edit/:index",
    name: "TabletEdit",
    component: TabletEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/account",
    name: "Account",
    component: Account,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/account/create/:level:hotelIndex?",
    name: "AccountCreate",
    component: AccountEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/account/edit/:index",
    name: "AccountEdit",
    component: AccountEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/order",
    name: "Order",
    component: Order,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/summaryStatistics",
    name: "SummaryStatistics",
    component: SummaryStatistics,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/notice",
    name: "Notice",
    component: Notice,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/notice/create",
    name: "NoticeCreate",
    component: NoticeCreate,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
  {
    path: "/master/notice/edit/:index",
    name: "NoticeEdit",
    component: NoticeEdit,
    meta: {
      title: route => {
        return "스테이오더 마스터";
      },
    },
  },
];
