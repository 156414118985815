import moment from "moment";

export function Filter(target, key, descriptor) {
  Object.defineProperty(target, "filterValue", {
    get: function() {
      return Object.keys(this[key]).reduce((_result, _key) => {
        let value = this[key][_key];

        if (moment.isMoment(value)) {
          value = moment(value).format("YYYY-MM-DD");
        }

        return {
          ..._result,
          [`filter.${_key}`]: value,
        };
      }, {});
    },
  });

  return descriptor;
}
