<template>
  <div>
    <a-tabs size="small" default-active-key="Kr" v-model="tabDefaultKey">
      <a-tab-pane v-for="tab in tabs" :key="tab.key">
        <div
          df
          fdr
          fic
          slot="tab"
          class="hotel-edit-language-tab"
          :class="{ on: isLanguageUsed(tab.key) }"
        >
          {{ tab.title }}
        </div>

        <a-form-model-item
          v-if="name ? true : false"
          :prop="`information.${tab.key}.name`"
          :label="name"
        >
          <a-input v-model="value.information[tab.key].name" />
        </a-form-model-item>

        <a-form-model-item
          v-if="address ? true : false"
          :prop="`information.${tab.key}.address`"
          :label="address"
        >
          <a-button v-if="tab.key === 'Kr'" @click="daumPostcodeOpen"
            >{{$t('components.etc.searchAdr')}}</a-button
          >
          <a-input v-model="value.information[tab.key].address" />
        </a-form-model-item>

        <a-form-model-item
          v-if="rank ? true : false"
          :prop="`information.${tab.key}.rank`"
          :label="rank"
        >
          <a-input-number
            style="width: 120px"
            v-model="value.confHotel.rank"
            :min="0"
            :max="10000"
          >
          </a-input-number>
        </a-form-model-item>

        <a-form-model-item
          v-if="rooms ? true : false"
          :prop="`information.${tab.key}.rooms`"
          :label="rooms"
        >
          <a-input-number
            style="width: 120px"
            v-model="value.confHotel.rooms"
            :min="0"
            :max="10000"
          >
          </a-input-number>
        </a-form-model-item>

        <a-form-model-item
          v-if="description ? true : false"
          :prop="`information.${tab.key}.description`"
          :label="description"
        >
          <a-textarea
            v-model="value.information[tab.key].description"
            :rows="descriptionRows"
          />
        </a-form-model-item>

        <div class="example" v-if="editorDescription ? true : false">
          <quill-editor
            ref="myQuillEditor"
            v-model="value.information[tab.key].description"
            :options="editorOption"
          />
        </div>
      </a-tab-pane>
    </a-tabs>

    <daum-postcode
      :visible="daumPostcodeVisible"
      @close="daumPostcodeClose"
      @save="daumPostcodeSave"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import DaumPostcode from "@/components/modals/DaumPostcode";
import {i18n} from "@/i18n";

import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

@Component({
  components: { DaumPostcode, quillEditor },
})
export default class LanguageTab extends Vue {
  @Prop({ default: undefined }) value;
  @Prop({ default: undefined }) isLanguageUsed;
  @Prop({ default: undefined }) tabs;
  @Prop({ default: "" }) name;
  @Prop({ default: "" }) address;
  @Prop({ default: "" }) description;
  @Prop({ default: "" }) rank;
  @Prop({ default: "" }) rooms;
  @Prop({ default: 4 }) descriptionRows;
  @Prop({ default: "" }) editorDescription;

  tabDefaultKey = "Kr";

  daumPostcodeVisible = false;

  mounted() {
    this.tabDefaultKey = "Kr";
  }

  tabDefaultKeyChange() {
    this.tabDefaultKey = "En";
  }

  daumPostcodeOpen() {
    this.daumPostcodeVisible = true;
  }

  daumPostcodeClose() {
    this.daumPostcodeVisible = false;
  }

  daumPostcodeSave(value) {
    if (value) {
      this.value.information.Kr.address = value.address;
      this.value.latitude = String(value.location.lat);
      this.value.longitude = String(value.location.lng);
    }
  }

  editorOption = {
    placeholder: i18n.tc('components.etc.writeStoreDis'),
    modules: {
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
        ],
      },
    },
  };
}
</script>

<style lang="scss" scoped>
.example {
  display: flex;
  min-height: 330px;
  max-height: 500px;
  overflow: hidden;
  padding-bottom: 60px;

  .editor,
  .output {
    width: 50%;
  }

  $toolbar-height: 41px;

  .editor {
    padding-bottom: $toolbar-height;

    .ql-custom-button {
      width: 100px;
    }
  }

  .output {
    border: 1px solid #ccc;
    border-left: none;

    .title {
      border-bottom: 1px solid #ccc;
    }
  }
}
</style>
