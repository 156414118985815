<template>
  <div df fdr style="height: 100%">
    <div class="category-group">
      <div v-if="levelCheck !== '1'" style="padding: 16px" df fdr fjb fic>
        <div>{{$t('views.admin.services.category')}}</div>
        <div>
          <a-button
            @click="openEditCategory(false)"
            icon="plus"
            style="margin-right: 5px"
          />
          <a-button
            @click="openEditCategory(true)"
            icon="edit"
            style="margin-right: 5px"
          />
          <a-popconfirm
            :title="
              items && items.length > 0
                ? $t('views.admin.services.askDeleteCt')
                : $t('views.admin.services.askDeleteCt2')
            "
            :ok-text="$t('views.admin.services.delete')"
            :cancel-text="$t('views.admin.services.cancel')"
            @confirm="deleteCategory(selectedCategoryIndex)"
          >
            <a-button type="danger" ghost icon="delete" />
          </a-popconfirm>
        </div>
      </div>

      <title-list-draggable
        :list="categories"
        :listOrderChanged="categoryOrderChanged"
        :selectedKeys="selectedKeys"
        :selectItem="selectCategory"
        :isAlwaysUse="true"
      />
    </div>

    <div flex style="padding: 16px" df fdc>
      <a-space direction="vertical">
        <div df fdr fjb>
          <h1 style="font-size: 20px">{{ title }}</h1>

          <a-space v-if="selectedCategoryIndex && levelCheck !== '1'">
            <a-button @click="openEditItem()">{{$t('views.admin.services.addImg')}}</a-button>
          </a-space>

          <a-space v-if="selectedCategoryIndex && levelCheck === '1'">
            <a-button @click="openEditCategory(true)">{{$t('views.admin.services.categoryInfo')}}</a-button>
          </a-space>
        </div>

        <draggable-table
          style="width: 100%"
          v-if="selectedCategoryIndex && levelCheck !== '1'"
          v-model="items"
          :columns="columns"
          @dragChanged="itemOrderChanged"
          :drag-options="dragOptions"
        >
          <template v-slot:drag="{}">
            <drag-icon />
          </template>

          <template v-slot:image="{ record }">
            <a @click="openEditItem(record.index)">
              <div class="image-preview">
                <div
                  :style="{ backgroundImage: `url('${record.image}')` }"
                ></div>
              </div>
            </a>
          </template>

          <template v-slot:description="{ record }">
            <a @click="openEditItem(record.index)">
              <div class="text-style">
                {{ record.description }}
              </div>
            </a>
          </template>

          <template v-slot:delete="{ record }">
            <a-popconfirm
              :title="$t('views.admin.services.askDeleteSt')"
              :ok-text="$t('views.admin.services.delete')"
              :cancel-text="$t('views.admin.services.cancel')"
              @confirm="itemDelete(record.index)"
            >
              <a-button
                type="danger"
                shape="circle"
                icon="delete"
                ghost
                size="small"
              />
            </a-popconfirm>
          </template>
        </draggable-table>

        <a-table
          rowKey="index"
          style="width: 100%"
          v-if="selectedCategoryIndex && levelCheck === '1'"
          :data-source="items"
          :columns="columns"
        >
          <template slot="image" slot-scope="_, record">
            <a @click="openEditItem(record.index)">
              <div class="image-preview">
                <div
                  :style="{ backgroundImage: `url('${record.image}')` }"
                ></div>
              </div>
            </a>
          </template>

          <template slot="description" slot-scope="_, record">
            <a @click="openEditItem(record.index)">
              <div class="text-style">
                {{ record.description }}
              </div>
            </a>
          </template>
        </a-table>
      </a-space>
    </div>

    <image-category-add-modal
      :key="categoriesModalKey"
      :data="data"
      :visible="editCategory"
      :afterClose="afterCloseEditCategory"
      @close="closeEditCategory"
      :serviceIndex="serviceIndex"
    />

    <image-item-add-modal
      :data="itemData"
      :visible="editItem"
      :afterClose="afterCloseEditItem"
      :categoryIndex="selectedCategoryIndex"
      @close="closeEditItem"
      @load="selectCategory"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import TypeOfServiceApi from "@/api/admin/typeOfService";
import Tabs from "@/utils/languageTab";
import {ImageServiceColumns} from "@/utils/columns/admin/imageService";
import {ImageServiceReadColumns} from "@/utils/columns/admin/imageServiceRead";
import TitleListDraggable from "@/components/service/TitleListDraggable";

import DraggableTable from "@/components/DraggableTable";
import DragIcon from "@/components/DragIcon";
import ImageCategoryAddModal from "@/components/modals/ImageCategoryAddModal";
import ImageItemAddModal from "@/components/modals/ImageItemAddModal";
import {i18n} from "@/i18n";

@Component({
  components: {
    DraggableTable,
    DragIcon,
    ImageCategoryAddModal,
    ImageItemAddModal,
    TitleListDraggable,
  },
})
export default class ImageService extends Vue {
  @Prop({ default: "" }) serviceIndex;
  @Prop({ default: "" }) serviceCode;

  levelCheck = localStorage.getItem("level");

  // columns =
  //   this.levelCheck === "1" ? ImageServiceReadColumns : ImageServiceColumns;
    get columns() {
    const colums =this.levelCheck === "1" ? ImageServiceReadColumns() : ImageServiceColumns();
    return colums;
  }

  tabs = Tabs;
  moment = moment;

  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  categoriesModalKey = "key";

  categories = [];
  categoryIndex = "";
  editCategory = false;
  data = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
          },
        }),
        {},
      ),
    },
  };

  items = [];
  editItem = false;
  itemData = {
    uploadedUrl: [],
    description: "",
    linkUrl: "",
  };

  get selectedCategoryIndex() {
    return this.categoryIndex && Number(this.categoryIndex);
  }

  get selectedKeys() {
    return this.selectedCategoryIndex ? [this.selectedCategoryIndex] : [];
  }

  get title() {
    return (
      this.selectedCategoryIndex &&
      this.categories.find((a) => a.index === this.selectedCategoryIndex)
        .information.Kr.name
    );
  }

  async mounted() {
    await this.load();

    if (this.categories !== null && this.categories.length > 0) {
      this.categoryIndex = this.categories[0].index;
      await this.selectCategory(this.categories[0]?.index);
    }
  }

  async load(reset) {
    try {
      const { data } = await TypeOfServiceApi.CategoryList(this.serviceIndex);

      this.categories = data.map(({ information, ...item }) => ({
        ...item,
        name: information.Kr.name,
        information,
      }));

      if (!reset) {
        return;
      }

      if (!this.categories || !this.categories.length) {
        this.categoryIndex = null;
        return;
      }

      this.categoryIndex = this.categories[0].index;
      await this.selectCategory(this.categories[0]?.index);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async categoryOrderChanged() {
    try {
      await TypeOfServiceApi.CategoryOrder(this.serviceIndex, this.categories);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async deleteCategory(index) {
    try {
      await TypeOfServiceApi.CategoryDelete(index);

      await this.load(true);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async openEditCategory(isEdit) {
    try {
      this.editCategory = true;

      this.categoriesModalKey = "key";
      if (!isEdit) {
        return;
      }

      const { data } = await TypeOfServiceApi.CategoryDetail(
        this.selectedCategoryIndex,
      );

      this.data = {
        ...data,
        information: {
          ...this.tabs.reduce(
            (result, { key }) => ({
              ...result,
              [key]: {
                name: "",
                address: "",
                description: "",
              },
            }),
            {},
          ),
          ...data.information,
        },
      };

      this.categoriesModalKey = this.data.index;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  closeEditCategory() {
    this.editCategory = false;
  }

  async afterCloseEditCategory() {
    await this.load();

    this.data = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
            },
          }),
          {},
        ),
      },
    };

    this.categoriesModalKey = "key2";
  }

  async selectCategory(index) {
    try {
      if (index) {
        this.categoryIndex = index;
      }

      const { data } = await TypeOfServiceApi.CategoryImageList(
        Number(this.categoryIndex),
      );

      this.items = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async itemOrderChanged() {
    try {
      await TypeOfServiceApi.CategoryImageOrder(
        Number(this.categoryIndex),
        this.items,
      );
      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async openEditItem(index) {
    try {
      this.editItem = true;

      if (!index) {
        return;
      }

      const { data } = await TypeOfServiceApi.CategoryImageDetail(
        Number(index),
      );

      this.itemData = {
        index: data.index,
        uploadedUrl: [data.image],
        description: data.description,
        linkUrl: data.linkUrl,
      };
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async itemDelete(index) {
    try {
      await TypeOfServiceApi.CategoryImageDelete(Number(index));
      await this.selectCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  closeEditItem() {
    this.editItem = false;
  }

  afterCloseEditItem() {
    this.itemData = {
      uploadedUrl: [],
      description: "",
      linkUrl: "",
    };
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}

.image-preview {
  position: relative;
  height: 150px;
  width: 100%;

  &::after {
    padding-top: 100%;
    content: "";
  }

  > div {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    left: 0px;
    top: -5px;
    right: 0px;
    bottom: -5px;
  }
}

.text-style {
  display: inline-block;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1.2;
  text-align: left;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
