import {i18n} from "@/i18n";
export function TabletColumns(sortedInfo) {
  return [
    {
      width: 100,
      align: "right",
      dataIndex: "index",
      title: i18n.tc('js.tablet.tabletId'),
      scopedSlots: { customRender: "index" },
    },
    {
      width: 120,
      align: "right",
      dataIndex: "room",
      title: i18n.tc('js.tablet.roomNm'),
      scopedSlots: { customRender: "room" },
    },
    {
      dataIndex: "hotelName",
      title: i18n.tc('js.tablet.hotelNm'),
    },
    {
      width: 200,
      align: "right",
      dataIndex: "serialNumber",
      title: i18n.tc('js.tablet.serial'),
      scopedSlots: { customRender: "serialNumber" },
    },
    {
      width: 120,
      align: "center",
      dataIndex: "status",
      title: i18n.tc('js.tablet.status'),
      scopedSlots: { customRender: "status" },
    },
    {
      width: 120,
      align: "center",
      dataIndex: "knoxStatus",
      title: i18n.tc('js.tablet.nox'),
      scopedSlots: { customRender: "knoxStatus" },
    },
    {
      width: 120,
      align: "center",
      dataIndex: "display",
      title: i18n.tc('js.tablet.display'),
      scopedSlots: { customRender: "display" },
    },
    {
      width: 80,
      align: "right",
      dataIndex: "typeSt",
      title: i18n.tc('js.tablet.type'),
    },
    {
      key: "lastRefreshTime",
      width: 160,
      align: "right",
      dataIndex: "lastRefreshTime",
      title: i18n.tc('js.tablet.orderHis'),
      scopedSlots: { customRender: "lastRefreshTime" },
      sortOrder: sortedInfo.columnKey === "lastRefreshTime" && sortedInfo.order,
      sorter: true,
    },
    {
      width: 80,
      align: "center",
      scopedSlots: { customRender: "delete" },
    },
  ];
}
