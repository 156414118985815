<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{
          $t("views.master.hotel.title1")
        }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{
          $t("views.master.hotel.title2")
        }}</a-breadcrumb-item>
      </a-breadcrumb>

      <a-button>
        <router-link :to="{ name: 'SalesHotelCreate' }">{{
          $t("views.master.hotel.create.title")
        }}</router-link>
      </a-button>
    </div>
    <div class="main-background">
      <div df fdr fjb fic style="margin-bottom: 16px">
        <div>
          <div>
            <a-radio-group
              v-model="filter.status"
              button-style="solid"
              @change="changeFilterType(filter)"
            >
              <a-radio-button value>{{
                $t("views.master.hotel.all")
              }}</a-radio-button>
              <a-radio-button
                v-for="(value, key) in status"
                :key="key"
                :value="key"
              >
                {{ value }}
              </a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <a-input-search
          @change="changeFilterType(filter)"
          v-model="filter.keyword"
          :placeholder="$t('views.master.hotel.selectHotelNameAndAddress')"
          style="width: 260px"
        />
      </div>

      <div>
        <a-table
          rowKey="index"
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="changePage"
          :loading="loading"
        >
          <template slot="name" slot-scope="_, record">
            <a @click="moveHotelEdit(record.index)">
              {{ getLang(record).name || "-" }}
            </a>
          </template>

          <template slot="agent" slot-scope="_, record">
            {{ record.agent ? agentView(record.agent) : "" }}
          </template>

          <template slot="salesMan" slot-scope="_, record">
            {{ record.salesMan ? agentView(record.salesMan) : "" }}
          </template>

          <template slot="recomend" slot-scope="_, record">
            {{ record.recomend ? agentView(record.recomend) : "" }}
          </template>

          <template slot="address" slot-scope="_, record">
            {{ getLang(record).address || "-" }}
          </template>

          <template slot="status" slot-scope="_, record">
            <div
              df
              fdr
              fjc
              fic
              :style="{
                color:
                  record.status && record.status === '1'
                    ? ``
                    : `${Colors.grayDark}`,
              }"
            >
              <div>
                {{
                  record.status && record.status === "1"
                    ? $t("views.master.hotel.use")
                    : $t("views.master.hotel.noUse")
                }}
              </div>
            </div>
          </template>

          <template slot="detail" slot-scope="_, record">
            <a-button @click="moveHotelEdit(record.index)">
              {{ $t("js.hotel.detail") }}
            </a-button>
          </template>

          <a-popconfirm
            slot="delete"
            slot-scope="_, record"
            :title="$t('views.master.hotel.deleteHotelMsg')"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteSubmit(record.index)"
          >
            <a-button type="danger" shape="circle" icon="delete" />
          </a-popconfirm>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import HotelApi from "@/api/master/hotel";

import Colors from "@/utils/colors";
import { Filter } from "@/utils/filterValue";
import { HotelColumns } from "@/utils/columns/master/hotel";
import { i18n } from "@/i18n";

@Component({
  components: {},
})
export default class Hotel extends Vue {
  Colors = Colors;

  loading = true;

  get columns() {
    const colums = HotelColumns();
    return colums;
  }

  get status() {
    return {
      0: i18n.tc("views.master.hotel.noUse"),
      1: i18n.tc("views.master.hotel.use"),
    };
  }
  @Filter
  filter = {
    keyword: "",
    status: "",
  };

  data = [];
  total = 0;

  get pagination() {
    const { current, pageSize } = this.$route.query;
    return {
      current: current ? Number(current) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
      total: this.total,
    };
  }

  getLang(record) {
    return (
      (record.information &&
        (record.information.Kr || record.information.En)) ||
      {}
    );
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = ["current", "pageSize", "status", "keyword"];

    if (keys.some((key) => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  mounted() {
    this.load();
  }

  changePage({ current, pageSize, total }) {
    this.pagination.total = total;
    this.$router.push({
      query: {
        ...this.$route.query,
        current,
        pageSize,
      },
    });
  }

  changeFilterType({ keyword, status }) {
    this.$router.push({
      query: {
        keyword,
        status,
      },
    });
  }

  async load() {
    try {
      this.loading = true;

      const { current, pageSize, keyword, status } = this.$route.query;

      const pagination = {
        current: current ? Number(current) : 1,
        pageSize: pageSize ? Number(pageSize) : 10,
      };

      this.filter = {
        status: status ? status : "",
        keyword: keyword ? keyword : "",
      };

      const { list, total } = await HotelApi.List({
        ...pagination,
        ...this.filterValue,
      });

      this.data = list;
      this.total = total;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async deleteSubmit(index) {
    try {
      this.loading = true;
      await HotelApi.Delete(index);

      await this.load();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.master.hotel.errorMsg");

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async moveHotelAdmin(index) {
    try {
      const { information, confHotel } = await HotelApi.Detail(index);

      await localStorage.setItem("timezone", confHotel.timezone);
      await localStorage.setItem("country", confHotel.country);

      window.open(
        "http://" +
          location.host +
          "/admin/" +
          information.Kr.name +
          "/" +
          index +
          "/dashboard",
        "_blank",
      );

      // await this.$router.replace({
      //   name: "Admin_Dashboard",
      //   params: {
      //     key: Number(index),
      //     hotelName: String(information.Kr.name),
      //   },
      // });
    } catch {}
  }

  async moveHotelEdit(index) {
    await this.$router.push({
      name: "SalesHotelEdit",
      params: {
        index: String(index),
      },
    });
  }

  agentView(value) {
    return value.name + "(" + value.salesCode + ")"
  }
}
</script>
