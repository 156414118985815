<template>
  <a-modal
    :visible="visible"
    :closable="false"
    :title="categoryTitle"
    :afterClose="afterClose"
  >
    <a-form-model
      v-if="data"
      ref="editForm"
      :model="data"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item prop="groupName" :label="$t('components.modal.staff.groupNm')">
        <a-input v-model="data.groupName"/>
      </a-form-model-item>
    </a-form-model>

    <div slot="footer">
      <a-button @click="closeEditCategory">{{$t('components.modal.staff.cancel')}}</a-button>
      <a-button
        type="primary"
        :loading="loadingEditCategory"
        @click="save"
        >{{ data.groupId ? $t('components.modal.staff.save') : $t('components.modal.staff.add') }}</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import { TimeSaveFunc } from "@/utils/timeFunc";

import StaffGroupApi from "@/api/admin/staffGroup";
import {i18n} from "@/i18n";

@Component({
  components: { },
})
export default class StaffGroupAddModal extends Vue {
  levelCheck = localStorage.getItem("level");
  @Prop({ default: undefined }) data;
  @Prop({ default: false }) visible;
  @Prop({ default: undefined }) afterClose;

  moment = moment;
  labelCol = { span: 5 };
  wrapperCol = { span: 19 };

  loadingEditCategory = false;

  closeEditCategory() {
    this.$emit("close");
  }
  
  async saveEditCategory() {
    try {
      this.loadingEditCategory = true;

      const groupData = {
        groupName: this.data.groupName,
        storeId: Number(this.$route.params.key),
      };

      if (this.data.groupId) {
        await StaffGroupApi.Update(this.data.groupId, groupData);
      } else {
        await StaffGroupApi.Create(groupData);
      }

      this.closeEditCategory();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loadingEditCategory = false;
    }
  }

  get rules() {
    return {
      groupName: [
        {
          required: true,
          message: i18n.tc('components.modal.staff.groupNmMsg'),
        },
      ],
    };
  }

  get categoryTitle() {
    // if (this.levelCheck === "1") {
    //   return "카테고리 정보";
    // } else {
    //   return this.data?.index ? "부서관리 수정" : "부서관리 추가";
    // }
    return this.data?.index ? i18n.tc('components.modal.staff.editGroup') : i18n.tc('components.modal.staff.addGroup');
  }

  async save() {
    this.$refs.editForm.validate(async valid => {
      if (valid) {
        await this.saveEditCategory();
      }
    });
  }
}
</script>
