import Vue from "vue";
import VueI18n from "vue-i18n";
// import en from '../src/locales/en_US.json'
// import ko from '../src/locales/ko_KR.json'

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../src/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const localLang = localStorage.getItem("locale");

export const i18n = new VueI18n({
  locale: localLang ?? "ko",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});
