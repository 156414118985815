import {i18n} from "@/i18n";
export function ServiceColumns(){
  return [
  {
    align: "center",
    dataIndex: "name",
    title:  i18n.tc('js.service.serviceNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    align: "center",
    dataIndex: "receptionInterlock",
    title: i18n.tc('js.service.reception'),
    scopedSlots: { customRender: "receptionInterlock" },
  },
  {
    width: 80,
    align: "center",
    scopedSlots: { customRender: "delete" },
  },
];
}