<template>
  <div df fdr flex>
    <a-table
      flex
      rowKey="id"
      :columns="receptionColumns"
      :data-source="receptionList"
      @change="receptionLoad"
      :loading="loading"
    >
      <template slot="name" slot-scope="_, record">
        <a @click="showReceptionAddModal(true, record)">{{ record.name }}</a>
      </template>

      <a-popconfirm
        slot="delete"
        slot-scope="_, record"
        :title="$t('components.hotel.askDeleteRc')"
        ok-text="Yes"
        cancel-text="No"
        @confirm="receptionDelete(record.adminId)"
      >
        <a-button type="danger" shape="circle" icon="delete" />
      </a-popconfirm>
    </a-table>

    <reception-add-modal
      :data="receptionData"
      :visible="isReceptionAdd"
      :hotelIndex="hotelIndex"
      @close="closeReceptionAdd"
      @load="receptionLoad"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";

import HotelApi from "@/api/master/hotel";
import {i18n} from "@/i18n";

import {ReceptionColumns} from "@/utils/columns/master/reception";

import ReceptionAddModal from "@/components/modals/ReceptionAddModal";

@Component({
  components: { ReceptionAddModal },
})
export default class HotelReceptionForm extends Vue {
  @Prop({ default: undefined }) hotelIndex;

  loading = false;
  get receptionColumns() {
    const colums =ReceptionColumns();
    return colums;
  }

  receptionList = [];

  isReceptionAdd = false;
  receptionData = {
    id: "",
    name: "",
    password: null,
    receptionStores: [],
  };

  mounted() {
    this.receptionLoad();
  }

  async receptionLoad() {
    try {
      this.loading = true;
      const { data } = await HotelApi.ReceptionList(this.hotelIndex);

      this.receptionList = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async receptionDelete(index) {
    try {
      this.loading = true;

      await HotelApi.ReceptionDelete(index);
      await this.receptionLoad();
    } catch (e) {
      let error = e.response?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  showReceptionAddModal(edit, value) {
    this.isReceptionAdd = true;

    if (!edit) {
      return;
    }

    this.receptionData = {
      ...this.receptionList.find(item => item.adminId === value.adminId),
    };
  }

  closeReceptionAdd() {
    this.isReceptionAdd = false;

    this.receptionData = {
      id: "",
      name: "",
      password: null,
      receptionStores: [],
    };
  }
}
</script>

<style lang="scss" scoped></style>
