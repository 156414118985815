import axios from "axios";
export const _axios = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://test-admin-api.hihotelorder.com:43030/api/v1/"
      // ? "http://localhost:43030/api/v1/"
      : `https://admin-api.hihotelorder.com/api/v1/`,
      // : "http://test-admin-api.hihotelorder.com:43030/api/v1/",
});

export default class Client {
  static storeId = null;

  static async getAuthorizeHeader() {
    const token = await localStorage.getItem("token");

    return token || Client.storeId
      ? {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          ...(Client.storeId ? { StoreId: `${Client.storeId}` } : {}),
        }
      : {};
  }

  static async send({ url, method, params, data, isForm = false, headers }) {
    try {
      const res = await _axios.request({
        url,
        method,
        params,
        data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...(isForm
            ? {}
            : {
                ...(await Client.getAuthorizeHeader()),
              }),
          ...headers,
        },
      });

      return res.data;
    } catch (ex) {
      console.error(ex);

      throw ex;
    }
  }

  static async get(url, params) {
    return await Client.send({ method: "GET", url, params });
  }

  static async del(url, params, data) {
    return await Client.send({ method: "DELETE", url, data, params });
  }

  static async post(url, data, params) {
    return await Client.send({ method: "POST", url, data, params });
  }

  static async put(url, data, params) {
    return await Client.send({ method: "PUT", url, data, params });
  }

  static async form(url, file, method) {
    return await Client.send({
      method: method || "PUT",
      url,
      data: file,
      headers: {
        "Content-Type": file.type,
      },
      isForm: true,
    });
  }

  static async fileForm(url, file, method) {
    let formData = new FormData();
    formData.append("file", file);

    return await Client.send({
      url,
      method: method || "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  static toBinaryString(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = function() {
          try {
            resolve(reader.result);
          } catch (ex) {
            reject(ex);
          }
        };
        reader.readAsBinaryString(file);
      } catch (ex) {
        reject(ex);
      }
    });
  }

  static async fileDownload({ url, method, params, data }) {
    try {
      await _axios({
        url,
        method,
        params,
        data,
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...{
            ...(await Client.getAuthorizeHeader()),
          },
          "Content-Type": "application/json",
          responseType: "arraybuffer",
        },
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        const contentDisposition = response.headers["content-disposition"]; // 파일 이름
        let fileName = "HOTELORDER";

        if (contentDisposition) {
          const [fileNameMatch] = contentDisposition
            .split(";")
            .filter(str => str.includes("filename*"));
          if (fileNameMatch) {
            [, fileName] = fileNameMatch.split("=UTF-8''");
            fileName = decodeURI(fileName);
          }
        }

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${fileName}`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    } catch (ex) {
      console.error(ex);

      throw ex;
    }
  }
}
