<template>
  <div df fdr flex>
    <draggable-table
      flex
      :loading="loading"
      :columns="deliveryColumns"
      v-model="PUList"
      @change="pickUpLoad"
      @dragChanged="PUOrderChanged"
      :drag-options="dragOptions"
    >
      <template v-slot:drag="{}">
        <drag-icon />
      </template>

      <template v-slot:name="{ record }">
        <a @click="showPUEditModal(record.index)">{{ record.name }}</a>
      </template>

      <template v-slot:status="{ record }">
        {{ record.status ? $t('components.hotel.use') : $t('components.hotel.unuse') }}
      </template>

      <template v-slot:delete="{ record }">
        <a-popconfirm
          :title="$t('components.hotel.askDelete')"
          ok-text="Yes"
          cancel-text="No"
          @confirm="PUDelete(record.index)"
        >
          <a-button type="danger" shape="circle" icon="delete" />
        </a-popconfirm>
      </template>
    </draggable-table>

    <master-delivery-item-edit-modal
      :key="pickUpModalKey"
      :data="selectedPU"
      :visible="isPUEdit"
      @close="closePUEdit"
      @saveSubmit="savePUEdit"
    />
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import {i18n} from "@/i18n";

import PickUpApi from "@/api/shared/pickUp";
import Tabs from "@/utils/languageTab";
import {DeliveryColumns} from "@/utils/columns/master/delivery";
import { TimeSaveFunc, TimeShowFunc } from "@/utils/timeFunc";

import DraggableTable from "@/components/DraggableTable";
import DragIcon from "@/components/DragIcon";
import MasterDeliveryItemEditModal from "@/components/modals/MasterDeliveryItemEditModal";

@Component({
  components: { MasterDeliveryItemEditModal, DraggableTable, DragIcon },
})
export default class HotelPickUpForm extends Vue {
  @Prop({ default: undefined }) hotelIndex;

  drag = false;
  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  moment = moment;
  tabs = Tabs;
  loading = false;

  get deliveryColumns() {
    const colums = DeliveryColumns();
    return colums;
  }
  pickUpModalKey = "pickUp_key";

  PUList = [];

  isPUEdit = false;
  selectedPU = {
    information: {
      ...this.tabs.reduce(
        (result, { key }) => ({
          ...result,
          [key]: {
            name: "",
            description: "",
          },
        }),
        {},
      ),
    },
    uploadedUrl: [],
    phone: "",
    isAlways: true,
    openTime: "0000",
    minPrice: 0,
    isDeliveryPrice: 0,
    deliveryPrice: 0,
  };

  mounted() {
    this.pickUpLoad();
  }

  async pickUpLoad() {
    try {
      const { data } = await PickUpApi.List(this.hotelIndex);
      this.PUList = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async PUOrderChanged() {
    try {
      await PickUpApi.Order(this.hotelIndex, this.PUList);

      await this.pickUpLoad();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }

  async PUDelete(index) {
    try {
      this.loading = true;

      await PickUpApi.Delete(index);
      await this.pickUpLoad();
    } catch (e) {
      let error = e.response?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  showPUEditModal(index) {
    this.isPUEdit = true;
    this.pickUpModalKey = "delivery_key";

    if (!index) {
      return;
    }

    this.selectedPU = this.PUList.find((a) => a.index === index);

    const closeTimeValue = TimeShowFunc(this.selectedPU.closeTime);

    this.selectedPU = {
      ...this.selectedPU,
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
        ...this.selectedPU.information,
      },
      isAlways:
        this.selectedPU.openTime === "0000" &&
        this.selectedPU.closeTime === "0000",
      openTime: moment(this.selectedPU.openTime || "0000", "HHmm"),
      closeTime: moment(closeTimeValue || "0000", "HHmm"),
      uploadedUrl: [this.selectedPU.icon],
    };

    this.pickUpModalKey = this.selectedPU.index;
  }

  async closePUEdit() {
    this.isPUEdit = false;
    this.selectedPU = {
      information: {
        ...this.tabs.reduce(
          (result, { key }) => ({
            ...result,
            [key]: {
              name: "",
              description: "",
            },
          }),
          {},
        ),
      },
      uploadedUrl: [],
      phone: "",
      isAlways: true,
      openTime: "0000",
      closeTime: "0000",
      minPrice: 0,
      isDeliveryPrice: 0,
      deliveryPrice: 0,
    };

    this.pickUpModalKey = "pickUp_key2";
    await this.pickUpLoad();
  }

  async savePUEdit() {
    try {
      const time = TimeSaveFunc(
        this.selectedPU.openTime,
        this.selectedPU.closeTime,
      );

      const pickUpItem = {
        ...this.selectedPU,
        openTime: time.openTime,
        closeTime: time.closeTime,
        icon: this.selectedPU.uploadedUrl[0],
      };

      delete pickUpItem.uploadedUrl;

      if (pickUpItem.index) {
        await PickUpApi.Update(this.hotelIndex, pickUpItem);
      } else {
        await PickUpApi.Create(this.hotelIndex, pickUpItem);
      }

      await this.pickUpLoad();
      await this.closePUEdit();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped></style>
