import client from "../client";

export default class Product {
  static async ProductList(categoryID, options) {
    return await client.get(`admin/product/item/list/${categoryID}`, options);
  }

  static async Delete(itemID) {
    return await client.del(`admin/product/item/delete/${itemID}`);
  }

  static async Create(categoryID, data) {
    return await client.post(`admin/product/item/create/${categoryID}`, data);
  }

  static async Update(data) {
    return await client.put(`admin/product/item/update`, data);
  }

  static async Detail(itemIndex) {
    return await client.get(`admin/product/item/${itemIndex}`);
  }

  static async ProductOrder(categoryID, data) {
    return await client.put(`admin/product/item/order/${categoryID}`, data);
  }

  static async ProductStatusChange(itemID, data) {
    return await client.get(`admin/product/item/status/change/${itemID}`, data);
  }

  static async SubImageDelete(imageId) {
    return await client.del(`admin/product/item/image/${imageId}`);
  }

  static async OptionGroupCreate(itemId, data) {
    return await client.post(
      `admin/product/option/group/create/${itemId}`,
      data,
    );
  }

  static async OptionGroupUpdate(data) {
    return await client.put(`admin/product/option/group/update`, data);
  }

  static async OptionGroupList(itemId) {
    return await client.get(`admin/product/option/group/list/${itemId}`);
  }

  static async OptionGroupOrder(itemId, data) {
    return await client.put(`admin/product/option/group/order/${itemId}`, data);
  }

  static async OptionGroupDetail(groupId) {
    return await client.get(`admin/product/option/group/detail/${groupId}`);
  }

  static async OptionGroupDelete(groupId) {
    return await client.del(`admin/product/option/group/delete/${groupId}`);
  }

  static async OptionGroupItemCreate(groupId, data) {
    return await client.post(
      `admin/product/option/item/create/${groupId}`,
      data,
    );
  }

  static async OptionGroupItemUpdate(data) {
    return await client.put(`admin/product/option/item/update`, data);
  }

  static async OptionGroupItemList(groupId) {
    return await client.get(`admin/product/option/item/list/${groupId}`);
  }

  static async OptionGroupItemOrder(groupId, data) {
    return await client.put(`admin/product/option/item/order/${groupId}`, data);
  }

  static async OptionGroupItemDetail(optionId) {
    return await client.get(`admin/product/option/item/detail/${optionId}`);
  }

  static async OptionGroupItemDelete(optionId) {
    return await client.del(`admin/product/option/item/delete/${optionId}`);
  }

  static async ExcelUplad(categoryId, data) {
    return await client.fileForm(
      `admin/product/excelRead/${categoryId}`,
      data,
      "POST",
    );
  }
}
