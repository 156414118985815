import {i18n} from "@/i18n";
export function OverallManagementRumyColumns() { 
  return [
  {
    dataIndex: "icon",
    scopedSlots: { customRender: "icon" },
  },
  {
    dataIndex: "name",
    title: i18n.tc('js.manage.serviceNm'),
    scopedSlots: { customRender: "name" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "isRumy",
    title:  i18n.tc('js.manage.rumy'),
    scopedSlots: { customRender: "isRumy" },
  },
  {
    width: "400px",
    align: "center",
    dataIndex: "departmentIds",
    title:  i18n.tc('js.manage.rumyPart'),
    scopedSlots: { customRender: "departmentIds" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "isAlways",
    title: i18n.tc('js.manage.alwaysOpen'),
    scopedSlots: { customRender: "isAlways" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "openTime",
    title: i18n.tc('js.manage.openTime'),
    scopedSlots: { customRender: "openTime" },
  },
  {
    width: "180px",
    align: "center",
    dataIndex: "closeTime",
    title: i18n.tc('js.manage.closeTime'),
    scopedSlots: { customRender: "closeTime" },
  },
  {
    width: "120px",
    align: "center",
    title:  i18n.tc('js.manage.addMent'),
    scopedSlots: { customRender: "multilingual" },
  },
];
}