<template>
  <table class="draggable-table">
    <colgroup>
      <col :key="i" :width="item.width" v-for="(item, i) in columns" />
    </colgroup>

    <thead class="ant-table-thead">
      <tr>
        <th
          :key="i"
          :style="{ textAlign: item.align }"
          v-for="(item, i) in columns"
        >
          {{ item.title }}
        </th>
      </tr>
    </thead>

    <draggable
      tag="tbody"
      class="ant-table-tbody"
      :value="value"
      v-bind="dragOptions"
      @change="dragChanged"
      @start="drag = true"
      @end="drag = false"
    >
      <tr
        class="ant-table-row ant-table-row-level-0"
        v-for="item in value"
        :key="item.index"
      >
        <td
          :key="column.dataIndex"
          :style="{ textAlign: column.align }"
          v-for="column in columns"
        >
          <slot
            :name="column.scopedSlots && column.scopedSlots.customRender"
            v-bind:value="item[column.dataIndex]"
            v-bind:record="item"
          >
            {{
              column.customRender
                ? column.customRender(item[column.dataIndex], item)
                : item[column.dataIndex]
            }}
          </slot>
        </td>
      </tr>
    </draggable>
  </table>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";

@Component({
  components: {
    draggable,
  },
})
export default class DraggableTable extends Vue {
  @Prop({ default: () => ({}) }) dragOptions;
  @Prop({ default: () => [] }) value;
  @Prop({ default: () => [] }) columns;

  drag = false;

  dragChanged(e) {
    const { newIndex, oldIndex } = e.moved;
    if (newIndex !== oldIndex) {
      const temp = [...this.value];
      const item = temp.splice(oldIndex, 1)[0];
      temp.splice(newIndex, 0, item);

      this.$emit("input", temp);
    }

    this.$emit("dragChanged", e);
  }
}
</script>

<style lang="scss">
.draggable-table {
  width: 100%;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
}
</style>
