<template>
  <a-select
    :value="value"
    show-search
    option-filter-prop="children"
    :filter-option="filterOption"
    :placeholder="$t('components.etc.selectHotel')"
    style="width: 250px; margin-right: 8px"
    :loading="loading"
    @change="change"
  >
    <a-select-option v-if="all" value>{{$t('components.etc.allHotel')}}</a-select-option>
    <a-select-option v-for="item in list" :key="item.index" :value="item.index">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import HotelApi from "@/api/master/hotel";
import {i18n} from "@/i18n";

@Component({
  components: {},
})
export default class HotelList extends Vue {
  @Prop({ default: undefined }) value;
  @Prop({ default: true }) all;
  @Prop({ default: false }) isEmitName;
  @Prop({ default: false }) isEmitAppCode;

  loading = false;

  list = [];

  mounted() {
    this.loadHotels();
  }

  async change(value) {
    console.log("----------");
    console.log(value);
    console.log("------------");
    const hotelName = this.list.find(item => item.index === value);
    await this.$emit("input", value);
    await this.$emit("change", value);

    if (this.isEmitName) {
      await this.$emit("name", hotelName.name);
    }
    if(this.isEmitAppCode) {
      await this.$emit("appCode", hotelName.appCode);
    }
  }

  async loadHotels() {
    try {
      this.loading = true;
      this.list = await HotelApi.SimpleList();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.layout.admin.responseError');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
</script>
