<template>
  <a-modal
    :title="$t('components.modal.option.orderDetail')"
    :visible="visible"
    :closable="false"
    @ok="handleOk"
    @cancel="handleOk"
  >
    <a-space direction="vertical" class="order-details">
      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.hotelNm") }}</div>
        <div>
          {{ detailData.hotelName }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.room") }}</div>
        <div>
          {{ detailData.roomNumber }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.orderId") }}</div>
        <div>
          {{ detailData.index }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.regDate") }}</div>
        <div>
          {{
            gmtConversion(detailData.registeredTime).format("YYYY-MM-DD HH:mm")
          }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.state") }}</div>
        <div>
          {{ status[detailData.status] }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.service") }}</div>
        <div>
          {{ detailData.serviceName }}
        </div>
      </div>
    </a-space>

    <div df fdr class="table-title">
      <div flex="1">{{ $t("components.modal.option.state") }}</div>

      <div flex="3" df fje fie style="margin-right: 20px">
        {{ $t("components.modal.option.date") }}
      </div>
    </div>
    <div df fdc class="y-scroll">
      <div
        df
        fdr
        style="padding: 8px"
        v-for="(item, index) in detailData.orderStatusLog"
        :key="index"
      >
        <div flex="1">
          {{
            detailData.serviceCode == "SV_PICKUP_ITEM" && item.status == "4"
              ? $t("components.modal.option.waitingPickUp")
              : status[item.status]
          }}
        </div>
        <div flex="3" df fje fie>
          {{ gmtConversion(item.regDate).format("YYYY-MM-DD") }}
          {{ gmtConversion(item.regDate).format("HH:mm") }}
        </div>
      </div>
    </div>

    <div df fdr class="table-title">
      <div flex="3">{{ $t("components.modal.option.orderList") }}</div>

      <div flex="1" df fje fie>{{ $t("components.modal.option.amount") }}</div>

      <div flex="1" df fje fie>
        {{ $t("components.modal.option.pricePerA") }}
      </div>

      <div flex="1" df fje fie>{{ $t("components.modal.option.freeA") }}</div>

      <div flex="1" df fje fie>{{ $t("components.modal.option.pay") }}</div>
    </div>

    <div
      style="padding: 8px"
      v-for="item in orderDetailData"
      :key="item.index"
      :value="item.index"
      fdr
      df
    >
      <div flex="3">
        {{ item ? item.productName : "" }}
      </div>

      <div flex="1" df fje fie>
        {{ item ? item.quantity : "" }}
      </div>

      <div flex="1" df fje fie>
        {{ item ? Number(item.salePrice).toLocaleString() : "" }}
      </div>

      <div flex="1" df fje fie>
        {{ item ? item.maxQuantity : "" }}
      </div>

      <div flex="1" df fje fie>
        {{
          item
            ? Number(
                item.salePrice * (item.quantity - item.maxQuantity),
              ).toLocaleString()
            : ""
        }}
      </div>
    </div>

    <div style="padding: 2px 8px" df fdr v-if="detailData.deliveryPrice">
      <div flex="4" />
      <div flex="1" df fje fie style="font-weight: 400">
        {{ $t("components.modal.delivery.deliveryPrice") }}
      </div>
      <div flex="1" df fje fie style="font-weight: 400">
        {{ Number(detailData.deliveryPrice).toLocaleString() }}
      </div>
    </div>

    <div style="padding: 8px" df fdr>
      <div flex="4" />
      <div flex="1" df fje fie style="font-weight: 700">
        {{ $t("components.modal.option.sum") }}
      </div>
      <div flex="1" df fje fie style="font-weight: 700">
        {{ Number(detailData.amount).toLocaleString() }}
      </div>
    </div>

    <div class="table-title">
      <div flex="1">{{ $t("components.modal.option.payInfo") }}</div>
    </div>

    <div
      v-if="detailData.payInformation && detailData.payInformation.length > 0"
    >
      <div df fdr>
        <div flex="3">{{ $t("components.modal.option.authNo") }}</div>
        <div>
          {{ detailData.payInformation[0].authNo }}
        </div>
      </div>

      <div df fdr>
        <div flex="3">{{ $t("components.modal.option.payAmount") }}</div>
        <div fie>
          {{ Number(detailData.payInformation[0].amount).toLocaleString() }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.payType") }}</div>
        <div>
          {{ payTypeView(detailData.payInformation[0].payType) }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.cardName") }}</div>
        <div>
          {{ detailData.payInformation[0].cardName }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.acqName") }}</div>
        <div>
          {{ detailData.payInformation[0].acqName }}
        </div>
      </div>
    </div>

    <div
      v-if="
        roleCheck === 'Master' || roleCheck === 'Agent' || rollCheck === 'Sales'
      "
    >
      <div class="table-title">
        <div flex="1">{{ $t("components.modal.option.feeInfo") }}</div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.feeAgent") }}</div>
        <div>
          {{ Number(detailData.feeCompany).toLocaleString() }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.feeSales") }}</div>
        <div>
          {{ Number(detailData.feeSales).toLocaleString() }}
        </div>
      </div>

      <div df fdr>
        <div flex="1">{{ $t("components.modal.option.feeRecommend") }}</div>
        <div>
          {{ Number(detailData.feeRecommend).toLocaleString() }}
        </div>
      </div>
    </div>

    <div class="table-title">
      <div flex="1">{{ $t("components.modal.option.memo") }}</div>
    </div>

    <div>
      <textarea
        style="
          width: 100%;
          border-style: none;
          border: 1px solid #eee;
          padding: 8px;
        "
        cols="10"
        rows="10"
        :value="detailData.memo"
        readonly
      >
      </textarea>
    </div>

    <div slot="footer">
      <a-button type="primary" @click="handleOk">{{
        $t("components.modal.option.ok")
      }}</a-button>
    </div>
  </a-modal>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { GmtConversion } from "@/utils/gmtConversion";
import moment from "moment";

@Component({
  components: {},
})
export default class OrderDetailModal extends Vue {
  roleCheck = localStorage.getItem("role");
  @Prop({ default: false }) visible;
  @Prop({ default: [] }) detailData;
  @Prop({ default: [] }) orderDetailData;
  @Prop({ default: undefined }) handleOk;
  @Prop({ default: undefined }) timezone;
  @Prop({ default: {} }) status;

  moment = moment;

  async created() {
    console.log(this.detailData);
  }

  gmtConversion(value) {
    if (this.timezone) {
      return GmtConversion(value, this.timezone);
    } else {
      return GmtConversion(value);
    }
  }

  payTypeView(value) {
    // NORMAL:일반결제, ORI_CANCEL:취소 원거래, CANCEL:취소 결제
    if (value == "NORMAL") {
      return "일반결제";
    } else if (value == "ORI_CANCEL") {
      return "취소 원거래";
    } else if (value == "CANCEL") {
      return "취소 결제";
    } else {
      return "";
    }
  }

  async enumInfoLoad() {
    try {
      const status = await EnumInfoApi.List("order_status");
      const parseData = JSON.parse(status.data);

      this.status = parseData[0].Data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.admin.order.errorMsg");

      this.$message.error(error);
    }
  }
}
</script>

<style lang="scss" scoped>
.order-details {
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
}

.table-title {
  background-color: #eeeeee;
  padding: 8px;
  margin-top: 20px;
}

.y-scroll {
  white-space: nowrap;
  overflow-y: scroll;
  width: 100%;
  position: relative;
  height: 100px;
}
</style>
