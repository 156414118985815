<template>
  <div>
    <div class="main-background" style="padding:0;">
      <div style="height: 100%">
        <div df fdr style="height: 100%">
          <div class="category-group">
            <div style="padding: 16px" df fdr fjb fic>
              <div>{{ $t("views.admin.staffManagement.groupManage") }}</div>
              <div>
                <a-button
                  @click="openEditGroup(false)"
                  icon="plus"
                  style="margin-right: 5px"
                />
                <a-button
                  @click="openEditGroup(true)"
                  icon="edit"
                  style="margin-right: 5px"
                />
              </div>
            </div>

            <ul>
              <li
                df
                fdr
                fic
                v-for="g in groups"
                :key="g.groupId"
                :value="g.groupId"
              >
                <div
                  flex
                  class="category-group-item"
                  :class="{
                    on: selectedStaffGroupIndex == g.groupId,
                  }"
                  @click="selectStaffGroup(g.groupId)"
                >
                  {{ g.groupName }}
                </div>

                <a-popconfirm
                  @click="
                    e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  "
                  @confirm="() => deleteGroup(g.groupId)"
                  :title="$t('views.admin.staffManagement.askDeleteMem')"
                  ok-text="OK"
                  cancel-text="Cancel"
                  placement="right"
                >
                  <div
                    class="category-group-item"
                    :class="{
                      on: selectedStaffGroupIndex == g.groupId,
                    }"
                    style="color:#f00;"
                  >
                    <a-icon type="delete" />
                  </div>
                </a-popconfirm>
              </li>
            </ul>
          </div>

          <div class="category-group" style="border-right-color:black;">
            <div style="padding: 16px" df fdr fjb fic>
              <div>{{ $t("views.admin.staffManagement.member") }}</div>
              <div style="height:32px;"></div>
              <!-- <div>
                <a-button
                  @click="openEditGroup(false)"
                  icon="plus"
                  style="margin-right: 5px"
                />
              </div> -->
            </div>

            <ul>
              <li
                df
                fdr
                fic
                class="category-group-item use"
                v-for="s in staffInGroups"
                :key="s.staffId"
                :value="s.staffId"
              >
                <div flex>{{ s.staffName }}</div>

                <a-popconfirm
                  @confirm="() => deleteInGroup(s.staffId)"
                  :title="$t('views.admin.staffManagement.askDeleteMem')"
                  ok-text="OK"
                  cancel-text="Cancel"
                  placement="right"
                >
                  <div style="color:#f00;"><a-icon type="delete" /></div>
                </a-popconfirm>
              </li>
            </ul>
          </div>

          <div flex style="padding: 16px" df fdc>
            <a-space direction="vertical">
              <div df fdr fjb>
                <h1 style="font-size: 20px">
                  {{ $t("views.admin.staffManagement.userManage") }}
                </h1>
              </div>

              <!-- <status-radio-form
                :itemStatusCount="itemStatusCount"
                @change="selectStaffGroup()"
                @statusRadioFilter="statusRadioFilter"
              /> -->

              <div df fdr fjb fic style="margin-bottom: 8px">
                <a-input-search
                  @keyup="e => changeFilterType(e, filter)"
                  v-model="filter.keyword"
                  :placeholder="$t('views.admin.staffManagement.searchTitle')"
                  style="width: 260px"
                />

                <a-space v-if="selectedStaffGroupIndex">
                  <!-- <div v-if="roleCheck === 'Master'" df fdr fic>
                    <excel-form
                      :excelUpload="excelUpload"
                      :openExcelForm="openExcelForm"
                    />
                  </div> -->
                  <a-button @click="openEditItem()">{{
                    $t("views.admin.staffManagement.enroll")
                  }}</a-button>
                </a-space>
              </div>
              <a-table
                rowKey="staffId"
                style="width: 100%"
                :data-source="staffs"
                :columns="columns"
                :filterSearch="true"
              >
                <template slot="status" slot-scope="_, record">
                  <div
                    df
                    fdr
                    fjc
                    fic
                    :style="{
                      color: tableStatusColor(record.staffStatus),
                    }"
                  >
                    <div>
                      {{ tableStatusName(record.staffStatus) }}
                    </div>
                  </div>
                </template>

                <template slot="group" slot-scope="_">
                  <a-select
                    :value="_.groupId"
                    style="width: 80px;"
                    @change="value => changeItemGroup(_.staffId, value)"
                  >
                    <a-select-option value="">{{
                      $t("views.admin.staffManagement.none")
                    }}</a-select-option>
                    <a-select-option
                      v-for="g in groups"
                      :key="g.groupId"
                      :value="g.groupId"
                    >
                      {{ g.groupName }}
                    </a-select-option>
                  </a-select>
                </template>

                <template slot="id" slot-scope="_">
                  <a @click="openEditItem(_.staffId)">
                    {{ _.loginId }}
                  </a>
                </template>

                <template slot="name" slot-scope="_">
                  {{ _.staffName }}
                </template>

                <template slot="position" slot-scope="_">
                  {{ _.staffPosition }}
                </template>

                <template slot="phone" slot-scope="_">
                  {{ _.staffPhone }}
                </template>

                <template slot="delete" slot-scope="_">
                  <a-popconfirm
                    @confirm="() => deleteItem(_.staffId)"
                    :title="$t('views.admin.staffManagement.realDelete')"
                    :ok-text="$t('views.admin.staffManagement.delete')"
                    :cancel-text="$t('views.admin.staffManagement.cancel')"
                    placement="left"
                  >
                    <a-button type="danger">
                      <a-icon type="delete" />
                    </a-button>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-space>
          </div>
        </div>

        <staff-group-add-modal
          :key="categoryModalKey"
          :data="groupData"
          :visible="editStaffGroup"
          :afterClose="afterCloseEditStaffGroup"
          @close="closeEditStaffGroup"
        />

        <staff-add-modal
          :key="itemModalKey"
          :services="services"
          :groups="groups"
          :data="staffData"
          :originData="JSON.parse(JSON.stringify(staffData))"
          :visible="editItem"
          :afterClose="afterCloseEditItem"
          serviceType="staff"
          @close="closeEditItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";

import ServiceApi from "@/api/admin/service";
import StaffApi from "@/api/admin/staff";
import StaffGroupApi from "@/api/admin/staffGroup";
import CategoryApi from "@/api/admin/category";
import ProductApi from "@/api/admin/product";

import { Filter } from "@/utils/filterValue";
import { StaffColumns } from "@/utils/columns/admin/staff";

import DraggableTable from "@/components/DraggableTable";
import ExcelForm from "@/components/service/ExcelForm";
import StatusRadioForm from "@/components/service/StatusRadioForm";
import ItemStatusPopover from "@/components/service/ItemStatusPopover";
import ItemDeletePopconfirm from "@/components/service/ItemDeletePopconfirm";
import TitleListDraggable from "@/components/service/TitleListDraggable";
import DragIcon from "@/components/DragIcon";

import StaffGroupAddModal from "@/components/modals/StaffGroupAddModal";
import StaffAddModal from "@/components/modals/StaffAddModal";

import ItemDetail from "@/components/staff/ItemDetail";
import { i18n } from "@/i18n";

@Component({
  components: {
    DraggableTable,
    ExcelForm,
    StatusRadioForm,
    ItemStatusPopover,
    ItemDeletePopconfirm,
    TitleListDraggable,
    DragIcon,
    StaffGroupAddModal,
    StaffAddModal,
    ItemDetail,
  },
})
export default class Staff extends Vue {
  levelCheck = localStorage.getItem("level");
  roleCheck = localStorage.getItem("role");

  get content() {
    return "item-detail";
  }

  get columns() {
    const colums = StaffColumns();
    return colums;
  }
  categoryModalKey = "category_key";
  itemModalKey = "item_key";

  detail = false;
  moment = moment;

  itemStatusCount = {};

  @Filter
  filter = {
    keyword: "",
  };

  dragOptions = {
    animation: 200,
    disabled: false,
    ghostClass: "ghost",
  };

  staffGroupIndex = 0;
  editStaffGroup = false;

  itemIndex = 0;
  items = [];
  editItem = false;

  services = [];
  staffGroupData = [];
  staffAllData = [];
  staffData = [];
  hotelIndex = 0;

  groups = [];
  allStaffs = [];
  staffInGroups = [];
  staffs = [];

  groupData = {};
  staffData = {};

  get selectedStaffGroupIndex() {
    return this.staffGroupIndex && Number(this.staffGroupIndex);
  }

  get selectedKeys() {
    return this.selectedStaffGroupIndex ? [this.selectedStaffGroupIndex] : [];
  }

  async mounted() {
    await this.load();

    if (
      this.groups !== null &&
      this.groups.length > 0 &&
      this.$route.query.category === undefined
    ) {
      this.staffGroupIndex = this.groups[0].groupId;
      await this.selectStaffGroup();
    } else {
      await this.setRouter();
    }
  }

  userGroupName(groupId) {
    try {
      return this.groups.filter(item => item.groupId == groupId)[0].groupName;
    } catch (e) {
      return "-";
    }
  }

  async load(reset) {
    try {
      const hotelIndex = this.$route.params.key;
      this.hotelIndex = hotelIndex;

      const { keyword } = this.$route.query;

      this.filter = {
        keyword: keyword ? keyword : "",
      };

      this.services = await ServiceApi.List(hotelIndex);
      this.staffGroupData = await StaffGroupApi.List(hotelIndex);
      this.staffAllData = await StaffApi.List(hotelIndex);
      this.staffData = this.staffAllData.data.filter(
        item => item.staffName.indexOf(this.filter.keyword) > -1,
      );
      // API가 아닌 AllData로 불러서 Vue에서 필터링 하는 이유
      // >> AllData와 필터링 데이터 모두 API를 호출함으로써, 호출양이 늘어나기 때문에,
      // >> API 호출양을 줄이고자, Vue에서 필터링 한다.

      console.log(this.services);

      this.groups = this.staffGroupData.data.map(({ ...item }) => ({
        ...item,
      }));

      this.allStaffs = this.staffAllData.data.map(({ ...item }) => ({
        ...item,
        groupId: item.groupId ? item.groupId : "",
        appService: item.appService ? JSON.parse(item.appService) : [],
      }));

      this.staffs = this.staffData.map(({ ...item }) => ({
        ...item,
        groupId: item.groupId ? item.groupId : "",
        appService: item.appService ? JSON.parse(item.appService) : [],
      }));

      await this.selectStaffGroup();

      if (!reset) {
        return;
      }

      if (!this.groups || !this.groups.length) {
        this.staffGroupIndex = null;
        return;
      }

      this.staffGroupIndex = this.groups[0].index;
    } catch {}
  }

  async selectStaffGroup(index) {
    if (index) {
      this.staffGroupIndex = index;
    }

    this.staffInGroups = this.allStaffs.filter(
      item => item.groupId == this.staffGroupIndex,
    );
    console.log(this.staffInGroups);
  }

  closeEditStaffGroup() {
    this.editStaffGroup = false;
  }

  afterCloseEditStaffGroup() {
    this.load();
    this.groupData = {};
    this.categoryModalKey = "category_group";
  }

  openEditGroup(isEdit) {
    this.editStaffGroup = true;

    this.categoryModalKey = "category_group";
    if (!isEdit) {
      this.groupData = {
        ...this.groupData,
      };
      return;
    }

    const { ...groupData } = this.groups.find(
      a => a.groupId == this.selectedStaffGroupIndex,
    );

    this.groupData = {
      ...groupData,
    };
    this.categoryModalKey = this.groupData.groupId;
  }

  async deleteCategory(index) {
    try {
      await CategoryApi.Delete(index);

      await this.load(true);
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc("views.layout.admin.responseError");

      this.$message.error(error);
    }
  }

  async openEditItem(staffId) {
    if (staffId) {
      let data = this.staffs.filter(item => item.staffId == staffId)[0];
      this.staffData = {
        ...data,
        staffStatus: "1",
      };
    } else {
      this.staffData = {
        staffStatus: "1",
        groupId: "",
      };
    }

    this.editItem = true;
    this.itemModalKey = "item_key";
  }

  closeEditItem() {
    this.editItem = false;
  }

  afterCloseEditItem() {
    this.load();
    this.staffData = {
      staffStatus: "1",
      groupId: "",
    };

    this.itemModalKey = "item_key2";
  }

  @Watch("$route")
  routeChanged(oldValue, newValue) {
    const keys = ["keyword"];

    if (keys.some(key => oldValue.query[key] !== newValue.query[key])) {
      this.load();
    }
  }

  async setRouter() {
    if (this.$route.query.category !== undefined) {
      await this.selectStaffGroup(this.$route.query.category);
    }

    if (this.$route.query.item == undefined) {
      this.itemIndex = 0;
      this.detail = false;
    } else {
      this.detail = true;
      this.itemIndex = Number(this.$route.query.item);
    }
  }

  openExcelForm() {
    window
      .open(
        "https://hihotelorder.s3.ap-northeast-2.amazonaws.com/excel/%EC%95%84%EC%9D%B4%ED%85%9C_%EC%97%91%EC%85%80_%EC%96%91%EC%8B%9D_240705.xlsx",
        "_blank",
      )
      .focus();
  }

  async changeItemGroup(staffId, value) {
    let staffData = this.staffs.filter(item => item.staffId == staffId)[0];
    const data = {
      ...staffData,
      groupId: value ? value : null,
      appService: JSON.stringify(staffData.appService),
    };
    await StaffApi.Update(data.staffId, data);
    this.load();
  }

  async deleteGroup(groupId) {
    await StaffGroupApi.Delete(groupId);
    this.load();
  }

  async deleteItem(staffId) {
    await StaffApi.Delete(staffId);
    this.load();
  }

  async deleteInGroup(staffId) {
    let staffData = this.staffs.filter(item => item.staffId == staffId)[0];
    const data = { ...staffData, groupId: null };
    await StaffApi.Update(staffId, data);
    this.load();
  }

  changeFilterType(e, { keyword }) {
    console.log(e.keyCode);
    if (e.keyCode == 13) {
      this.$router.push({
        query: {
          keyword,
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
}
</style>
