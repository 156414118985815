<template>
  <div class="hotel">
    <div df fdr fjb fic class="bread-crumb-parent">
      <a-breadcrumb class="bread-crumb">
        <a-breadcrumb-item class="fontSub">{{ $t('views.master.notice.title1') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.notice.title2') }}</a-breadcrumb-item>
        <a-breadcrumb-item class="fontMain">{{ $t('views.master.notice.edit.title') }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="main-background">
      <div>
        <div style="margin-bottom: 30px" df fdr fic>
          <div style="width: 100px">{{ $t('views.master.notice.edit.subject') }}:</div>
          <a-input v-model="data.noticeTitle" />
        </div>

        <div style="height: 700px">
          <quill-editor
            style="height: 650px"
            ref="myQuillEditor"
            :options="editorOption"
            v-model="data.noticeContent"
          />
        </div>

        <div df fje>
          <a-button
            style="width: 100px"
            :loading="loading"
            type="primary"
            @click="submit"
            >{{$t('views.master.notice.edit.update')}}</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import NoticeApi from "@/api/shared/notice";
// import Tabs from "@/utils/languageTab";

import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import {i18n} from "@/i18n";

@Component({
  components: {
    quillEditor,
  },
})
export default class NoticeEdit extends Vue {
  // tabs = Tabs;
  noticeIndex = this.$route.params.index;
  loading = false;

  data = {};

  async mounted() {
    this.load();
  }

  async load() {
    try {
      this.loading = true;
      const data = await NoticeApi.Detail(this.noticeIndex);
      this.data = data;
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.master.notice.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  async submit() {
    if (!this.data.noticeTitle) {
      this.$message.error(i18n.tc('views.master.notice.create.subjectMsg'));
      return;
    }

    if (!this.data.noticeContent) {
      this.$message.error(i18n.tc('views.master.notice.create.contentMsg'));
      return;
    }

    try {
      this.loading = true;
      await NoticeApi.Update(this.noticeIndex, this.data);
      this.$router.back();
    } catch (e) {
      let error = e.response?.data?.data
        ? e.response.data.data
        : i18n.tc('views.master.notice.errorMsg');

      this.$message.error(error);
    } finally {
      this.loading = false;
    }
  }

  editorOption = {
    placeholder: i18n.tc('views.master.notice.noticeMsg'),
    modules: {
      imageDrop: true,
      imageResize: true,
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          ["bold", "italic", "underline", "strike"],
          [{ header: 1 }, { header: 2 }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
        ],
      },
    },
    theme: "snow",
    imageResize: {
      displayStyles: {
        backgroundColor: "black",
        border: "none",
        color: "white",
      },
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };
}
</script>

<style lang="scss" scoped></style>
