import {i18n} from "@/i18n";
const Tabs = [
  { key: "Kr", title: i18n.tc('js.langs.kr') },
  { key: "En", title: i18n.tc('js.langs.en') },
  { key: "Jp", title: i18n.tc('js.langs.jp') },
  { key: "Cn", title: i18n.tc('js.langs.cn') },
  { key: "Vn", title: i18n.tc('js.langs.vn') },
];

export default Tabs;
